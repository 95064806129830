import { useState } from "react";
import {
  Box,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { JoinedInitiativesTable } from "./JoinedInitiatives/JoinedInitiativesTable";
import { ReviewInitiativesTable } from "./ReviewInitiatives/ReviewInitiativesTable";
import { useReviewInitiatives, useEnrollments } from "@/hooks/api";
import { getInitiativesToReview } from "@/utils";

const initiativesTabs = ["Review", "Following"];

export const Initiatives = () => {
  const [selectedInitiativesTab, setSelectedInitiativesTab] = useState(
    initiativesTabs[0]
  );
  const { data: enrollments } = useEnrollments();
  const { reviewerData, ownerData } = useReviewInitiatives();
  const initiativesToReview = getInitiativesToReview(
    reviewerData ?? [],
    ownerData ?? []
  );

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 3,
        bgcolor: (theme) => theme.palette.background.default,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        overflow: "hidden",
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h6">My initiatives</Typography>
        <Box display="flex" flex={1} justifyContent="center">
          <ToggleButtonGroup exclusive value={selectedInitiativesTab}>
            {initiativesTabs.map((tab) => (
              <ToggleButton
                onClick={() => setSelectedInitiativesTab(tab)}
                value={tab}
                key={tab}
              >
                {`${tab} ${tab === "Review" ? initiativesToReview.length : enrollments?.length}`}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
      {selectedInitiativesTab === "Review" ? (
        <ReviewInitiativesTable initiatives={initiativesToReview} />
      ) : (
        <JoinedInitiativesTable enrollments={enrollments ?? []} />
      )}
    </Paper>
  );
};
