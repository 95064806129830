import { Container, Typography } from "@mui/material";
import { InitiativeList } from "@/components";
import { Consent } from "./Consent";
import { useSearchInitiatives } from "@/hooks/api";
import { TopSection } from "./TopSection";
import { Filters } from "../common/filters/Filters";

const InitiativesOverview = () => {
  const { data, isLoading, isFiltered, total, loadMore } =
    useSearchInitiatives();

  return (
    <>
      <TopSection />
      <Container>
        <Filters py={3}>
          <Typography variant="h6">Ongoing initiatives</Typography>
          <Typography color="text.secondary">({total})</Typography>
        </Filters>
        <InitiativeList
          initiatives={data ?? []}
          isFiltered={isFiltered}
          isLoading={isLoading}
          loadMore={loadMore}
        />
      </Container>

      <Consent />
    </>
  );
};

export { InitiativesOverview };
