import { SyntheticEvent } from "react";
import { Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useActivitiesState } from "@/state";


export default function Tabs() {
  const { currentTab, activityCounter, setState } = useActivitiesState();

  const handleChange = (_: SyntheticEvent, tab: string) => {
    setState({ currentTab: tab });
  };

  const counter = (value?: number) => `(${value ?? 0})`;

  return (
    <TabContext value={currentTab}>
      <TabList
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          value="contributions"
          label={`Contributions ${counter(activityCounter?.contributions)}`}
        />
        <Tab
          value="initiatives"
          label={`Initiatives ${counter(activityCounter?.initiatives)}`}
        />
        <Tab
          value="shoutouts"
          label={`Shout-outs ${counter(activityCounter?.shoutouts)}`}
        />
      </TabList>
    </TabContext>
  );
}
