import { useOffices } from "@/hooks/api";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
} from "@mui/material";

type Props = {
  value: string;
  setRegion: (region: string) => void;
};

export const RegionSelector = ({ value, setRegion }: Props) => {
  const { regions } = useOffices();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegion((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      <Typography variant="subtitle1">Region</Typography>
      <RadioGroup row value={value} onChange={handleChange}>
        {regions.map((region) => (
          <FormControlLabel
            key={region}
            value={region}
            control={<Radio size="small" />}
            label={region.toUpperCase()}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
