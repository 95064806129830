// SVGs
import Chapter from "@/assets/heros/chapter.svg";
import DiversityAndInclusion from "@/assets/heros/dei.svg";
import Marketing from "@/assets/heros/marketing.svg";
import PeopleDevelopment from "@/assets/heros/perople-development.svg";
import PracticeArea from "@/assets/heros/practice-area.svg";
import Recruitment from "@/assets/heros/recruitment.svg";
import System from "@/assets/heros/system.svg";
// types
import { Category } from "@/interface";
import { Guidance } from "@/interface/Guidance.ts";

enum CategoriesNames {
  Chapter = "Chapter",
  DiversityAndInclusion = "Diversity & Inclusion",
  Marketing = "Marketing",
  PeopleDevelopment = "People Development",
  PracticeArea = "Practice Area",
  Recruitment = "Recruitment",
  System = "System",
}

export const SVGS = new Map<string, string>([
  [CategoriesNames.Chapter, Chapter],
  [CategoriesNames.DiversityAndInclusion, DiversityAndInclusion],
  [CategoriesNames.Marketing, Marketing],
  [CategoriesNames.PeopleDevelopment, PeopleDevelopment],
  [CategoriesNames.PracticeArea, PracticeArea],
  [CategoriesNames.Recruitment, Recruitment],
  [CategoriesNames.System, System],
]);

export const TAGS = new Map<string, string[]>([
  [
    CategoriesNames.Chapter,
    [
      "Affiliation & events",
      "Knowledge building & sharing",
      "L&D support",
      "Venture & tech demo",
      "Domain related knowledge building",
    ],
  ],
  [
    CategoriesNames.DiversityAndInclusion,
    ["Social impact", "Sustainability", "DEI networks", "DEI role modeling"],
  ],
  [CategoriesNames.Marketing, ["BCG X blog contributions", "Marketing events"]],
  [
    CategoriesNames.PeopleDevelopment,
    ["Mentorship", "Teaming", "Role modeling", "Knowledge sharing"],
  ],
  [
    CategoriesNames.PracticeArea,
    [
      "PA affiliation & events",
      "PA knowledge building",
      "Contribution to new PA offers",
    ],
  ],
  [
    CategoriesNames.Recruitment,
    [
      "Candidate interviews",
      "Candidate prep calls",
      "Interview case development",
      "Summer intern support",
      "On campus events",
    ],
  ],
  [
    CategoriesNames.System,
    [
      "Office events",
      "New joiner onboarding",
      "Alumni relations",
      "Client visits & tours",
    ],
  ],
]);
const DEFAULT_DESCRIPTION = `Lorem ipsum dolor sit amet consectetur. Aenean sed suscipit semper mattis eget facilisi quis. 
   At morbi sit cursus dignissim imperdiet. Nibh est nibh metus sit in magna in neque. 
   Duis dui massa id facilisis euismod dui in nunc sit.`;

export const toCategories = (data: Category[] | undefined) => {
  return [...(data || [])].map((category) => ({
    ...category,
    description: category.description || DEFAULT_DESCRIPTION,
    tags: TAGS.get(category.name) || [],
    svg: SVGS.get(category.name),
  }));
};

export const actions = <T>(categories: T[], current: number) => {
  function next() {
    return current < categories.length ? ++current : current;
  }
  function prev() {
    return current > 0 ? --current : current;
  }
  function nextDisabled() {
    return current === categories.length - 1;
  }
  function prevDisabled() {
    return current === 0;
  }

  function steps() {
    return `${current + 1} of ${categories.length}`;
  }

  return { next, prev, nextDisabled, prevDisabled, steps };
};

export const DeiQ1: Guidance = {
  question: "Sparx distribution guidelines",
  scenario:
  `
  **1-2 SPARX**\n
  For **small** but **notable one-off contributions**, e.g. supporting an event, presenting in a lunch n learn\n

  **3-4 SPARX**\n
  Taking a **lead role** in an initiative or a longer term effort (e.g. leading an affiliation event, running a DEI network, supporting L&D)

  **5 SPARX**\n
  For **high effort contributions** to company priorities, showing repeat commitment (e.g. recruiting interviews, organizing large-scale initiatives)
  `,
  showToWhalesOnly: true
}

// DEI questions mock
export const DeiQ2: Guidance = {
  question:
    "Have you considered contributions colleagues have made relating to different citizenship categories?",
  scenario: 
  `
  - **System** (office affiliation event, alumni relations)
  - **Chapter** (chapter knowledge building & sharing, chapter offering evolution, chapter affiliation)
  - **Practice Area** (PA event organising, material creation)
  - **People Development** (mentoring jr. colleagues)
  - **Diversity & Inclusion** (e.g. women's network event)
  - **Marketing** (e.g. contributing to BCG X blog The Multiplier)
  - **Recruiting** (e.g. attending campus events, creating case studies)
  `,
  showToWhalesOnly: false
  };

export const DeiQ3: Guidance = {
  question:
    "Identify the individuals who have made significant but less noticeable contributions. How have their contributions impacted the team or project?",
  scenario: 'Hiroto, member of the Tokyo Office affiliation team, has been supporting office Lunch & Learn events by booking venues, sending out diary placeholders and reminders, arranging food orders in advance and creating the agenda for the event to be circulated. While his contributions do not occur during the event itself, they significantly contribute towards the smooth execution and growing popularity of it!',
  showToWhalesOnly: false
};

export const DeiQ4: Guidance = {
  question:
    "When was the last time you acknowledged someone outside of your regular team or project?",
  scenario:
    'During a recent X Starter week, multiple CT members from different chapters leaned in and supported the HR & Recruiting teams with running the "Case simulation" exercise as part of onboarding. Moreover, the Case Simulation Ambassador team had recently created & refined content to make the exercise possible. This enhanced the overall experience of the new joiners both through the task itself and by helping them meet additional colleagues during their first week.',
  showToWhalesOnly: false
};

export const DeiQ5: Guidance = {
  question:
    "Have you recently acknowledged someone for their efforts in fostering company culture or community?",
  scenario: 'Olivia started an initiative connecting new parents coming back from parental leave to share best practice and advice in how to navigate their "new normal". This has created a sense of community for members and a safe space where to ask questions!',
  showToWhalesOnly: false
};

export const DeiQ6: Guidance = {
  question:
    "Have you given Sparx to a teammate who is located in a different region/location?",
  scenario: 'Susie, (VA in Singapore) created learning materials to enhance and upskill the cohort. She then scheduled multiple sharebacks to present the materials and ultimately ensure VAs across X can benefit from the session! The VAs located in Dallas found the session very relevant for a case they are currently working on, highlighting the importance of global knowledge sharing & capability building.',
  showToWhalesOnly: false
};

export const DeiMock: Guidance[] = [DeiQ1, DeiQ2, DeiQ3, DeiQ4, DeiQ5, DeiQ6];
