import { AutoLinkNode, LinkNode } from "@lexical/link";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { TableNode, TableCellNode, TableRowNode } from "@lexical/table";
import { MarkNode } from "@lexical/mark";
import { ListNode, ListItemNode } from "@lexical/list";
import { LexicalCommand, createCommand } from "lexical";
import EditorTheme from "../themes/EditorTheme";

const PlaygroundNodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  MarkNode,
  // OverflowNode,
  // PollNode,
  // StickyNode,
  // ImageNode,
  // InlineImageNode,
  // MentionNode,
  // EmojiNode,
  // ExcalidrawNode,
  // EquationNode,
  // AutocompleteNode,
  // KeywordNode,
  // HorizontalRuleNode,
  // TweetNode,
  // YouTubeNode,
  // FigmaNode,
  // MarkNode,
  // CollapsibleContainerNode,
  // CollapsibleContentNode,
  // CollapsibleTitleNode,
  // PageBreakNode,
  // LayoutContainerNode,
  // LayoutItemNode,
];

export const initialConfig = {
  namespace: "Playground",
  nodes: [...PlaygroundNodes],
  onError: (error: Error) => {
    throw error;
  },
  theme: EditorTheme,
};

export const TOGGLE_EDIT_LINK_MENU: LexicalCommand<undefined> = createCommand();
export const LINK_SELECTOR = `[data-lexical-editor] a`;
export const OPEN_LINK_MENU_ID = "open-link-menu";
