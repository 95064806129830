import { Rewards, RewardsByCycle } from "@/interface";

export const getRewardsTextAndSubtext = (data: RewardsByCycle) => {
  const totalSenders = data.sparxSendersCount + data.shoutoutSendersCount;
  const totalShoutouts = data.totalShoutouts;
  const totalSparx = data.totalSparx;

  let rewardsText = "";
  let subtext = "Collect and view them now.";

  if (totalSenders === 1) {
    if (totalSparx > 0 && totalShoutouts > 0) {
      rewardsText = "1 person sent you Sparx or gave you a shoutout!";
    } else if (totalSparx > 0 && totalShoutouts === 0) {
      rewardsText = "1 person sent you Sparx!";
      subtext = "Collect and view it now.";
    } else if (totalSparx === 0 && totalShoutouts > 0) {
      rewardsText = "1 person sent you a shoutout!";
      subtext = "Collect and view it now.";
    }
  } else if (totalSenders > 1) {
    if (totalSparx > 0 && totalShoutouts > 0) {
      rewardsText = `${totalSenders} people sent you Sparx or gave you a shoutout!`;
    } else if (totalSparx > 0 && totalShoutouts === 0) {
      rewardsText = `${totalSenders} people sent you Sparx!`;
    } else if (totalSparx === 0 && totalShoutouts > 0) {
      rewardsText = `${totalSenders} people sent you a shoutout!`;
    }
  }

  return {
    rewardsText,
    subtext,
  };
};

// 1)  show open (lock) => status = 'LOCKED'
// 2)  show open (gift) => status = 'UNLOCKED' & (totalSparx > 0 || totalShoutouts > 0)
// 3)  skip => status = 'UNLOCKED' & totalSparx=  0 & totalShoutouts = 0
// 4)  view old => status = 'UNLOCKED' && hasBeenUnboxed

export const showButton = (reward: Rewards) => {
  const { status, totalSparx, totalShoutouts, hasBeenUnboxed } = reward;
  if (status === "LOCKED") return "open-lock";
  if (status === "UNLOCKED" && hasBeenUnboxed) return "view-old";

  // status will always be "UNLOCKED"
  if (totalSparx !== null && totalShoutouts !== null) {
    return totalSparx === 0 && totalShoutouts === 0 ? "skip" : "open-gift";
  }
};
