import { Rewards } from "@/interface";
import { useRewardsState } from "@/state";
import { Button } from "@mui/material";

type Props = {
  reward: Rewards;
};

export const ViewButton = ({ reward }: Props) => {
  const { setState } = useRewardsState();

  const onClick = () => {
    setState({ openCollectRewardsModal: "collect", symbol: reward.symbol });
  };
  return (
    <Button color="secondary" variant="outlined" onClick={onClick}>
      View
    </Button>
  );
};
