import { Theme } from "@mui/material/styles";

declare module "@mui/material/Dialog" {
  interface DialogProps {
    variant?: "primary" | "secondary" | "tertiary";
  }
}

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      // variants: [
      //   {
      //     props: { variant: "primary" },
      //     style: {
      //       ".MuiDialog-paper": {
      //         backgroundImage: theme.mixins.gradientCard,
      //         boxShadow: "0px 8px 100px 0px #00665266",
      //         ":before": {
      //           zIndex: 0,
      //           content: "''",
      //           inset: 0,
      //           padding: "2px",
      //           position: "absolute",
      //           borderRadius: "12px",
      //           background: theme.mixins.gradientModalBorder,
      //           mask: `
      //             linear-gradient(#fff 0 0) content-box,
      //             linear-gradient(#fff 0 0)
      //           `,
      //           WebkitMaskComposite: "xor",
      //           maskComposite: "exclude",
      //         },
      //       },
      //     },
      //   },
      //   {
      //     props: { variant: "secondary" },
      //     style: {
      //       ".MuiDialog-paper": {
      //         backgroundImage: theme.mixins.gradientGrey,
      //         border: `1px solid ${theme.palette.grey["A400"]}`,
      //       },
      //     },
      //   },
      //   {
      //     props: { variant: "tertiary" },
      //     style: {
      //       ".MuiDialog-paper": {
      //         background: theme.palette.background.paper,
      //         boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.16)",
      //         padding: `${theme.spacing(5)} ${theme.spacing(8)}`,
      //       },
      //     },
      //   },
      // ],
    },
    // MuiDialogTitle: {
    //   styleOverrides: {
    //     root: {
    //       padding: `${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(
    //         1
    //       )} ${theme.spacing(0)}`,
    //     },
    //   },
    // },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
  };
}
