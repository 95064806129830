import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";

const NoDataMessage = ({
  title,
  message,
  startIcon,
  endIcon,
  colSpan,
  buttonText,
  align = "center",
  buttonDisabled,
  buttonClick,
  noPadding = false,
  buttonVariant = "contained",
}: {
  title: string;
  message: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  colSpan?: number;
  buttonText?: string | null;
  buttonDisabled?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  buttonClick?: () => void;
  noPadding?: boolean;
  buttonVariant?: "text" | "outlined" | "contained";
}) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align={align}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={align}
          py={noPadding ? 0 : 10}
        >
          <Typography
            variant="h6"
            marginBottom={0.5}
            sx={(theme) => ({
              color: theme.palette.text.primary,
              width: "320px",
            })}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            marginBottom={2}
            maxWidth={420}
            color="text.secondary"
          >
            {message}
          </Typography>
          {buttonText && buttonText !== "" && (
            <Button
              startIcon={startIcon}
              endIcon={endIcon}
              variant={buttonVariant}
              color="primary"
              onClick={buttonClick}
              disabled={buttonDisabled}
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export { NoDataMessage };
