import { stringToColor } from "@/utils/avatars";
import { Avatar, AvatarGroup as MuiAvatarGroup } from "@mui/material";

type Props = {
  max?: number;
  avatars: string[];
};

export const AvatarGroup = ({ avatars, max = 3 }: Props) => {
  return (
    <MuiAvatarGroup
      max={max}
      sx={{
        "& .MuiAvatar-root": {
          width: 24,
          height: 24,
          fontSize: 12,
        },
      }}
    >
      {avatars.map((r, i) => {
        return (
          <Avatar
            key={`${r}-${i}`}
            alt={r}
            src={r}
            sx={{ bgcolor: stringToColor(r) }}
          />
        );
      })}
    </MuiAvatarGroup>
  );
};
