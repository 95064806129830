import { Button, Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LeftAlignDialog } from "@/components";
import { useNavigate } from "react-router-dom";
import { useSparxState } from "@/state";
import { endCycleDateFormat } from "@/utils";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const SuccessDialog = ({ open, onClose }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { sparx } = useSparxState();

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paperFullScreen": {
          borderRadius: theme.spacing(0),
        },
      }}
      >
      <LeftAlignDialog
        title={"Your edit is saved and Sparx are updated!"}
        subTitle={`You can edit or revert this transaction until ${endCycleDateFormat(new Date(sparx?.cycle.endDate ?? ""))}`
        }
        onClose={onClose}
      >
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
          Got it
        </Button>
        <Typography variant="subtitle2" textAlign="center">
          Any unused Sparx will reset at the end of this quarter.
        </Typography>
      </LeftAlignDialog>
    </Dialog>
  );
};




