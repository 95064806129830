import { useEffect, useState } from "react";
import { List, ListItem, Stack, TextField, Typography } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { useOperationsState, useUserState } from "@/state";
import { UserAvatar, UserInfo } from "@/components";
import { EditTransactionTransactions } from "@/interface";

type Props = {
  onSumChange: (incrementalChange: number) => void;
  originalBudget: number;
  temporaryTransactions: EditTransactionTransactions[];
  setTemporaryTransactions: (transactions: EditTransactionTransactions[]) => void;
}

export const AssignSparxForm = ({onSumChange, originalBudget, temporaryTransactions, setTemporaryTransactions}: Props) => {
  const { selectedActivity } = useOperationsState();
  const { userData } = useUserState();
  const canSendMultiple = userData?.isWhale;
  const [error, setError] = useState<string | undefined>(undefined);
  const activityOriginalSparxSum  = selectedActivity?.transactions.reduce((acc, { amount }) => acc + amount, 0) ?? 0;
  const totalAvailableBudget = originalBudget + activityOriginalSparxSum;

  useEffect(() => {
    const newSparxSum = temporaryTransactions.reduce((acc, { amount }) => acc + amount, 0);
    const incrementalChange = newSparxSum - activityOriginalSparxSum;
    onSumChange(incrementalChange)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryTransactions]);

  const handleChange = (transactionId: string, value: number | string) => {
    const newValue = Number(value);

    if (!isNaN(newValue) && newValue > 0) {
      const newSparxSum = temporaryTransactions.reduce((acc, transaction) => {
        return acc + (transaction.id === transactionId ? newValue : transaction.amount);
      }, 0);

      if (newSparxSum > totalAvailableBudget) {
        setError(`New sparx amount exceeds current available budget of ${originalBudget} + ${activityOriginalSparxSum} previously delegated sparx.`);
      } else {
        setError(undefined)
      }

      // Update the specific transaction amount in temporaryTransactions
      const updatedTransactions = temporaryTransactions.map((transaction) => {
        if (transaction.id === transactionId) {
          return { ...transaction, amount: newValue };
        }
        return transaction;
      });

      setTemporaryTransactions(updatedTransactions);
    }
  };

  return (
    <Stack spacing={2}>
      <List>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">User</Typography>
          <Typography variant="subtitle2">Sparx Amount</Typography>
        </ListItem>
        {temporaryTransactions.map(({ id, receiver, amount }, index) => (
          <Stack
            key={`${receiver.id}-${index}`}
            sx={{
              borderBottom: index < temporaryTransactions.length - 1 ? 1 : 0,
              borderColor: "divider",
              py: 2,
            }}
            spacing={1}
          >
            <ListItem sx={{ p: 0 }}>
              <UserAvatar user={receiver}>
                <UserInfo user={receiver} />
              </UserAvatar>

              <AutoAwesomeRoundedIcon
                color="disabled"
                fontSize="small"
                sx={{ mx: 2, ml: "auto" }}
              />
              <TextField
                dir="rtl"
                type="number"
                onChange={(e) => handleChange(id, e.target.value)}
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 1,
                    style: { textAlign: "right" },
                  },
                  style: {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
                variant="outlined"
                size="small"
                sx={{ width: "80px" }}
                value={amount}
                disabled={!canSendMultiple}
                error={!!error}
              />
            </ListItem>
          </Stack>
        ))}
        {error && (
          <Stack alignItems="flex-end">
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.error.dark,
              }}
            >
              {error}
            </Typography>
          </Stack>
        )}
      </List>
    </Stack>
  );
};
