const mixins = {
  sideMenuOpenWidth: "168px",
  sideMenuClosedWidth: "78px",
  gradientGrey:
    "linear-gradient(0deg, #15151580, #15151580), linear-gradient(180deg, #5D5D6240 0%, #5D5D6240 100%)",
  gradientCard:
    "radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(11, 242, 219, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%), radial-gradient(80.38% 222.5% at -13.75% -12.36%, rgba(152,249,255, 0.3) 0%, rgba(255, 255, 255, 0) 90%), radial-gradient(80.69% 208.78% at 108.28% 112.58%, rgb(183,170,255, 0.3) 0%, rgba(73, 40, 255, 0) 100%)",
  gradientListOverlay:
    "linear-gradient(180deg, rgba(28, 28, 30, 0.6) 0%, rgba(28, 28, 30, 0.95) 100%)",
  gradientModalBorder:
    "radial-gradient(80.69% 208.78% at 108.28% 112.58%, #EABFFF 0%, rgba(135, 38, 183, 0) 100%), radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98F9FF 0%, rgba(255, 255, 255, 0) 100%)",
  horizontalGradientPurple:
    "linear-gradient(90deg, rgba(44,24,153,1) 0%, rgba(58,32,204,1) 100%)",
  verticalGradientPurple:
    "linear-gradient(180deg, rgba(58,32,204,1) 0%, rgba(44,24,153,1) 100%)",
};

export default mixins;
