import { Box } from "@mui/material";

enum BannerSeverity {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
}

type Props = {
    severity: BannerSeverity;
    message: React.ReactNode;
};

const severityBackgroundColors: Record<BannerSeverity, string> = {
    [BannerSeverity.INFO]: '',
    [BannerSeverity.WARNING]: '',
    [BannerSeverity.ERROR]: '#F9DEDC',
};

const Banner = ({ severity, message }: Props) => {
    return (
        <Box sx={{
            backgroundColor: severityBackgroundColors[severity],
            fontSize: "14px",
            height: "58px",
            marginBottom: "-16px",
            padding: "8px",
            position: "relative",
            textAlign: "center",
            top: "-32px"
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        left = {{ xs: "-16px", sm: "-12px" }}
        width = {{ xs: "calc(100% + 16px)", sm: "calc(100% + 36px)" }}>
            <Box>{message}</Box>
        </Box>
    );
};

export { Banner, BannerSeverity };