import { useDropzone } from "react-dropzone";
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { Box, Stack, StackProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UploadProps } from "./types";
import FilePreview from "./preview/FilePreview";
import { Alerts } from "./alerts/Alerts";

const StyledDropZone = styled("div")(({ theme }) => ({
  outline: "none",
  cursor: "pointer",
  overflow: "hidden",
  padding: "24px 32px 32px 32px",
  borderRadius: theme.spacing(2),
  background: theme.palette.grey[50],
  border: `1px dashed ${theme.palette.grey[200]}`,
  "&:hover": {
    opacity: 0.7,
  },
}));

export default function FileUploader({
  disabled,
  extendFile,
  onDelete,
  maxSize = 1048576, // 1 MB
  accept = {
    "text/csv": [],
  },
  placeholder = "CSV (max 1MB)",
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    accept,
    maxSize,
    maxFiles: 1,
    ...other,
  });

  return (
    <Box sx={{ width: 1 }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.7,
          }),
          ...(disabled && {
            opacity: 0.5,
            pointerEvents: "none",
          }),
        }}
      >
        <input {...getInputProps()} />

        <Placeholder placeholder={placeholder as string} />
      </StyledDropZone>

      <Box
        sx={{
          minHeight: 60,
          mt: 2,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: (theme) => theme.spacing(2),
          visibility: extendFile ? "visible" : "hidden",
        }}
      >
        <FilePreview extendFile={extendFile} onDelete={onDelete} />

        <Alerts warnings={extendFile?.warnings} errors={extendFile?.errors} />
      </Box>
    </Box>
  );
}

function Placeholder({
  sx,
  placeholder,
  ...other
}: StackProps & { placeholder: string }) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 1,
        ...sx,
      }}
      {...other}
    >
      <UploadFileRoundedIcon fontSize="medium" />
      <Typography variant="body2">
        Click to upload
        <Typography
          variant="body2"
          component="span"
          sx={{
            mx: 0.5,
            color: "text.secondary",
          }}
        >
          or drag and drop
        </Typography>
      </Typography>
      <Typography variant="caption" color="secondary">
        {placeholder}
      </Typography>
    </Stack>
  );
}
