import { Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useSparxState } from "@/state";

export const SparxMessage = () => {
  const { activity, setActivityMessage } = useSparxState();

  if (!activity || activity?.transactions.length === 0) return null;

  return (
    <Card sx={{ background: "transparent" }} elevation={0}>
      <CardContent>
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Leave a message</Typography>
            <TextField
              value={activity.message}
              onChange={(e) => setActivityMessage(e.target.value)}
              placeholder="Leave a message"
              multiline
              rows={3}
              variant="outlined"
              InputProps={{
                style: {
                  borderRadius: "6px",
                },
              }}
            />
            <Typography variant="caption" color="text.secondary" sx={{ m: 1 }}>
              Messages are hidden until the end of the quarter. For immediate
              visibility, post as a shout-out.
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
