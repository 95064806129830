import { API_ENDPOINTS } from "@/config";
import {
  Category,
  InitiativeOffice,
  SparxUserRole,
  FilterOption,
  KeyValuePair,
} from "@/interface";

export const updateFilters = (
  value: KeyValuePair,
  filters: Record<string, string>
) => {
  const newFilters = { ...filters };
  if (newFilters[value.key]) {
    delete newFilters[value.key];
  } else {
    newFilters[value.key] = value.value;
  }

  return newFilters;
};

export const toKeys = (record: Record<string, string>) => Object.keys(record);
export const toValues = (record: Record<string, string>) =>
  Object.values(record);

export const toQuery = (
  categories: string[],
  regions: string[],
  offices: string[],
  keyword: string
) => {
  const base = API_ENDPOINTS.initiatives.search;
  // Initialize an array to hold parts of the query string
  const queryParams = [];

  // Append categories to the query if not empty
  if (categories.length > 0) {
    const categoryIds = categories.join(",");
    queryParams.push(`categories=${categoryIds}`);
  }

  if (regions.length > 0) {
    const regionParams = encodeURIComponent(regions.join(","));
    queryParams.push(`regions=${regionParams}`);
  }
  if (offices.length > 0) {
    const officeIds = offices.join(",");
    queryParams.push(`offices=${officeIds}`);
  }

  // Prepare the keyword as a URL path parameter, URL-encoded
  const pathKeyword =
    keyword.trim() !== "" && keyword.trim().length >= 2
      ? `/${encodeURIComponent(keyword)}`
      : "";

  // Return null if there are no query parameters and no keyword
  if (queryParams.length === 0 && pathKeyword === "") {
    return null;
  }

  // Join all parts of the query string with '&' and prepend with '?'
  const query = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  // Return the full URL with the keyword as part of the path
  return `${base}${pathKeyword}${query}`;
};

export const toContributorsQuery = (
  categories: string[],
  regions: string[],
  offices: string[],
  keyword: string
) => {
  const base = `${API_ENDPOINTS.contributions}/users`;
  // Initialize an array to hold parts of the query string
  const queryParams = [];

  // Append categories to the query if not empty
  if (categories.length > 0) {
    const categoryIds = categories.join(",");
    queryParams.push(`categories=${categoryIds}`);
  }

  if (regions.length > 0) {
    const regionParams = encodeURIComponent(regions.join(","));
    queryParams.push(`regions=${regionParams}`);
  }

  if (offices.length > 0) {
    const officeIds = offices.join(",");
    queryParams.push(`offices=${officeIds}`);
  }

  const pathKeyword =
    keyword.trim() !== "" && keyword.trim().length >= 2
      ? `keywords=${encodeURIComponent(keyword)}`
      : "";

  if (queryParams.length === 0 && pathKeyword === "") {
    return null;
  }

  const query = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  // Return the full URL with the keyword as part of the path if it exists
  return `${base}${query}${pathKeyword ? (query ? "&" : "?") + pathKeyword : ""}`;
};

export const toUsersQuery = (
  role: SparxUserRole | undefined,
  origin?: "sparx"
) => {
  const queryParams = [];
  if (role) queryParams.push(`role=${role}`);

  if (origin) queryParams.push(`origin=${origin}`);

  // Join all parts of the query string with '&' and prepend with '?'
  return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
};

export const toCategoryFilterOpts = (data?: Category[]): FilterOption[] => {
  const mapToFilterOption = (
    categories?: Category[]
  ): FilterOption[] | undefined => {
    return categories
      ?.map(({ id, name, subCategories }) => ({
        key: id,
        value: name,
        children: mapToFilterOption(subCategories),
      }))
      .sort((a, b) => {
        const aLength = a.children?.length ?? 0;
        const bLength = b.children?.length ?? 0;
        return bLength - aLength;
      });
  };

  return mapToFilterOption(data) ?? [];
};

export const toLocationFilterOpts = (
  data: Record<string, InitiativeOffice[]> | undefined
): FilterOption[] => {
  if (!data) return [];

  const locations: FilterOption[] = [
    {
      key: "GLOBAL",
      value: "Global",
    },
  ]

  for (const region in data) {
    locations.push({
      key: region,
      value: region,
      children: data[region].map((office) => {
        return {
          key: office.id,
          value: office.value,
        };
      }),
    });
  }

  return locations;
};
