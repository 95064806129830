import { styled } from "@mui/material";
import WidgetsIcon from "@mui/icons-material/WidgetsRounded";
import SportsCricketIcon from "@mui/icons-material/SportsCricketRounded";
import DiversityIcon from "@mui/icons-material/Diversity1Rounded";
import EscalatorWarningRoundedIcon from '@mui/icons-material/EscalatorWarningRounded';
import BusinessCenterIcon from "@mui/icons-material/BusinessCenterRounded";
import Category from "@mui/icons-material/CategoryRounded";
import CampaignIcon from "@mui/icons-material/CampaignRounded";
import {
  amber,
  blue,
  cyan,
  deepPurple,
  lightBlue,
  pink,
  teal,
} from "@mui/material/colors";

const IconWrapper = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0.5),
  borderRadius: 6,
  width: "24px",
  height: "24px",

  "& .MuiSvgIcon-root": {
    width: "16px",
    height: "16px",
  },
}));

const icons: Record<string, JSX.Element> = {
  Chapter: (
    <IconWrapper style={{ background: teal[50] }}>
      <WidgetsIcon style={{ color: teal[800] }} />
    </IconWrapper>
  ),
  "Practice Area": (
    <IconWrapper style={{ background: lightBlue[50] }}>
      <SportsCricketIcon style={{ color: lightBlue[800] }} />
    </IconWrapper>
  ),
  "Diversity & Inclusion": (
    <IconWrapper style={{ background: pink[50] }}>
      <DiversityIcon style={{ color: pink[800] }} />
    </IconWrapper>
  ),
  "People Development": (
    <IconWrapper style={{ background: deepPurple[50] }}>
      <EscalatorWarningRoundedIcon style={{ color: deepPurple[800] }} />
    </IconWrapper>
  ),
  Recruitment: (
    <IconWrapper style={{ background: cyan[50] }}>
      <BusinessCenterIcon style={{ color: cyan[800] }} />
    </IconWrapper>
  ),
  System: (
    <IconWrapper style={{ background: amber[50] }}>
      <Category style={{ color: amber[800] }} />
    </IconWrapper>
  ),
  Marketing: (
    <IconWrapper style={{ background: blue[50] }}>
      <CampaignIcon style={{ color: blue[800] }} />
    </IconWrapper>
  ),
};

type Props = {
  name: string;
};

export const CategoryIcon = ({ name }: Props) => {
  if (icons[name]) return icons[name];
};
