import {
  alpha,
  Avatar,
  AvatarGroup,
  Button,
  DialogTitle,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { CelebrationRounded } from "@mui/icons-material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import BorderedSection from "./common/BorderedSection";

export const CollectRewardsSkeleton = () => {
  return (
    <>
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <DialogTitle textAlign="center">Collect your rewards!</DialogTitle>
        <AvatarGroup max={4}>
          {[...Array(3)]?.map((_, index) => {
            return <Avatar key={index} sx={{ width: 64, height: 64 }} />;
          })}
          ;
        </AvatarGroup>
        <Stack alignItems="center">
          <Skeleton width={300} />
          <Skeleton width={150} />
        </Stack>
        <BorderedSection>
          <Stack direction="row" justifyContent="space-evenly">
            <Stack spacing={2}>
              <Typography variant="subtitle1" textAlign="center">
                Sparx
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <AutoAwesomeRoundedIcon
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #3A20CC 0%, #9747FF 100%)",
                    color: (theme) => `${theme.palette.common.white}`,
                    borderRadius: "8px",
                    padding: "4px",
                  }}
                />
                <Skeleton width={30} height={30} />
                <Typography
                  variant="body2"
                  sx={{ color: alpha("#000000", 0.87) }}
                >
                  {" "}
                  Received
                </Typography>
              </Stack>
            </Stack>

            <Divider orientation="vertical" flexItem />

            <Stack spacing={2}>
              <Typography variant="subtitle1" textAlign="center">
                Shoutouts
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CelebrationRounded
                  sx={{
                    backgroundColor: (theme) => `${theme.palette.success.main}`,
                    color: (theme) => `${theme.palette.common.white}`,
                    borderRadius: "8px",
                    padding: "4px",
                  }}
                />
                <Skeleton width={30} height={30} />
                <Typography
                  variant="body2"
                  sx={{ color: alpha("#000000", 0.87) }}
                >
                  {" "}
                  Received
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </BorderedSection>
        <Button disabled={true} variant="contained" color="primary">
          Collect Rewards
        </Button>
      </Stack>
    </>
  );
};
