import { Allocation } from "@/interface";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

type Props = {
  secondary?: boolean;
  allocations: Allocation[];
};

const StyledContainer = styled(TableContainer)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  ".no-border": {
    borderBottom: 0,
  },
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTableCell = styled(TableCell)({
  fontWeight: 700,
});

export const AllocationTable = ({ allocations, secondary }: Props) => {
  return (
    <StyledContainer>
      <Table>
        <TableHead>
          <StyledRow>
            <StyledTableCell>Employees</StyledTableCell>
            <StyledTableCell align="right">Individual sparx</StyledTableCell>
            <StyledTableCell align="right">Total sparx amount</StyledTableCell>
          </StyledRow>
        </TableHead>
        <TableBody>
          {allocations.map((allocation, index) => (
            <StyledRow
              key={allocation.employees}
              className={index === allocations.length - 1 ? "no-border" : ""}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <Avatar
                  sx={{
                    background: (theme) => theme.palette.grey[200],
                    ...(index === 0 &&
                      secondary && {
                        background: (theme) => theme.palette.secondary.light,
                        color: (theme) => theme.palette.secondary.main,
                      }),
                  }}
                />
                {allocation.employees}
              </TableCell>
              <TableCell align="right">{allocation.individualSparx}</TableCell>
              <TableCell align="right">{allocation.totalSparx}</TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </StyledContainer>
  );
};
