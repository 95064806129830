import { SyntheticEvent } from "react";
import { Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useOperationsState } from "@/state";


export default function Tabs() {
  const { selectedTab, setState } = useOperationsState();

  const handleChange = (_: SyntheticEvent, tab: string) => {
    setState({ selectedTab: tab });
  };

  return (
    <TabContext value={selectedTab}>
      <TabList
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          value="cycles"
          label="Cycles"
        />
        <Tab
          value="transactions"
          label="Transactions"
        />
        <Tab
          value="analysis"
          label="Analysis"
        />
      </TabList>
    </TabContext>
  );
}
