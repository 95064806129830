import { Theme } from "@mui/material";

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiTextField-root": {
            background: "transparent !important",
          },
          ".MuiAutocomplete-inputRoot": {
            maxHeight: "94px",
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
        },
        paper: {
          background: theme.palette.background.default,
          boxShadow: theme.shadows[4],
        },
      },
    },
  };
}
