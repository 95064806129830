import { useMediaQuery, useTheme } from "@mui/material";
import { Header, Menu } from "@/components";
import { useMenuItems } from "@/hooks/useMenuItems";

const Shell = () => {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const menuItems = useMenuItems();

  return isMediumUp ? <Menu items={menuItems} /> : <Header />;
};

export { Shell };
