import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  Chip,
  Box,
  FormHelperText,
} from "@mui/material";
import { useSparxState } from "@/state";
import { useCategories } from "@/hooks/api";
import { useEffect } from "react";

type Props = {
  initialCategoryIds?: string[];
};

export const CategoriesSelector = ({initialCategoryIds = []}: Props) => {
  const { categoryIds, setCategoryIds } = useSparxState();
  const { categories } = useCategories();
  const MAX_SELECTIONS = 3;

  useEffect(() => {
    if (initialCategoryIds.length > 0) {
      setCategoryIds(initialCategoryIds);
    }
  }, [initialCategoryIds, setCategoryIds]);

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    if (Array.isArray(e.target.value) && e.target.value.length <= MAX_SELECTIONS){
      setCategoryIds(e.target.value)
    }
  };

  const handleDelete = (categoryIdToBeDeleted: string) => {
    setCategoryIds(categoryIds.filter(categoryId => categoryId !== categoryIdToBeDeleted))
  }

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="category-label">Categories</InputLabel>
        <Select
          labelId="categories-label"
          multiple
          value={categoryIds ?? []}
          label="Categories"
          onChange={handleChange}
          renderValue={(selected) =>
            (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
              return (
                <Chip
                  key={value}
                  label={categories?.filter(category => category.id === value)?.[0]?.name}
                  onDelete={() => handleDelete(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  size="small"
                  color="secondary"
                />
              );
            })}
      </Box>)
          }
        >
          {categories?.map((category) => (
            <MenuItem key={category.id} value={category.id} disabled={!categoryIds.includes(category.id) && categoryIds.length >= MAX_SELECTIONS}>
              <Checkbox checked={categoryIds.includes(category.id)} />
              <ListItemText primary={category.name} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select up to 3 categories recipients contributed to</FormHelperText>
      </FormControl>
    </>
  );
};
