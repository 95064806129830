import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  TableCell,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { ROUTES } from "@/config";
import { OverflowMenu, UserAvatar, UserInfo } from "@/components";
import { useSparxState } from "@/state";
import { formatDateLongMonth } from "@/utils";
import { DeleteDialog } from "./DeleteDialog";
import { TransactionItem } from "@/interface";
import { SparxTransactionEnum } from "@/enums";

type Props = {
  transaction: TransactionItem;
  onEditButtonClick: (activityId: string | undefined) => void;
  mutate: () => void;
};

export const TableRow = ({
  transaction,
  onEditButtonClick,
  mutate
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openOverflowMenu = Boolean(anchorEl);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const { setTransaction } = useSparxState();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setTransaction(transaction.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null)
  };

  const onEdit = () => {
    onEditButtonClick(transaction.activityId);
    navigate(`${ROUTES.admin_edit_delegate_sparx}?id=${transaction.activityId}`);
  }

  const onDelete = () => {
    setOpenDeleteDialog(true)
  }

  return (
    <>
      <MuiTableRow
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <TableCell>
          <UserAvatar user={transaction.receiver}>
            <UserInfo user={transaction.receiver}/>
          </UserAvatar>
        </TableCell>
        <TableCell>
          <Stack alignItems="flex-end">
            <Typography variant="body2">
              {transaction.type === SparxTransactionEnum.DELEGATION ? "Delegated" : "Sent"}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="right">
          <Stack alignItems="flex-end">
            <Typography variant="subtitle1">{`-${transaction.amount}`}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="right">
          {formatDateLongMonth(new Date(transaction.createdAt ?? ""))}
        </TableCell>
        <TableCell align="right">
          <OverflowMenu
            open={openOverflowMenu}
            anchorEl={anchorEl}
            deleteCaption="Revert transaction"
            hideEditButton={false}
            setOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            onEdit={transaction.type === SparxTransactionEnum.DELEGATION ? onEdit : undefined}
            onDelete={onDelete}
          />
        </TableCell>
      </MuiTableRow>
      <DeleteDialog 
        transaction={transaction}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onSubmit={() => {
          setOpenDeleteDialog(false);
          setAnchorEl(null);
          mutate();
        }}
      />
    </>
  );
};