import { API_ENDPOINTS, fetcher } from "@/config";
import { EnrollmentTransitionEnum } from "@/enums";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

type Args = {
  enrollmentId: number | string;
};

export const useLeaveInitiative = (initiativeId: string) => {
  const { userData } = useUserState();
  const { authToken } = useUserState();

  const dropInitiative = async (url: string, { arg }: { arg: Args }) => {
    const { enrollmentId } = arg;
    await fetcher(
      `${url}/${enrollmentId}`,
      {
        config: {
          method: "PATCH",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            transition: EnrollmentTransitionEnum.DROP,
            userId: userData?.id,
          }),
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Dropped from initiative",
      }
    );
  };

  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.initiatives.root}/${initiativeId}/enrollments`,
    dropInitiative
  );

  return {
    trigger,
    isMutating,
  };
};
