import { create } from "zustand";

interface SearchState {
  categories: Record<string, string>;
  regions: Record<string, string>;
  offices: Record<string, string>;
  keyword: string;
  //
  setState: (args: Partial<SearchState>) => void;
  cleanup: () => void;
  isFilterApplied: () => boolean;
}

const initState = {
  categories: {},
  regions: {},
  offices: {},
  keyword: "",
};

const useSearchState = create<SearchState>((set, get) => ({
  ...initState,

  setState: (args?: Partial<SearchState>) => {
    set({ ...args });
  },

  cleanup: () => {
    set({ ...initState });
  },

  isFilterApplied: () => {
    const { categories, regions, offices, keyword } = get();
    return (
      Object.keys(categories).length > 0 ||
      Object.keys(regions).length > 0 ||
      Object.keys(offices).length > 0 ||
      Boolean(keyword)
    );
  },
}));

export { useSearchState };
