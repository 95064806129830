import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export interface ExtendCycleBodyRequest {
  extensionOfDays?: number;
  extensionToDate?: string;
}

export const useExtendCycle = (symbol: string) => {
  const { authToken } = useUserState();

  const put = async (url: string, { arg }: { arg: { data: ExtendCycleBodyRequest } }) => {
    await fetcher(
      url,
      {
        config: {
          method: "PUT",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(arg.data),
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Cycle end date successfully updated",
        hasFooter: false,
      }
    );
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `${API_ENDPOINTS.tokens.cycle_extend.replace(":symbol", symbol)}`,  
    put
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
