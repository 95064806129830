import { Theme } from "@mui/material/styles";

export default function Pagination(theme: Theme) {
  return {
    MuiPagination: {
      styleOverrides: {
        root: {
          ".MuiPagination-ul": {
            borderRadius: theme.spacing(1.5),
            backgroundColor: "white",
            border: `1px solid ${theme.palette.grey[100]}`,
          },
          ".MuiPagination-ul>li>button": {
            width: theme.spacing(5),
            height: theme.spacing(4.5),
          },
          ".MuiPagination-ul>li": {
            borderLeft: `1px solid ${theme.palette.grey[100]}`,
          },
          ".MuiPagination-ul>li:first-child": {
            borderLeft: "0px",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          margin: 0,
          backgroundColor: "white",
          borderRadius: "0",
          "&.Mui-selected": {
            backgroundColor: theme.palette.secondary.light,
          },
          "&.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.light,
          },
        },
      },
    },
  };
}
