import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { EnrollmentData } from "@/interface";

export const useEnrollments = () => {
  const { authToken } = useUserState();

  const { data, error, isLoading } = useSWR<EnrollmentData[]>(
    `${API_ENDPOINTS.me.enrollments}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    data,
    error,
    isLoading,
  };
};
