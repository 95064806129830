import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Stack } from "@mui/material";
import { InitiativeOverview } from "../common/overview/InitiativeOverview";
import { InitiativeCategory } from "../common/category/InitiativeCategory";
import { Links } from "../common/links/Links";
import { InitiativeLocation } from "../common/location/Location";
import { Dates } from "../common/dates/Dates";
import { Collaboration } from "../common/collaboration/Collaboration";
import { Team } from "../common/team/Team";
import { ManageInitiativeButton } from "../common/ManageInitiativeButton";
import { PageContainer } from "../common/PageContainer";
import { useSearchParams } from "react-router-dom";
import { useInitiative } from "@/hooks/api";
import { useCalculatePaddingTop } from "@/hooks";
import { useInitiativeState } from "@/state";
import { ROUTES } from "@/config";
import { Roles } from "@/enums";
import { toLocal } from "@/utils";
import { InitiativeGuard } from "@/guard/InitiativeGuard";
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import { SubnavigationBar} from "@/components";

export const EditInitiative = () => {
  const navigate = useNavigate();
  const paddingTop = useCalculatePaddingTop();
  const { setState } = useInitiativeState();
  const [params] = useSearchParams();
  const id = params.get("id");
  const { data } = useInitiative(id ?? "");

  useEffect(() => {
    if (data) setState({ ...toLocal(data) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <InitiativeGuard role={Roles.ADMIN} owners={data?.owners ?? []}>
      <SubnavigationBar 
        breadcrumbs={[
          { label: "Initiatives", href: ROUTES.initiatives, icon: <LightbulbRoundedIcon /> },
          { label: "Edit", href: `${ROUTES.edit_initiative}/?id=${id}` },
        ]}
        actions={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button onClick={() => navigate(`${ROUTES.initiative}/${id}`)} variant="outlined" color="secondary">
              Cancel
            </Button>
            <ManageInitiativeButton method="PATCH" initiativeId={id ?? ""} />
          </Stack>
        }
      />
      <PageContainer sx={{pt: paddingTop}}>
        <Container className="container">
          <InitiativeOverview pageTitle="Edit initiative" />
          <InitiativeCategory />
          <Links />
          <InitiativeLocation />
          <Dates />
          <Collaboration />
          <Team />
        </Container>
      </PageContainer>
    </InitiativeGuard>
  );
};
