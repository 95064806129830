import { Box, Typography } from "@mui/material";
import { AddContribution } from "./external/AddContribution";

export const TabHeader = () => {
  return (
    <Box display="flex" justifyContent="space-between" gap={1}>
      <Typography variant="h6">My contributions</Typography>
      <AddContribution />
    </Box>
  );
};
