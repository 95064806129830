import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { useSentSparx } from "@/hooks/api";
import { UserData } from "@/interface";
import { useCommonState } from "@/state";
import { AutoAwesomeRounded } from "@mui/icons-material/";
import { useMemo } from "react";

type Props = {
  user: UserData;
  skip: boolean;
};

export const ButtonRenderer = ({ user, skip }: Props) => {
  const { setState } = useCommonState();
  const { data, isLoading } = useSentSparx(user.id, skip);

  const onClick = () => setState({ sparxReceiver: user });

  const { disabled, amount } = useMemo(() => {
    if (!data || isLoading) {
      return {
        disabled: true,
        amount: undefined,
      };
    }

    const { amount, senderIsWhale } = data;
    return {
      disabled: !senderIsWhale && amount > 0,
      amount,
    };
  }, [data, isLoading]);

  const renderText = () => {
    if (isLoading) {
      return <Skeleton width="100%" />;
    }
    if (disabled) {
      return "You can only send 1 Sparx per person per quarter";
    }
    return `You previously sent ${amount}`;
  };

  return (
    <Stack gap={1}>
      <Button
        variant="contained"
        size="small"
        startIcon={<AutoAwesomeRounded />}
        disabled={disabled}
        onClick={onClick}
      >
        Send Sparx
      </Button>
      <Typography variant="caption" color="text.secondary" textAlign="center">
        {renderText()}
      </Typography>
    </Stack>
  );
};
