import { Typography, Button } from "@mui/material";
import { LeftAlignDialog } from "@/components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import { useSparxState } from "@/state";
import { endCycleDateFormat } from "@/utils";

type Props = {
  title?: string;
  subTitle?: string;
  onClose: () => void;
  banner?: string;
  bannerSubtitle1?: string;
  bannerSubtitle2?: string;
  redirect?: boolean;
};

export const SuccessDialog = ({ title, subTitle, onClose, banner, bannerSubtitle1, bannerSubtitle2, redirect = true }: Props) => {
  const navigate = useNavigate();
  const toSparx = () => {
    if (redirect) {
      navigate(ROUTES.sparx);
    } else {
      onClose();
    }
  }
  const { sparx } = useSparxState();

  return (
    <LeftAlignDialog
      title={title || "Sparx sent!"}
      banner={banner}
      bannerSubtitle1={bannerSubtitle1}
      bannerSubtitle2={bannerSubtitle2}
      subTitle={
        subTitle ||
        `You can still edit or revert your transaction until ${endCycleDateFormat(
          new Date(sparx?.cycle.endDate ?? "")
        )}`
      }
      onClose={onClose}
    >
      {banner && <Typography variant="subtitle1">
        Unused Sparx will reset at the end of this quarter, so keep the good vibes going!
      </Typography>}
      <Button variant="contained" color="secondary" onClick={toSparx}>
        Got it
      </Button>
      {!banner && <Typography variant="subtitle2" textAlign="center">
        Any unused Sparx will reset at the end of this quarter.
      </Typography>}
    </LeftAlignDialog>
  );
};
