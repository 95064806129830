import { Stack } from "@mui/material";
import { Header } from "./Header";
import { Cycles } from "./Cycles";

const CyclesPanel = () => {

  return (
    <Stack spacing={4}>
      <Header />
      <Cycles />
    </Stack>
  );
};

export { CyclesPanel };
