import { UserData } from "@/interface";
import {
  Box,
  Popover,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import { UserAvatar } from "../UserAvatar";
import { UserInfo } from "../UserInfo/UserInfo";
import { ButtonRenderer } from "./ButtonRederer";
import { useUserState } from "@/state";
import { OfficeLocation } from "./Location";
import { Deactivated } from "./Deactivated";

type Props = {
  open: boolean;
  user: UserData;
  anchorEl: HTMLElement | null;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: 244,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  pointerEvents: "auto", // Ensure pointer events are enabled
}));

export const UserInfoPopover = ({
  user,
  open,
  anchorEl,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const { userData } = useUserState();
  if (!open) return null;

  const isDeactivated = user.active === false;
  // levels 5+ are excluded from receiving Sparx per PRD
  const canReceiveSparx =
    (user?.level ?? 0) < 5 && userData?.id !== user.id && !isDeactivated;

  return (
    <ThemeProvider
      theme={(currentTheme) =>
        createTheme({ ...currentTheme, palette: { mode: "dark" } })
      }
    >
      <Popover
        sx={{
          pointerEvents: "none",
          borderRadius: 2,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              width: "auto",
              overflow: "inherit",
              background: (theme) => theme.palette.background.paper,
              boxShadow: (theme) => theme.shadows[4],
              popoverContent: {
                pointerEvents: "auto",
              },
            },

            onMouseEnter,
            onMouseLeave,
          },
        }}
      >
        <StyledBox>
          <UserAvatar user={user} hasTooltip={false}>
            <UserInfo user={user} />
          </UserAvatar>
          {isDeactivated ? <Deactivated /> : <OfficeLocation user={user} />}
          {canReceiveSparx && <ButtonRenderer user={user} skip={!open} />}
        </StyledBox>
      </Popover>
    </ThemeProvider>
  );
};
