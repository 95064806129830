import { Button } from "@mui/material";
import { Rewards } from "@/interface";
import { useRewardsState } from "@/state";
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import RedeemRoundedIcon from '@mui/icons-material/RedeemRounded';


type Props = {
  reward: Rewards;
};

export const OpenButton = ({ reward }: Props) => {
  const { setState } = useRewardsState();

  const onClick = () => {
    setState({
      openCollectRewardsModal:
        reward.status === "LOCKED" ? "locked" : "collect",
      reward,
      symbol: reward.symbol,
    });
  };

  return (
    <Button
      startIcon={reward.status === "LOCKED" ? <HttpsRoundedIcon /> : <RedeemRoundedIcon />}
      color="secondary"
      variant="contained"
      onClick={onClick}
    >
      Open
    </Button>
  );
};
