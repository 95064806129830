import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box } from "@mui/material";
import { SendRounded } from "@mui/icons-material";
import { useDelegateOnBehalf } from "@/hooks/api";

type Props = {
  title: string;
  onDone: () => void;
  senderId?: string;
  receiverId?: string;
  amount: number;
  message: string;
  error: string;
} & LoadingButtonProps;

export const DelegateButton = ({ title, onDone, senderId, receiverId, amount, message, error, ...rest }: Props) => {
  const { trigger: triggerDelegateOnBehalf, isMutating: isLoading } =
    useDelegateOnBehalf();

  const onClick = () => {
    if (senderId && receiverId) {
      triggerDelegateOnBehalf({ data: {senderId, receiverId, amount, message} }).then(
        () => {
          onDone();
        }
      );
    }
  };

  return (
    <Box sx={{ mt: "auto", display: "flex", justifyContent: "flex-start" }}>
      <LoadingButton
        variant="contained"
        loading={isLoading}
        disabled={!(senderId && receiverId && amount && message && !error)}
        onClick={onClick}
        endIcon={<SendRounded />}
        {...rest}
      >
        {title}
      </LoadingButton>
    </Box>
  );
};
