import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { SenderTrxResponseObject } from   "@/interface";

export const useAdminTrxBySender = (
  senderId: string,
  recipientId?: string | undefined,
  page: number = 1,
  size: number = 100,
  offset: number = 0
) => {
  const { authToken } = useUserState();

  let apiUrl = `${API_ENDPOINTS.admin.trx}/${senderId}?page=${page}&size=${size}&offset=${offset}`;

  // Append recipientId as a query parameter if it is provided
  if (recipientId) {
    apiUrl += `&receiverId=${recipientId}`;
  }

  const { data, isLoading, mutate } = useSWR<SenderTrxResponseObject>(
    apiUrl,
    (url: string) => {
      return fetcher(url, { authToken });
    },
    { revalidateOnFocus: false }
  );

  return {
    data,
    isLoading,
    mutate,
  };
};
