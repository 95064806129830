import { API_ENDPOINTS, fetcher } from "@/config";
import { InitiativeTransitionsEnum } from "@/enums";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useApprove = (id: string) => {
  const { authToken } = useUserState();
  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.initiatives.root}/${id}/${InitiativeTransitionsEnum.APPROVE}`,
    (url: string) =>
      fetcher(
        url,
        {
          config: {
            method: "PATCH",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${authToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: null,
          },
        },
        {
          variant: "success",
          isOpen: true,
          message: "Initiative approved",
        }
      )
  );

  return {
    trigger,
    isMutating,
  };
};
