import { Theme, alpha } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }

  interface ButtonPropsVariantOverrides {
    textMenu: true;
    sparx: true;
    info: true;
    group: true;
    ProfileDropdownMenu: true;
    link: true;
  }
}

export default function Button(theme: Theme) {

  const gradientOverlay = 'linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 100%)';
  const innerShadow = '0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset';
  
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "large",

      },
      variants: [
        {
          props: { variant: "sparx" },
          style: {
            boxShadow: "none",
            transition: "all .2s ease-out",
            color: "#FFFFFF",
            background: theme.palette.secondary.main,
            height: "40px",
            ":hover": {
              background: theme.palette.secondary.dark,
            },
            "&.Mui-disabled": {
              background: theme.palette.tertiary[50],
              color: theme.palette.tertiary[400],
            },
          },
        },
        {
          props: { variant: "link" },
          style: {
            boxShadow: "none",
            transition: "all .2s ease-out",
            background: "#FFFFFF",
            color: theme.palette.customColors.oceanBlue,
          },
        },
        {
          props: { variant: "group" },
          style: {
            boxShadow: "none",
            transition: "all .2s ease-out",
            background: "#FFFFFF",
            color: "#4C4C4F",
            border: `1px solid ${theme.palette.grey[100]}`,
            height: "36px",
            ":hover": {
              background: `1px solid ${theme.palette.grey[200]}`,
            },
          },
        },
        {
          props: { variant: "info" },
          style: {
            boxShadow: "none",
            transition: "all .2s ease-out",
            background: theme.palette.info.dark,
            color: "white",
            height: "40px",
            ":hover": {
              background: alpha(theme.palette.info.dark, 0.85),
            },
          },
        },
        {
          props: { variant: "contained", color: "tertiary" },
          style: {
            border: `1px solid ${theme.palette.grey[500]}`,
            boxShadow: "none",
            transition: "all .2s ease-out",
            background: "#FFFFFF",
            padding: theme.spacing(0, 2),
            "&.Mui-disabled": {
              background: theme.palette.background.default,
              color: theme.palette.tertiary[400],
              border: `1px solid ${theme.palette.tertiary[400]}`,
            },
          },
        },
        {
          props: { variant: "textMenu" },
          style: {
            transition: "all .2s ease-out",
            color: theme.palette.grey[50],
            "&.Mui-disabled": {
              background: "inherit",
              color: theme.palette.tertiary[200],
            },
            ":hover": {
              background: "inherit",
            },
          },
        },
      ],

      styleOverrides: {
        root: {
          transition: "all .3s ease-out",
          background: gradientOverlay,
        },
        containedPrimary: {
          background: `${gradientOverlay}, ${theme.palette.primary.main}`,
          boxShadow: `${innerShadow}, 0px 1px 2px 0px rgba(27, 17, 79, 0.40), 0px 0px 0px 1px #6D53FF`,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            background: `${gradientOverlay}, ${theme.palette.primary.dark}`,
            boxShadow: `${innerShadow}, 0px 1px 2px 0px rgba(27, 17, 79, 0.40), 0px 0px 0px 1px #6D53FF`,
          },
        },
        containedSecondary: {
          background: `${gradientOverlay}, ${theme.palette.secondary.main}`,
          boxShadow: `${innerShadow}, 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 1px 2px 0px rgba(35, 35, 38, 0.40), 0px 0px 0px 1px #232326`,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            background: `${gradientOverlay}, ${theme.palette.secondary.dark}`,
            boxShadow: `${innerShadow}, 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 1px 2px 0px rgba(35, 35, 38, 0.40), 0px 0px 0px 1px #232326`,
          },
        },
        containedError: {
          background: `${gradientOverlay}, ${theme.palette.error.main}`,
          boxShadow: `${innerShadow}, 0px 1px 2px 0px rgba(154, 26, 26, 0.40), 0px 0px 0px 1px #E04141;`,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            background: `${gradientOverlay}, ${theme.palette.error.dark}`,
            boxShadow: `${innerShadow}, 0px 1px 2px 0px rgba(154, 26, 26, 0.40), 0px 0px 0px 1px #E04141;`,
          },
        },
        outlinedSecondary: {
          background: `${gradientOverlay}, ${theme.palette.background.default}`,
          boxShadow:  `${innerShadow}, 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 1px 2px 0px rgba(48, 42, 81, 0.12), 0px 0px 0px 1px rgba(29, 18, 105, 0.08)`,
          border: "none",
          paddingTop: `${theme.spacing(1)}`, // override of 7px to 8px so all buttons have the same height
          paddingBottom: `${theme.spacing(1)}`, // override of 7px to 8px so all buttons have the same height
          '&:hover': {
            background: `${gradientOverlay}, ${theme.palette.action.hoverOpacity}`,
            boxShadow:  `${innerShadow}, 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 1px 2px 0px rgba(48, 42, 81, 0.12), 0px 0px 0px 1px rgba(29, 18, 105, 0.08)`,
            border: "none",
          },
        },
      },
    },
  };
}
