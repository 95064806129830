import { Divider, Stack } from "@mui/material";
import { Container } from "../Container";
import { Dates } from "./Dates";
import { EnrollmentData } from "@/interface";
import { Contribution } from "./Contribution";

type Props = {
  startDate?: string;
  endDate?: string;
  enrolled?: EnrollmentData;
  dropped?: EnrollmentData;
  canContribute?: boolean;
  mutate: () => void;
};

export const InfoBox = ({
  startDate,
  endDate,
  canContribute,
}: Props) => {
  return (
    <Container>
      <Stack spacing={2}>
        <Dates startDate={startDate} endDate={endDate} />
        {canContribute && <Divider sx={{ border: 0 }} />}
        {canContribute && <Contribution />}
      </Stack>
    </Container>
  );
};
