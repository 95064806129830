import { customColors } from "./colors";

const light = {
  background: {
    paper: "#F0F0F1",
    default: "#FFFFFF",
  },
  primary: {
    main: "#6D53FF",
  },
  secondary: {
    main: "#232326",
  },
  error: {
    main: "#DC2626",
  },
  warning: {
    main: "#D97706",
  },
  success: {
    main: "#16A34A",
  },
  info: {
    main: "#2563EB",
  },
  divider: "rgba(0,0,0,0.08)",
  // slate palette
  tertiary: {
    50: "#F8FAFC",
    100: "#F1F5F9",
    200: "#E2E8F0",
    300: "#CBD5E1",
    400: "#94A3B8",
    500: "#64748B",
    600: "#475569",
    700: "#334155",
    800: "#1E293B",
    900: "#0F172A",
    A400: "#94A3B833",
  },
  //neutral palette
  grey: {
    50: "#F0F0F1",
    100: "#DBDBDD",
    200: "#B2B2B4",
    300: "#757577",
    400: "#4C4C4F",
    500: "#232326",
    600: "#1C1C1E",
    700: "#151517",
    800: "#0E0E0F",
    900: "#070708",
    A100: "#B5B5BB",
    A200: "#98989D",
    A400: "#5D5D6280",
  },
  customColors,
};

const dark = {
  background: {
    default: "#121212",
  },
  primary: {
    main: "#6D53FF",
    constrastText: "#fffff",
  },
  secondary: {
    main: "#232326",
    constrastText: "rgba(255, 255, 255, 0.87)",
  },
  // slate palette
  tertiary: {
    50: "#F8FAFC",
    100: "#F1F5F9",
    200: "#E2E8F0",
    300: "#CBD5E1",
    400: "#94A3B8",
    500: "#64748B",
    600: "#475569",
    700: "#334155",
    800: "#1E293B",
    900: "#0F172A",
    A400: "#94A3B833",
  },
  //neutral palette
  grey: {
    50: "#5D5D6225",
    100: "#98989D",
    200: "#7B7B7F",
    300: "#5D5D62",
    400: "#333337",
    500: "#232326",
    600: "#1C1C1E",
    700: "#151517",
    800: "#0E0E0F",
    900: "#070708",
    A100: "#B5B5BB",
    A400: "#5D5D6280",
  },
  customColors,
};

export default function palette(themeMode: "light" | "dark") {
  return themeMode === "light" ? light : dark;
}
