import { useState } from "react";
import {
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { SuccessMessage } from "@/interface";
import { UserAvatar, UserInfo } from "@/components";
import { useOperationsState } from "@/state";
import { useAdminTrxBySender } from "@/hooks/api";
import { DelegateOnBehalfModal } from "./delegate/DelegateOnBehalfModal";
import { DelegateSuccessDialog } from "./delegate/DelegateSuccessDialog";

export const SenderHeader = () => {
  const theme = useTheme();
  const { sender, recipient, senderTransactions } = useOperationsState();
  const { data, mutate} = useAdminTrxBySender(sender?.[0]?.id, recipient[0]?.id || undefined);
  const [openDelegationOnBehalfModal, setOpenDelegationOnBehalfModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState<SuccessMessage | undefined>(undefined);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const onDone = (successMessage: SuccessMessage) => {
    setOpenDelegationOnBehalfModal(false);
    setOpenSuccessDialog(true);
    setSuccessMessage(successMessage);
  }

  const onCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    mutate();
  }

  const DataField = ({amount, label}: { amount?: number, label: string }) => {
    return (
    <Stack alignItems="flex-end">
      <Typography variant="subtitle1">{amount ?? 0}</Typography>
      <Typography variant="caption" sx={{color: theme.palette.grey[300]}}>{label}</Typography>
    </Stack>
    )
  }

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2} mb={3}>
      <UserAvatar user={sender?.[0]}>
        <UserInfo user={sender?.[0]}/>
      </UserAvatar>
      <DataField label="Sparx" amount={senderTransactions?.sender.budget.remainingBudget}/>
      <DataField label="Delegated" amount={senderTransactions?.sender.budget.delegated}/>
      <DataField label="Sent" amount={senderTransactions?.sender.budget.sent}/>
      <Button 
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => setOpenDelegationOnBehalfModal(true)}
      >
        Delegate on behalf
      </Button>
      <DelegateOnBehalfModal
        open={openDelegationOnBehalfModal}
        delegator={sender?.[0]}
        budget={data?.sender.budget.remainingBudget}
        onClose={() => setOpenDelegationOnBehalfModal(false)}
        onDone={(successMessage) => onDone(successMessage)}
      />
      <DelegateSuccessDialog
        open={openSuccessDialog}
        setOpen={setOpenSuccessDialog}
        message={successMessage}
        onClose={onCloseSuccessDialog}
      />
    </Stack>
  );
};