import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import { LinkItem } from "@/interface";
import { useInitiativeState } from "@/state";
import { validateUrlAndLabel } from "@/utils";

type Props = {
  onDone: () => void;
};

const StyledCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.default,
  border: `1 solid ${theme.palette.divider}`,
  boxShadow: theme.shadows[2],
}));

export const AddLink = ({ onDone }: Props) => {
  const { links, setState } = useInitiativeState();
  const [link, setLink] = useState<LinkItem>({ url: "", label: "" });
  const [errors, setErrors] = useState<LinkItem>({ url: "", label: "" });

  const onClick = () => {
    const { isValid, urlValidation, labelValidation } = validateUrlAndLabel(
      link.url,
      link.label
    );

    if (isValid) {
      setState({ links: [...links, link] });
      onDone();
    } else {
      setErrors({ url: urlValidation ?? "", label: labelValidation ?? "" });
    }
  };

  return (
    <Stack spacing={2}>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" color="secondary" onClick={onDone}>
          Cancel
        </Button>
      </Box>

      <StyledCard elevation={0}>
        <CardContent
          sx={{
            display: "flex",
            gap: 2,
            p: 1,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          <TextField
            value={link.url}
            onChange={(e) => setLink({ ...link, url: e.target.value })}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: <InsertLinkRoundedIcon sx={{ mr: 2 }} />,
            }}
            sx={{
              flex: 1,
            }}
            error={!!errors.url}
            helperText={errors.url}
          />
          <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
            <TextField
              value={link.label}
              onChange={(e) => setLink({ ...link, label: e.target.value })}
              variant="outlined"
              size="small"
              sx={{
                width: 1,
              }}
              error={!!errors.label}
              helperText={errors.label}
            />
            <Button variant="contained" color="secondary" onClick={onClick}>
              Save
            </Button>
          </Stack>
        </CardContent>
      </StyledCard>
    </Stack>
  );
};
