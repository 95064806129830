import useSWRMutation from "swr/mutation";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";

export const useAdminDeleteTrx = (
  transactionId: string,
) => {
  const { authToken } = useUserState();

  const deleteTransaction = async (url: string) => {
    await fetcher(
      url,
      {
        config: {
          method: "DELETE",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Transaction deleted with success",
        hasFooter: false,
      }
    );
  }

  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.admin.trx}/${transactionId}`,
    deleteTransaction
  );

  return {
    trigger,
    isMutating,
  };
};
