import { Theme } from "@mui/material/styles";

import AppBar from "./AppBar";
import Autocomplete from "./Autocomplete";
import Avatar from "./Avatar";
import Accordion from "./Accordion";
import Card from "./Card";
import Container from "./Container";
import Chip from "./Chip";
import CssBaseline from "./CssBaseLine";
import Button from "./Button";
import Breadcrumbs from "./Breadcrumbs";
import ButtonGroup from "./ButtonGroup";
import Drawer from "./Drawer";
import Dialog from "./Dialog";
import Input from "./Input";
import Link from "./Link";
import List from "./List";
import Paper from "./Paper";
import Pagination from "./Pagination";
import Radio from "./Radio";
import Select from "./Select";
import Switch from "./Switch";
import Table from "./Table";
import ToggleButton from "./ToggleButton";
import Tab from "./Tab";

export default function Overrides(theme: Theme) {
  return Object.assign(
    AppBar(theme),
    Autocomplete(theme),
    Avatar(theme),
    Accordion(theme),

    Card(theme),
    Container(theme),
    Chip(theme),
    // CSS Baseline
    CssBaseline(),

    Button(theme),
    Breadcrumbs(theme),
    ButtonGroup(theme),

    Drawer(theme),
    Dialog(theme),

    Input(theme),

    Link(theme),
    List(theme),

    Paper(theme),
    Pagination(theme),

    Radio(theme),

    Switch(theme),
    Select(theme),

    Table(theme),
    ToggleButton(theme),
    Tab(),
  );
}
