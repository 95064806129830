import { API_ENDPOINTS, fetcher } from "@/config";
import { ContributionData } from "@/interface";
import { useUserState } from "@/state";
import useSWR from "swr";

export const useContributions = (id: string) => {
  const { authToken } = useUserState();

  const { data, mutate } = useSWR<{
    totContributions: number;
    contributions: ContributionData[];
  }>(
    `${API_ENDPOINTS.initiatives.root}/${id}/contributions/`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    data,
    mutate,
  };
};
