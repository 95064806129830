import { useRef, useState } from "react";
import { Box, Chip, Menu, MenuProps, styled } from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { SingleSelect } from "./SingleSelect";
import { MultiSelect } from "./MultiSelect";
import { FilterOption, KeyValuePair } from "@/interface";

type Props = {
  label: string;
  options: FilterOption[];
  selected: Record<string, string>;
  multiple?: boolean;
  onSelect?: (option: KeyValuePair) => void;
  onSelectParent?: (option: KeyValuePair) => void;
  onSelectChild?: (option: KeyValuePair) => void;
  isDisabled?: boolean;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 288,
  },
}));

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<{ isDisabled?: boolean }>(({ theme, isDisabled }) => ({
  "& .MuiChip-deleteIcon": {
    color: theme.palette.secondary,
    cursor: isDisabled ? "default" : "pointer",
  },
  color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "null",
}));

const ITEM_HEIGHT = 48;

export const Filter = ({
  label,
  options,
  multiple,
  selected,
  onSelect,
  onSelectParent,
  onSelectChild,
  isDisabled = false,
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const disableDropdown = isDisabled || Object.keys(options).length === 0;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSingleSelect = (option: KeyValuePair) => {
    onSelect?.(option);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <>
      <Box ref={divRef} onClick={handleClick} sx={{ width: "fit-content" }}>
        <StyledChip
          label={label}
          size="medium"
          clickable={!disableDropdown}
          color={
            Object.keys(selected).length === 0 || disableDropdown
              ? "default"
              : "secondary"
          }
          onDelete={() => divRef?.current?.click()}
          deleteIcon={
            disableDropdown ? (
              <ExpandMoreRounded />
            ) : open ? (
              <ExpandLessRounded />
            ) : (
              <ExpandMoreRounded />
            )
          }
          sx={{ height: 32 }}
          isDisabled={disableDropdown}
        />
      </Box>

      {!disableDropdown && (
        <StyledMenu
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 5.5,
            },
          }}
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {multiple ? (
            <MultiSelect
              options={options}
              selected={selected}
              onSelectParent={onSelectParent}
              onSelectChild={onSelectChild}
            />
          ) : (
            <SingleSelect
              options={options}
              selected={selected}
              onSelect={handleSingleSelect}
            />
          )}
        </StyledMenu>
      )}
    </>
  );
};
