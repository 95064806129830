import { useSparxState } from "@/state";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Visibility } from "../shoutouts";

const StyledStack = styled(Box)<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: 1,
    borderRadius: theme.spacing(1),
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? "none" : "initial",
    padding: theme.spacing(1, 2),
    background: alpha(blue[700], 0.1),
  })
);

export const Shout = () => {
  const { shoutout, isPublic, setShoutoutPrivacy, setShoutout, activity } =
    useSparxState();

  return (
    <StyledStack disabled={activity?.message === "" || !activity?.message} flexWrap="wrap">
      <FormControlLabel
        label={
          <Stack>
            <Typography variant="subtitle2">
              Post this message as a shout-out
            </Typography>
            <Typography variant="caption" color="disabled">
              This message will instantly appear in the section.
            </Typography>
          </Stack>
        }
        control={
          <Checkbox
            size="small"
            color="secondary"
            checked={shoutout}
            onChange={() => setShoutout(!shoutout)}
          />
        }
      />
      <Visibility isPublic={isPublic} onSelect={setShoutoutPrivacy} />
    </StyledStack>
  );
};
