import Chip from '@mui/material/Chip';
import { getStatusText } from "../utils";
import { InitiativeStatus } from "@/enums/InitiativeStatus";

interface StatusChipProps {
  status: string;
}

interface CustomChipProps {
  label: string;
}

const StatusChip = ({ status }: StatusChipProps) => {

  const CustomChip = ({ label }: CustomChipProps ) => {
    switch (status) {
      case InitiativeStatus.SUBMITTED:
        return <Chip variant="outlined" color="info" label={label} />;
      case InitiativeStatus.ACTIVE:
        return <Chip variant="outlined" color="success" label={label} />;
      case InitiativeStatus.REJECTED:
        return <Chip variant="outlined" color="error" label={label} />;

      default:
        return <Chip label={label} />;
    }
  }

  return (
    <CustomChip label={getStatusText(status)}/>
  )
}

export default StatusChip;