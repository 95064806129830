import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useInitiativeMutation = (
  method: "POST" | "PATCH",
  initiativeId?: string
) => {
  const { authToken } = useUserState();

  async function sendRequest(url: string, { arg }: { arg: { data: any } }) {
    const response = await fetcher<{ id: string }>(
      url,
      {
        config: {
          method,
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(arg.data),
        },
      },
      {
        variant: "success",
        isOpen: true,
        message:
          method === "POST"
            ? "Initiative added with success"
            : "Initiative updated with success",
        hasFooter: false,
      }
    );

    return response;
  }

  const { trigger, isMutating, error } = useSWRMutation(
    method === "POST"
      ? API_ENDPOINTS.initiatives.root
      : `${API_ENDPOINTS.initiatives.root}/${initiativeId}`,
    sendRequest
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
