import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

type Props = {
  label: string;
  date: string | undefined;
  onChange: (date: dayjs.Dayjs | null) => void;
  disabled?: boolean;
};

export const StyledDatePicker = ({
  label,
  date,
  disabled,
  onChange,
}: Props) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dayjs(date)}
        onChange={onChange}
        disabled={disabled}
        slotProps={{
          layout: {
            sx(theme) {
              return {
                ".MuiDateCalendar-root": {
                  backgroundColor: theme.palette.background.default,
                },
              };
            },
          },
          day: {
            sx(theme) {
              return {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                },
                "&.MuiPickersDay-dayWithMargin": {
                  fontSize: theme.typography.subtitle2.fontSize,
                },
              };
            },
          },
          textField: {
            size: "small",
            fullWidth: true,
          },
        }}
      />
    </LocalizationProvider>
  );
};
