import { Box, Stack, Typography } from "@mui/material";
import { RegionSelector } from "./RegionSelector";
import { OfficeSelector } from "./OfficeSelector";
import { useOffices } from "@/hooks/api";
import { useInitiativeState } from "@/state";
import { InitiativeOffice } from "@/interface";

export const InitiativeLocation = () => {
  const { regions, offices } = useOffices();
  const { region, setState, offices: value } = useInitiativeState();

  const onRegionChange = (region: string) => {
    setState({ region, offices: [] });
  };

  const onOfficeChange = (offices: InitiativeOffice[]) => {
    setState({ offices });
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Location</Typography>
      <Box display="flex" gap={2}>
        <Box flex={1}>
          <RegionSelector
            region={region}
            regions={regions}
            onChange={onRegionChange}
          />
        </Box>
        <Box flex={1}>
          <OfficeSelector
            value={value ?? []}
            offices={offices[region] ?? []}
            onChange={onOfficeChange}
          />
        </Box>
      </Box>
    </Stack>
  );
};
