import { Stack, Typography } from "@mui/material";
import { ArchiveRounded } from "@mui/icons-material";

export const Deactivated = () => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <ArchiveRounded color="disabled" />
      <Typography color="text.secondary" variant="body2">
        Deactivated account
      </Typography>
    </Stack>
  );
};
