import { ContributionsByInitiative } from "@/interface";
import { Stack, Typography } from "@mui/material";

type Props = {
  data: ContributionsByInitiative;
};

export const InitiativeInfo = ({ data }: Props) => {
  const { initiative } = data;
  return (
    <Stack>
      <Typography variant="subtitle1">Contributions added</Typography>
      <Typography>
        to&nbsp;
        <Typography color="primary" component={"span"} variant="body2">
          {initiative.title}
        </Typography>
      </Typography>
    </Stack>
  );
};
