import { TableBody, TableCell, TableRow, Typography } from "@mui/material";

export const EmptyState = () => {
  return (
    <TableBody>
      <TableRow
        style={{
          height: 100,
        }}
      >
        <TableCell colSpan={5} align="center">
          <Typography variant="h6">No matches found</Typography>
          <Typography color="text.secondary">
            Try different search terms or adjusting your filters
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
