import { Editor } from "@/components";
import { useInitiativeState } from "@/state";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { DescriptionHelperText } from "./DescriptionHelperText";

type Props = {
  pageTitle: string;
  subTitle?: string;
};

export const InitiativeOverview = ({ pageTitle, subTitle }: Props) => {
  const { title, description, errors, setState } = useInitiativeState();
  
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      title: e.target.value,
      errors: { ...errors, title: undefined },
    });
  };

  return (
    <>
      <Stack mb={2}>
        <Typography variant="h5" component="h1">
          {pageTitle}
        </Typography>
        {subTitle && <Typography variant="body2">{subTitle}</Typography>}
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h6">About</Typography>
        <TextField
          value={title}
          label="Title"
          variant="outlined"
          size="small"
          error={!!errors?.title}
          helperText={errors?.title}
          onChange={onTitleChange}
          sx={{
            "& .MuiFormHelperText-root": {
              background: (theme) => theme.palette.background.paper,
              m: 0.5,
              px: 1,
            },
          }}
        />

        <Stack>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.background.default,
              p: 0.5,
              border: 1,
              borderColor: "rgba(0, 0, 0, 0.22)", // custom override to match input components
              borderRadius: theme.shape,
              boxShadow: theme.shadows[1],
            })}
          >
            <Editor
              placeholder="Description"
              initialContent={description}
              onChange={(markdown) => {
                setState({
                  description: markdown,
                  errors: { ...errors, description: undefined },
                });
              }}
            />
          </Box>
          <Box m={0.5}>
            <DescriptionHelperText error={errors?.description} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
