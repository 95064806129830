import { API_ENDPOINTS, fetcher } from "@/config";
import { Rewards } from "@/interface";
import { useUserState } from "@/state";
import useSWR from "swr";

export const useRewards = () => {
  const { authToken } = useUserState();

  const { data, error, isLoading } = useSWR<Rewards[]>(
    `${API_ENDPOINTS.rewards.root}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    rewards: data || [],
    error,
    isLoading,
  };
};
