import { forwardRef } from "react";
import { useRewardsState } from "@/state";
import { DialogContent, DialogTitle, IconButton, Slide } from "@mui/material";
import { RewardSlider } from "./slider/RewardSlider";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TransitionProps } from "@mui/material/transitions";
import { CustomDialog } from "@/components";

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CollectRewards = () => {
  const { openRewardsModal, setState } = useRewardsState();

  const onClose = () => setState({ openRewardsModal: false });

  return (
    <CustomDialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
      fullScreen={true}
      open={openRewardsModal}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseRoundedIcon />
      </IconButton>

      <DialogTitle textAlign="center" sx={{ mt: 4 }}>
        Collect Rewards
      </DialogTitle>
      <DialogContent>
        <RewardSlider />
      </DialogContent>
    </CustomDialog>
  );
};
