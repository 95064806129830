import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { LeftAlignDialog } from "@/components";
import dayjs from 'dayjs';

type Props = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  newEndDate: dayjs.Dayjs | null;
};

export const ChangeCycleDialog = ({
  open,
  onSubmit,
  onClose,
  newEndDate
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const delegationFreezeDate = newEndDate ? newEndDate.subtract(2, 'week') : null;

  const handleSubmit = () => {
      onSubmit();
      onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paperFullScreen": {
          borderRadius: theme.spacing(0),
        },
      }}
    >
      <LeftAlignDialog
        title="Setting new end date for the cycle?"
        subTitle={
          <>
            {`You are changing the cycle's end date`}
            {newEndDate && (
              <> to <strong>{newEndDate.format('DD/MM/YYYY')}</strong>.</>
            )}
            <br />
            {`This change will update the delegation freeze date`}
            {delegationFreezeDate && (
              <> to <strong>{delegationFreezeDate.format('DD/MM/YYYY')}</strong>.</>
            )}
            <br />
            Note: This action cannot be reversed.
          </>
        }
        onClose={onClose}
      >
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            color="error"
          >
            Set new date
          </LoadingButton>
        </DialogActions>
      </LeftAlignDialog>
    </Dialog>
  );
};
