import { Button, Container } from "@mui/material";
import { CyclesPanel } from "./cycles";
import { TransactionsPanel } from "./transactions";
import { AnalysisPanel } from "./analysis";
import Tabs from "./common/Tabs";
import { useOperationsState } from "@/state";
import { ROUTES } from "@/config";
import { useCalculatePaddingTop } from "@/hooks";
import { SubnavigationBar } from "@/components";
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { TabContext, TabPanel } from "@mui/lab";
import { downloadZip } from "@/utils";
import { API_ENDPOINTS } from "@/config";
import { useUserState, useUIActionsState } from "@/state";


export const Operations = () => {
  const paddingTop = useCalculatePaddingTop();
  const { authToken } = useUserState();
  const { setSnackbarOpen } = useUIActionsState();
  const { selectedTab } = useOperationsState();

  const onDownload = async () => {
    const url = `${API_ENDPOINTS.admin.reports}`;
    try {
      downloadZip(authToken, url);
    } catch (e) {
      setSnackbarOpen({
        variant: "error",
        isOpen: true,
        message: (e as Error).message,
        hasFooter: false,
      });
    }
  };

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[{ label: "Operations", href: ROUTES.operations, icon: <ManageHistoryRoundedIcon /> }]}
        tabs={<Tabs/>}
        actions={
          (<Button 
            startIcon={<ArrowDownwardRoundedIcon />}
            variant="contained"
            color="primary"
            onClick={onDownload}
          >
            Reports
          </Button>)
        }
      />
      <Container sx={{ display: "flex", flexDirection: "column", gap: 2, pt: paddingTop}}>
        <TabContext value={selectedTab}>
          <TabPanel value="cycles" sx={{ p: 0 }}>
            <CyclesPanel />
          </TabPanel>
          <TabPanel value="transactions" sx={{ p: 0 }}>
            <TransactionsPanel />
          </TabPanel>
          <TabPanel value="analysis" sx={{ p: 0 }}>
            <AnalysisPanel />
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};
