import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Category } from "@/interface";

type Props = {
  categoryId: string;
  categories: Category[];
  setCategory: (category: string) => void;
};

export const CategorySelector = ({
  categoryId,
  categories,
  setCategory,
}: Props) => {
  const handleChange = (e: SelectChangeEvent) => {
    setCategory(e.target.value);
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="category-label">Category</InputLabel>
        <Select
          labelId="category-label"
          value={categoryId}
          label="Category"
          onChange={handleChange}
        >
          {categories?.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};
