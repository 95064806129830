import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { TransactionsByCycle } from "@/interface";
import { DirectionFilterEnum, OwnershipFilterEnum } from "@/enums";
import { useUserState } from "@/state";

export const useTransactions = (txDirection: DirectionFilterEnum, txOwnership: OwnershipFilterEnum) => {
  const { authToken } = useUserState();

  const queryParams = new URLSearchParams({
    txDirectionFilter: txDirection || DirectionFilterEnum.ALL,
    txOwnershipFilter: txOwnership || OwnershipFilterEnum.ALL
  }).toString();

  const { data, mutate, isLoading } = useSWR<TransactionsByCycle[]>(
    `${API_ENDPOINTS.tokens.sparx_transactions}?${queryParams}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    data,
    mutate,
    isLoading
  };
};