import { useState, useEffect } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { UserData } from "@/interface";
import { useOperationsState } from "@/state";
import { EditTransactionTransactions } from "@/interface";

export const DisabledUserAutoComplete = () => {
  const [value, setValue] = useState<Partial<UserData>[]>([]);
  const { selectedActivity } = useOperationsState();

  useEffect(() => {
    if (selectedActivity?.transactions && selectedActivity.transactions.length > 0) {
      setValue(selectedActivity?.transactions.map((tr: EditTransactionTransactions) => tr.receiver) ?? []);
    } 
  }, [selectedActivity]);

  return (
    <Autocomplete
      multiple
      value={value}
      size="small"
      disabled
      options={[]}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={`${option.lastName}, ${option.firstName}`}
              size="small"
              color="secondary"
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Recipient(s)"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
          helperText={"You are not allowed to change the recipients of a past transaction."}
        />
      )}
    />
  );
};
