import {
  Chip,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import { UserData } from "@/interface";
import { AvatarGroup, UserAvatar, UserInfo } from "@/components";

const StyledRow = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(1),
}));

type Role = "Owner" | "Sponsor" | "Participant" | "Participants";

type Props = {
  user: UserData;
  role: Role;
};

export const UserRowRenderer = ({ user, role }: Props) => {
  return (
    <StyledRow key={user.id}>
      <TableCell
        component="th"
        scope="row"
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <UserAvatar user={user}>
          <UserInfo user={user} />
        </UserAvatar>
      </TableCell>
      <TableCell>{user?.email}</TableCell>
      <TableCell>{chipRenderer(role)}</TableCell>
    </StyledRow>
  );
};

export const NoParticipants = () => {
  return (
    <StyledRow>
      <TableCell
        colSpan={3}
        sx={{
          pb: 0,
          textAlign: 'center',
        }}
      >
        <Typography color="text.secondary">This initiative has no participants yet</Typography>
      </TableCell>
    </StyledRow>
  );
};

type HiddenProps = {
  users: UserData[];
  role: Role;
};

export const HiddenParticipants = ({ users, role }: HiddenProps) => {
  return (
    <StyledRow>
      <TableCell
        component="th"
        scope="row"
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <AvatarGroup avatars={users.map((user) => user?.firstName)} />
        <Typography color="text.secondary">
          {`${users.length} Participant${users.length > 1 ? 's' : ''}`}
        </Typography>
      </TableCell>
      <TableCell />
      <TableCell>{chipRenderer(role)}</TableCell>
    </StyledRow>
  );
};

const chipRenderer = (role: Role) => {
  if (role === "Owner") return <StyledChip label={role} color="primary" />;
  if (role === "Sponsor")
    return <StyledChip label={role} variant="outlined" color="success" />;
  return <StyledChip label={role} />;
};
