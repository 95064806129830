import { Box, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  title: string;
}>;

export const ResponsiveContainer = ({ title, children }: Props) => {
  return (
    <Stack spacing={{ sx: 2, md: 4 }} sx={{ flex: 1 }}>
      <Typography variant="h4" component="h1">
        {title}
      </Typography>

      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "1fr", // All on mobile devices stack on top of each other
          sm: "1fr", // Same as xs
          md: "1fr 2fr", // 2 columns from medium devices and up
          lg: "1fr 2fr", // Same as md
          xl: "1fr 2fr", // Same as md
        }}
        gap={{ xs: 2, md: 6, lg: 12 }}
      >
        {children}
      </Box>
    </Stack>
  );
};
