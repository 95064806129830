import { LoadingButton } from "@mui/lab";
import { useEditShoutout } from "@/hooks/api";
import { ShoutoutData, ShoutoutEditBodyRequest } from "@/interface";

type Props = {
  content: string;
  isPublic: boolean;
  shoutoutId: string;
  disabled: boolean;
  onComplete: (shoutout: ShoutoutData) => void;
};

export const EditShoutButton = ({
  content,
  isPublic,
  shoutoutId,
  disabled,
  onComplete,
}: Props) => {
  const { trigger, isMutating } = useEditShoutout(shoutoutId);

  const onEdit = () => {
    const data: ShoutoutEditBodyRequest = {
      details: content,
      public: isPublic,
    };

    trigger({ data }).then((res) => onComplete(res));
  };

  return (
    <LoadingButton
      disabled={disabled}
      variant="contained"
      color="primary"
      onClick={onEdit}
      loading={isMutating}
    >
      Save
    </LoadingButton>
  );
};
