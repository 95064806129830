import { useJoinInitiative } from "@/hooks/api";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { useState } from "react";
import { JoinOnboardingDialog } from "../../../joinOnboarding/JoinOnboardingDialog";
import { OnboardingDialog } from "../../../joinOnboarding/OnboardingDialog";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const JoinDialog = ({ open, onClose }: Props) => {
  const { id } = useParams();
  const { trigger, isMutating } = useJoinInitiative(id ?? "", false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const onJoin = () => {
    trigger({}).then(() => onClose());
  };

  const triggerOnboarding = () => {
    setShowOnboarding(true);
    onClose();
  };

  const handleOnboardingComplete = () => {
    onJoin();
    setShowOnboarding(false);
  };

  return (
    <>
      <CustomDialog open={open && !showOnboarding} onClose={onClose}>
        <DialogTitle textAlign="center">Join Initiative?</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            Are you sure you want to join this initiative? This action will add
            you to the initiative and notify the initiative owner.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <LoadingButton
            loading={isMutating}
            onClick={triggerOnboarding}
            variant="contained"
            color="primary"
          >
            Join
          </LoadingButton>
        </DialogActions>
      </CustomDialog>
      <OnboardingDialog open={showOnboarding}>
        <JoinOnboardingDialog onComplete={handleOnboardingComplete} />
      </OnboardingDialog>
    </>
  );
};
