import { useRef, useState } from "react";
import { UserData } from "@/interface";
import { Box, Typography, alpha, styled } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { UserInfoPopover } from "../UserInfoPopover";

type Props = {
  highlight: boolean;
  receiver: UserData;
};

const StyledTag = styled(Box)<{
  ownerState: {
    highlight?: boolean;
  };
}>(({ ownerState, theme }) => ({
  display: "flex",
  padding: "2px 8px",
  borderRadius: "6px",
  alignItems: "center",
  background: alpha(theme.palette.info.main, 0.08),
  cursor: "pointer",
  ...(ownerState.highlight && {
    backgroundColor: yellow[100],
  }),
}));

export const TaggedUser = ({ receiver, highlight }: Props) => {
  const anchorEl = useRef<HTMLElement | null>(null);

  const [open, setOpen] = useState(false);

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const nameRenderer = (user: UserData) =>
    `${user.firstName}, ${user.lastName}`;

  return (
    <>
      <StyledTag
        ref={anchorEl}
        ownerState={{ highlight }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography
          variant={highlight ? "subtitle2" : "body2"}
          color={highlight ? "secondary" : "info.main"}
        >
          @ {nameRenderer(receiver)}
        </Typography>
      </StyledTag>
      <UserInfoPopover
        open={open}
        anchorEl={anchorEl.current}
        user={receiver}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
    </>
  );
};
