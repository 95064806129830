import { UserData } from "@/interface";
import { UserAutocomplete } from "@/components";

type Props = {
  receivers: UserData[];
  onChange: (receivers: UserData[]) => void;
  disabled?: boolean;
};

export const Receivers = ({ receivers, disabled, onChange }: Props) => {
  return (
    <UserAutocomplete
      label="Enter Person's Name"
      value={receivers}
      onChange={onChange}
      sx={{ width: 300 }}
      disabled={disabled}
    />
  );
};
