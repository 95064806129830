import { Button, ButtonProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { SendRounded } from "@mui/icons-material";

export const SendSparxButton = ({ ...rest }: ButtonProps) => {
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ borderColor: grey[300] }}
        {...rest}
        endIcon={<SendRounded />}
      >
        Send sparx
      </Button>
    </>
  );
};
