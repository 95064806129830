import { useEffect, useState } from "react";
import { useSparxState } from "@/state";
import { Box, TextField, Typography, styled } from "@mui/material";
import { Shout } from "./Shout";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(1),
}));

type Props = {
  resetMessage?: boolean;
  allowPostToShoutouts?: boolean;
  isRequiredField?: boolean;
};

export const LeaveMessage = ({
  resetMessage,
  allowPostToShoutouts = true,
  isRequiredField = false,
}: Props) => {
  const [error, setError] = useState(false);
  const { activity, setActivityMessage } = useSparxState();

  useEffect(() => {
    if (resetMessage) {
      setActivityMessage("");
    }
  }, [resetMessage, setActivityMessage]);

  if (!activity || activity.transactions.length === 0) {
    return null;
  }

  const handleBlur = () => {
    if (isRequiredField && !activity.message) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <StyledBox>
      <Typography variant="subtitle2" mb={1}>
        Leave a message
      </Typography>
      <TextField
        required={isRequiredField}
        helperText={
          allowPostToShoutouts
            ? "Messages are hidden until the end of the quarter. For immediate visibility, post as a shout-out."
            : "*This field is required. Describe how the delegated tokens should be used."
        }
        value={activity.message}
        onChange={(e) => setActivityMessage(e.target.value)}
        onBlur={handleBlur}
        error={isRequiredField && error}
        multiline
        rows={3}
        variant="outlined"
      />
      {allowPostToShoutouts && <Shout />}
    </StyledBox>
  );
};
