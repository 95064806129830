import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useDeleteTransaction = (transactionId: string) => {
  const { authToken } = useUserState();

  async function sendRequest(url: string) {
    await fetcher(url, {
      config: {
        method: "DELETE",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    });
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `${API_ENDPOINTS.tokens.root}/${transactionId}`,
    sendRequest
  );

  return {
    trigger,
    isDeleting: isMutating,
    error,
  };
};
