import { useEffect, useRef } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";

type Props = { initialContent?: string };

const HTML = '<p class="EditorTheme__paragraph" dir="ltr">';

export const LoadInitialContent = ({ initialContent }: Props) => {
  const [editor] = useLexicalComposerContext();
  const isMountedRef = useRef<boolean>(false);
  useEffect(() => {
    if (!initialContent || isMountedRef.current) {
      return;
    }

    editor.update(() => {
      if (initialContent.startsWith(HTML)) {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        editor.setEditable(false);
        const parser = new DOMParser();
        const dom = parser.parseFromString(initialContent, "text/html");
        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);
        // Select the root
        $getRoot().select();
        // Insert them at a selection.
        $getRoot().clear();
        $getRoot().select();
        $insertNodes(nodes);
        requestAnimationFrame(() => editor.setEditable(true));
      } else {
        $convertFromMarkdownString(initialContent, TRANSFORMERS);
      }
    });
    isMountedRef.current = true;
  }, [editor, initialContent]);
  return null;
};
