import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Stack,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import { useCalculatePaddingTop } from "@/hooks";
import { useAdminEditTrx, useBalance } from "@/hooks/api";
import { ROUTES } from "@/config";
import { useOperationsState, useSparxState, useUIActionsState } from "@/state";
import { BalanceCard, ResponsiveContainer, SubnavigationBar, UserAvatar, UserInfo } from "@/components";
import { endCycleDateFormat } from "@/utils";
import { DisabledUserAutoComplete } from "./DisabledUserAutoComplete";
import { AssignSparxForm } from "./AssignSparxForm";
import { LeaveMessage } from "./LeaveMessage";
import { UpdateButton } from "./UpdateButton";
import { SuccessDialog } from "./SuccessDialog";

export const EditDelegatedSparx = () => {
  useBalance();
  const paddingTop = useCalculatePaddingTop();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const activityId = params.get("id");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { setSnackbarOpen } = useUIActionsState();
  const { sender, senderTransactions, selectedActivity, setState, updatedActivity } = useOperationsState();
  const originalBudget = senderTransactions?.sender.budget.remainingBudget ?? 0;
  const [temporaryBudget, setTemporaryBudget] = useState<number>(originalBudget);
  const [temporaryTransactions, setTemporaryTransactions] = useState(selectedActivity?.transactions ?? []);
  const { trigger } = useAdminEditTrx(activityId ?? "");
  const { sparx } = useSparxState();

  useEffect(() => {
    if (temporaryBudget !== originalBudget) {
      setState({ updatedActivity: true });
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryBudget, originalBudget]);

  const onSubmitUpdate = async () => {
    if (selectedActivity) {
      try {
        await trigger({
          data: {
            ...selectedActivity,
            transactions: temporaryTransactions
          }
        }).then(() => {
          setSuccessDialogOpen(true)
        })
      } catch (error) {
        setSnackbarOpen({
          variant: "error",
          isOpen: true,
          message: (error as Error).message,
          hasFooter: false,
        });
      }  
    }
  }

  const onSumChange = (incrementalChange: number) => {
    setTemporaryBudget(originalBudget - incrementalChange);
  }

  if (sender.length === 0) {
    navigate(ROUTES.operations);
    return null;
  }

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[
          { label: "Operations", href: ROUTES.operations, icon: <ManageHistoryRoundedIcon /> },
          { label: "Edit Delegation Transaction", href: `${ROUTES.admin_edit_delegate_sparx}?id=${activityId}` }
        ]}
        actions={
          <UpdateButton
            title="Update & Save"
            disabled={selectedActivity?.message === undefined || !selectedActivity.message || !updatedActivity || temporaryBudget < 0}
            onClick={() => onSubmitUpdate()}
          />
        }
      />  
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: 2,
          pt: paddingTop,
        }}
      >
        <ResponsiveContainer title="Edit Delegation Transaction">
          <Grid item xs={12} md={5}>
            <Stack spacing={{ xs: 2, md: 4 }}>
              <BalanceCard headline={`${sender?.[0].firstName}'s Budget (${sparx?.cycle.name})`} balance={temporaryBudget} dateUntil={`${endCycleDateFormat(new Date(sparx?.cycle.endDate ?? ""))}`}/>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={{ xs: 2, md: 4 }}>
              <Stack spacing={2}>
              <Typography variant="subtitle1">Original Sender</Typography>
                <UserAvatar user={sender?.[0]}>
                  <UserInfo user={sender?.[0]}/>
                </UserAvatar>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="subtitle1">Delegation Recipients</Typography>
                <DisabledUserAutoComplete />
              </Stack>
              <AssignSparxForm 
                onSumChange={onSumChange} 
                originalBudget={originalBudget}
                temporaryTransactions={temporaryTransactions}
                setTemporaryTransactions={setTemporaryTransactions}
              />
              <LeaveMessage required/>
            </Stack>
          </Grid>
        </ResponsiveContainer>
      </Container>
      <SuccessDialog open={successDialogOpen} onClose={()=> setSuccessDialogOpen(false)}/>
    </>
  );
};
