import { Stack, AvatarGroup, Typography, Avatar } from "@mui/material";
import { UserData } from "@/interface/";
import { formatNames, getAvatarSrc } from "@/utils";

interface GroupedAvatarsProps {
  users: UserData[];
  max?: number;
}

export const GroupedAvatars = ({ users, max = 3 }: GroupedAvatarsProps) => {
  const renderer = () => {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        {formatNames(users)}
      </Typography>
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap={1}
    >
      <AvatarGroup max={max} spacing={25}>
        {users?.slice(0, max).map((user, index) => {
          const { base64, initials } = getAvatarSrc(user);
          return (
            <Avatar src={base64} key={index} variant="circular">
              {initials}
            </Avatar>
          );
        })}
        ;
      </AvatarGroup>
      {renderer()}
    </Stack>
  );
};
