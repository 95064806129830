import { Box, Stack, styled, Typography } from "@mui/material";
import Image from "@/assets/Contributor.png";
import { Join } from "../../about/actions/Join";

type Props = {
  title: string;
  mutate: () => void;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  img: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(2),
    overflow: "hidden",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

export const NoContributions = ({ title, mutate }: Props) => {
  return (
    <StyledBox>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="img"
      >
        <img src={Image} alt="Join to contribute" />
      </Box>
      <Stack alignItems="center">
        <Typography variant="h6">No contributions</Typography>
        <Typography>Your contributions will show up here. </Typography>
      </Stack>
      <Stack alignItems="center">
        <Join display={true} mutate={mutate} />
      </Stack>

      <Stack alignItems="center">
        <Typography variant="body2">
          Join “<strong>{title}</strong>” and start contributing.
        </Typography>
        <Typography variant="caption">
          The top 20% of contributors will be rewarded with a bonus boost at the
          end of the year.
        </Typography>
        <Typography variant="caption">
          Join and log your own contributions to gain visibility and earn Sparx.
        </Typography>
      </Stack>
    </StyledBox>
  );
};
