import { Box } from "@mui/material";
import { useInitiativeState } from "@/state";
import { StyledDatePicker } from "./StyledDatePicker";

export const StartDate = () => {
  const { startDate, setState } = useInitiativeState();

  return (
    <Box flex={1}>
      <StyledDatePicker
        label="Start date"
        date={startDate}
        onChange={(date) =>
          setState({ startDate: date?.toISOString() ?? undefined })
        }
      />
    </Box>
  );
};
