import { useState } from "react";
import { ContributionData } from "@/interface";
import { alpha, Box, Stack, Typography } from "@mui/material";
import { SvgIcon } from "@mui/material";
import { FlagRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import { grey } from "@mui/material/colors";
import { LockedIcon } from "../../../../common/locked";
import { MarkdownRenderer, OverflowMenu } from "@/components";

type Props = {
  contribution: ContributionData;
  isLast: boolean;
  onDelete?: (contribution: ContributionData) => void;
  onEdit?: (contribution: ContributionData) => void;
};

export const Renderer = ({ contribution, isLast, onDelete, onEdit }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit?.(contribution);
    handleCloseMenu();
  };

  const handleDelete = () => {
    onDelete?.(contribution);
    handleCloseMenu();
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ ml: 1 }}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <SvgIcon
              fontSize="medium"
              sx={{
                borderRadius: "50%",
                background: "red",
                p: 0.5,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.light, 0.4),
              }}
            >
              <FlagRounded
                sx={{ color: (theme) => theme.palette.primary.dark }}
              />
            </SvgIcon>
            <Typography variant="subtitle2">Contributed</Typography>
            <LockedIcon fontSize="small" sx={{ color: grey[500] }} />
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2" sx={{ color: grey[500] }}>
              {dayjs(contribution.createdAt).format("ll")}
            </Typography>
            {(onEdit || onDelete) && (
              <OverflowMenu
                open={open}
                anchorEl={anchorEl}
                setOpen={handleOpenMenu}
                onClose={handleCloseMenu}
                onEdit={handleEdit}
                onDelete={handleDelete}
                deleteCaption="Delete"
              />
            )}
          </Stack>
        </Box>
        <Box className={`divider ${isLast ? "last" : ""}`} sx={{ pl: 3 }}>
          <MarkdownRenderer markdown={contribution.details} />
          {contribution?.updatedAt && (
            <>
              <Typography variant="body2" color="text.disabled">
                (edited)
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
