import { useState } from "react";

import { ContributionData, ContributionsByInitiative } from "@/interface";
import { Box, Stack, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { UserAvatar } from "@/components";
import { ContributionRenderer } from "./ContributionRenderer";
import { CategoryInfo } from "./CategoryInfo";
import { InitiativeInfo } from "./InitiativeInfo";
import { DeleteContribution } from "@/pages/common/Delete";
import { EditContribution } from "@/pages/common/Edit";
import { useUserState } from "@/state";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  ".divider": {
    borderLeft: `2px solid ${grey[200]}`,
    marginLeft: theme.spacing(2.5),
  },
  ".last": {
    borderColor: `transparent`,
  },
}));

type Props = {
  data: ContributionsByInitiative[];
  onMutate: () => void;
};

export const ContributionList = ({ data, onMutate }: Props) => {
  const { userData } = useUserState();
  const [open, setOpen] = useState<"delete" | "edit">();
  const [contribution, setContribution] = useState<ContributionData>();

  const onAction = (
    action: "delete" | "edit",
    contribution: ContributionData
  ) => {
    setOpen(action);
    setContribution(contribution);
  };

  const renderer = (data: ContributionsByInitiative) => {
    const { items, initiative } = data;

    return (
      <StyledContainer key={initiative.id} gap={1}>
        <Box display="flex" justifyContent="space-between">
          <UserAvatar user={userData}>
            <InitiativeInfo data={data} />
          </UserAvatar>
          <CategoryInfo data={data} />
        </Box>

        <Box className="divider" sx={{ height: 10 }} />

        {items.map((item, index) => {
          return (
            <ContributionRenderer
              key={item.id}
              contribution={item as ContributionData}
              isLast={index === items.length - 1}
              onDelete={(c) => onAction("delete", c)}
              onEdit={(c) => onAction("edit", c)}
            />
          );
        })}
      </StyledContainer>
    );
  };

  return (
    <>
      <Stack gap={1} sx={{ mt: 2 }}>
        {data.map(renderer)}
      </Stack>

      <DeleteContribution
        open={open === "delete"}
        contribution={contribution}
        onClose={() => setOpen(undefined)}
        mutate={onMutate}
      />

      <EditContribution
        open={open === "edit"}
        title={contribution?.initiative?.title ?? "External contribution"}
        contribution={contribution}
        onClose={() => setOpen(undefined)}
        mutate={() => {
          setOpen(undefined);
          onMutate();
        }}
      />
    </>
  );
};
