import { Box, Stack, Typography, useTheme } from "@mui/material";
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import {
  Category,
  CategoryData,
  InitiativeOffice,
  LinkItem,
} from "@/interface";
import { Container } from "./Container";
import { Links } from "./Links";

import { CategoryIcon, MarkdownRenderer } from "@/components";

type Props = {
  description: string;
  category?: CategoryData;
  subCategories: Category[];
  region: string;
  offices: InitiativeOffice[];
  links: LinkItem[];
};

export const Overview = ({
  description,
  category,
  subCategories,
  region,
  offices,
  links,
}: Props) => {

  return (
    <Container>
      <Stack spacing={2}>
        <Typography variant="h6">About</Typography>

        <MarkdownRenderer markdown={description} />

        <Renderer
          section={category?.name ?? ""}
          value={subCategories.map((sub) => sub.name)}
          icon={<CategoryIcon name={category?.name ?? ""} />}
        />
        <Renderer section="Region" value={[region]} icon={<PublicRoundedIcon color="action"/>} />
        <Renderer
          section="Office"
          value={offices.map((office) => office.label)}
          icon={<CorporateFareRoundedIcon color="action" />}
          skip={offices.length === 0}
        />
        <Links links={links} />
      </Stack>
    </Container>
  );
};

type RendererProps = {
  section: string;
  value: string[];
  icon: JSX.Element;
  skip?: boolean;
};

const Renderer = ({ section, value, icon, skip }: RendererProps) => {
  const theme = useTheme();

  if (skip) return null;
  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      {icon}
      <Stack>
        <Typography variant="caption" color={theme.palette.grey[300]}>{section}</Typography>
        <Typography variant="body2">{value.join(" • ")}</Typography>
      </Stack>
    </Box>
  );
};
