import { API_ENDPOINTS, fetcher } from "@/config";
import { Category } from "@/interface";
import { useUserState } from "@/state";
import useSWR from "swr";

export const useCategories = () => {
  const { authToken } = useUserState();

  const { data, isLoading, error } = useSWR<Category[]>(
    `${API_ENDPOINTS.initiatives.categories}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );
  return {
    categories: data,
    isLoading,
    error,
  };
};
