import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import type { MenuItem } from "@/interface";
import { ProfileDropdown } from "..";
// components
import { MenuHeader } from "./MenuHeader";
import { StyledMenuItem } from "./MenuItem";
import { Drawer } from "./Drawer";
import { getActiveState } from "@/utils";
import { useUIActionsState } from "@/state";

type MenuProps = {
  items: MenuItem[];
};

function Menu({ items }: MenuProps) {
  const { isMenuOpen: open, setMenuOpen: setOpen } = useUIActionsState();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Drawer open={open}>
      <Box
        sx={{
          pb: 2,
          height: 1,
          display: "flex",
          flexDirection: "column",
          gap: 6,
        }}
      >
        <MenuHeader open={open} onExpand={setOpen} />

        <List>
          {items.map((item) => (
            <ListItem key={item.label} disablePadding>
              <StyledMenuItem
                active={getActiveState(location.pathname, item.to ?? "")}
                onClick={() => {
                  item.to && navigate(item.to);
                }}
              >
                <Tooltip title={item.label} placement="right">
                  <ListItemIcon>{item.icon}</ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={item.label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </StyledMenuItem>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <ProfileDropdown open={open} />
      </Box>
    </Drawer>
  );
}

export { Menu };
