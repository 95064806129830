import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useSearchState, useUserState } from "@/state";
import {
  ShoutoutData,
  ShoutoutPaginationData,
} from "@/interface";
import { useEffect, useState } from "react";
import {
  toValues,
} from "@/utils";
import { useReactions } from "./useReactions.ts";

export const useShouts = () => {
  const [page, setPage] = useState(1);
  const { regions, offices } = useSearchState();
  const [reset, setReset] = useState(false);
  const { authToken } = useUserState();
  const [shoutouts, setShoutouts] = useState<ShoutoutData[]>([]);
  const { addReaction, deleteReaction } = useReactions(setShoutouts);
  const [queryParams, setQueryParams] = useState<string>("");

  const { data, isLoading, mutate } = useSWR<ShoutoutPaginationData>(
    `${API_ENDPOINTS.shoutouts.root}?page=${page}&size=${20}&${queryParams}`,
    (url: string) => {
      return fetcher(url, { authToken });
    },
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    const queryParams = [];

    const gatherLocations = (...locations: any[]) =>
      locations
        .filter((location) => Object.keys(location).length > 0)
        .map((location) => toValues(location));

    const officeLocations = gatherLocations(offices);
    const regionLocations = gatherLocations(regions);

    if (officeLocations.length > 0) {
      const params = encodeURIComponent(officeLocations.flat().join(","));
      queryParams.push(`officeLocations=${params}`);
    }
    if (regionLocations.length > 0) {
      const params = encodeURIComponent(regionLocations.flat().join(","));
      queryParams.push(`regions=${params}`);
    } 
    
    const query = queryParams.length > 0 ? `${queryParams.join("&")}` : "";
    setQueryParams(query);
    setPage(1);
    setReset(true);
  }, [regions, offices]);

  useEffect(() => {
    const items = (data?.items ?? []) as ShoutoutData[];
    if (reset) {
      setShoutouts(items);
      setReset(false);
    } else {
      setShoutouts([...shoutouts, ...items]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const total = data?.totalItems ?? 0;

  const loadMore = () => {
    if (shoutouts.length < total) setPage((page) => page + 1);
  };

  const refresh = () => {
    setPage(1);
    setReset(true);
    mutate();
  };

  const deleteShoutout = (shoutoutId: string) => {
    setShoutouts(
      [...shoutouts].filter((shoutout) => shoutout.id !== shoutoutId)
    );
  };

  const editShoutout = (shoutout: ShoutoutData) => {
    setShoutouts(
      [...shoutouts].map((shout) => {
        if (shout.id === shoutout.id) return { ...shout, ...shoutout };
        return { ...shout };
      })
    );
  };

  return {
    shoutouts,
    total,
    isLoading,
    loadMore,
    refresh,
    deleteShoutout,
    editShoutout,
    addReaction,
    deleteReaction,
  };
};
