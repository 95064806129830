import {
  Box,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { useBulkSparxState,  } from "@/state";
import { untilMonths } from "@/utils";

export const Header = () => {
  const { sparx } = useBulkSparxState();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Stack>
        <Typography variant="h5">Review & Reward Contributions</Typography>
        <Box display="flex">
          <Typography variant="body2">Send Sparx to recognize contributions before</Typography>
          <Chip
            label={untilMonths(new Date(sparx?.cycle.endDate ?? ""))}
            sx={{ ml: 1 }}
          />
        </Box>
      </Stack>
    </Box>
  );
};
