import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { Gender } from "@/interface";

export const useGenders = () => {
  const { authToken } = useUserState();

  const { data, isLoading, error } = useSWR<Gender[]>(
    `${API_ENDPOINTS.admin.sparx_analysis.genders}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    genders: data,
    isLoading,
    error,
  };
};
