import { PropsWithChildren } from "react";
import { Card, CardContent, Typography } from "@mui/material";

type Props = PropsWithChildren<{
  title?: string;
  subTitle?: string;
}>;

export const GeneralCard = ({ title, subTitle, children }: Props) => (
  <Card sx={{ height: 'auto', borderRadius: 2, boxShadow: 3, backgroundColor: "white" }}>
    <CardContent>
      {title && <Typography fontWeight="bold">
        {title}
      </Typography>}
      {subTitle && <Typography variant="body2">
        {subTitle}
      </Typography>}
      {children}
    </CardContent>
  </Card>
);
