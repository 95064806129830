import { API_ENDPOINTS, fetcher } from "@/config";
import { InitiativeOffice } from "@/interface";
import { useUserState } from "@/state";
import { useMemo } from "react";
import useSWR from "swr";

export const useOffices = () => {
  const { authToken } = useUserState();

  const { data, isLoading, error } = useSWR<Record<string, InitiativeOffice[]>>(
    `${API_ENDPOINTS.offices}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  const { regions, offices } = useMemo(() => {
    const regions: string[] = ["global"];
    const offices: Record<string, InitiativeOffice[]> = { ["global"]: [] };

    for (const region in data) {
      regions.push(region);
      offices[region] = data[region].map((office) => {
        return { ...office, region };
      });

      offices["global"].push(...offices[region]);
    }

    return { regions, offices };
  }, [data]);

  return {
    data,
    regions,
    offices,
    isLoading,
    error,
  };
};
