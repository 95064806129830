import {
  BadgeTemplate,
  BookmarkData,
  Category,
  ContributionData,
  EnrollmentBadge,
  EnrollmentData,
  InitiativeOffice,
} from ".";
import { UserData } from "./UserData";

export enum CommitmentEnum {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
}

export enum ParticipationEnum {
  REMOTE = "Remote",
  HYBRID = "Hybrid",
  IN_PERSON = "In person",
}

export type InitiativeErrors = {
  title?: string;
  description?: string;
  categoryId?: string;
  location?: string;
  reviewers?: string;
};

export interface InitiativeBase {
  title: string;
  description: string;
  categoryId: string;
  category?: CategoryData;
  subCategories: Category[];
  location: string[];
  region: string;
  offices: InitiativeOffice[];
  collaboration: boolean;
  commitment: CommitmentEnum;
  participation: ParticipationEnum;
  owners: UserData[];
  reviewers: UserData[];
  sponsors?: UserData[];
  links: LinkItem[];
  startDate?: string;
  endDate?: string;
}

interface InitiativeBadge {
  id: number;
  initiativeId: number;
  imageUrl: string;
  enrollmentBadge: EnrollmentBadge[];
  badgeTemplate: BadgeTemplate;
  initiative: string;
}

interface LinkItem {
  url: string;
  label: string;
}

interface OfficeLocation {
  id: string;
  name: string;
}

interface Office {
  createdAt?: string;
  deletedAt?: string;
  id?: number;
  initiativeId?: number;
  office?: OfficeLocation;
  officeId: string;
}

interface InitiativeData {
  id: string;
  title: string;
  slug?: string;
  details: string;
  status: string;
  xApiKey?: string;
  estimate?: string;
  createdAt: string;
  updatedAt?: string;
  endDate: string;
  bookmark?: BookmarkData[];
  enrollment?: EnrollmentData[];
  initiativeBadge?: InitiativeBadge[];
  initiativeCategories: InitiativeCategory[];
  collaboration: boolean;
  commitment: string;
  participation: string;
  related?: InitiativeData[];
  offices?: Office[];
  region?: string;
  participationType?: string;
  links?: LinkItem[];
  owners?: InitiativeOwnershipData[];
  mdps?: InitiativeOwnershipData[];
  reviewers?: InitiativeOwnershipData[];
  reviewNote?: string;
  // Added fields
  startDate?: string;
  categoryId?: string;
  subCategoryIds?: string[];
  estimateType?: string;
  numParticipants?: number;
  contributions?: ContributionData[];
  category?: CategoryData;
  deletedAt?: string;
}

export interface InitiativeCategory {
  id: string;
  initiativeId: string;
  categoryId: string;
  category: CategoryData;
}

export interface CategoryData {
  id: string;
  name: string;
  description?: string;
  badgeTemplate?: string[];
  initiativeCategory?: string[];
}

interface InitiativeOwnershipData {
  createdAt: string;
  deletedAt?: string;
  id: string;
  initiativeId: number;
  userId: string;
  user: UserData;
}

export enum TeamKeys {
  "initiativeOwners" = "initiativeOwners",
  "initiativeMdps" = "initiativeMdps",
}

export type Team = Record<TeamKeys, Partial<UserData>[] | undefined>;

export type { InitiativeData, InitiativeOwnershipData, LinkItem };

export type ValidationResult = {
  isValid: boolean;
  labelValidation?: string;
  urlValidation?: string;
};

export type PaginatedInitiatives = {
  items: InitiativeData[];
  page: number;
  size: number;
  totalItems: number;
};
