import { Rewards } from "@/interface";
import { create } from "zustand";

export type OpenCollectRewardsModalType = "collect" | "locked" | undefined;

interface RewardsState {
  openCollectRewardsModal: OpenCollectRewardsModalType;
  openRewardsModal: boolean;
  openSlackDialog: boolean;
  symbol?: string;
  reward?: Rewards;
  //
  setState: (args: Partial<RewardsState>) => void;
  reset: () => void;
}

const useRewardsState = create<RewardsState>((set) => ({
  openCollectRewardsModal: undefined,
  openRewardsModal: false,
  openSlackDialog: false,

  setState: (args?: Partial<RewardsState>) => {
    set({ ...args });
  },

  reset: () => {
    set({
      openCollectRewardsModal: undefined,
      openRewardsModal: false,
      openSlackDialog: false,
      symbol: undefined,
      reward: undefined,
    });
  },
}));

export { useRewardsState };
