import { Paper } from "@mui/material";
import { PropsWithChildren } from "react";

const BorderedSection = ({ children }: PropsWithChildren) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: 2,
        bgcolor: (theme) => theme.palette.background.default,
        border: "1px solid",
        borderColor: (theme) => theme.palette.divider,
        overflow: "hidden",
        "&>div:not(:last-child)": {
          borderBottom: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      }}
    >
      {children}
    </Paper>
  );
};

export default BorderedSection;
