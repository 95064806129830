import { useDeleteContribution } from "@/hooks/api";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";
import { ContributionData } from "@/interface";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  contribution?: ContributionData;
  onClose: () => void;
  mutate: () => void;
};

export const DeleteContribution = ({
  open,
  contribution,
  onClose,
  mutate,
}: Props) => {
  const { trigger, isMutating } = useDeleteContribution(
    contribution?.id as string
  );

  const onLeave = () => {
    trigger().then(() => {
      onClose();
      mutate();
    });
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">Delete Contribution</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={2}>
          Are you sure you want to delete this contribution? By doing so, it
          will be permanently deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <LoadingButton
          startIcon={<DeleteRounded />}
          loading={isMutating}
          onClick={onLeave}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};
