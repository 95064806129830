import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";


export const RewardsPlaceholder = () => {
  return (
    <QuestionMarkRoundedIcon
      sx={(theme) => ({
        backgroundColor: theme.palette.grey.A200,
        borderRadius: "8px",
        padding: "4px",
        color: "white",
      })}
    />
  );
};