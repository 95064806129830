import { useEffect, useState } from "react";
import { Box, CardContent, Stack, Typography } from "@mui/material";
import { UserAvatar, UserInfo } from "@/components";
import { Reward } from "@/interface";
import { formatDateLongMonth } from "@/utils";
import { Crosspost } from "./Crosspost";
import { SlackDialog } from "./SlackDialog";
import { useRewardsState } from "@/state";
// icons
import Sparx_1 from "@/assets/reward/Sparx_1.svg";
import Sparx_2 from "@/assets/reward/Sparx_2.svg";
import Sparx_3 from "@/assets/reward/Sparx_3.svg";
import Sparx_4 from "@/assets/reward/Sparx_4.svg";
import Sparx_5 from "@/assets/reward/Sparx_5.svg";
import Sparx_6 from "@/assets/reward/Sparx_6.svg";
import Sparx_7 from "@/assets/reward/Sparx_7.svg";
import Sparx_8 from "@/assets/reward/Sparx_8.svg";
import Sparx_9 from "@/assets/reward/Sparx_9.svg";
import Sparx_10 from "@/assets/reward/Sparx_10.svg";

type Props = {
  reward: Reward;
};

const icons = [
  Sparx_1,
  Sparx_2,
  Sparx_3,
  Sparx_4,
  Sparx_5,
  Sparx_6,
  Sparx_7,
  Sparx_8,
  Sparx_9,
  Sparx_10,
];

export const Transaction = ({ reward }: Props) => {
  const { openSlackDialog, setState } = useRewardsState();
  const { sender, createdAt, details, amount } = reward;
  const [currentIcon, setCurrentIcon] = useState(0);
  const [revealed, setRevealed] = useState(false);

  useEffect(() => {
    if (revealed) return;
    if (amount && currentIcon < amount - 1) {
      const timer = setInterval(() => {
        setCurrentIcon((prevIcon) => prevIcon + 1);
      }, 100);
      return () => clearInterval(timer);
    } else {
      setRevealed(true);
    }
  }, [currentIcon, amount, revealed]);

  const handleOutlookClick = () => {
    window.location.href = `mailto:${sender?.email || ""}?subject=Thanks%20for%20the%20sparx!`;
  };

  return (
    <>
      <Box className="media">
        <img src={icons[currentIcon]} />
      </Box>

      <CardContent sx={{ px: 2, width: 412 }}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <UserAvatar user={sender}>
              <Stack justifyContent="center" spacing={0}>
                <UserInfo user={sender} />
                <Typography variant="caption" color="text.secondary">
                  Sent you Sparx
                </Typography>
              </Stack>
            </UserAvatar>
            {createdAt && (
              <Typography variant="body2" color="text.secondary">
                {formatDateLongMonth(new Date(createdAt))}
              </Typography>
            )}
          </Stack>
          {details && (
            <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
              {details}
            </Typography>
          )}
          <Crosspost onClickOutlook={handleOutlookClick}/>
        </Stack>
      </CardContent>

      {openSlackDialog && <SlackDialog open={openSlackDialog} onCancel={() => setState({ openSlackDialog: false})} author={sender}/>}

    </>
  );
};
