import { useInitiativeMutation, useLeaveInitiative } from "@/hooks/api";
import { useInitiativeState } from "@/state";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Owners } from "../../../common/team/Owners";
import { PropsWithChildren, useState } from "react";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  enrollmentId: number | string;
  onClose: () => void;
  title: string;
};

export const TransferDialog = ({
  open,
  enrollmentId,
  onClose,
  title,
}: Props) => {
  const { id } = useParams();
  const [step, setStep] = useState<"confirm" | "transfer">("confirm");
  const { getInitiative } = useInitiativeState();
  const { trigger: mutationTrigger, isMutating: mutationIsMutating } =
    useInitiativeMutation("PATCH", id ?? "");
  const { trigger: leaveTrigger, isMutating: leaveIsMutating } =
    useLeaveInitiative(id ?? "");
  const isMutating = mutationIsMutating || leaveIsMutating;

  const handleClose = () => {
    onClose();
    setStep("confirm");
  };

  const onTransfer = () => {
    const { fields } = getInitiative();
    const { owners } = fields;
    /* If user is the only owner, update owners field with new owner and remove user from initiative */
    mutationTrigger({ data: { fields: { owners } } })
      .then(() => leaveTrigger({ enrollmentId }))
      .then(() => handleClose());
  };

  const Confirm = () => (
    <Dialog
      title="Leave Initiative?"
      subTitle="You will need to transfer ownership to leave this initiative."
      step={"confirm"}
    >
      <Button onClick={onClose} variant="outlined" color="secondary">
        Cancel
      </Button>
      <LoadingButton
        loading={isMutating}
        onClick={() => setStep("transfer")}
        variant="contained"
        color="error"
      >
        Continue
      </LoadingButton>
    </Dialog>
  );

  const Transfer = () => (
    <Dialog
      title="Transfer Ownership?"
      subTitle={`Choose a new owner for "${title}". The new initiative owner will be notified`}
      step={"transfer"}
      el={<Owners />}
    >
      <Button
        onClick={() => setStep("confirm")}
        color="secondary"
        variant="outlined"
      >
        Back
      </Button>
      <LoadingButton
        loading={isMutating}
        onClick={onTransfer}
        variant="contained"
        color="error"
      >
        Transfer ownership
      </LoadingButton>
    </Dialog>
  );

  return (
    <CustomDialog open={open} onClose={handleClose}>
      {step === "confirm" && <Confirm />}
      {step === "transfer" && <Transfer />}
    </CustomDialog>
  );
};

type DialogProps = PropsWithChildren<{
  title: string;
  subTitle: string;
  step: "confirm" | "transfer";
  el?: JSX.Element;
}>;

const Dialog = ({ title, subTitle, step, el, children }: DialogProps) => {
  return (
    <>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={2}>
          {subTitle}
        </DialogContentText>
        {el}
      </DialogContent>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2" color="text.secondary" p={2}>
          {step === "confirm" ? "1 out of 2" : "2 out of 2"}
        </Typography>
        <DialogActions>{children}</DialogActions>
      </Grid>
    </>
  );
};
