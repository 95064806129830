import { CustomToolTip } from "@/components";
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import { SvgIconProps, Typography, tooltipClasses } from "@mui/material";
import { grey } from "@mui/material/colors";

export const LockedIcon = ({ ...rest }: SvgIconProps) => {
  return (
    <CustomToolTip
      title={
        <Typography variant="caption">
          Only initiative owners and BCG X Leadership can see your contributions
        </Typography>
      }
      placement="bottom"
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: grey[600],
          color: (theme) => theme.palette.common.white,
        },
      }}
    >
      <LockPersonRoundedIcon {...rest} />
    </CustomToolTip>
  );
};
