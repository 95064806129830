import { useInitiativeState } from "@/state";
import { Box, Button, IconButton } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { UserData } from "@/interface";
import { UserAutocomplete } from "@/components";

export const Sponsors = () => {
  const { setState, sponsors } = useInitiativeState();

  const addSponsors = (sponsors: UserData[]) => setState({ sponsors });
  const removeSponsors = () => setState({ sponsors: undefined });
  const onChange = (reviewers: UserData[]) => addSponsors(reviewers);

  const showButton = sponsors === undefined;

  return showButton ? (
    <Box display="flex" justifyContent="flex-end">
      <Button
        variant="text"
        color="primary"
        startIcon={<AddRoundedIcon />}
        onClick={() => addSponsors([])}
      >
        MDP Sponsor
      </Button>
    </Box>
  ) : (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <UserAutocomplete
        value={sponsors ?? []}
        onChange={onChange}
        sx={{ flex: 1 }}
        label="MDP Sponsor"
        role="mdp"
      />
      <IconButton size="small" onClick={removeSponsors}>
        <DeleteRoundedIcon />
      </IconButton>
    </Box>
  );
};
