import { Box, BoxProps } from "@mui/material";
import { ReactionType } from "@/enums";
import { toUnicode } from "./utils";

type Props = {
  emojiType: keyof typeof ReactionType;
} & BoxProps;

export const Emoji = ({ emojiType, ...rest }: Props) => {
  const unicode = toUnicode[emojiType];
  return <Box {...rest}>{unicode}</Box>;
};
