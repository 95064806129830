import {
  ContributionData,
  ContributionsByInitiative,
  Contributor,
} from "@/interface";
import { Box, Stack, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { UserAvatar } from "@/components";
import { ContributionRenderer } from "./ContributionRenderer";
import { InitiativeInfo } from "./InitiativeInfo";
import { CategoryInfo } from "./CategoryInfo";
import { useSearchState } from "@/state";
import { hasCommon } from "@/utils";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  ".divider": {
    borderLeft: `2px solid ${grey[200]}`,
    marginLeft: theme.spacing(2.5),
  },
  ".last": {
    borderColor: `transparent`,
  },
}));

type Props = {
  contributor?: Contributor;
  data: ContributionsByInitiative[];
};

export const ContributionList = ({ contributor, data }: Props) => {
  const { isFilterApplied } = useSearchState();
  const set = new Set(contributor?.totalContributionsIds);
  const filterApplied = isFilterApplied();

  const renderer = (data: ContributionsByInitiative) => {
    const { items, initiative } = data;

    return (
      <StyledContainer
        key={initiative.id}
        sx={{
          ...(filterApplied && {
            // Highlight only when a filter is applied
            opacity: hasCommon(
              items.map(({ id }) => id as string),
              set
            )
              ? 1
              : 0.2,
          }),
        }}
        gap={1}
      >
        <Box display="flex" justifyContent="space-between">
          <UserAvatar user={contributor?.user}>
            <InitiativeInfo data={data} />
          </UserAvatar>
          <CategoryInfo data={data} />
        </Box>

        <Box className="divider" sx={{ height: 10 }} />

        {items.map((item, index) => {
          return (
            <ContributionRenderer
              key={item.id}
              contribution={item as ContributionData}
              isLast={index === items.length - 1}
            />
          );
        })}
      </StyledContainer>
    );
  };

  return (
    <Stack gap={4} sx={{ mt: 2 }}>
      {data.map(renderer)}
    </Stack>
  );
};
