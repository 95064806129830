import { CommitmentEnum } from "@/interface";
import { useInitiativeState } from "@/state";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Typography,
  Stack,
  Paper,
} from "@mui/material";
import { commitmentHelper } from "../helpers";

export const Commitment = () => {
  const { commitment, setState } = useInitiativeState();

  const { getContent, getIcon, getHoursPerWeek } = commitmentHelper();

  const radioRenderer = (value: CommitmentEnum) => {
    return (
      <FormControlLabel
        value={value}
        control={<Radio size="small" color="secondary" />}
        label={
          <Stack direction="row" gap={1}>
            {getIcon(value, false)}
            {value}
            <Typography variant="caption" color="text.secondary">
              (~ {getHoursPerWeek(value)})
            </Typography>
          </Stack>
        }
      />
    );
  };

  return (
    <Box display="flex" gap={3}>
      <FormControl>
        <Typography color="text.secondary" paddingBottom={2}>
          Commitment
        </Typography>
        <RadioGroup
          value={commitment}
          onChange={(_, value) =>
            setState({ commitment: value as CommitmentEnum })
          }
        >
          {radioRenderer(CommitmentEnum.LOW)}
          {radioRenderer(CommitmentEnum.MEDIUM)}
          {radioRenderer(CommitmentEnum.HIGH)}
        </RadioGroup>
      </FormControl>
      <Box
        display="flex"
        alignItems="flex-end"
        sx={{ px: 1, mt: 4, width: 0.5 }}
      >
        {renderer(commitment, getContent(commitment))}
      </Box>
    </Box>
  );
};

const renderer = (key: CommitmentEnum, content: string) => {
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography
        sx={{ mb: 2 }}
        fontWeight={700}
      >{`${key} commitment`}</Typography>
      <Typography variant="body2">{content}</Typography>
    </Paper>
  );
};
