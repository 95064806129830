import { Stack, AvatarGroup, Avatar } from "@mui/material";
import { UserData } from "@/interface/";
import { getAvatarSrc } from "@/utils";

interface GroupedAvatarsProps {
  users: UserData[];
  amount: number;
}

export const GroupedAvatars = ({ users, amount }: GroupedAvatarsProps) => {
  const difference = amount - users.length;

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
      <AvatarGroup max={4}>
        {users?.map((user, index) => {
          const { base64, initials } = getAvatarSrc(user);
          return (
            <Avatar src={base64} key={index} sx={{ width: 64, height: 64 }}>
              {initials}
            </Avatar>
          );
        })}
        {difference > 0 && (
          <Avatar sx={{ width: 64, height: 64 }}>
            +{difference}
          </Avatar>
        )}
      </AvatarGroup>
    </Stack>
  );
};
