import { Typography, Button, Box, styled, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import background from "@/assets/Galaxy Full Background.svg";

const Root = styled(Box)({
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  display: "grid",
  placeItems: "center",
});

export const ErrorPage = () => {
  const navigate = useNavigate();
  const toHome = () => navigate(ROUTES.root);

  return (
    <Root>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h4" color="background.default" fontWeight="500">
          Page not found
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="background.default"
          fontWeight="400"
          width={300}
        >
          Either this page doesn’t exist or you don’t have permission to access
          it.
        </Typography>
        <Button variant="contained" color="primary" onClick={toHome}>
          <HomeRoundedIcon sx={{ fontSize: "20px", marginRight: "8px" }} />
          Back to home
        </Button>
      </Stack>
    </Root>
  );
};
