import { Theme, alpha } from "@mui/material/styles";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    badge: true;
    rectangle: true;
    active: true;
    closed: true;
  }
}

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      variants: [
        {
          props: { variant: "badge" },
          style: {
            background: theme.palette.grey[400],
            padding: theme.spacing(0.8),
          },
        },
        {
          props: { variant: "active" },
          style: {
            background: "white",
            padding: "4px 10px 4px 10px",
            border: `1px solid ${theme.palette.grey[100]}`,
            height: theme.spacing(4),
          },
        },
        {
          props: { variant: "closed" },
          style: {
            padding: "4px 10px 4px 10px",
            height: theme.spacing(4),
            color: alpha("#4C4C4F", 0.68),
          },
        },
      ],
      styleOverrides: {
        root: () => ({
          height: "auto",
        }),
        icon: {
          margin: 0,
        },
      },
    },
  };
}
