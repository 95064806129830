import { LinkItem } from "@/interface";
import { Button, Link, Stack, Typography } from "@mui/material";
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

type Props = {
  links: LinkItem[];
};

export const Links = ({ links }: Props) => {
  if (links?.length === 0) return null;
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">Links</Typography>
      <Stack direction="row" alignItems="center">
        {links.map(({ label, url }, index) => {
          const key = `${url}-${index}`;
          return (
            <Button
              key={key}
              variant="link"
              href={url}
              LinkComponent={Link}
              startIcon={<LaunchRoundedIcon />}
              disableRipple
            >
              <Typography variant="body2">{label}</Typography>
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};
