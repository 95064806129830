import { useState } from "react";
import { Box, TextField, Typography, styled } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(1),
}));

type Props = {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

export const DelegateMessage = ({message, setMessage}: Props) => {
  const [error, setError] = useState(false);
  const handleBlur = () => {
    if (!message) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <StyledBox>
      <Typography variant="subtitle2" mb={1}>
        Leave a message
      </Typography>
      <TextField
        required
        helperText="*This field is required. Describe how the delegated tokens should be used."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onBlur={handleBlur}
        error={error}
        multiline
        rows={3}
        variant="outlined"
      />
    </StyledBox>
  );
};
