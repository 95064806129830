import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { untilMonths } from "@/utils";
import BudgetCardViz from "@/assets/BudgetCardViz.svg";

const StyledCard = styled(Card)({
  background:
    "radial-gradient(176.35% 119.12% at 69.88% 31.65%, #194CC3 2.24%, #062675 48.96%, #031A57 81.87%)",
  position: "relative",
});

const StyledAlert = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  background: theme.palette.customColors.lightRed,
  color: theme.palette.customColors.darkRed,
  flex: 1,
}));

type Props = {
  headline: string;
  balance: number;
  dateUntil: string;
  showAlert?: boolean;
};

export const BalanceCard = ({ headline, balance, dateUntil, showAlert = true }: Props) => {

  return (
    <div>
      <StyledCard
        sx={(theme) => ({
          boxShadow: theme.shadows[4],
          color: theme.palette.primary.contrastText,
          padding: theme.spacing(2),
          border: "none",
        })}
      >
        <CardMedia
          component="img"
          image={BudgetCardViz}
          sx={{
            position: "absolute",
            bottom: 23,
            right: 0,
            width: "auto",
          }}
        />
        <CardContent>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">{headline}</Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="h3"
                mt={3}
                sx={(theme) => ({
                  boxShadow: theme.shadows[2],
                })}
              >
                {balance}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Available until{" "}
                  {untilMonths(new Date(dateUntil ?? ""))}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </StyledCard>

      {showAlert && (<Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        {balance < 0 && (
          <StyledAlert variant="subtitle2" textAlign="center">
            <b>{balance} sparx</b> Budget not available
          </StyledAlert>
        )}
      </Box>)}
    </div>
  );
};
