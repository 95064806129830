import { Stack, Typography } from "@mui/material";

export const Header = () => {

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Stack spacing={1}>
        <Typography variant="h5" component="h1">
          Cycles
        </Typography>
        <Typography variant="body1">
          Manage cycles end date and monitor distribution
        </Typography>
      </Stack>
    </Stack>
  );
};
