import { useMemo } from "react";
import { useEnrollments } from "@/hooks/api";
import { useActivitiesState } from "@/state";
import { toInitiatives } from "@/utils";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export const InitiativeSelector = () => {
  const { data: enrollments } = useEnrollments();
  const { selectedInitiative, setState } = useActivitiesState();
  const initiatives = useMemo(() => toInitiatives(enrollments), [enrollments]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedInitiative = initiatives.find(
      (initiative) => initiative.id === event.target.value
    );
    setState({ selectedInitiative });
  };

  return (
    <FormControl size="small" sx={{ width: 350 }}>
      <InputLabel id="Initiative-label">Initiatives</InputLabel>
      <Select
        labelId="Initiative-label"
        value={selectedInitiative?.id ?? ""}
        label="Initiatives"
        onChange={handleChange}
      >
        {initiatives?.map((initiative) => {
          return (
            <MenuItem key={initiative.id} value={initiative.id}>
              {initiative?.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
