import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useDeleteShoutout = (shoutoutId?: string) => {
  const { authToken } = useUserState();

  const dropInitiative = async (url: string) => {
    await fetcher(
      url,
      {
        config: {
          method: "DELETE",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Shout-out deleted",
      }
    );
  };

  const { trigger, isMutating } = useSWRMutation(
    shoutoutId ? `${API_ENDPOINTS.me.shoutouts.given}/${shoutoutId}` : null,
    dropInitiative
  );

  return {
    trigger,
    isMutating,
  };
};
