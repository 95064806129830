import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';

type ItemProps = {
  caption: string;
  title: string;
  content: string;
  onClick: () => void;
};

type Props = {
  items: ItemProps[];
};

export const GuidelineCard = ({ items }: Props) => {
  return (
    <Card
      sx={{
        background: (theme) => theme.palette.common.white,
        minHeight: "175px",
      }}
      elevation={0}
    >
      {items.map((item, index) => (
        <Fragment key={index}>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="overline">{item.caption}</Typography>
              <Typography variant="subtitle1">{item.title}</Typography>
              <Typography variant="body2">{item.content}</Typography>
            </Stack>
          </CardContent>
          <CardActions sx={{ paddingY: 1 }}>
            <IconButton sx={{ marginLeft: "auto" }} onClick={item.onClick}>
              <ArrowOutwardRoundedIcon />
            </IconButton>
          </CardActions>
          {index < items.length - 1 && <Divider sx={{ marginX: 2 }} />}
        </Fragment>
      ))}
    </Card>
  );
};
