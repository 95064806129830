import { Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren } from "react";
import { LockedIcon } from "../locked";

type Props = PropsWithChildren<{
  showFooter?: boolean;
}>;

export const EditorFooter = ({ children, showFooter = true }: Props) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      gap={1}
      mt={1}
    >
      {showFooter && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ color: grey[500] }}
        >
          <LockedIcon />
          <Typography variant="subtitle2">BCG X Leadership</Typography>
        </Stack>
      )}
      {children}
    </Box>
  );
};
