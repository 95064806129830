import * as React from "react";
import { ModalVariantsEnum } from "../enums/ModalVariants";
import { useUIActionsState, useUserState } from "../state";

type FetcherProps = {
  authToken?: string;
  config?: RequestInit;
};

type SnackbarProps = {
  variant: "success" | "error";
  isOpen: boolean;
  message: string;
  hasFooter?: boolean;
};

type ModalProps = {
  isOpen: boolean;
  content: React.ReactNode;
  variant: ModalVariantsEnum;
  actions?: React.ReactNode;
};

export const fetcher = async <T = any>(
  url: string,
  { authToken, config }: FetcherProps,
  snackbarOptions?: SnackbarProps,
  modalOptions?: ModalProps
): Promise<T> => {
  try {
    const response = await fetch(
      url,
      config || {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 401) {
      await useUserState.getState().refresh();

      return await fetcher(url, {
        authToken: useUserState.getState().authToken,
      });
    }

    if (response.status > 401 || response.status === 400) {
      const resp = (await response.json()) as {
        message?: string;
        error?: string;
      };
      throw new Error(resp.message || resp.error);
    }

    if (response.status === 204) return {} as T;

    if (snackbarOptions)
      useUIActionsState.getState().setSnackbarOpen(snackbarOptions);
    if (modalOptions) useUIActionsState.getState().setModalOpen(modalOptions);

    return getJSON(response);
  } catch (error) {
    let errorMessage =
      "We’re having some technical issues, contact our support team";
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    useUIActionsState.getState().setSnackbarOpen({
      variant: "error",
      isOpen: true,
      message: errorMessage,
      hasFooter: false,
    });
    throw error;
  }
};

const getJSON = async (response: Response) => {
  try {
    return await response.json();
  } catch (error) {
    return {};
  }
};
