import { ContributionData } from "./ContributionData";
import { CategoryData } from "./InitiativeData";
import { UserData } from "./UserData";

export enum ContibutionView {
  ALL,
  MINE,
}

export type GroupedContributions = {
  user: UserData;
  items: ContributionData[];
};

export type InitiativeOverview = {
  id?: string;
  title: string;
  category: CategoryData;
  subCategories: CategoryData[];
  region: { id: string; name: string };
};

export type ContributionsByInitiative = {
  initiative: InitiativeOverview;
  isExternal: boolean;
  items: ContributionData[];
};

export type ContributionInActiveCycle = {
  contributionsInActiveCycle: ContributionData[];
  title: string;
  totalContributions: number;
  totalSparxReceivedFromSender: number;
  userId: string;
  fullName: string;
};

export type Contributor = {
  // Fields sent by API
  user: UserData;
  totalContributionsIds: string[];
  totalSparxReceivedFromSender: number;
  // Fields used and computed locally on the front end
  amount?: number;
  error?: string;
  numberOfContributions: number;
};
