import { Box, Stepper as MuiStepper, Step, Button } from "@mui/material";
import { CustomStep } from "./Step";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { Reward } from "@/interface";

type Props = {
  rewards: Reward[];
  activeStep: number;
  onBack: () => void;
  onNext: () => void;
  onDone: () => void;
};

export const Stepper = ({
  rewards,
  activeStep,
  onBack,
  onNext,
  onDone,
}: Props) => {
  const onClick = () => {
    if (activeStep === rewards.length - 1) onDone();
    else onNext();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{ width: "100%", pb: 4 }}
    >
      <Button
        disabled={activeStep === 0}
        onClick={onBack}
        startIcon={<ChevronLeftRoundedIcon />}
      >
        Back
      </Button>

      <Box sx={{ overflow: "hidden" }}>
        <MuiStepper activeStep={activeStep}>
          {rewards.map((reward, index) => {
            return (
              <Step key={`${reward.id}-${index}`}>
                <CustomStep
                  isActive={activeStep === index}
                  type={reward.type}
                />
              </Step>
            );
          })}
        </MuiStepper>
      </Box>
      <Button onClick={onClick} endIcon={<ChevronRightRoundedIcon />}>
        {activeStep === rewards.length - 1 ? "Done" : "Next"}
      </Button>
    </Box>
  );
};
