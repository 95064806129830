import { API_ENDPOINTS, fetcher } from "@/config";
import { ActivityBodyRequest } from "@/interface";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";
import { useSearchParams } from "react-router-dom";

export const useSendTokens = () => {
  const { authToken } = useUserState();
  const [params] = useSearchParams();
  const activityId = params.get("id");

  async function sendRequest(
    url: string,
    { arg }: { arg: { data: ActivityBodyRequest } }
  ) {
    await fetcher(url, {
      config: {
        method: activityId ? "PATCH" : "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(arg.data),
      },
    });
  }

  const { data, trigger, isMutating, error } = useSWRMutation(
    activityId
      ? `${API_ENDPOINTS.tokens.activities}/${activityId}` // PATCH
      : API_ENDPOINTS.tokens.activities,
    sendRequest
  );

  return {
    trigger,
    activities: data,
    isMutating,
    error,
  };
};
