import { Box, Button, Stack, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";

type Props = PropsWithChildren<{ count: number }>;

export const ClosedCycles = ({ count, children }: Props) => {
  const [show, setShow] = useState(false);

  const onClick = () => setShow(!show);

  return (
    <Stack spacing={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography sx={{ color: (theme) => theme.palette.grey[200] }}>
          Previous ({count})
        </Typography>
        <Button
          disableRipple
          variant="text"
          color="secondary"
          onClick={onClick}
        >
          {show ? "Hide previous" : "Show previous"}
        </Button>
      </Box>
      {show && children}
    </Stack>
  );
};
