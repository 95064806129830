import { Box, Stack, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AddEndDate } from "./AddEndDate";
import { EndDate } from "./EndDate";
import { StartDate } from "./StartDate";

export const Dates = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6">Dates</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Box display="flex" gap={2}>
          <StartDate />
          <EndDate />
          <AddEndDate />
        </Box>
      </LocalizationProvider>
    </Stack>
  );
};
