import { useState } from "react";
import { AddReactionOutlined } from "@mui/icons-material/";
import { IconButton, Popover, Box, styled } from "@mui/material";
import { Picker } from "./ReactionsPicker";
import { Reaction } from "@/interface";
import { ReactionType } from "@/enums";
import { ReactionsRenderer } from "./ReactionsRenderer";
import { useUserState } from "@/state";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
}));

type Props = {
  reactions: Reaction[];
  onReact: (active: boolean, reactionId: string, type: ReactionType) => void;
};

export const Reactions = ({ reactions, onReact }: Props) => {
  const { userData } = useUserState();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReactionClick = (type: ReactionType) => {
    const reaction = reactions.find((r) => r.reactionType === type);
    const data = reaction?.users.get(userData?.id ?? "");
    onReact(!!data, data?.id ?? "", type);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <StyledBox>
        <IconButton onClick={handleClick}>
          <AddReactionOutlined fontSize="inherit" />
        </IconButton>
        <ReactionsRenderer
          reactions={reactions}
          onReact={handleReactionClick}
        />
      </StyledBox>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Picker onReact={handleReactionClick} />
      </Popover>
    </>
  );
};
