import { Theme } from "@mui/material/styles";

export default function Table(theme: Theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
        },
        sizeSmall: {
          padding: 0,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
      },
    },
  };
}
