import DOMPurify from "dompurify";
import {
  Box,
  Popover,
  ThemeProvider,
  createTheme,
  styled, Typography, Button, Stack, Divider,
} from "@mui/material";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import { ensureHttpPrefix } from "@/utils";
import { supportLink } from "@/config";

type Props = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: 244,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  pointerEvents: "auto",
}));

export const DelegateFrozenPopover = ({open, anchorEl, onMouseEnter, onMouseLeave}: Props) => {
  if (!open) return null;

  const handleClick = () => {
    const sanitizedLink = ensureHttpPrefix(DOMPurify.sanitize(supportLink));
    window.open(sanitizedLink, "_blank");
  }

  return (
    <ThemeProvider
      theme={(currentTheme) =>
        createTheme({ ...currentTheme, palette: { mode: "dark" } })
      }
    >
      <Popover
        sx={{
          pointerEvents: "none",
          borderRadius: 2,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              width: "auto",
              overflow: "inherit",
              background: (theme) => theme.palette.background.paper,
              boxShadow: (theme) => theme.shadows[4],
              popoverContent: {
                pointerEvents: "auto",
              },
              mt: 1,
            },
            onMouseEnter,
            onMouseLeave,
          },
        }}
      >
        <StyledBox>
          <Typography variant="body2" color="text.primary" sx={{ pt: 2, px: 2 }}>
            Delegation frozen
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{ px: 2 }}>
            Sparx need to be delegated up to 2 weeks before end of the cycle.
          </Typography>
          <Stack gap={1}>
            <Divider />
            <Button
              variant="contained"
              size="small"
              startIcon={<SupportAgentRoundedIcon />}
              sx={{ m: 2, mt: 1, mb: 2 }}
              onClick={handleClick}
            >
              Contact Support
            </Button>
          </Stack>
        </StyledBox>
      </Popover>
    </ThemeProvider>
  );
};
