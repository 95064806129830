import { Stack, Typography, Link } from "@mui/material";
import { useCallback, useEffect } from "react";
import { Modal } from "@/components";
import { API_ENDPOINTS, fetcher, linkXPN } from "@/config";
import { ModalVariantsEnum } from "@/enums";
import { useUIActionsState, useUserState } from "@/state";
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import useSWRMutation from "swr/mutation";
import { LoadingButton } from "@mui/lab";
import DOMPurify from "dompurify";
import { ensureHttpPrefix } from "@/utils";

export const Consent = () => {
  const { userData, authToken } = useUserState();
  const { isModalOpen, setModalOpen } = useUIActionsState();

  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.me.onboarding}`,
    async (url: string) => {
      await fetcher(
        url,
        {
          config: {
            method: "POST",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${authToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              hasBeenOnboarded: true,
            }),
          },
        },
        {
          variant: "success",
          isOpen: true,
          message: "Success",
          hasFooter: false,
        }
      );
    }
  );

  const getContent = () => (
    <Stack alignItems="center" spacing={2.5}>
      <ErrorRoundedIcon
        sx={(theme) => ({
          color: "#BE123C",
          fontSize: `${theme.spacing(6)}`,
        })}
      />
      <Typography variant="subtitle1" textAlign="center">
        我同意根据隐私政策{" "}
        <Link
          component="a"
          target="_blank"
          href={ensureHttpPrefix(DOMPurify.sanitize(linkXPN))}
          underline="none"
          sx={(theme) => ({ color: theme.palette.info.dark })}
        >
          (XConnext Privacy Notice)
        </Link>{" "}
        所述的目的和方式处理我的敏感个人信息
      </Typography>
      <Typography variant="body1" textAlign="center"></Typography>
    </Stack>
  );

  const getActions = useCallback(() => {
    const handleActionButtonClick = async () => {
      await trigger();
      setModalOpen({
        isOpen: false,
        content: null,
        actions: null,
        variant: ModalVariantsEnum.PRIMARY,
      });
    };

    return (
      <Stack width="100%" alignItems="center" spacing={2.5} mt={1}>
        <LoadingButton
          loading={isMutating}
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => handleActionButtonClick()}
        >
          CONSENT
        </LoadingButton>
      </Stack>
    );
  }, [trigger, setModalOpen, isMutating]);

  useEffect(() => {
    if (userData?.needsOnboarding && !userData?.hasBeenOnboarded) {
      setModalOpen({
        isOpen: true,
        content: getContent(),
        actions: getActions(),
        variant: ModalVariantsEnum.TERTIARY,
      });
    }
  }, [
    getActions,
    setModalOpen,
    userData?.hasBeenOnboarded,
    userData?.needsOnboarding,
  ]);

  return isModalOpen ? (
    <Modal enableOutsideClick={false} showCloseButton={false} />
  ) : null;
};
