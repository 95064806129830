export default function CssBaseline() {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: [
          {
            "@font-face": {
              fontFamily: "Henderson",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
              src: "local('Henderson'), local('Henderson-Regular'), url('/fonts/BCGHenSansRegular.ttf') format('truetype')",
            },
          },
          {
            "@font-face": {
              fontFamily: "HendersonMod",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
              src: "local('HendersonMod'), local('HendersonMod-Regular'), url('/fonts/BCGHendersonMod-Regular.otf') format('opentype')",
            },
          },
        ],
        "#root": {
          maxWidth: "100dvw",
        },
      },
    },
  };
}
