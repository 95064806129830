import { TextField } from "@mui/material";
import { StyledStack } from "./StyledStack";
import { AutoAwesomeRounded } from "@mui/icons-material";

type Props = {
  amount?: string | number;
  setAmount: (value: string | number) => void;
  error?: string;
};

export const AmountField = ({ amount, error, setAmount }: Props) => {
  const handleChange = (value: number | string) => {
    if (/^\d*$/.test(String(value))) {
      setAmount(value);
    }
  };

  return (
    <StyledStack direction="row" onClick={(e) => e.stopPropagation()}>
      <AutoAwesomeRounded
        sx={{ fontSize: 12 }}
        color={Number(amount) > 0 ? "secondary" : undefined}
      />
      <TextField
        dir="rtl"
        type="number"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        InputProps={{
          inputProps: { min: 0, step: 1, style: { textAlign: "right" } },
          style: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
        variant="outlined"
        size="small"
        sx={{ width: "80px" }}
        value={amount ?? ""}
        error={!!error}
      />
    </StyledStack>
  );
};
