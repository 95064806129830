import { alpha, TableRow, TableCell, Typography } from "@mui/material";
import { formatDateLongMonth, showButton } from "@/utils";
import { ViewButton } from "./ViewButton";
import { OpenButton } from "./OpenButton";
import { Rewards } from "@/interface";
import { ConditionalCell } from "./ConditionalCell";

type Props = {
  reward: Rewards;
};

export const RewardsTableRowItem = ({ reward }: Props) => {
  const { totalSparx, totalShoutouts } = reward;

  const flag = showButton(reward);

  return (
    <TableRow
      sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}
    >
      <TableCell>{reward.symbol}</TableCell>
      <TableCell align="right">
        <ConditionalCell flag={flag} value={totalSparx} type="sparx" />
      </TableCell>
      <TableCell align="right">
        <ConditionalCell flag={flag} value={totalShoutouts} type="shoutout" />
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" sx={{ color: alpha("#000000", 0.38) }}>
          {reward?.endDate ? formatDateLongMonth(new Date(reward.endDate)) : ""}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {["open-lock", "open-gift"].includes(flag ?? "") && (
          <OpenButton reward={reward} />
        )}

        {flag === "view-old" && <ViewButton reward={reward} />}
      </TableCell>
    </TableRow>
  );
};
