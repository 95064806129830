import { UserData } from "@/interface";
import { userInfo } from "@/utils";
import { Stack, Typography, TypographyProps } from "@mui/material";

type Props = {
  user?: UserData | Partial<UserData>;
  showJobTitle?: boolean;
  jobTitleSx?: TypographyProps["sx"];
} & TypographyProps;

export const UserInfo = ({ user, showJobTitle = true, jobTitleSx, ...rest }: Props) => {
  const { fullName, jobTitle } = userInfo(user);
  return (
    <Stack sx={{width: 1, overflow: "hidden"}}>
      <Typography variant="body2" color="text.primary" sx={jobTitleSx} {...rest}>
        {fullName}
      </Typography>
      {showJobTitle && (
        <Typography variant="caption" color="text.secondary" sx={jobTitleSx}>
          {jobTitle}
        </Typography>
      )}
    </Stack>
  );
};
