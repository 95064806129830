import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { CustomSnackbar, FloatingHelpButton } from "@/components";
import { useUIActionsState, useUserState } from "../state";
import { Shell } from "./common/Shell";
import { useBalance, useReviewers } from "@/hooks/api";
import { ErrorPage } from "./Error";
import { SendSparxModal } from "./common/sparx/SendSparxModal";
import { SuccessDialog } from "@/pages/Sparx/common/SuccessDialog.tsx";
import BudgetCardViz from "@/assets/BudgetCardViz.svg";

const Layout = () => {
  useBalance();
  const { me } = useUserState();
  const { isErrorPageOpen } = useUIActionsState();
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onClose = () => {
    setOpenDialog(false);
  }

  /**
   * Init reviewers
   */
  useReviewers();

  useEffect(() => {
    me();
  }, [me]);

  if (isErrorPageOpen) {
    return (
      <>
        <Shell />
        <Box component="main" sx={{ height: "100vh" }}>
          <ErrorPage />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", pb: 4 }}>
        <Shell />
        <Box sx={{ flexGrow: 1, height: 1 }}>
          <Outlet />
        </Box>
      </Box>
      <SendSparxModal onSuccess={() => setOpenDialog(true)} />
      <Dialog
        maxWidth="sm"
        open={openDialog}
        fullScreen={fullScreen}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paperFullScreen": {
            borderRadius: theme.spacing(0),
          },
        }}
      >
        <SuccessDialog
          banner={BudgetCardViz}
          bannerSubtitle1={'Your Sparx are on their way.'}
          bannerSubtitle2={'You will brighten someone\'s day once they unbox them!'}
          onClose={onClose}
          redirect={false}
        />
      </Dialog>
      <CustomSnackbar />
      <FloatingHelpButton />
    </>
  );
};

export { Layout };
