import { ScrollToTopButton } from "@/components";
import { useMyShoutouts } from "@/hooks/api";
import { ShoutoutsView } from "@/interface";
import { useActivitiesState } from "@/state";
import { useEffect } from "react";
import { ShoutoutsEditor, ShoutoutsList } from "@/pages/common/shoutouts";

export const Received = () => {
  const { setState, shoutoutsView } = useActivitiesState();
  const {
    shoutouts,
    total,
    isLoading,
    refresh,
    loadMore,
    deleteShoutout,
    editShoutout,
    addReaction,
    deleteReaction,
  } = useMyShoutouts(ShoutoutsView.RECEIVED);

  useEffect(() => {
    setState({ totalReceived: total });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  const handleMutate = () => {
    refresh();
    setState({ shouldRefreshGivenShoutouts: true });
  }


  if (shoutoutsView !== ShoutoutsView.RECEIVED) return null;

  return (
    <>
      <ShoutoutsEditor onMutate={handleMutate} />
      <ShoutoutsList
        shouts={shoutouts}
        hasReactions
        isLoading={isLoading}
        loadMore={loadMore}
        onDelete={deleteShoutout}
        onEdit={editShoutout}
        onAddReaction={addReaction}
        onDeleteReaction={deleteReaction}
      />

      <ScrollToTopButton />
    </>
  );
};
