import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { UserData } from "@/interface";
import { useUserState } from "@/state";

export const useReviewers = () => {
  const { authToken } = useUserState();

  const { data } = useSWR<UserData[]>(
    `${API_ENDPOINTS.admin.root}`,
    (url: string) => fetcher(url, { authToken }),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    data,
  };
};
