import { Box, CardContent, Skeleton, Stack } from "@mui/material";

export const SkeletonCard = () => {
  return (
    <>
      <Box className="media" />
      <CardContent sx={{ px: 2, width: 412 }}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width={120} />
            </Stack>
            <Skeleton variant="text" width={100} />
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Stack>
      </CardContent>
    </>
  );
};
