import { useJoinInitiative } from "@/hooks/api";
import { EnrollmentData } from "@/interface";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

type Props = {
  enrollment?: EnrollmentData;
  mutate: () => void;
};

export const Rejoin = ({ enrollment, mutate }: Props) => {
  const { id } = useParams();
  const { trigger } = useJoinInitiative(id ?? "", true);

  if (!enrollment) return null;

  const onRejon = () => {
    trigger({ enrollmentId: enrollment.id }).then(() => {
      mutate();
    });
  };

  return (
    <Button color="secondary" variant="outlined" onClick={onRejon}>
      Rejoin
    </Button>
  );
};
