import { API_ENDPOINTS, fetcher } from "@/config";
import { SparxCycle } from "@/interface";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useAllocateCycle = (symbol: string) => {
  const { authToken } = useUserState();

  async function sendRequest(url: string, { arg }: { arg: { file: File } }) {
    const formData = new FormData();
    formData.append("file", arg.file);

    const response = await fetcher<{ cycle: SparxCycle }>(url, {
      config: {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      },
    });

    return response;
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `${API_ENDPOINTS.tokens.cycles}/${symbol}/upload`,
    sendRequest
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
