import { FormControl, TextField, Autocomplete, Chip } from "@mui/material";
import { Category } from "@/interface";

type Props = {
  categories: Category[];
  categoryId: string;
  subCategory: Category[] | undefined;
  setSubCategory: (sub: Category[] | undefined) => void;
};

export const SubCategorySelector = ({
  categories,
  categoryId,
  subCategory,
  setSubCategory,
}: Props) => {
  const subCategories =
    categories.find((cat) => cat.id === categoryId)?.subCategories ?? [];

  if (subCategories?.length === 0) return null;

  return (
    <FormControl size="small" fullWidth>
      <Autocomplete
        size="small"
        value={subCategory?.[0]}
        onChange={(_: any, subs) => setSubCategory(subs ? [subs] : undefined)}
        options={subCategories}
        getOptionLabel={(option) => option.name}
        fullWidth
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Sub category" />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.name}
              size="small"
              variant="rectangle"
              {...getTagProps({ index })}
              key={option.id}
            />
          ))
        }
      />
    </FormControl>
  );
};
