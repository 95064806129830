import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export enum URL_FRAGMENTS {
  AUTH_CALLBACK_FRAGMENT = "/auth/login/okta/callback",
}

export const LOGIN_URL = `${
  import.meta.env.VITE_BACKEND_URL
}/auth/login/okta/?redirect=${window.location.origin}${
  URL_FRAGMENTS.AUTH_CALLBACK_FRAGMENT
}`;

export const API = import.meta.env.VITE_BACKEND_URL;

export const API_ENDPOINTS = {
  me: {
    root: `${API}/me`,
    ledger: `${API}/me/ledger`,
    enrollments: `${API}/me/enrollments`,
    onboarding: `${API}/me/onboarding`,
    initiatives: {
      root: `${API}/me/initiatives`,
      reviewer: `${API}/me/initiatives/reviewer`,
      participant: `${API}/me/initiatives/participant`,
      owner: `${API}/me/initiatives/owner`,
    },
    contributions: `${API}/me/contributions`,
    orders: `${API}/me/store/orders`,
    shoutouts: {
      created: `${API}/me/shoutouts/created`,
      received: `${API}/me/shoutouts/received`,
      given: `${API}/me/shoutouts/given`,
    },
    activities: `${API}/me/activities`,
  },
  refresh: `${API}/auth/refresh`,
  initiatives: {
    root: `${API}/initiatives`,
    categories: `${API}/categories`,
    search: `${API}/initiatives/search`,
    reviewer: `${API}/initiatives/reviewer`,
    contributions: `${API}/initiatives/:id/contributions`,
  },
  search: `${API}/search`,
  profile: `${API}/profile`,
  enrollments: `${API}/enrollments`,
  admin: {
    root: `${API}/admins`,
    users: `${API}/admin/users`,
    roles: `${API}/admin/roles`,
    shoutouts: `${API}/admin/shoutouts`,
    initiatives: `${API}/admin/initiatives`,
    search: `${API}/admin/search`,
    reports: `${API}/admin/report`,
    sparx_analysis: {
      business_units: `${API}/admin/spx/analysis/business-unit`,
      genders: `${API}/admin/spx/analysis/gender`,
      transaction_types: `${API}/admin/spx/analysis/transaction-type`,
      system_overview: `${API}/admin/spx/analysis/system`,
    },
    delegate_on_behalf: `${API}/admin/spx/delegate`,
    trx: `${API}/admin/spx/transactions`,
  },
  shoutouts: {
    root: `${API}/shoutouts`,
    filters: `${API}/shoutouts/filters`,
    reactions: `${API}/shoutouts/reactions`,
    report: `${API}/shoutouts/report`,
  },
  offices: `${API}/offices`,
  users: {
    search: `${API}/users/search`,
  },
  contributions: `${API}/contributions`,
  tokens: {
    root: `${API}/tokens`,
    // New token endpoints
    balance: `${API}/tokens/balance`,
    activities: `${API}/tokens/activities`,
    delegated: `${API}/tokens/delegated`,
    // TODO: [hmachaao]: remove below old endpoints
    pools: `${API}/tokens/pools`,
    transactions: `${API}/tokens/pools/:id/transactions`,
    // cycles
    cycles: `${API}/tokens/cycles`,
    cycle_extend: `${API}/tokens/cycles/:symbol/extend`,
    sparx_transactions: `${API}/tokens/cycles/transactions`,
    transactions_export: `${API}/tokens/cycles/transactions-export`,
    file_download: `${API}/tokens/cycles/transactions-export`,
    // Returns the total amount of Sparx sent by the requesting user to the specified user (identified by the :id) during the active cycle
    sent_sparx: `${API}/tokens/:id/sparx/active-cycle`,
  },
  rewards: {
    root: `${API}/token-rewards`,
    carousel: `${API}/token-rewards/:symbol/carousel`,
  },
  slack: `${API}/slack/message`,
};

export const ROUTES = {
  root: "/",
  initiatives: "/initiatives",
  initiative: "/initiative",
  initiative_preview: "/initiative/preview",
  initiative_review: "/initiative/review",
  create_initiative: "/create-initiative",
  edit_initiative: "/edit-initiative",
  my_activity: "/me",
  sparx: "/sparx",
  send_sparx: "/sparx/send",
  edit_sparx: "/sparx/edit",
  delegate_sparx: "/sparx/delegate",
  review_sparx_contributions: "/sparx/contributions/review",
  operations: "/operations",
  admin_edit_delegate_sparx: "/operations/edit",
  rewards: "/rewards",
};

export * from "./api";

export const guideLink =
  "https://bcgcloudeur.sharepoint.com/:p:/r/sites/cfbp84-5b/_layouts/15/Doc.aspx?sourcedoc=%7B6A599F4D-A2E0-4958-81DF-0CAF761D2BF1%7D&file=Initiative_Creation_Guidelines.pptx&action=edit&mobileredirect=true";

export const linkXPN =
  "https://bcgcloud.sharepoint.com/:w:/s/DataProtectionOffice/Eb6-OJi1XCtPthdvgqignj4BBgKoPyZkSRFNIBhJRUxuPA?e=AWQ6s3";

export const FAQLink =
  "https://bcgcloudeur.sharepoint.com/:w:/s/BCG_X_Operations_Team/EQE09jgNFqxFg1MMnvbquGkBY5QmaQj5h4G39p7tihbonQ?e=knyWmQ";

export const supportLink =
  "https://bcg.enterprise.slack.com/archives/C0725AM69TR";

export const privacyPolicyLink =
  "https://bcgcloudeur.sharepoint.com/:w:/s/BCG_X_Operations_Team/EdSTo-VgY-hEtYDavCWilm4B6xhiO08DQb1rofQ1hCS8hg?e=d1J6cT";
