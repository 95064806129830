import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { InitiativeData } from "@/interface";

export const useReviewInitiatives = () => {
  const { authToken } = useUserState();

  const { data: reviewerData } = useSWR<InitiativeData[]>(
    `${API_ENDPOINTS.me.initiatives.reviewer}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

     
  const { data: ownerData } = useSWR<InitiativeData[]>(
    `${API_ENDPOINTS.me.initiatives.owner}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    reviewerData,
    ownerData,
  };
};
