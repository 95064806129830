import { Box, Button, Stack, Typography } from "@mui/material";
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { AllocationTable } from "./AllocationTable";
import { Allocation } from "@/interface";
import { API_ENDPOINTS } from "@/config";
import { useUserState, useUIActionsState } from "@/state";
import { download } from "@/utils";

type Props = {
  symbol: string;
  filename: string;
  allocations: Allocation[];
};

export const SparxAllocation = ({ allocations, symbol, filename }: Props) => {
  const { authToken } = useUserState();
  const { setSnackbarOpen } = useUIActionsState();

  const onDownload = async () => {
    const url = `${API_ENDPOINTS.tokens.cycles}/${symbol}/file`;
    try {
      await download(authToken, url, filename);
    } catch (e) {
      setSnackbarOpen({
        variant: "error",
        isOpen: true,
        message: (e as Error).message,
        hasFooter: false,
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Stack>
          <Typography fontWeight={700}>Sparx allocation</Typography>
        </Stack>

        <Button
          startIcon={<ArrowDownwardRoundedIcon />}
          variant="text"
          color="primary"
          onClick={onDownload}
        >
          {filename}
        </Button>
      </Box>
      <AllocationTable allocations={allocations} />
    </Stack>
  );
};
