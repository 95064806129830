import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, ButtonProps } from "@mui/material";
import { ROUTES } from "@/config";
import { useIsVisible } from "@/hooks";
import { useUIActionsState } from "@/state";

const CreateInitiativeButton = () => {
  const ref = useRef<HTMLButtonElement>(null);
  const isCreateInitiativeButtonVisible = useIsVisible(ref);
  const navigate = useNavigate();
  const { setMultipleFloatingButtonsInViewport } = useUIActionsState();

  useEffect(() => {
    if (isCreateInitiativeButtonVisible) {
      setMultipleFloatingButtonsInViewport(false);
    } else {
      setMultipleFloatingButtonsInViewport(true);
    }

    // Cleanup function to reset the state when component unmounts
    return () => {
      setMultipleFloatingButtonsInViewport(false);
    };
  }, [isCreateInitiativeButtonVisible, setMultipleFloatingButtonsInViewport]);

  const buttonProps: ButtonProps = {
    component: "button",
    startIcon: <AddRoundedIcon />,
    variant: "contained",
    color: "primary",
    onClick: () => navigate(ROUTES.create_initiative),
    children: "Create Initiative",
  };

  return (
    <>
      <Button
        {...buttonProps}
        sx={({ spacing }) => ({
          position: "fixed",
          right: spacing(2),
          bottom: spacing(2),
          display: isCreateInitiativeButtonVisible ? "none" : "flex",
          zIndex: 1,
        })}
      />

      <Button {...buttonProps} ref={ref} />
    </>
  );
};

export { CreateInitiativeButton };
