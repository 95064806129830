import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { InitiativeData } from "@/interface";
import { useUserState } from "@/state";

export const useInitiative = (id?: string) => {
  const { authToken } = useUserState();

  const { data, error, isLoading, mutate } = useSWR<InitiativeData>(
    id ? `${API_ENDPOINTS.initiatives.root}/${id}` : null,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    data,
    error,
    isLoading,
    mutate,
  };
};
