import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useActivateCycle = (symbol: string) => {
  const { authToken } = useUserState();

  async function sendRequest(url: string) {

    await fetcher(url, {
      config: {
        method: "PUT",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    },
    {
      variant: "success",
      isOpen: true,
      message: "Cycle activated & tokens allocated successfully",
      hasFooter: false,
    }
  );
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `${API_ENDPOINTS.tokens.cycles}/active/${symbol}`,
    sendRequest
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
