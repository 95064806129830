import { ParticipationEnum } from "@/interface";
import { useInitiativeState } from "@/state";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { participationHelper } from "../helpers";

export const Participation = () => {
  const { participation, setState } = useInitiativeState();
  const { getParticipationIcon } = participationHelper(false);

  const renderer = (value: ParticipationEnum) => {
    return (
      <FormControlLabel
        value={value}
        control={<Radio size="small" color="secondary" />}
        label={
          <Stack direction="row" gap={1}>
            {getParticipationIcon(value)}
            {value}
          </Stack>
        }
      />
    );
  };

  return (
    <Stack>
      <FormControl>
        <Typography color="text.secondary" paddingBottom={2}>
          Participation
        </Typography>
        <RadioGroup
          value={participation}
          onChange={(_, value) =>
            setState({ participation: value as ParticipationEnum })
          }
        >
          {renderer(ParticipationEnum.REMOTE)}
          {renderer(ParticipationEnum.HYBRID)}
          {renderer(ParticipationEnum.IN_PERSON)}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
