import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ReportSuccessModal = ({ open, onClose }: Props) => {

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">Thanks for reporting</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={4}>
          We will review this shout-out and delete it if any issues are found.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained"
          color="primary">
          Close
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
