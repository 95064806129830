import { useState } from "react";
import { ContributionData } from "@/interface";
import { Box, Stack, Typography, SvgIcon, alpha } from "@mui/material";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import dayjs from "dayjs";
import { MarkdownRenderer, OverflowMenu } from "@/components";

type Props = {
  contribution: ContributionData;
  isLast: boolean;
  onDelete?: (contribution: ContributionData) => void;
  onEdit?: (contribution: ContributionData) => void;
};

export const ContributionRenderer = ({
  contribution,
  isLast,
  onDelete,
  onEdit,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit?.(contribution);
    handleCloseMenu();
  };

  const handleDelete = () => {
    onDelete?.(contribution);
    handleCloseMenu();
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ ml: 1 }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <SvgIcon
            fontSize="medium"
            sx={{
              borderRadius: "50%",
              background: "red",
              p: 0.5,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.5),
            }}
          >
            <FlagRoundedIcon
              sx={{ color: (theme) => theme.palette.primary.dark }}
            />
          </SvgIcon>
          <Typography variant="subtitle2">Contributed</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2" color="text.secondary">
            {dayjs(contribution.createdAt).format("ll")}
          </Typography>
          {(onEdit || onDelete) && (
            <OverflowMenu
              open={open}
              anchorEl={anchorEl}
              setOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              onEdit={handleEdit}
              onDelete={handleDelete}
              deleteCaption="Delete"
            />
          )}
        </Stack>
      </Box>
      <Box className={`divider ${isLast ? "last" : ""}`} sx={{ pl: 3 }}>
        <MarkdownRenderer
          markdown={contribution?.details ?? ""}
          sx={{
            wordWrap: "break-word",
          }}
        />
      </Box>
    </Box>
  );
};
