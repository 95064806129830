import { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { SparxUserRole, UserData } from "@/interface";
import { useUsers } from "@/hooks/api";
import { UserAvatar, UserInfo } from "@/components";

type Props = {
  value: UserData[];
  onChange: (users: UserData[]) => void;
  sx?: SxProps<Theme>;
  error?: boolean;
  helperText?: string;
  errorMsg?: string;
  disabled?: boolean;
  label: string;
  role?: SparxUserRole;
  getOptionDisabled?: (option: UserData) => boolean;
};

export const UserAutocomplete = ({
  value,
  onChange,
  error,
  helperText,
  errorMsg,
  sx,
  disabled,
  label,
  role,
  getOptionDisabled,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<UserData[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [noOptionsText, setNoOptionsText] = useState("Start typing a name or email");
  const { data, isLoading } = useUsers({ value: inputValue, role });

  useEffect(() => {
    setOptions(data ?? []);

    if (inputValue && data?.length === 0) {
      setNoOptionsText("User not found");
    } else {
      setNoOptionsText("Start typing a name or email");
    }
  }, [data, inputValue]);

  /** 
   * The optionLabel must include the inputValue string in order for the option to be displayed.
   * In this case, inputValue can be a name or email so the optionLabel must include all those values. 
   */
  const getOptionLabel = (option: UserData) => `${option.lastName}, ${option.firstName}, ${option.email}`;

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_: any, users) => onChange(users)}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={open && isLoading}
      noOptionsText={noOptionsText}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option.id}
        >
          <UserAvatar user={option}>
            <UserInfo user={option} />
          </UserAvatar>
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={`${option.lastName}, ${option.firstName}`}
            size="small"
            color="secondary"
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      getOptionDisabled={getOptionDisabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
          helperText={errorMsg ?? helperText}
          label={label}
        />
      )}
      sx={sx}
    />
  );
};
