import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDetailsState } from "@/state";
import { getToggleViews } from "@/utils";

type Props = {
  total_mine?: number;
  total_all?: number;
  showViewToggle?: boolean;
};

export const ContributionsHeader = ({
  total_mine,
  total_all,
  showViewToggle,
}: Props) => {
  const { contibutionView, setState } = useDetailsState();

  const views = getToggleViews(total_mine, total_all);

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
      <Typography variant="h6">Contributions</Typography>
      {showViewToggle && (
        <Box display="flex" justifyContent="center" flex={1}>
          <ToggleButtonGroup exclusive value={contibutionView}>
            {views.map(({ label, value }) => (
              <ToggleButton
                onClick={() => setState({ contibutionView: value })}
                value={value}
                key={value}
              >
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  );
};
