import { PropsWithChildren, useRef, useState } from "react";
import { UserData } from "@/interface";
import { getAvatarSrc } from "@/utils";
import { Avatar, AvatarProps, Stack } from "@mui/material";
import { UserInfoPopover } from "../UserInfoPopover";

type Props = {
  user?: UserData | Partial<UserData>;
  hasTooltip?: boolean;
} & AvatarProps;

type UserProps = PropsWithChildren<Props>;

export const UserAvatar = ({
  user,
  hasTooltip = true,
  children,
  ...rest
}: UserProps) => {
  const { base64, initials } = getAvatarSrc(user);
  const anchorEl = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = () => setOpen(true);

  const handlePopoverClose = () => setOpen(false);

  const events: Record<string, React.MouseEventHandler<HTMLDivElement>> = {
    onMouseEnter: handlePopoverOpen,
    onMouseLeave: handlePopoverClose,
  };

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <Avatar
          ref={anchorEl}
          src={base64}
          variant="circular"
          {...(hasTooltip && { ...events })}
          {...rest}
        >
          {initials}
        </Avatar>
        {children}
      </Stack>
      {hasTooltip && (
        <UserInfoPopover
          open={open}
          anchorEl={anchorEl.current}
          user={user as UserData}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )}
    </>
  );
};
