import { InitiativeStatus } from "@/enums";
import { Chip } from "@mui/material";

type Props = {
  status?: InitiativeStatus;
};

export const Status = ({ status }: Props) => {
  const style = (status: InitiativeStatus | undefined) => {
    switch (status) {
      case InitiativeStatus.FINISHED:
      case InitiativeStatus.DRAFT:
      case InitiativeStatus.DELETED:
      case InitiativeStatus.JOINED:
      case InitiativeStatus.PENDING:
        return { variant: "contained" };

      case InitiativeStatus.SUBMITTED:
        return { variant: "outlined", color: "secondary" };
      case InitiativeStatus.APPROVED:
      case InitiativeStatus.ACTIVE:
        return { variant: "outlined", color: "success" };
      case InitiativeStatus.REJECTED:
        return { variant: "outlined", color: "error" };
      default:
        return { variant: "contained" };
    }
  };

  const label = (status: InitiativeStatus | undefined) => {
    if (!status) return "Unknown";
    if (status === InitiativeStatus.SUBMITTED) return "Waiting for approval";
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  return (
    <Chip label={label(status)} size="medium" {...(style(status) as any)} />
  );
};
