import { Container, styled } from "@mui/material";
import { PropsWithChildren } from "react";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  background: theme.palette.background.default,
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.shape.borderRadius,
}));

export const ContributionsContainer = ({ children }: PropsWithChildren) => {
  return <StyledContainer>{children}</StyledContainer>;
};
