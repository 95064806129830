import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useInitiative } from "@/hooks/api";
import { UserAvatar, UserInfo } from "@/components";

const OwnersTable = () => {
  const { id } = useParams();
  const { data } = useInitiative(id);
  const owners = data?.owners;

  return (
    <TableContainer
      sx={{
        borderRadius: "16px",
        border: "1px solid #E0E0E0",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <TableCell>
              <Typography fontWeight="700" fontSize="14px">
                Owners
              </Typography>
            </TableCell>
            <TableCell>
              <Box display="flex" justifyContent="right">
                <Typography fontWeight="700" fontSize="14px">
                  Email
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {owners?.map((owner) => (
            <TableRow
              key={owner.user.id}
              sx={{
                "&:not(:last-child)": {
                  borderBottom: "1px solid #E0E0E0",
                },
              }}
            >
              <TableCell>
                <UserAvatar user={owner?.user}>
                  <UserInfo user={owner?.user} />
                </UserAvatar>
              </TableCell>
              <TableCell>
                <Box display="flex" justifyContent="right">
                  <Typography variant="body2">{owner.user.email}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OwnersTable;
