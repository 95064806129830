import {
  CommitmentEnum,
  InitiativeBase,
  InitiativeErrors,
  ParticipationEnum,
} from "@/interface";
import { isEditorEmpty, toServer } from "@/utils";
import { create } from "zustand";

interface InitiativeState extends InitiativeBase {
  errors?: InitiativeErrors;
  //
  setState: (args: Partial<InitiativeState>) => void;
  resetState: () => void;
  isFormValid: () => boolean;
  getInitiative: () => any;
}

const initState = {
  title: "",
  description: "",
  categoryId: "",
  category: undefined,
  subCategories: [],
  location: [],
  offices: [],
  region: "global",
  collaboration: false,
  commitment: CommitmentEnum.LOW,
  participation: ParticipationEnum.REMOTE,
  owners: [],
  reviewers: [],
  links: [],
  startDate: new Date().toISOString(),
  endDate: undefined,
};

const useInitiativeState = create<InitiativeState>((set, get) => ({
  ...initState,

  setState: (args?: Partial<InitiativeState>) => {
    set({ ...args });
  },

  resetState: () => {
    set({ ...initState });
  },

  isFormValid: () => {
    const { title, description, categoryId, location, reviewers } = get();

    const errors: InitiativeErrors = {};
    let valid = true;

    const fields = {
      title: title,
      categoryId: categoryId,
      location: location, // Assuming location should not be empty
      reviewers: reviewers.length,
    };

    const errorKeyMapping: { [key: string]: string } = {
      categoryId: 'category',
      reviewers: 'reviewer',
    };

    for (const [key, value] of Object.entries(fields)) {
      if (!value || value === 0) {
        const errorKey = errorKeyMapping[key] || key;
        const capitalizedErrorKey = errorKey.charAt(0).toUpperCase() + errorKey.slice(1);
        errors[key as keyof InitiativeErrors] = `*${capitalizedErrorKey} is required.`;
        valid = false;
      }
    }

    if (isEditorEmpty(description)) {
      errors["description"] = `*Description is required.`;
      valid = false;
    }

    set({ errors });

    return valid;
  },

  getInitiative: () => {
    return toServer(get());
  },
}));

export { useInitiativeState };
