import {
  Typography,
  Stack,
  DialogActions,
  DialogContent,
  Button,
  Box,
} from "@mui/material";
import { useState } from "react";
import OwnersTable from "./OwnersTable";
import onboarding2Svg from "@/assets/InitiativeOnboarding_2.svg";

type Props = {
  onComplete: () => void;
};

export const JoinOnboardingDialog = ({ onComplete }: Props) => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (step < 2) {
      setStep((prevStep) => prevStep + 1);
    } else {
      onComplete();
    }
  };

  return (
    <DialogContent
      sx={(theme) => ({
        padding: theme.spacing(4),
        paddingTop: theme.spacing(1),
      })}
    >
      <Stack spacing={3} alignItems="center" pb={6}>
        <Stack spacing={1} alignItems="center">
          <Typography variant="overline">WHAT TO DO NEXT</Typography>
          <Typography variant="h6">
            {step === 1
              ? "Connect with Initiative Owners"
              : "Get Compensated: Log Your Contributions"}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            width="100%"
          >
            <Typography variant="body1">
              {step === 1
                ? "The initiative owners have been informed of your interest. Please contact them to start planning your involvement and discuss how you can best support the initiative."
                : "The top 20% of contributors will be rewarded with a bonus boost at the end of the year. Log your own contributions to gain visibility and earn Sparx."}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ width: "100%" }}>
          {step === 1 ? (
            <OwnersTable />
          ) : (
            <img src={onboarding2Svg} alt="Log Contributions" />
          )}
        </Box>
      </Stack>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {step} out of 2
        </Typography>
        <Box>
          <Button variant="contained" onClick={handleNext} autoFocus>
            {step === 1 ? "Next" : "Got it"}
          </Button>
        </Box>
      </DialogActions>
    </DialogContent>
  );
};
