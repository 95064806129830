import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { SentSparx } from "@/interface";

export const useSentSparx = (userId: string, skip: boolean) => {
  const { authToken } = useUserState();

  const { data, isLoading } = useSWR<SentSparx>(
    skip ? null : `${API_ENDPOINTS.tokens.sent_sparx.replace(":id", userId)}`,
    (url: string) => {
      return fetcher(url, { authToken });
    },
    { revalidateOnFocus: false }
  );

  return {
    data,
    isLoading,
  };
};
