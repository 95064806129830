export const SHOUTS_PAGE_SIZE = 20;
export const INITIATIVES_PAGE_SIZE = 20;
export const APP_BAR_HEIGHT = 56;
export const SUBNAVIGATION_BAR_HEIGHT = 64;
export const SIDE_MENU_WIDTH = 240;
export const SIDE_MENU_MINI_WIDTH = 72;
export const MIN_DELEGATION_TOKENS = 10;
export const MAX_WHALE_TX_TOKEN_LIMIT = 5;

export const DELEGATION_FREEZE_DAYS_BEFORE_CYCLE_END = 15;
