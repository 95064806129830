import { create } from "zustand";
import { persist } from "zustand/middleware";
import { API_ENDPOINTS, LOGIN_URL, fetcher } from "../config";
import type { AuthTokens, InitiativeData, UserData } from "../interface";
import { EnrollmentStatusEnum } from "../enums";

interface UserProps extends AuthTokens {
  userData?: UserData;
}

interface UserState extends UserProps {
  setTokens: (tokens: AuthTokens) => void;
  me: () => void;
  refresh: () => Promise<void>;
  logout: () => void;
  getInitiatives: (status: EnrollmentStatusEnum[]) => InitiativeData[];
}

const useUserState = create(
  persist<UserState>(
    (set, get) => ({
      init: (props: Partial<UserProps>) => set({ ...props }),
      setTokens: (tokens: AuthTokens) => set({ ...tokens }),
      logout: () => {
        set({
          userData: undefined,
          authToken: undefined,
          refreshToken: undefined,
        });
      },
      me: async () => {
        const { authToken, refreshToken, logout } = get();
        if (!authToken || !refreshToken) return;
        try {
          const userData = await fetcher(API_ENDPOINTS.me.root, {
            authToken: get().authToken,
          });
          set({ userData });
        } catch (e) {
          console.log(e);
          logout();
          throw e;
        }
      },
      refresh: async () => {
        const { logout } = get();
        try {
          const tokens = await fetcher(API_ENDPOINTS.refresh, {
            config: {
              method: "POST",
              credentials: "include",
            },
          });
          if (!tokens.authToken || !tokens.refreshToken)
            throw new Error("No tokens");

          set({ ...tokens });
        } catch (e) {
          console.log(e);
          logout();
          window.location.replace(LOGIN_URL);
          throw e;
        }
      },
      getInitiatives: (status: EnrollmentStatusEnum[]) => {
        const { enrollments } = get().userData || { enrollments: [] };
        const initiativesData = (enrollments || []).map((enroll) => {
          const { initiative, status } = enroll;
          return { ...initiative, status };
        });

        return initiativesData.filter((i) =>
          status.includes(i.status as EnrollmentStatusEnum)
        ) as InitiativeData[];
      },
    }),
    {
      name: "xchange-storage",
    }
  )
);

export { useUserState };
