import { Box, Skeleton, Stack } from "@mui/material";

export const ContributionListSkeleton = () => {
  return (
    <Stack gap={2}>
      <Box display="flex" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <Skeleton variant="circular" width={32} height={32} />
          <Stack gap={1}>
            <Skeleton variant="rectangular" width={130} height={10} />
            <Skeleton variant="rectangular" width={150} height={10} />
          </Stack>
        </Stack>
      </Box>
      {[...Array(3)].map(renderer)}
    </Stack>
  );
};

const renderer = () => {
  return (
    <Stack sx={{ ml: 0.5 }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="rectangular" width={130} height={10} />
      </Stack>
      <Stack gap={1} sx={{ pl: 4 }}>
        {[...Array(5)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width="100%"
            height={10}
          />
        ))}
      </Stack>
    </Stack>
  );
};
