import {
  Activity,
  ActivityBodyRequest,
  ShoutoutBodyRequest,
  SparxBalance,
  UserData
} from "@/interface";
import { SparxTransactionEnum } from "@/enums";

export const computeActivityTotalAmount = (activity?: Activity) => {
  if (!activity) return 0;
  return activity?.transactions.reduce(
    (prev, next) => prev + Number(next.amount),
    0
  );
};

export const updateBalance = (
  sparx: SparxBalance | undefined,
  prevActivityTransTotalAmount: number,
  activityTransTotalAmount: number
) => {
  const balance = sparx?.balance ?? 0;

  return balance + prevActivityTransTotalAmount - activityTransTotalAmount;
};

export const toActivityBodyRequest = (activity: Activity, sender: UserData, categoryIds?: string[]) => {
  const request: ActivityBodyRequest = { transactions: [] };
  if (activity?.id) request.id = activity.id;
  request.message = activity?.message ?? "";

  activity?.transactions.forEach((tr) => {
    request.transactions.push({
      sender: String(sender.id),
      receiver: tr.receiver.id,
      amount: Number(tr.amount),
      type: tr.type,
    });
  });

  request.categoryIds = categoryIds

  return request;
};

export const toShoutout = (
  activity: Activity,
  isPublic: boolean
): ShoutoutBodyRequest => {
  const receivers = activity.transactions.map((tr) => String(tr.receiver.id));
  return {
    fields: {
      receivers,
      details: activity?.message ?? "",
      public: isPublic,
    },
  };
};

export const toActivities = (activities?: Activity[]) => {
  const delegated: Activity[] = [];
  const sent: Activity[] = [];

  const isAssignment = (activity: Activity) =>
    activity?.transactions.some((tr) => tr.type === SparxTransactionEnum.ALLOCATION);

  activities?.forEach((activity) => {
    if (isAssignment(activity)) sent.push(activity);
    else delegated.push(activity);
  });

  return {
    sent,
    delegated,
  };
};

export const totalActivitiesSparx = (activities: Activity[]) => {
  return activities.reduce(
    (prev, next) => prev + computeActivityTotalAmount(next),
    0
  );
};