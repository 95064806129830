import { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Menu,
  ListItemIcon,
  Stack,
  Tooltip,
} from "@mui/material";
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

const options = [
  {
    label: "Everyone at BCG X",
    tooltip: "Everyone at BCG X can see this",
    public: true,
    icon: <PublicRoundedIcon fontSize="small" />,
  },
  {
    label: "Only mentioned",
    tooltip: "Only mentioned can see this",
    public: false,
    icon: <LockPersonRoundedIcon fontSize="small" />,
  },
];

type Props = {
  isPublic: boolean;
  onSelect: (isPublic: boolean) => void;
};

export const Visibility = ({ isPublic, onSelect }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    onSelect(options[index].public);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedIndex = options.findIndex((opt) => opt.public === isPublic);

  return (
    <div>
      <List sx={{ p: 0 }}>
        <Tooltip title={options[selectedIndex].tooltip} placement="top-start">
          <ListItemButton
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickListItem}
          >
            <ListItemText
              primary={
                <Stack direction="row" alignItems="center" gap={1}>
                  {options[selectedIndex].icon}
                  {options[selectedIndex].label}
                  {open ? <ExpandLessRoundedIcon fontSize="small"/ > : <ExpandMoreRoundedIcon fontSize="small" />}
                </Stack>
              }
            />
          </ListItemButton>
        </Tooltip>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.label}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
