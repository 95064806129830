import { SVGProps } from "react";

const XChangeLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1619 14.5664L0.65625 0H7.49191C8.77898 0 9.97637 0.64128 10.6676 1.69984L19.9986 16L10.6671 30.3002C9.97637 31.3587 8.77854 32 7.49191 32H0.65625L10.1619 17.4332C10.7328 16.5585 10.7328 15.4411 10.1619 14.5664ZM29.8372 14.5664L39.3428 0H39.3424H32.5067C31.2201 0 30.0223 0.64128 29.3315 1.69984L20.0004 15.9996L29.3319 30.2997C30.0227 31.3583 31.2205 31.9996 32.5072 31.9996H39.3428L29.8372 17.4332C29.2663 16.5585 29.2663 15.4411 29.8372 14.5664Z"
      fill="#00FFCC"
    />
  </svg>
);

export { XChangeLogo };
