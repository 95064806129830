import { Box, Button, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useContributions } from "@/hooks/api";
import { useDetailsState } from "@/state";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';

export const Contribution = () => {
  const { id } = useParams();
  const { data } = useContributions(id ?? "");
  const { setState } = useDetailsState();

  const displayContributions = (count: number) => {
    return `${count} Contribution${count !== 1 ? "s" : ""}`;
  };

  const onClick = () => setState({ currentTab: "contributions" });

  return (
    <Stack spacing={2}>
      <Button
        startIcon={<AddRoundedIcon />}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        Add contribution
      </Button>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ " & > *": { color: (theme) => theme.palette.success.main } }}
      >
        <BoltRoundedIcon />
        <Typography>
          {displayContributions(data?.totContributions ?? 0)}
        </Typography>
      </Box>
    </Stack>
  );
};
