import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { FilePreviewProps } from "../types";
import { fileData, formatFileSize } from "../utils";

export default function FilePreview({
  extendFile,
  onDelete,
}: FilePreviewProps) {
  if (!extendFile) return null;

  const { name = "", size, loading } = fileData(extendFile);

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        p: 1,
      }}
    >
      <UploadFileRoundedIcon />

      <Stack flexGrow={1} sx={{ minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>

        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {formatFileSize(size)}
        </Typography>
      </Stack>

      {loading ? (
        <CircularProgress size="small" color="secondary" aria-label="Loading" />
      ) : (
        <IconButton
          edge="end"
          size="small"
          aria-label="remove-button"
          onClick={onDelete}
        >
          <DeleteRoundedIcon />
        </IconButton>
      )}
    </Stack>
  );
}
