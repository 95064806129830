import { SyntheticEvent } from "react";
import { Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useDetailsState } from "@/state";


export default function Tabs() {
  const { currentTab, setState } = useDetailsState();

  const handleChange = (_: SyntheticEvent, tab: string) => {
    setState({ currentTab: tab });
  };

  return (
    <TabContext value={currentTab}>
      <TabList
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab value="contributions" label={"Contributions"} />
        <Tab value="about" label={"About"} />
      </TabList>
    </TabContext>
  );
}
