const common = {
  leadingTrim: "both",
  textEdge: "cap",
  fontWeight: 400,
};

const typography = {
  fontFamily: ["Henderson", "HendersonMod", "sans-serif"].join(","),
  ...common,
  h1: {
    fontWeight: 700,
    fontFamily: "HendersonMod",
  },
  h2: {
    fontWeight: 700,
    fontFamily: "HendersonMod",
  },
  h3: {
    fontWeight: 700,
    fontFamily: "HendersonMod",
  },
  h4: {
    fontWeight: 700,
    fontFamily: "HendersonMod",
  },
  h5: {

    fontWeight: 700,
    fontFamily: "HendersonMod",
  },
  h6: {
    fontSize: "1.25rem",
    lineHeight: "160%",  
    fontWeight: 700,
    fontFamily: "HendersonMod",
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "150%",
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: "143%",
    fontWeight: 400,
    
  },
  subtitle1: {
    fontSize: "1rem",
    lineHeight: "175%",
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: "0.875rem",
    lineHeight: "157%",
    fontWeight: 700,
  },
  subtitle3: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 700,
  },
  button: {
    lineHeight: "1.625rem",
    letterSpacing: "0.025rem",
    fontWeight: 700,
    textTransform: "capitalize",
  },
} as const;

export default typography;
