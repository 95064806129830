import { Theme } from "@mui/material/styles";

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            margin: theme.spacing(0),
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
            ":before": {
              opacity: 1,
            },
          },
          ":first-of-type": {
            borderTopRightRadius: theme.spacing(1.5),
            borderTopLeftRadius: theme.spacing(1.5),
          },
          ":last-of-type": {
            borderBottomRightRadius: theme.spacing(1.5),
            borderBottomLeftRadius: theme.spacing(1.5),
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: theme.spacing(2),
            "&.Mui-expanded": {
              padding: theme.spacing(1),
              minHeight: "auto",
            },
          },
          content: {
            "&.Mui-expanded": {
              margin: theme.spacing(1),
            },
          },
        },
      },
    },
  };
}
