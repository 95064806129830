import { Box, Card, CardContent, Skeleton, Stack, styled } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  height: 352,
  background: theme.palette.background.default,
  transition: ".2s all ease-out",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
}));

const InitiativeCardSkeleton = () => {
  return (
    <StyledCard elevation={0}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rounded" width={200} height={10} />
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Stack>
      </CardContent>
      <CardContent>
        <Stack direction="row" alignItems="center" gap={1}>
          <Skeleton variant="rounded" width={45} height={40} />
          <Box sx={{ width: "100%" }}>
            <Skeleton />
            <Skeleton width={200} />
          </Box>
        </Stack>
      </CardContent>
      <CardContent sx={{ mt: "auto" }}>
        <Box sx={{ width: "100%" }}>
          <Skeleton width={100} />
          <Skeleton />
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export { InitiativeCardSkeleton };
