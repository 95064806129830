import { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useUserState } from "@/state";
import { InitiativeData } from "@/interface";
import { NoDataMessage } from "@/components";
import { RowItem } from "./RowItem";

type OrderByOptions = "name" | "category" | "role" | "status" | undefined;

type Props = {
  initiatives: InitiativeData[]
}

export const ReviewInitiativesTable = ({initiatives}: Props) => {
  const { userData } = useUserState();
  const [orderBy, setOrderBy] = useState<OrderByOptions>(undefined);
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const handleRequestSort = (property: OrderByOptions) => {
    if (orderBy === property) {
      if (order === "asc") {
        setOrder("desc");
      } else if (order === "desc") {
        setOrderBy(undefined);
        setOrder("desc");
      } else {
        setOrder("asc");
      }
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const sortedInitiatives: InitiativeData[] = useMemo(() => {
    return initiatives.slice().sort((a, b) => {
      if (!orderBy) return 0;

      let aValue: number | string;
      let bValue: number | string;

      switch (orderBy) {
        case "name":
          aValue = a.title;
          bValue = b.title;
          break;
        case "category":
          aValue = a.category?.name ?? 0;
          bValue = b.category?.name ?? 0;
          break;
        case "role": {
          const aHasRole = a.reviewers?.some((reviewer) => reviewer.userId === userData?.id) ?? false;
          const bHasRole = b.reviewers?.some((reviewer) => reviewer.userId === userData?.id) ?? false;
          // Convert boolean to number for sorting, true becomes 1 and false becomes 0
          aValue = aHasRole ? 1 : 0;
          bValue = bHasRole ? 1 : 0;
          break;
        }
        case "status":
          aValue = a.status;
          bValue = b.status;
          break;
        default:
          aValue = a.updatedAt ?? 0;
          bValue = b.updatedAt ?? 0;
          break;
      }

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [initiatives, orderBy, userData?.id, order]);

  return (
    <TableContainer sx={{ py: 2 }}>
      <Table>
        <TableHead>
          <TableRow sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}>
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={() => handleRequestSort("name")}
              >
                <Typography variant="subtitle2">Initiative name</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "category"}
                direction={orderBy === "category" ? order : "asc"}
                onClick={() => handleRequestSort("category")}
              >
                <Typography variant="subtitle2">Category</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "role"}
                direction={orderBy === "role" ? order : "asc"}
                onClick={() => handleRequestSort("role")}
              >
                <Typography variant="subtitle2">Role</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "status"}
                direction={orderBy === "status" ? order : "asc"}
                onClick={() => handleRequestSort("status")}
              >
                <Typography variant="subtitle2">Status</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedInitiatives.length === 0 ? (
            <NoDataMessage
              colSpan={5}
              title={"No initiatives to review."}
              message={
                "If you own a rejected initiative or are reviewing a pending one, you’ll see them here."
              }
            />
          ) : (
            sortedInitiatives.map((initiative) => 
              <RowItem key={initiative.id} initiative={initiative}/>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
