import {
  FormControl,
  TextField,
  Autocomplete,
  Chip,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { InitiativeOffice } from "@/interface";

type Props = {
  value: InitiativeOffice[];
  offices: InitiativeOffice[];
  onChange: (value: InitiativeOffice[]) => void;
};

export const OfficeSelector = ({ value, offices, onChange }: Props) => {
  return (
    <FormControl size="small" fullWidth>
      <Autocomplete
        size="small"
        value={value}
        onChange={(_: any, offices) => onChange(offices)}
        multiple
        options={offices}
        groupBy={(option) => option.region ?? ""}
        getOptionLabel={(option) => option.label}
        fullWidth
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Offices" />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.value}
              size="small"
              color="secondary"
              {...getTagProps({ index })}
              key={option.id}
            />
          ))
        }
        renderGroup={(params) => (
          <>
            <Box sx={{ px: 2, py: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {params.group}
              </Typography>
            </Box>
            {params.children}
          </>
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox sx={{ px: 0 }} color="secondary" checked={selected} />
            <Typography color="text.primary" sx={{ ml: 1 }}>
              {option.label}
            </Typography>
          </li>
        )}
      />
    </FormControl>
  );
};
