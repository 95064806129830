import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled, Stack, Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { AutoAwesomeRounded } from "@mui/icons-material";
import { UserAvatar, UserInfo } from "@/components";
import { UserData } from "@/interface";
import { AmountField } from "@/pages/Sparx/contributions/common/AmountField.tsx";

const StyledContainer = styled(TableContainer)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  ".no-border": {
    borderBottom: 0,
  },
}));

const StyledRow = styled(TableRow)({
  borderBottom: `1px solid ${grey[300]}`,
});

const StyledTableCell = styled(TableCell)<{ error?: string }>(({ theme, error }) => ({
  paddingBottom: error ? 0 : theme.spacing(2),
  fontWeight: 700,
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  justifyContent: "flex-end",
  alignItems: "center",
  color: theme.palette.text.disabled,
}));

type Props = {
  delegator?: UserData;
  budget?: number;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export const Delegator = ({delegator, budget = 0, amount, setAmount, error, setError}: Props) => {
  const onChangeAmount = (value: string | number) => {
    setAmount(Number(value))
    if (Number(value) > budget) {
      setError("The total amount entered exceeds the available Sparx budget.")
    } else if (error) {
      setError("");
    }
  }

  return (
    <StyledContainer>
      <Table>
        <TableHead>
          <StyledRow>
            <StyledTableCell>Delegated from</StyledTableCell>
            <StyledTableCell align="right">User's Budget</StyledTableCell>
            <StyledTableCell align="right">Delegated Amount</StyledTableCell>
          </StyledRow>
        </TableHead>
        <TableBody>
          <StyledRow className="no-border">
            <StyledTableCell
              component="th"
              scope="row"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
              error={error}
            >
              <UserAvatar user={delegator}>
                <UserInfo user={delegator} />
              </UserAvatar>
            </StyledTableCell>
            <StyledTableCell align="right" error={error}>
              <StyledStack direction="row">
                <AutoAwesomeRounded
                  sx={{ fontSize: 12 }}
                />
                {budget && (budget - amount)}
              </StyledStack>
            </StyledTableCell>
            <StyledTableCell align="right" error={error}>
              <AmountField
                amount={amount}
                setAmount={onChangeAmount}
                error={error}
              />
            </StyledTableCell>
          </StyledRow>
          {error &&
            <StyledRow>
              <StyledTableCell colSpan={3} align="right" sx={{paddingTop: "0px"}}>
                  <Typography variant="subtitle2" color="error">
                    {error}
                  </Typography>
              </StyledTableCell>
            </StyledRow>
          }
        </TableBody>
      </Table>
    </StyledContainer>
  );
};
