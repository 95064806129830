import { Dialog as MuiDialog } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  open: boolean;
}>;

export const OnboardingDialog = ({ open, children }: Props) => {
  return (
    <MuiDialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 700,
            pt: 2,
          },
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      {children}
    </MuiDialog>
  );
};
