import {
  CategoryData,
  ContibutionView,
  ContributionData,
  ContributionInActiveCycle,
  ContributionsByInitiative,
  Contributor,
  GroupedContributions,
  InitiativeOverview,
} from "@/interface";

export const getToggleViews = (
  total_mine: number = 0,
  total_all: number = 0
) => {
  return [
    {
      value: ContibutionView.ALL,
      label: `All contributions ${total_all}`,
    },
    {
      value: ContibutionView.MINE,
      label: `My contributions ${total_mine}`,
    },
  ];
};

export const toContribution = (
  details: string,
  initiativeId: string | undefined,
  categoryId: string | undefined,
  subCategoryIds: string[] | undefined,
  region: string | undefined
) => {
  let fields: Record<any, any> = {
    details,
    startDate: new Date().toISOString(),
  };

  if (initiativeId) fields = { ...fields, initiativeId };
  if (categoryId) fields = { ...fields, categoryId };
  if (subCategoryIds) fields = { ...fields, subCategoryIds };
  if (region) fields = { ...fields, region };

  return { fields };
};
export const contributionsByUser = (
  contributions: ContributionData[] | undefined,
  userId?: string | number
): { all: GroupedContributions[]; mine: GroupedContributions | undefined } => {
  if (!contributions) return { all: [], mine: undefined };

  const groupedObject: {
    [key: string]: GroupedContributions;
  } = contributions?.reduce(
    (acc, item) => {
      if (!acc[item.user.id]) {
        acc[item.user.id] = {
          user: item.user,
          items: [],
        };
      }
      acc[item.user.id].items.push(item);
      return acc;
    },
    {} as { [key: string]: GroupedContributions }
  );

  const conributions = Object.values(groupedObject);

  return {
    all: conributions,
    mine: conributions.filter((c) => c.user.id === userId)?.[0] ?? undefined,
  };
};

const groupContributionsByInitiative = (conributions: ContributionData[]) => {
  const groupedObject: {
    [key: string]: ContributionsByInitiative;
  } = {};

  conributions.forEach((contribution) => {
    if (contribution.initiative) {
      const { initiative, region } = contribution;
      if (!groupedObject[initiative.id]) {
        groupedObject[initiative.id] = {
          items: [],
          initiative: {
            ...initiative,
            region,
            category: initiative?.category as CategoryData,
            subCategories: initiative?.initiativeCategories?.map(
              (sub) => sub.category
            ),
          },
          isExternal: false,
        };
      }
      groupedObject[contribution.initiative.id].items.push(contribution);
    } else {
      // external contribution
      groupedObject[contribution.id] = {
        items: [contribution],
        initiative: {
          // No initiative ID d for external contributions, Using contribution ID for rendering purposes instead
          id: contribution.id,
          title: "External contribution",
          region: contribution.region,
          category: contribution.category,
          subCategories: contribution?.subCategories ?? [],
        },
        isExternal: true,
      };
    }
  });

  return groupedObject;
};

export const byInitiative = (
  data?: ContributionInActiveCycle
): ContributionsByInitiative[] => {
  if (!data) return [];
  const { contributionsInActiveCycle } = data;
  const groupedObject = groupContributionsByInitiative(
    contributionsInActiveCycle
  );

  return Object.values(groupedObject);
};

export const contributionsByInitiative = (data?: ContributionData[]) => {
  if (!data)
    return {
      contributions: [],
      initiatives: [],
    };

  const groupedObject = groupContributionsByInitiative(data);
  const contributions = Object.values(groupedObject);

  const initiatives: InitiativeOverview[] = contributions
    .filter((c) => c.initiative.title !== "External contribution")
    .map((c) => c.initiative);

  return {
    contributions,
    initiatives,
  };
};

export const toContributors = (data?: Contributor[], userSparx?: Record<string, number>): Contributor[] => {
  if (!data) return [];
  const contributors: Contributor[] = [];

  data?.forEach((contributor) => {
    contributors.push({
      ...contributor,
      numberOfContributions: contributor.totalContributionsIds.length,
      amount: userSparx?.[contributor.user.id],
      error: undefined,
    });
  });

  return contributors;
};

export const hasCommon = (array: string[], set: Set<string>) => {
  for (const element of array) {
    if (set.has(element)) {
      return true;
    }
  }
  return false;
};
