import { markdownToHtml } from "@/utils";
import { Typography, TypographyProps } from "@mui/material";

type Props = {
  markdown: string;
} & TypographyProps;

export const MarkdownRenderer = ({ markdown, ...rest }: Props) => {
  const getMarkdownText = () => {
    const rawMarkup = markdownToHtml(markdown);
    return {
      __html: rawMarkup,
    };
  };

  return (
    <Typography
      variant="body2"
      dangerouslySetInnerHTML={getMarkdownText()}
      {...rest}
    />
  );
};
