import { Button, DialogContent, Stack } from "@mui/material";
import { ShoutoutData, UserData } from "@/interface";
import { useEffect, useState } from "react";
import { ContributionEditor as ShoutoutEditor } from "@/pages/common/editor/ContributionEditor";
import { EditShoutButton } from "./EditShoutButton";
import { Receivers } from "../common/Receivers";
import { Visibility } from "../common/Visibility";
import { CustomDialog } from "@/components/CustomDialog";

type Props = {
  open: boolean;
  shoutout?: ShoutoutData;
  onClose: () => void;
  mutate: (shoutout: ShoutoutData) => void;
};

export const EditShoutout = ({ open, shoutout, mutate, onClose }: Props) => {
  const [content, setContent] = useState("");
  const [receivers, setReceivers] = useState<UserData[]>([]);
  const [isPublic, setIsPublic] = useState(true);

  useEffect(() => {
    if (shoutout) {
      setContent(shoutout.details);
      setReceivers(shoutout.receivers.map((r) => r.receiver) ?? []);
      setIsPublic(shoutout.public);
    }
  }, [shoutout]);

  const disabled =
    content === shoutout?.details && isPublic === shoutout?.public;

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
      maxWidth="lg"
    >
      <DialogContent>
        <ShoutoutEditor
          element={
            <Receivers
              receivers={receivers}
              disabled={true}
              onChange={setReceivers}
            />
          }
          caption="Edit shout-out for"
          initialContent={shoutout?.details}
          onEditorChange={(html) => setContent(html)}
          showFooter={false}
        >
          <Visibility isPublic={isPublic} onSelect={setIsPublic} />
          <Stack direction="row" gap={1}>
            <Button onClick={onClose} variant="outlined" color="secondary">
              Cancel
            </Button>
            <EditShoutButton
              shoutoutId={shoutout?.id as string}
              content={content}
              isPublic={isPublic}
              disabled={disabled}
              onComplete={(shoutout) => {
                onClose();
                mutate(shoutout);
              }}
            />
          </Stack>
        </ShoutoutEditor>
      </DialogContent>
    </CustomDialog>
  );
};
