import { Stack, Typography } from "@mui/material";
import { useBulkSparxState } from "@/state";
import { AutoAwesomeRounded } from "@mui/icons-material";
import { UserAvatar } from "@/components";

export const Profile = () => {
  const { contributor } = useBulkSparxState();

  return (
    <Stack justifyContent="center" alignItems="center" width={1}>
      <UserAvatar
        user={contributor?.user}
        sx={{ width: 52, height: 52, mb: 2 }}
      />
      <Typography variant="subtitle1">{contributor?.user.fullName}</Typography>
      <Typography variant="body2">{contributor?.user?.title}</Typography>
      <Stack
        direction="row"
        color="text.disabled"
        alignItems="center"
        spacing={1}
      >
        <AutoAwesomeRounded sx={{ fontSize: 12 }} />
        <Typography variant="caption">
          {contributor?.totalSparxReceivedFromSender}
        </Typography>
      </Stack>
    </Stack>
  );
};
