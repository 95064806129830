import { API_ENDPOINTS } from "@/config";
import { useSendTokens, useShoutout } from "@/hooks/api";
import { useShoutsState, useSparxState, useUserState } from "@/state";
import { toActivityBodyRequest, toShoutout } from "@/utils";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box } from "@mui/material";
import { useSWRConfig } from "swr";
import { SendRounded } from "@mui/icons-material";

type Props = {
  title: string;
  onDone: () => void;
} & LoadingButtonProps;

export const SaveButton = ({ title, onDone, ...rest }: Props) => {
  const { mutate } = useSWRConfig();
  const { userData } = useUserState();
  const { trigger: triggerTokens, isMutating: tokensLoading } = useSendTokens();
  const { trigger: triggerShoutouts, isMutating: shoutoutsLoading } =
    useShoutout();
  const { activity, isPublic, isValid, setReset, shoutout, categoryIds } = useSparxState();
  const { setState } = useShoutsState();

  const onClick = () => {
    if (activity && userData) {
      triggerTokens({ data: toActivityBodyRequest(activity, userData, categoryIds) }).then(
        () => {
          onDone();
          setReset();
          mutate(API_ENDPOINTS.tokens.balance);
          mutate(API_ENDPOINTS.tokens.activities);
        }
      );
      if (shoutout)
        triggerShoutouts({ data: toShoutout(activity, isPublic) }).then(
          (shout) => {
            setState({ shoutout: shout });
          }
        );
    }
  };

  return (
    <Box sx={{ mt: "auto", display: "flex", justifyContent: "flex-start" }}>
      <LoadingButton
        variant="contained"
        loading={tokensLoading || shoutoutsLoading}
        disabled={!isValid() || categoryIds.length === 0}
        onClick={onClick}
        endIcon={<SendRounded />}
        {...rest}
      >
        {title}
      </LoadingButton>
    </Box>
  );
};
