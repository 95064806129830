import { useState } from "react";
import { useOperationsState } from "@/state";
import { Box, TextField, Typography, styled } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(1),
}));

type Props = {
  required?: boolean;
};

export const LeaveMessage = ({
  required = false,
}: Props) => {
  const [error, setError] = useState(false);
  const { selectedActivity, updateActivityMessage } = useOperationsState();


  if (!selectedActivity || selectedActivity.transactions.length === 0) {
    return null;
  }

  const handleBlur = () => {
    if (required && !selectedActivity.message) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <StyledBox>
      <Typography variant="subtitle2" mb={1}>
        Leave a message
      </Typography>
      <TextField
        required={required}
        helperText={"*This field is required. Describe how the delegated tokens should be used."}
        value={selectedActivity.message}
        onChange={(e) => updateActivityMessage(e.target.value)}
        onBlur={handleBlur}
        error={required && error}
        multiline
        rows={3}
        variant="outlined"
      />
    </StyledBox>
  );
};
