import { ContibutionView } from "@/interface/Contributions";
import { create } from "zustand";

interface InitiativeDetailsState {
  contibutionView: ContibutionView;
  currentTab: string;
  content?: string;
  openDeleteDialog: boolean;
  //
  setState: (args: Partial<InitiativeDetailsState>) => void;
}

const useDetailsState = create<InitiativeDetailsState>((set) => ({
  contibutionView: ContibutionView.ALL,
  currentTab: "about",
  openDeleteDialog: false,

  setState: (args?: Partial<InitiativeDetailsState>) => {
    set({ ...args });
  },
}));

export { useDetailsState };
