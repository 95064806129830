import { useEffect } from "react";
import {
  Paper,
  Stack,
} from "@mui/material";
import { useOperationsState } from "@/state";
import { useAdminTrxBySender } from "@/hooks/api"; 
import { SenderHeader } from "./SenderHeader";
import { TransactionsTable } from "./TransactionsTable";

export const SenderTransactionsContainer = () => {
  const { sender, recipient, setState } = useOperationsState();
  const { data, isLoading, mutate } = useAdminTrxBySender(sender[0].id, recipient[0]?.id || undefined);

  useEffect(() => {
    setState({ senderTransactions: data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack spacing={{ xs: 2, md: 6 }}>
      <Paper
        elevation={0}
        sx={{
          padding: 3,
          bgcolor: (theme) => theme.palette.background.default,
          border: "1px solid",
          borderColor: (theme) => theme.palette.divider,
          overflow: "hidden",
        }}
      >
        <SenderHeader />
        <TransactionsTable isLoading={isLoading} mutate={mutate}/>
      </Paper>
    </Stack>
  );
};