import {
  Checkbox,
  Collapse,
  IconButton,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Fragment, useState } from "react";
import { FilterOption, KeyValuePair } from "@/interface";

type Props = {
  options: FilterOption[];
  selected: Record<string, string>;
  onSelectParent?: (option: KeyValuePair) => void;
  onSelectChild?: (option: KeyValuePair) => void;
};

export const MultiSelect = ({
  options,
  selected,
  onSelectParent,
  onSelectChild,
}: Props) => {
  const [expand, setExpand] = useState<Record<string, boolean>>({});

  const onExpand = (key: string) => {
    setExpand((prevExpand) => ({
      ...prevExpand,
      [key]: !prevExpand[key],
    }));
  };

  const itemTextRenderer = (option: FilterOption) => {
    const { value, children } = option;
    const count = children?.length ?? 0;

    return (
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography>{value}</Typography>
            {count > 0 && (
              <Typography variant="body2" color="text.secondary">
                ({count})
              </Typography>
            )}
          </Stack>
        }
      />
    );
  };

  const renderChildItems = (children: FilterOption[], parentKey: string) => {
    return children.map(({ key, value }) => (
      <MenuItem
        key={`${parentKey}-${key}`}
        onClick={() => onSelectChild?.({ key, value })}
        sx={{ pl: 4 }}
      >
        <Checkbox checked={!!selected[key]} />
        <ListItemText primary={value} />
      </MenuItem>
    ));
  };

  const renderer = (option: FilterOption) => {
    const { key, children } = option;
    const hasChildren = children && children?.length > 0;

    return (
      <Fragment key={key}>
        <MenuItem onClick={() => onSelectParent?.(option)} sx={{ pl: 1 }}>
        <Checkbox checked={!!selected[key]} />
          <ListItemText primary={itemTextRenderer(option)} />
          {hasChildren && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onExpand(key);
              }}
            >
              {expand[option.key] ? (
                <ExpandLessRounded />
              ) : (
                <ExpandMoreRounded />
              )}
            </IconButton>
          )}
        </MenuItem>
        {hasChildren && (
          <Collapse in={expand[key]}>
            {renderChildItems(children, key)}
          </Collapse>
        )}
      </Fragment>
    );
  };

  return options.map(renderer);
};
