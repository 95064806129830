import {
  Color,
  CssBaseline,
  ThemeOptions,
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";

import palette from "./palette";
import typography from "./typography";
import Overrides from "./overrides";

import { useMemo } from "react";
import mixins from "./mixins";
import { useUIActionsState } from "../state";
import { createShadows } from "./shadows";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Partial<Color>;
    customColors: {
      lightRed: string;
      darkRed: string;
      oceanBlue: string;
      mediumGreen: string;
      infoBackground: string;
      transactionAmountBackground: string,
      transactionAmountText: string,
      menuBackgroundActive: string,
    };
  }
  interface Mixins {
    sideMenuOpenWidth: string;
    sideMenuClosedWidth: string;
    gradientCard: string;
    gradientGrey: string;
    gradientListOverlay: string;
    gradientModalBorder: string;
    horizontalGradientPurple: string;
    verticalGradientPurple: string;
  }
}

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { mode } = useUIActionsState();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(mode),
      shape: { borderRadius: 8 },
      typography,
      mixins,
      shadows: createShadows(),
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);

  theme.components = Overrides(theme);
  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
