import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { isEditorEmpty, toContribution } from "@/utils";
import { useAddContribution, useUpdateContribution } from "@/hooks/api";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";

type Props = {
  caption: "Add contribution" | "Save" | "Add External contribution";
  initiativeId?: string;
  contributionId?: string;
  categoryId?: string;
  subCategoryIds?: string[];
  region?: string;
  content?: string;
  onComplete: () => void;
  disabled?: boolean;
} & LoadingButtonProps;

export const EditorSaveButton = ({
  caption,
  initiativeId,
  categoryId,
  subCategoryIds,
  contributionId,
  region,
  content,
  onComplete,
  ...rest
}: Props) => {
  const [editor] = useLexicalComposerContext();
  const { trigger: add, isMutating } = useAddContribution();

  const { trigger: edit, isMutating: isEditing } = useUpdateContribution(
    contributionId as string
  );

  const clearEditor = () => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();
    });
    editor.focus();
  };

  const onAdd = () => {
    const data = toContribution(
      content ?? "",
      initiativeId,
      categoryId,
      subCategoryIds,
      region
    );
    add({ data }).then(() => {
      clearEditor();
      onComplete();
    });
  };

  const onEdit = () =>
    edit({
      data: { details: content },
    }).then(() => {
      clearEditor();
      onComplete();
    });

  const onClick = () => {
    if (
      caption === "Add contribution" ||
      caption === "Add External contribution"
    )
      onAdd();
    else onEdit();
  };

  return (
    <LoadingButton
      disabled={isEditorEmpty(content ?? "")}
      variant="contained"
      color="primary"
      onClick={onClick}
      loading={isMutating || isEditing}
      {...rest}
    >
      {caption}
    </LoadingButton>
  );
};
