import { useEffect } from "react";
import { Fab, styled, useScrollTrigger, Zoom } from "@mui/material";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useUIActionsState } from "@/state";

const ScrollTopButtonContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

export const ScrollToTopButton = () => {
  const { setMultipleFloatingButtonsInViewport } = useUIActionsState();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  useEffect(() => {
    if (trigger) {
      setMultipleFloatingButtonsInViewport(true);
    } else {
      setMultipleFloatingButtonsInViewport(false);
    }
  }, [trigger, setMultipleFloatingButtonsInViewport]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Zoom in={trigger}>
      <ScrollTopButtonContainer onClick={handleClick} role="presentation">
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpRoundedIcon />
        </Fab>
      </ScrollTopButtonContainer>
    </Zoom>
  );
};
