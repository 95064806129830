import { List, ListItem, Stack, TextField, Typography } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { useSparxState, useUserState } from "@/state";
import { UserAvatar, UserInfo } from "@/components";

export const AssignSparxForm = () => {
  const { activity, setAmount } = useSparxState();
  const { userData } = useUserState();
  const canSendMultiple = userData?.isWhale;

  if (!activity || activity?.transactions.length === 0) return null;
  const transactions = activity.transactions;

  const handleChange = (transactionId: string, value: number | string) => {
    // Regular expression to check if the value is an integer
    if (/^\d*$/.test(String(value))) {
      setAmount(transactionId, value);
    }
  };

  return (
    <Stack spacing={2}>
      <List sx={{ pt: 3 }}>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">User</Typography>
          <Typography variant="subtitle2">Sparx Amount</Typography>
        </ListItem>
        {transactions.map(({ id, receiver, amount, error }, index) => (
          <Stack
            key={`${receiver.id}-${index}`}
            sx={{
              borderBottom: index < transactions.length - 1 ? 1 : 0,
              borderColor: "divider",
              py: 2,
            }}
            spacing={1}
          >
            <ListItem sx={{ p: 0 }}>
              <UserAvatar user={receiver}>
                <UserInfo user={receiver} />
              </UserAvatar>

              <AutoAwesomeRoundedIcon
                color="disabled"
                fontSize="small"
                sx={{ mx: 2, ml: "auto" }}
              />
              <TextField
                dir="rtl"
                type="number"
                onChange={(e) => handleChange(id, e.target.value)}
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 1,
                    style: { textAlign: "right" },
                  },
                  style: {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
                variant="outlined"
                size="small"
                sx={{ width: "80px" }}
                value={amount}
                error={!!error}
                disabled={!canSendMultiple}
              />
            </ListItem>
            {error && (
              <Typography
                variant="subtitle2"
                sx={{
                  alignSelf: "flex-end",
                  color: (theme) => theme.palette.error.dark,
                }}
              >
                {error}
              </Typography>
            )}
          </Stack>
        ))}
      </List>
    </Stack>
  );
};
