import { FormHelperText } from "@mui/material";

type Props = {
  error?: string;
};

export const DescriptionHelperText = ({ error }: Props) => {
  return (
    <FormHelperText
      sx={{
        // fontSize: 12,
        margin: 0,
        px: 1,
        background: (theme) => theme.palette.background.paper,
        ...(error && {
          color: (theme) => theme.palette.error.main,
        }),
      }}
    >
      {error ??
        `Describe any citizenship activity outside your usual role. Include goals, activities, and reasons for colleagues to join.
`}
    </FormHelperText>
  );
};
