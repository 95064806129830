import { Box, IconButton } from "@mui/material";
import { XChangeLogo } from "@/images";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
// icons
import ArrowRight from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import ArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";

type Props = {
  open: boolean;
  onExpand: (open: boolean) => void;
};

export const MenuHeader = ({ open, onExpand }: Props) => {
  const navigate = useNavigate();
  const goToHome = () => navigate(ROUTES.root);
  const onClick = () => onExpand(!open);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box
        sx={{
          display: open ? "inline-flex" : "none",
          cursor: "pointer",
        }}
        onClick={goToHome}
      >
        <XChangeLogo />
      </Box>

      <IconButton
        onClick={onClick}
        sx={{ display: open ? "none" : "inline-flex" }}
      >
        <ArrowRight fontSize="inherit" />
      </IconButton>

      <IconButton
        onClick={onClick}
        sx={{ display: open ? "inline-flex" : "none" }}
      >
        <ArrowLeft fontSize="inherit" />
      </IconButton>
    </Box>
  );
};
