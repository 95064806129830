import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

type ReportShoutoutArgs = {
  shoutoutId: string | undefined;
  reason: string;
};

export const useReportShoutout = () => {
  const { authToken } = useUserState();

  const reportShoutout = async (
    url: string, 
    { arg }: { arg: ReportShoutoutArgs }
  ) => {
    const { shoutoutId, reason } = arg;
    const res = await fetcher(
      `${url}/${shoutoutId}`, 
      {
        config: {
          method: "POST",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ reason }),
        },
      },
    );

    return res;
  }

  const { trigger, isMutating } = useSWRMutation(
    API_ENDPOINTS.shoutouts.report,
    reportShoutout
  );

  return {
    trigger,
    isMutating,
  };
};
