import { StepIconProps, styled } from "@mui/material";
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";

type Props = {
  type: "shoutout" | "transaction";
} & StepIconProps;

const ColorlibStepIconRoot = styled("div")<{
  ownerState: {
    completed?: boolean;
    active?: boolean;
    type?: "shoutout" | "transaction";
  };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.action.disabled,
  color: theme.palette.common.white,
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: theme.spacing(1),
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor:
      ownerState?.type === "shoutout"
        ? theme.palette.success.main
        : theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor:
      ownerState?.type === "shoutout"
        ? theme.palette.success.main
        : theme.palette.primary.main,
  }),
  svg: {
    fontSize: 16,
  },
}));

export const StepIcon = (props: Props) => {
  const { active, completed, className, type } = props;

  const icons: { [index: string]: React.ReactElement } = {
    transaction: <AutoAwesomeRoundedIcon fontSize="small" />,
    shoutout: <CelebrationRoundedIcon fontSize="small" />,
  };

  const iconRenderer = () => {
    if (active || completed) return icons[type];
    return <LockRoundedIcon />;
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, type }}
      className={className}
    >
      {iconRenderer()}
    </ColorlibStepIconRoot>
  );
};
