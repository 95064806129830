import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const UploaderDialog = ({ open, onClose, children }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paperFullScreen": {
          borderRadius: theme.spacing(0),
        },
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 700,
            pt: 2,
          },
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      {children}
    </Dialog>
  );
};
