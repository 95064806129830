import { LoadingButton } from "@mui/lab";
import { isEditorEmpty } from "@/utils";
import { useCreateShoutout } from "@/hooks/api";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";
import { useShoutsState } from "@/state";
import { ShoutoutBodyRequest } from "@/interface";
import { CelebrationRounded } from "@mui/icons-material";

type Props = {
  onMutate: () => void;
};

export const CreateShoutButton = ({ onMutate }: Props) => {
  const { content, receivers, isPublic, setState } = useShoutsState();
  const [editor] = useLexicalComposerContext();
  const { trigger: create, isMutating } = useCreateShoutout();

  const clearEditor = () => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();
    });
    editor.focus();
  };

  const onCreate = () => {
    const data: ShoutoutBodyRequest = {
      fields: {
        receivers: receivers?.map((receiver) => receiver.id),
        details: content ?? "",
        public: isPublic,
      },
    };

    create({ data }).then(() => {
      setState({ receivers: [] });
      onMutate();
      clearEditor();
    });
  };

  return (
    <LoadingButton
      disabled={isEditorEmpty(content ?? "") || receivers?.length == 0}
      variant="contained"
      color="primary"
      onClick={onCreate}
      loading={isMutating}
      endIcon={<CelebrationRounded />}
      sx={{ textTransform: 'none' }} // Add this line to maintain the case as is
    >
      Post Shout-out
    </LoadingButton>
  );
};
