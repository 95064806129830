import { Box, styled, BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "& .container": {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    maxWidth: 688,
    marginBottom: theme.spacing(3),
  },

  "& .button": {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface PageContainerProps extends PropsWithChildren, BoxProps {}


export const PageContainer = ({ children, ...props }: PageContainerProps) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};
