import { API_ENDPOINTS, fetcher } from "@/config";
import { SparxCycle } from "@/interface";
import { useUserState } from "@/state";
import { toCycles } from "@/utils";
import useSWR from "swr";

export const useCycles = () => {
  const { authToken } = useUserState();

  const { data, mutate } = useSWR<SparxCycle[]>(
    `${API_ENDPOINTS.tokens.cycles}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  const { active, upcoming, closed } = toCycles(data ?? []);

  return {
    active,
    upcoming,
    closed,
    mutate,
  };
};
