import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { BusinessUnit } from "@/interface";

export const useBusinessUnits = () => {
  const { authToken } = useUserState();

  const { data, isLoading, error } = useSWR<BusinessUnit[]>(
    `${API_ENDPOINTS.admin.sparx_analysis.business_units}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    businessUnits: data,
    isLoading,
    error,
  };
};
