import { Box, Card, styled, Link, IconButton } from "@mui/material";
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useInitiativeState } from "@/state";

const StyledPaper = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 1,
  background: theme.palette.background.default,
  border: `1 solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
}));

export const LinksRenderer = () => {
  const { links, setState } = useInitiativeState();

  const onDelete = (index: number) => {
    const arr = [...links];
    arr.splice(index, 1);
    setState({ links: arr });
  };

  if (links.length === 0) return null;

  return (
    <StyledPaper elevation={0}>
      {links.map(({ url, label }, i) => {
        const key = `${url}-${label}-${i}`;
        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            key={key}
          >
            <Link href={url} underline="hover" color="secondary">
              <LaunchRoundedIcon
                sx={{ verticalAlign: "middle", mr: 1, fontSize: 16 }}
              />
              {label}
            </Link>
            <IconButton size="small" onClick={() => onDelete(i)}>
              <DeleteRoundedIcon />
            </IconButton>
          </Box>
        );
      })}
    </StyledPaper>
  );
};
