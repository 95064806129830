import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { SingleSelectAutocomplete } from "./SingleSelectAutocomplete";
import { useOperationsState } from "@/state";

const SearchSection = () => {
  const [toggleRecipientField, setToggleRecipientField] = useState(false);
  const { sender, recipient, setState} = useOperationsState();

  const onClearRecipient = () => {
    setToggleRecipientField(false);
    setState({ recipient: [] });
  }
  
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Stack width="50%" spacing={1}>
        <Typography variant="h6">Select Sender</Typography>
        <SingleSelectAutocomplete
          label="Sender"
          value={sender}
          onChange={(sender) => setState({ sender: sender })}
          helperText="*Select Sender to see their outbound transactions"
        />
      </Stack>
      <Stack width="50%" spacing={1}>
        {(toggleRecipientField || recipient.length > 0) ? (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Select Recipient</Typography>
              <Button variant="text" size="small" color="error" onClick={onClearRecipient} sx={{ '&:hover': {background: 'none'} }}>
                Skip Recipient
              </Button>
            </Stack>
            <SingleSelectAutocomplete
              label="Recipient"
              value={recipient}
              onChange={(recipient) => setState({ recipient: recipient })}
              helperText="Select Recipient to narrow results."
            />
          </>
        ) :
          <Button disabled={sender.length === 0} variant="text" size="small" startIcon={<AddRoundedIcon />} sx={{ justifyContent: "flex-start", '&:hover': {background: 'none'} }} style={{ marginTop: "16px" }} onClick={() => setToggleRecipientField(true)}>
            Select Recipient
          </Button>
        }
      </Stack>      
    </Stack>
  );
};

export { SearchSection };