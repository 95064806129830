import { useState, useEffect } from "react";
import {
  Box,
  Button,
  CardContent,
  Grid,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { CycleStatus } from "./CycleStatus";
import { SparxCycle } from "@/interface";
import { formatDate } from "@/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { useExtendCycle, ExtendCycleBodyRequest, useCycles } from "@/hooks/api";
import { useUIActionsState } from "@/state";
import { ChangeCycleDialog } from "./ChangeCycleDialog";

type Props = {
  cycle: SparxCycle;
  expanded: boolean;
  onExpand: () => void;
  onAllocate?: () => void;
  isFirstCycle?: boolean;
};

export const CycleInfo = ({
  cycle,
  expanded,
  onExpand,
  onAllocate,
  isFirstCycle,
}: Props) => {
  const { startDate, endDate: originalEndDate, state, name, symbol } = cycle;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs(originalEndDate));  
  const { trigger } = useExtendCycle(symbol);
  const { setSnackbarOpen } = useUIActionsState();
  const { mutate } = useCycles();


  const startDateLabel = state === "INACTIVE" ? "Starts:" : "Started:";
  const caption = isFirstCycle
    ? "Allocate & start cycle"
    : "Allocate & switch cycle";

  useEffect(() => {
    setSelectedDate(dayjs(originalEndDate));
  }, [originalEndDate]);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setSelectedDate(date);
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedDate(dayjs(originalEndDate));
  };

  const handleDateSubmit = async (selectedDate: dayjs.Dayjs | null) => {
    if (!selectedDate) return;

    const data: ExtendCycleBodyRequest = {
      extensionToDate: selectedDate?.toISOString(),
    };

    try {
      await trigger({ data });
    } catch (error) {
      setSnackbarOpen({
        variant: "error",
        isOpen: true,
        message: (error as Error).message,
        hasFooter: false,
      });
    } finally {
      setOpenDialog(false);
      mutate();
    }
  }

  return (
    <>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={2}>
            <Stack direction="row" spacing={1}>
              <Renderer value={name} fontWeight={700} />
              <Renderer value="Sparx allocation" fontWeight={700} greyed />
            </Stack>
          </Grid>
          <Grid item md={2}>
            <Stack direction="row" spacing={1}>
              <Renderer value={startDateLabel} greyed />
              <Renderer
                value={formatDate(new Date(startDate))}
                greyed={state !== "ACTIVE"}
              />
            </Stack>
          </Grid>
          <Grid item md={2}>
            <Stack direction="row" spacing={1}>
              <Renderer value="Ends:" greyed />
              <Renderer
                value={state !== "ACTIVE" ? formatDate(new Date(originalEndDate)) : ""}
                greyed={state !== "ACTIVE"}
              />
              {state === "ACTIVE" && (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                  <DatePicker 
                    value={selectedDate} 
                    minDate={dayjs(originalEndDate)}
                    onChange={handleDateChange}
                    slotProps={{
                      layout: {
                        sx(theme) {
                          return {
                            ".MuiDateCalendar-root": {
                              backgroundColor: theme.palette.background.default,
                            },
                          };
                        },
                      },
                      day: {
                        sx(theme) {
                          return {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: theme.palette.primary.main,
                            },
                            "&.MuiPickersDay-dayWithMargin": {
                              fontSize: theme.typography.subtitle2.fontSize,
                            },
                          };
                        },
                      },
                      textField: {
                        size: "small",
                        fullWidth: true,
                        variant: "standard",
                        InputProps: {
                          disableUnderline: true,
                          sx: {
                            fontWeight: 600,
                            width: "120px",
                          }
                        },
                      }
                    }}
                    sx={{
                      ".MuiButtonBase-root": {
                        color: "rgba(0, 0, 0, 0.87)",

                      }
                    }}
                  />
                </LocalizationProvider>
              )}
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Box display="flex" justifyContent="flex-end">
              {state === "INACTIVE" ? (
                <Button variant="contained" color="primary" onClick={onAllocate}>
                  {caption}
                </Button>
              ) : (
                <CycleStatus
                  state={state}
                  expanded={expanded}
                  onExpand={onExpand}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <ChangeCycleDialog 
        open={openDialog && !!selectedDate}
        onClose={handleDialogClose} 
        onSubmit={() => handleDateSubmit(selectedDate)} 
        newEndDate={selectedDate}
      />
    </>
  );
};

interface RendererProps extends TypographyProps {
  value: string;
  greyed?: boolean;
}

const Renderer = ({ value, greyed, ...rest }: RendererProps) => {
  return (
    <Typography
      {...rest}
      sx={{ color: (theme) => (greyed ? theme.palette.grey[200] : undefined) }}
    >
      {value}
    </Typography>
  );
};
