import { UserData } from "@/interface";
import { Buffer } from "buffer";

const hashCode = (s: string) => {
  let h = 0;
  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }
  return h;
};

export const stringToColor = (s: string): string => {
  const hash = hashCode(s);
  let color = "#";

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  return color;
};

const getFullname = (user: UserData) => `${user?.firstName} ${user?.lastName}`;

export const formatNames = (users: UserData[]) => {
  if (users.length === 0) {
    return "";
  } else if (users.length === 1) {
    return getFullname(users?.[0]);
  } else {
    const numberOfOthers = users.length - 1;
    return `${getFullname(users?.[0])} and ${numberOfOthers} other${
      numberOfOthers > 1 ? "s" : ""
    }`;
  }
};

export const getAvatarSrc = (user?: UserData | Partial<UserData>) => {
  if (!user) return { base64: "", initials: "" };

  const { profileImage, firstName, lastName } = user;

  let base64 = "";
  let initials = "";

  if (profileImage?.data) {
    const { data } = profileImage;
    base64 = `data:image/png;base64,${Buffer.from(data, "binary").toString("base64")}`;
  } else {
    initials =
      `${firstName?.charAt(0) ?? ""}${lastName?.charAt(0) ?? ""}`.toUpperCase();
  }

  return { base64, initials };
};

export const userInfo = (user?: UserData | Partial<UserData>) => {
  return {
    fullName: `${user?.lastName}, ${user?.firstName}`,
    jobTitle: user?.title,
  };
};
