import { useState } from "react";
import {
  MarkdownRenderer,
  OverflowMenu,
  Reactions,
  TaggedUserList,
  UserAvatar,
  UserInfo,
} from "@/components";
import { ShoutoutData } from "@/interface";
import { ReactionType } from "@/enums";
import { Box, Stack, Typography } from "@mui/material";
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import dayjs from "dayjs";
import { toReactions } from "@/utils";

type Props = {
  shout: ShoutoutData;
  hasReactions?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onReport?: () => void;
  onAddReaction?: (reaction: ReactionType) => void;
  onDeleteReaction: (reactionId: string, reaction: ReactionType) => void;
};

export const ShoutRenderer = ({
  shout,
  hasReactions,
  onEdit,
  onDelete,
  onReport,
  onAddReaction,
  onDeleteReaction,
}: Props) => {
  const { receivers, author, createdAt, details } = shout;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit?.();
    handleCloseMenu();
  };

  const handleDelete = () => {
    onDelete?.();
    handleCloseMenu();
  };

  const handleReport = () => {
    onReport?.();
    handleCloseMenu();
  }

  const handleReaction = (
    active: boolean,
    reactionId: string,
    type: ReactionType
  ) => {
    if (active) onDeleteReaction(reactionId, type);
    else onAddReaction?.(type);
  };

  return (
    <Stack sx={{ p: 2, pb: 3 }} gap={2}>
      <Box
        display="flex"
        justifyItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <UserAvatar user={author}>
          <Stack>
            <Box display="flex" alignItems="center" gap={0.5}>
              <UserInfo
                user={author}
                variant="subtitle2"
                showJobTitle={false}
              />
              <Box
                component="span"
                sx={{
                  width: 3,
                  height: 3,
                  bgcolor: "text.secondary",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginLeft: 0.5,
                }}
              />
              {shout.public ? (
                <PublicRoundedIcon fontSize="small" color="disabled" />
              ) : (
                <LockPersonRoundedIcon fontSize="small" color="disabled" />
              )}
            </Box>

            <Typography variant="caption">Posted a shout-out</Typography>
          </Stack>
        </UserAvatar>

        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2" color="text.secondary">
            {dayjs(createdAt).format("ll")}
          </Typography>
          <OverflowMenu
            open={open}
            anchorEl={anchorEl}
            setOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            onEdit={onEdit && handleEdit}
            onDelete={onDelete && handleDelete}
            onReport={handleReport}
            deleteCaption="Delete"
          />
        </Stack>
      </Box>
      <Stack>
        <TaggedUserList receivers={receivers?.map((r) => r.receiver)} />
        <MarkdownRenderer
          markdown={details}
          sx={{
            wordWrap: "break-word",
          }}
        />
      </Stack>
      {hasReactions && (
        <Reactions
          reactions={toReactions(shout?.reactions)}
          onReact={handleReaction}
        />
      )}
    </Stack>
  );
};
