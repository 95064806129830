export const colorsAvatars = ["#F472B6", "#0EA5E9", "#0C8A8A"];

export const shoutoutCategoryColors = [
  { color1: "#22C55E", color2: "#059669" },
  { color1: "#EC4899", color2: "#D946EF" },
  { color1: "#FACC15", color2: "#F97316" },
  { color1: "#10B8B8", color2: "#0EA5E9" },
  { color1: "#6D53FF", color2: "#7C3AED" },
  { color1: "#0C8A8A", color2: "#085C5C" },
];

export const customColors = {
  lightRed: "#FFF1F2",
  darkRed: "#BE123C",
  oceanBlue: "#2563EB",
  mediumGreen: "#16A34A",
  infoBackground: "#EDF2FD",
  transactionAmountBackground: "#ECF7F0",
  transactionAmountText: "#0F7233",
  menuBackgroundActive: "#6d53ff29",
};

export const colorsAvatarsArray = [
  { bgColor: "#DBD4FF", textColor: "#6D53FF" },
  { bgColor: "#00FFCC", textColor: "#006652" },
  { bgColor: "#FDBA74", textColor: "#C2410C" },
  { bgColor: "#BAE6FD", textColor: "#075985" },
  { bgColor: "#0C8A8A", textColor: "#72F0F0" },
  { bgColor: "#72F0F0", textColor: "#0C8A8A" },
];