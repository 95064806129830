import { ContributionData, UserData } from "@/interface";
import { Box, Chip, Typography, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Renderer } from "./Renderer";
import { useUserState } from "@/state";
import { SendSparxButton } from "../common/SendSparxButton";
import { UserAvatar } from "@/components";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  ".divider": {
    borderLeft: `2px solid ${grey[200]}`,
    marginLeft: theme.spacing(2.5),
  },
  ".last": {
    borderColor: `transparent`,
  },
}));

type Props = {
  user: UserData;
  items: ContributionData[];
  isOwner?: boolean;
  isAdmin?: boolean;
  onDelete: (contribution: ContributionData) => void;
  onEdit: (contribution: ContributionData) => void;
  onSendSparx: () => void;
};

export const ContributionRenderer = ({
  user,
  items,
  isOwner,
  isAdmin,
  onDelete,
  onEdit,
  onSendSparx,
}: Props) => {
  const { userData } = useUserState();

  const isContributionOwner = userData?.id === user.id;
  const showOwnerShip = isContributionOwner && isOwner;
  const canDelete = isContributionOwner || isAdmin;
  const showSparxButton = isOwner && userData?.id !== user.id;

  return (
    <StyledContainer gap={1}>
      <Box display="flex" justifyContent="space-between">
        <UserAvatar user={user}>
          <Typography variant="subtitle1">{user?.fullName}</Typography>
          {showOwnerShip && (
            <Chip label="Owner" color="secondary" sx={{ p: 0.5 }} />
          )}
        </UserAvatar>
        {showSparxButton && <SendSparxButton onClick={onSendSparx} />}
      </Box>

      <Box className="divider" sx={{ height: 20 }} />
      {items.map((item, index) => (
        <Renderer
          key={item.id}
          contribution={item}
          isLast={index === items.length - 1}
          onDelete={canDelete ? onDelete : undefined}
          onEdit={isContributionOwner ? onEdit : undefined}
        />
      ))}
    </StyledContainer>
  );
};
