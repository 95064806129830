import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";

type Props = {
  regions: string[];
  region: string;
  onChange: (region: string) => void;
};

export const RegionSelector = ({ region, regions, onChange }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="region-label">Region</InputLabel>
      <Select
        labelId="region-label"
        value={region}
        label="Region"
        onChange={handleChange}
      >
        {regions?.map((region) => {
          return (
            <MenuItem key={region} value={region}>
              {region.toUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
