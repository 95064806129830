import { Stack, Typography } from "@mui/material";

import { NoInitiatives } from "@/images";

export const Empty = () => {
  return (
    <Stack spacing={2}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        py={10}
      >
        <NoInitiatives />
        <Typography variant="h5" marginTop={4}>
          No matches found for your current selection
        </Typography>
        <Typography marginBottom={2}>
          Try adjusting your filters, changing your keyword, or consider
          creating your own initiative.
        </Typography>
      </Stack>
    </Stack>
  );
};
