import { OpenInNewRounded } from "@mui/icons-material";
import {
  Button,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useUserState } from "../../state";
import DOMPurify from "dompurify";
import { ensureHttpPrefix } from "@/utils";
import { UserAvatar } from "../UserAvatar";
import { UserInfo } from "../UserInfo";

type Props = {
  open?: boolean;
  width?: number;
  height?: number;
};

const ProfileDropdown = ({ open = false, width, height }: Props) => {
  const { userData } = useUserState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UserAvatar
        role="menu"
        user={userData}
        onClick={handleClick}
        aria-controls={anchorEl ? "profile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        sx={{
          width: width || 42,
          height: height || 42,
          ":hover": { cursor: "pointer" },
        }}
        hasTooltip={false}
      >
        {open && <UserInfo user={userData} jobTitleSx={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "no-wrap"}}/>}
      </UserAvatar>

      <Menu
        anchorEl={anchorEl}
        id="profile-menu"
        data-testid="profile-menu"
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        sx={(theme) => ({
          "& .MuiPaper-root": {
            backgroundColor: "background.default",
            boxShadow: theme.shadows[6],
          },
        })}
      >
        <Stack
          gap={2}
          sx={(theme) => ({
            minWidth: theme.spacing(30),
          })}
        >
          <Stack alignItems="center" width="100%" gap={1}>
            <Stack alignItems="center" width="100%" p={1}>
              <UserAvatar user={userData} sx={{ width: 56, height: 56 }} />
              <Stack alignItems="center" width="100%" >
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={(theme) => ({
                    marginTop: theme.spacing(2),
                  })}
                >
                  {userData?.firstName} {userData?.lastName}
                </Typography>
                {userData?.title && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={(theme) => ({
                      marginTop: theme.spacing(0),
                    })}
                  >
                    {userData?.title}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Button
              color="primary"
              variant="contained"
              target="_blank"
              href={ensureHttpPrefix(
                DOMPurify.sanitize(
                  `https://profiles.navigator.bcg.com/overview?hrid=${userData?.employeeNumber}`
                )
              )}
            >
              View KN Profile
              <OpenInNewRounded sx={{ fontSize: "20px", marginLeft: "8px" }} />
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export { ProfileDropdown };
