import { Button } from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@/config";

type Props = {
  isOwner?: boolean;
  isAdmin: boolean | undefined;
};

export const Edit = ({ isOwner, isAdmin = false }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const onEdit = () => {
    navigate(`${ROUTES.edit_initiative}/?id=${id}`);
  };

  return isOwner || isAdmin ? (
    <Button
      startIcon={<EditRoundedIcon />}
      color="secondary"
      variant="text"
      onClick={onEdit}
    >
      Edit
    </Button>
  ) : null;
};
