import { CategoryIcon } from "@/components";
import { ContributionsByInitiative } from "@/interface";
import { Box, Stack, Typography } from "@mui/material";

type Props = {
  data: ContributionsByInitiative;
};

export const CategoryInfo = ({ data }: Props) => {
  const { initiative } = data;
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <CategoryIcon name={initiative.category?.name} />
      <Stack>
        <Typography variant="body2">{initiative.category?.name}</Typography>
        {initiative.subCategories?.map((sub) => (
          <Typography key={sub.id} variant="subtitle2">
            {sub?.name}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};
