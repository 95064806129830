import { API_ENDPOINTS, fetcher } from "@/config";
import { ShoutoutBodyRequest, ShoutoutData } from "@/interface";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useShoutout = () => {
  const { authToken } = useUserState();

  async function sendRequest(
    url: string,
    { arg }: { arg: { data: ShoutoutBodyRequest } }
  ) {
    const res = await fetcher<ShoutoutData>(url, {
      config: {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(arg.data),
      },
    });

    return res;
  }

  const { trigger, isMutating, error } = useSWRMutation(
    API_ENDPOINTS.shoutouts.root,
    sendRequest
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
