import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

export const LoadingState = () => {
  return (
    <TableBody>
      <TableRow
        style={{
          height: 100,
        }}
      >
        <TableCell colSpan={5} align="center">
          <CircularProgress color="secondary" />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
