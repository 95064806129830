import { Theme } from "@mui/material/styles";

export default function Radio(theme: Theme) {
  return {
    MuiRadio: {
      styleOverrides: {
        styleOverrides: {
          root: {
            color: theme.palette.tertiary[400],
          },
        },
      },
    },
  };
}
