import { useLocation, useNavigate } from "react-router-dom";
import { useState, MouseEvent } from "react";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  useTheme
} from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useMenuItems } from "../../hooks/useMenuItems";
import { getActiveState } from "@/utils";
import { customColors } from "@/theme/colors";

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ active, theme }) => {
  return {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,

    ...(active && {
      color: theme.palette.text.primary,
      background: customColors.menuBackgroundActive,
    }),

    "& .MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: theme.spacing(2),
      color: theme.palette.text.secondary,
      ...(active && {
        color: theme.palette.text.primary,
      }),
    },
  };
});

const HeaderMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (event: MouseEvent<HTMLElement>) => {
    anchorEl ? handleCloseMenu() : handleOpenMenu(event);
  };

  const menuItems = useMenuItems();

  return (
    <Box>
      <IconButton
        aria-label="menu"
        onClick={toggleMenu}
        sx={{
          color: theme.palette.grey[200],
        }}
      >
        {anchorEl ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
      </IconButton>
      <Menu
        sx={{
          mt: 4,
          "& .MuiPaper-root": {
            backgroundColor: "background.default",
            boxShadow: theme.shadows[6],
            minWidth: 240,
            p: 2,
          },
        }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {menuItems.map((item) => (
          <StyledMenuItem
            key={item.label}
            onClick={() => {
              handleCloseMenu();
              item.to && navigate(item.to);
            }}
            active={getActiveState(location.pathname, item.to ?? "")}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText> {item.label}</ListItemText>
          </StyledMenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export { HeaderMenu };
