import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

export const CycleContainer = ({
  children,
  status,
}: PropsWithChildren<{ status: string }>) => {
  return (
    <Stack spacing={2}>
      <Typography>{status}</Typography>
      {children}
    </Stack>
  );
};
