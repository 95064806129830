import { MakeOptional } from "@mui/x-charts/internals";
import { PieValueType } from "@mui/x-charts";

export const donutGraphConfig = (totalValue: number) => {
  return {
    innerRadius: 65,
    outerRadius: 90,
    arcLabelRadius: 120,
    valueFormatter: (v: MakeOptional<PieValueType, "id">) => {
      return `${parseFloat((v.value / totalValue * 100).toFixed(1))}% (${v.value})`;
    },
  };
};
