import { Shadows } from "@mui/material";
  
  export const createShadows = (): Shadows => [
    'none', // elevation 0
    `
      0px 0px 0px 1px #0E3F7E02,
      0px 0.5px 0.5px -0.25px #2A334502,
      0px 1px 1px -0.5px #2A334502,
      0px 2px 2px -1px #2A334502,
      0px 4px 4px -2px #0E3F7E02,
      0px 8px 8px -4px #0E3F7E04
    `, // elevation 1
    `
      0px 0px 0px 1px #0E3F7E04,
      0px 1px 1px -0.5px #2A334504,
      0px 2px 2px -1px #2A334504,
      0px 4px 4px -2px #2A334504,
      0px 8px 8px -4px #0E3F7E04,
      0px 16px 16px -8px #0E3F7E08
    `, // elevation 2
    `
      0px 0px 0px 1px #0E3F7E06,
      0px 1.5px 1.5px -0.75px #2A334506,
      0px 3px 3px -1.5px #2A334506,
      0px 6px 6px -3px #2A334506,
      0px 12px 12px -6px #0E3F7E06,
      0px 24px 24px -12px #0E3F7E0C
    `, // elevation 3
    `
      0px 0px 0px 1px #0E3F7E08,
      0px 2px 2px -1px #2A334508,
      0px 4px 4px -2px #2A334508,
      0px 8px 8px -4px #2A334508,
      0px 16px 16px -8px #0E3F7E08,
      0px 32px 32px -16px #0E3F7E10
    `, // elevation 4
    '', '', '', '', // elevation 5-8 (skip for brevity)
    `
      0px 0px 0px 1px #0E3F7E10,
      0px 1.5px 1.5px -0.75px #2A334510,
      0px 4.5px 4.5px -2.25px #2A334610,
      0px 9px 9px -4.5px #2A334610,
      0px 18px 18px -9px #0E3F7E08,
      0px 36px 36px -18px #0E3F7E10
    `, // elevation 8
    '', '', '', '', // elevation 9-12 (skip for brevity)
    `
      0px 0px 0px 1px #0E3F7E18,
      0px 2px 2px -1px #2A334518,
      0px 6px 6px -3px #2A334618,
      0px 12px 12px -6px #2A334618,
      0px 24px 24px -12px #0E3F7E10,
      0px 48px 48px -24px #0E3F7E18
    `, // elevation 12
    '', '', '', '', // elevation 13-16 (skip for brevity)
    `
      0px 0px 0px 1px #0E3F7E20,
      0px 2.5px 2.5px -1.25px #2A334520,
      0px 7.5px 7.5px -3.75px #2A334620,
      0px 15px 15px -7.5px #2A334620,
      0px 30px 30px -15px #0E3F7E10,
      0px 60px 60px -30px #0E3F7E20
    `, // elevation 16
    '', '', '', '', // elevation 17-24 (skip for brevity)
    `
      0px 0px 0px 1px #0E3F7E28,
      0px 3px 3px -1.5px #2A334528,
      0px 9px 9px -4.5px #2A334628,
      0px 18px 18px -9px #2A334628,
      0px 36px 36px -18px #0E3F7E14,
      0px 72px 72px -36px #0E3F7E28
    `, // elevation 24
  ];