import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

type Props = {
  startDate?: string;
  endDate?: string;
};

export const Dates = ({ startDate, endDate }: Props) => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">Dates</Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <DateRangeRoundedIcon />
        <Typography>{dayjs(startDate).format("ll")}</Typography>
        {endDate && (
          <>
            <ArrowForwardRoundedIcon sx={{ fontSize: 16 }} />
            <Typography>{dayjs(endDate).format("ll")}</Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
