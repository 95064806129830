import { DialogProps, Dialog as MuiDialog } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<
  {
    open: boolean;
    onClose: () => void;
  } & DialogProps
>;

export const CustomDialog = ({ open, onClose, children, ...rest }: Props) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 400,
            pt: 2,
          },
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
      {...rest}
    >
      {children}
    </MuiDialog>
  );
};
