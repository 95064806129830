import {
  Button,
} from "@mui/material";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useDetailsState } from "@/state";

type Props = {
  isAdmin: boolean | undefined;
};

export const Delete = ({isAdmin = false }: Props) => {
  const { setState } = useDetailsState();

  return isAdmin ? (
    <Button
      startIcon={<DeleteRoundedIcon />}
      variant="text"
      color="secondary"
      onClick={() => setState({ openDeleteDialog: true })}
    >
      Delete
    </Button>
  ) : null;
};
