import useSWRMutation from "swr/mutation";
import { API_ENDPOINTS, fetcher } from "@/config";
import { DelegateOnBehalfPayload } from "@/interface";
import { useUserState } from "@/state";

export const useDelegateOnBehalf = () => {
  const { authToken } = useUserState();

  async function sendRequest(
    url: string,
    { arg }: { arg: { data: DelegateOnBehalfPayload } }
  ) {
    const res = await fetcher(url, {
      config: {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(arg.data),
      },
    });

    return res;
  }

  const { trigger, isMutating, error } = useSWRMutation(
    API_ENDPOINTS.admin.delegate_on_behalf,
    sendRequest
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
