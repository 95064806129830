import { Box, Skeleton, Stack } from "@mui/material";

export const ShoutoutsSkeleton = () => {
  return (
    <Stack sx={{ p: 2, pb: 3 }} gap={2}>
      <Box display="flex" justifyItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <Skeleton variant="circular" width={40} height={40} />
          <Stack gap={1}>
            <Skeleton variant="rounded" width={150} height={10} />
            <Skeleton variant="rounded" width={100} height={10} />
          </Stack>
        </Stack>
        <Skeleton variant="rounded" width={120} height={10} />
      </Box>

      <Stack gap={1}>
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} variant="rounded" width="100%" height={10} />
        ))}
      </Stack>
    </Stack>
  );
};
