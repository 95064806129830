import { useUserState } from "@/state";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import StatusChip from "../common/StatusChip";
import { InitiativeData } from "@/interface";
import { ROUTES } from "@/config";
import { CategoryIcon } from "@/components";

type Props = {
  initiative: InitiativeData;
};

export const RowItem = ({ initiative }: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const isReviewer = initiative.reviewers?.some(
    (reviewer) => reviewer.userId === userData?.id
  );

  return (
    <TableRow
      sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}
    >
      <TableCell>
        <Typography variant="body2">{initiative.title}</Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CategoryIcon name={initiative.category?.name ?? ""} />
          <Typography variant="subtitle2">
            {initiative.category?.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {isReviewer ? (
          <Chip color="warning" label="Reviewer" />
        ) : (
          <Chip color="info" label="Owner" />
        )}
      </TableCell>
      <TableCell>
        <StatusChip status={initiative.status} />
      </TableCell>
      <TableCell align="right">
        <Button
          sx={{ whiteSpace: "nowrap" }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            navigate(
              isReviewer
                ? `${ROUTES.initiative_review}/${initiative.id}`
                : `${ROUTES.initiative_preview}/${initiative.id}`
            )
          }
        >
          Review
        </Button>
      </TableCell>
    </TableRow>
  );
};
