import { Autocomplete, Checkbox, Chip, FormControl, TextField, Typography } from "@mui/material";
import { Category } from "@/interface";
import { useInitiativeState } from "@/state";

type Props = {
  categories: Category[];
};

export const SubCategorySelector = ({ categories }: Props) => {
  const { categoryId, subCategories: value, setState } = useInitiativeState();

  const subCategories =
    categories.find((cat) => cat.id === categoryId)?.subCategories ?? [];

  if (subCategories?.length === 0) return null;

  const onChange = (_: any, subs: Category[]) => {
    setState({ subCategories: subs ? subs : undefined });
  }

  return (
    <FormControl size="small" fullWidth>
      <Autocomplete
        size="small"
        value={value ?? []}
        onChange={onChange}
        multiple
        options={subCategories}
        getOptionLabel={(option) => option.name}
        fullWidth
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Sub category" />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.name}
              size="small"
              variant="rectangle"
              color="secondary"
              {...getTagProps({ index })}
              key={option.id}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox sx={{ px: 0 }} color="secondary" checked={selected} />
            <Typography color="text.primary" sx={{ ml: 1 }}>
              {option.name}
            </Typography>
          </li>
        )}
      />
    </FormControl>
  );
};
