import {
  alpha,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CelebrationRounded } from "@mui/icons-material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useRewardsByCycle } from "@/hooks/api";
import { useRewardsState } from "@/state";
import { GroupedAvatars } from "./GroupedAvatars";
import BorderedSection from "./common/BorderedSection";
import { CollectRewardsSkeleton } from "./CollectRewardsSkeleton";
import { getRewardsTextAndSubtext } from "@/utils";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CollectRewardsModal = ({ open, onClose }: Props) => {
  const { symbol, setState } = useRewardsState();
  const { data, isLoading } = useRewardsByCycle(symbol ?? "");
  const { rewardsText, subtext } = getRewardsTextAndSubtext(data);
  const showSparx = data.totalSparx > 0;
  const showShoutouts = data.totalShoutouts > 0;

  const onOpen = () => setState({ openRewardsModal: true });

  return (
    <CustomDialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogContent sx={{ padding: 3 }}>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseRoundedIcon />
        </IconButton>
        {isLoading ? (
          <CollectRewardsSkeleton />
        ) : (
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <DialogTitle textAlign="center">Collect your rewards!</DialogTitle>
            <GroupedAvatars
              users={[...data.shoutoutSenders, ...data.sparxSenders].slice(
                0,
                3
              )}
              amount={data.sparxSendersCount + data.shoutoutSendersCount}
            />
            <Stack textAlign="center">
              <Typography variant="subtitle1">{rewardsText}</Typography>
              <Typography>{subtext}</Typography>
            </Stack>
            <BorderedSection>
              <Stack direction="row" justifyContent="space-evenly">
                {showSparx && (
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" textAlign="center">
                      Sparx
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <AutoAwesomeRoundedIcon
                        sx={{
                          backgroundImage:
                            "linear-gradient(90deg, #3A20CC 0%, #9747FF 100%)",
                          color: (theme) => `${theme.palette.common.white}`,
                          borderRadius: "8px",
                          padding: "4px",
                        }}
                      />
                      <Typography variant="subtitle1">
                        {data.totalSparx}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: alpha("#000000", 0.87) }}
                      >
                        {" "}
                        Received
                      </Typography>
                    </Stack>
                  </Stack>
                )}
                {showSparx && showShoutouts && (
                  <Divider orientation="vertical" flexItem />
                )}
                {showShoutouts && (
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" textAlign="center">
                      Shoutouts
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <CelebrationRounded
                        sx={{
                          backgroundColor: (theme) =>
                            `${theme.palette.success.main}`,
                          color: (theme) => `${theme.palette.common.white}`,
                          borderRadius: "8px",
                          padding: "4px",
                        }}
                      />
                      <Typography variant="subtitle1">
                        {data.totalShoutouts}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: alpha("#000000", 0.87) }}
                      >
                        {" "}
                        Received
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </BorderedSection>
            <Button onClick={onOpen} variant="contained" color="primary">
              Collect Rewards
            </Button>
          </Stack>
        )}
      </DialogContent>
    </CustomDialog>
  );
};
