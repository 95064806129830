import { API_ENDPOINTS, fetcher } from "@/config";
import { ContributionInActiveCycle } from "@/interface";
import { useUserState } from "@/state";
import useSWR from "swr";

export const useContributorDetails = (userId?: string) => {
  const { authToken } = useUserState();

  const { data, mutate, isLoading } = useSWR<ContributionInActiveCycle>(
    userId ? `${API_ENDPOINTS.contributions}/users/${userId}/details/` : null,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    data,
    mutate,
    isLoading,
  };
};
