import { ShoutoutData, UserData } from "@/interface";
import { create } from "zustand";

interface ShoutoutsState {
  receivers: UserData[];
  content?: string;
  isPublic: boolean;
  // shoutout added using send sparx modal
  shoutout?: ShoutoutData;
  setState: (args: Partial<ShoutoutsState>) => void;
}

const useShoutsState = create<ShoutoutsState>((set) => ({
  receivers: [],
  isPublic: true,

  setState: (args?: Partial<ShoutoutsState>) => {
    set({ ...args });
  },
}));

export { useShoutsState };
