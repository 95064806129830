import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { SuccessMessage } from "@/interface";
import { SuccessDialog } from "@/pages/Sparx/common/SuccessDialog";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message?: SuccessMessage;
  onClose: () => void;
}

export const DelegateSuccessDialog = ({open, setOpen, message, onClose}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      sx={{
        "& .MuiDialog-paperFullScreen": {
          borderRadius: theme.spacing(0),
        },
      }}
    >
      <SuccessDialog
        title={message?.title}
        subTitle={message?.subtitle}
        onClose={onClose}
        redirect={false}
      />
    </Dialog>
  )
}
