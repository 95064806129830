import {
  Box,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
  useTheme,
} from "@mui/material";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { AllocationStatus, Distribution } from "@/interface";
import { normalize } from "@/utils";

type Props = {
  state: AllocationStatus;
  distribution: Distribution;
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  "&:last-child": {
    paddingBottom: theme.spacing(2),
  },
}));

const Bar = styled(LinearProgress)<{ state: AllocationStatus }>(
  ({ theme, state }) => ({
    height: 14,
    borderRadius: 43,

    [`&.${linearProgressClasses.root}`]: {
      backgroundColor: theme.palette.grey[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor:
        state === "ACTIVE"
          ? theme.palette.secondary.main
          : theme.palette.grey[200],
      borderRadius: 43,
    },
  })
);

export const DistributionBar = ({ state, distribution }: Props) => {
  const theme = useTheme();
  const { assigned, total } = distribution;
  const normalizedSparx = normalize(assigned, 0, total);
  const percentage = total !== 0 ? Math.floor((assigned / total) * 100) : 0;

  return (
    <StyledCardContent>
      <Stack spacing={1}>
        <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
          <Box sx={{ minWidth: 300, flex: 1 }}>
            <Bar
              variant="determinate"
              value={normalizedSparx}
              valueBuffer={100}
              state={state}
            />
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <Typography fontWeight={700}>{percentage}%</Typography>
            <Typography sx={{ color: theme.palette.grey[300] }}>
              ({assigned} out of {total})
            </Typography>
          </Box>
        </Box>

        <Stack direction="row" alignItems="center" spacing={1}>
          <CircleRoundedIcon
            fontSize="small"
            sx={{
              fontSize: 10,
              color:
                status === "Active"
                  ? theme.palette.secondary.main
                  : theme.palette.grey[200],
            }}
          />
          <Typography variant="caption" sx={{ color: theme.palette.grey[300] }}>
            Distributed Sparx
          </Typography>
        </Stack>
      </Stack>
    </StyledCardContent>
  );
};
