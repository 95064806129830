import { Box, Button, Stack, Typography } from "@mui/material";
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { DistributionBar } from "./DistributionBar";
import { AllocationStatus, Distribution } from "@/interface";
import { API_ENDPOINTS } from "@/config";
import { useUserState, useUIActionsState } from "@/state";
import { download, formatDate } from "@/utils";

type Props = {
  symbol: string;
  state: AllocationStatus;
  distribution: Distribution;
};

export const SparxDistribution = ({ state, distribution, symbol }: Props) => {
  const { lastUpdated } = distribution;
  const { authToken } = useUserState();
  const { setSnackbarOpen } = useUIActionsState();

  const onDownload = async () => {
    const url = `${API_ENDPOINTS.tokens.transactions_export}/${symbol}`;
    try {
      await download(authToken, url, `${symbol}-transactions.xlsx`);
    } catch (e) {
      setSnackbarOpen({
        variant: "error",
        isOpen: true,
        message: (e as Error).message,
        hasFooter: false,
      });
    }
  };

  return (
    <Stack spacing={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Stack>
          <Typography fontWeight={700}>Sparx distribution</Typography>
          <Typography variant="caption" color="text.secondary">
            Last updated on{" "}
            {lastUpdated ? formatDate(new Date(lastUpdated)) : "--"}
          </Typography>
        </Stack>

        <Button
          startIcon={<ArrowDownwardRoundedIcon />}
          variant="text"
          color="primary"
          onClick={onDownload}
        >
          Download all transactions
        </Button>
      </Box>

      <DistributionBar state={state} distribution={distribution} />
    </Stack>
  );
};
