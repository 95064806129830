import {
  TableCell,
  TableRow,
  Typography,
  Stack,
  styled,
} from "@mui/material";
import { AutoAwesomeRounded } from "@mui/icons-material";
import { UserAvatar, UserInfo } from "@/components";
import { Contributor } from "@/interface";
import { useBulkSparxState } from "@/state";
import { AmountField } from "../AmountField";
import { useEffect } from "react";

type Props = {
  contributor: Contributor;
  isItemSelected: boolean;
  sparxAmount?: number;
  onRowClick: (userId: string) => void;
  setUserSparxAmount: (userId: string, value: string | number) => void;
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  justifyContent: "flex-end",
  alignItems: "center",
  color: theme.palette.text.disabled,
}));

export const RowRenderer = ({
  contributor,
  isItemSelected,
  onRowClick,
  setUserSparxAmount,
  sparxAmount
}: Props) => {
  const { setAmount } = useBulkSparxState();
  const {
    numberOfContributions,
    totalSparxReceivedFromSender,
    user,
    error,
  } = contributor;

  useEffect(() => {
    if (sparxAmount !== undefined) {
      setAmount(user.id, sparxAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sparxAmount]);

  return (
    <TableRow
      hover
      onClick={() => onRowClick(user.id)}
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      sx={{
        cursor: "pointer",
        borderBottom: (theme) => `1px solid ${theme.palette.grey[50]}`,
      }}
    >
      <TableCell
        component="th"
        scope="row"
        padding="none"
        sx={{paddingLeft: '8px'}}
      >
        <UserAvatar user={user}>
          <UserInfo user={user} />
        </UserAvatar>
      </TableCell>
      <TableCell align="right">
        <Typography variant="subtitle2" color="blue">
          {numberOfContributions}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <StyledStack direction="row">
          <AutoAwesomeRounded sx={{ fontSize: 12 }} />
          <Typography variant="body2">
            {totalSparxReceivedFromSender}
          </Typography>
        </StyledStack>
      </TableCell>
      <TableCell align="right" sx={{ width: '283px' }}>
        <AmountField
          amount={sparxAmount}
          error={error}
          setAmount={(value) => setUserSparxAmount(user.id, value)}
        />
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};
