import { useMemo } from "react";
import { BarChart } from "@mui/x-charts";
import { SystemInfo } from "@/interface";
import { GeneralCard } from "@/components";

type Props = {
  data: SystemInfo[] | undefined
}

export const SystemOverviewCard = ({data}: Props) => {

  const systemOverview = useMemo(() => {
    return data
      ?.filter(systemInfo => systemInfo.system !== "Unknown")
      ?.map(systemInfo => {
      return {
        ...systemInfo,
        whalePercentage: parseFloat(
          (systemInfo.whale/(systemInfo.whale + systemInfo.shrimp) * 100)
            .toFixed(1)
        ),
        shrimpPercentage: parseFloat(
          (systemInfo.shrimp/(systemInfo.whale + systemInfo.shrimp) * 100)
            .toFixed(1)
        ),
      }
    }) ?? [];
  }, [data]);

  const valueFormatter = (value: number | null, { dataIndex }: { dataIndex: number }, field: 'shrimp' | 'whale') => {
    const item = systemOverview[dataIndex]
    const amount = field === 'shrimp' ? item.shrimp : item.whale;
    return `${value}% (${amount})`;
  };

  const computedGraphLeftMargin = systemOverview.reduce((longest, info) => {
    return info.system.length > longest.length ? info.system : longest;
  }, "").length * 6 + 15;


  const calculatedHeight = systemOverview.length * 80 + 80;

  return (
    <GeneralCard title="System Overview" subTitle="Sparx Distribution by Shrimp & Whales Across Systems">
      <BarChart
        dataset={systemOverview}
        series={[
          { dataKey: 'shrimpPercentage', label: 'Shrimp', color: "#FFAB91", valueFormatter: (value, params) => valueFormatter(value, params, 'shrimp') },
          { dataKey: 'whalePercentage', label: 'Whale', color: "#90CAF9", valueFormatter: (value, params) => valueFormatter(value, params, 'whale') },
        ]}
        yAxis={[{ scaleType: 'band', dataKey: 'system', disableLine: true, disableTicks: true }]}
        xAxis={[{ disableLine: true, disableTicks: true, tickLabelStyle: { display: 'none' } }]}
        height={calculatedHeight}
        margin={{ left: computedGraphLeftMargin, top: 60 }}
        layout={"horizontal"}
      />
    </GeneralCard>
  )
}