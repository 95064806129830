import { useState } from "react";
import { useReportShoutout } from "@/hooks/api";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField
} from "@mui/material";
import { ShoutoutData } from "@/interface";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  shoutout?: ShoutoutData;
  onClose: () => void;
  onSuccess: () => void;
};

export const ReportShoutout = ({ open, shoutout, onClose, onSuccess }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const { trigger, isMutating } = useReportShoutout();

  const onSubmit = () => {
    const input = inputValue.trim();
    trigger({ shoutoutId: shoutout?.id, reason: input}).then(() => {
      onClose();
      setInputValue("");
      onSuccess();
    });
  };

  const onCancel = () => {
    onClose();
    setInputValue("");
  }

  return (
    <CustomDialog open={open} onClose={onCancel} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          padding: 2,
          minWidth: 400,
          maxWidth: 646,
        },
      },
    }}>
      <DialogTitle textAlign="center">Report Shout-out</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={4}>
          Xconnect administrators will receive your report for review. They can see your name and the details you provide.
        </DialogContentText>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          label="I’d like to report this shout-out because..."
          variant="outlined"
          size="small"
          multiline={true}
          rows={5}
          sx={{
            width: "100%",
            "& .MuiFormHelperText-root": {
              background: (theme) => theme.palette.background.paper,
              m: 0.5,
              px: 1,
            },
          }}
        />
        <FormHelperText>*Please specify what exactly needs to be checked</FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={isMutating}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={!inputValue.trim()}
        >
          Report
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};
