import { Stack } from "@mui/material";
import { ContributionRenderer } from "./ContributionRenderer";
import { ContributionData, GroupedContributions } from "@/interface";
import { useState } from "react";
import { DeleteContribution } from "../../../../common/Delete";
import { EditContribution } from "../../../../common/Edit";
import { SendSparxModal } from "@/pages/common/sparx/SendSparxModal";
import { useCommonState } from "@/state";

type Props = {
  title: string;
  groupedContributions: GroupedContributions[];
  isOwner?: boolean;
  isAdmin?: boolean;
  mutate: () => void;
};

export const ContributionList = ({
  title,
  groupedContributions,
  isOwner,
  isAdmin,
  mutate,
}: Props) => {
  const { setState } = useCommonState();
  const [open, setOpen] = useState<"delete" | "edit">();
  const [contribution, setContribution] = useState<ContributionData>();

  const onAction = (
    action: "delete" | "edit",
    contribution: ContributionData
  ) => {
    setOpen(action);
    setContribution(contribution);
  };

  return (
    <>
      <Stack sx={{ p: 2 }} gap={4}>
        {groupedContributions?.map(({ user, items }) => (
          <ContributionRenderer
            key={user.id}
            user={user}
            isOwner={isOwner}
            isAdmin={isAdmin}
            items={items}
            onDelete={(c) => onAction("delete", c)}
            onEdit={(c) => onAction("edit", c)}
            onSendSparx={() => setState({ sparxReceiver: user })}
          />
        ))}
      </Stack>

      <DeleteContribution
        open={open === "delete"}
        contribution={contribution}
        onClose={() => setOpen(undefined)}
        mutate={mutate}
      />

      <EditContribution
        open={open === "edit"}
        title={title}
        contribution={contribution}
        onClose={() => setOpen(undefined)}
        mutate={() => {
          setOpen(undefined);
          mutate();
        }}
      />

      <SendSparxModal />
    </>
  );
};
