import { InitiativeStatus } from "@/enums";
import { Alert, styled } from "@mui/material";

type Props = {
  reviewNote?: string;
  status?: InitiativeStatus;
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  border: `1px solid ${theme.palette.error.main}`,
  borderRadius: theme.spacing(1),
}));

export const ReviewMessage = ({ reviewNote, status }: Props) => {
  return status === InitiativeStatus.REJECTED && reviewNote ? (
    <StyledAlert icon={false} severity="error">
      {reviewNote}
    </StyledAlert>
  ) : null;
};
