import {
  Button,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { DELEGATION_FREEZE_DAYS_BEFORE_CYCLE_END } from "@/constants";
import { Guidelines } from "./guideline/Guidelines";
import { ROUTES } from "@/config";
import { useBalance } from "@/hooks/api";
import { useCalculatePaddingTop } from "@/hooks";
import { hasAtLeastXDaysRemaining } from "@/utils";
import { BalanceCard } from "./BalanceCard";
import { Transactions } from "./transactions";
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { useSparxState, useUserState } from "@/state";
import { SubnavigationBar } from "@/components";
import { useNavigate } from "react-router-dom";
import { ReviewButton } from "./common/ReviewButton";
import { DelegateButtonWithPopover } from "./delegate/DelegateButtonWithPopover.tsx";
import { ArrowForwardRounded } from "@mui/icons-material";

const MySparx = () => {
  const paddingTop = useCalculatePaddingTop();
  const { data: balanceData } = useBalance();
  const { balance } = useSparxState();
  const { userData } = useUserState();
  const isWhale = userData?.isWhale;
  const navigate = useNavigate();
  
  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[{ label: "Sparx", href: ROUTES.sparx, icon: <AutoAwesomeRoundedIcon /> }]}
        actions={
          <Stack direction="row" alignItems="center" spacing={2}>
            <ReviewButton canSendMultiple={isWhale} />
            <DelegateButtonWithPopover disablePopover={hasAtLeastXDaysRemaining(balanceData?.cycle.endDate || "", DELEGATION_FREEZE_DAYS_BEFORE_CYCLE_END)}/>
            <Button variant="contained" color="primary" disabled={balance === 0} onClick={() => navigate(ROUTES.send_sparx)} endIcon={<ArrowForwardRounded /> }>
              Send Sparx
            </Button>
          </Stack>
        }
      />
      <Container>
        <Grid container spacing={3} sx={{mt: paddingTop}}>
          <Grid item xs={12} md={8}>
            <Transactions />
          </Grid>
          <Grid item xs={12} md={4}>
            <BalanceCard />
            <Guidelines />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export { MySparx };
