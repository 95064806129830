import { useInitiativeState } from "@/state";
import { Box, Button } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import dayjs from "dayjs";

export const AddEndDate = () => {
  const { endDate, setState } = useInitiativeState();

  const onClick = () => setState({ endDate: dayjs(new Date()).toISOString() });

  if (endDate) return null;

  return (
    <Box flex={1} display="flex" justifyContent="flex-end">
      <Button
        variant="text"
        color="primary"
        startIcon={<AddRoundedIcon />}
        onClick={onClick}
      >
        End date
      </Button>
    </Box>
  );
};
