import { PropsWithChildren } from "react";
import { LoadingState } from "./LoadingState";
import { EmptyState } from "./EmptyState";

type Props = PropsWithChildren<{
  isLoading?: boolean;
  isEmpty: boolean;
}>;

export const BodyRenderer = ({ isLoading, isEmpty, children }: Props) => {
  if (isLoading) return <LoadingState />;
  if (!isLoading && isEmpty) return <EmptyState />;

  return children;
};
