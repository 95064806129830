import { Grid, Stack, Typography } from "@mui/material";
import { useBusinessUnits, useGenders, useSystemOverview, useTransactionTypes } from "@/hooks/api";
import { TRANSACTION_TYPES_CONFIGS } from "./constants";
import { SystemOverviewCard } from "./components/SystemOverviewCard";
import { DonutGraphCard } from "./components/DonutGraphCard";
import { BarChartCard } from "./components/BarChartCard.tsx";

const AnalysisPanel = () => {
  const { businessUnits: businessUnitsFromApi, isLoading: businessLoading } = useBusinessUnits();
  const { transactionTypes: transactionTypesFromApi, totalValue: transactionTotal, isLoading: transactionLoading } = useTransactionTypes();
  const { genders: gendersFromApi, isLoading: genderLoading } = useGenders();
  const { systemOverview: systemOverviewFromApi, isLoading: systemLoading } = useSystemOverview();

  const isLoading = businessLoading || transactionLoading || genderLoading || systemLoading;

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Stack spacing={1}>
        <Typography variant="h5" component="h1">
          Analysis (Q4)
        </Typography>
        <Typography variant="body1">
          Make decisions based on aggregated data
        </Typography>
      </Stack>
      { !isLoading &&
        <Grid container spacing={4} paddingTop={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6.6}>
            <SystemOverviewCard data={systemOverviewFromApi}/>
          </Grid>
          {/* Right Column */}
          <Grid item xs={12} md={5.4} spacing={4}>
            <Grid item xs={12} mb={2}>
              <DonutGraphCard
                title="Transaction Type"
                subTitle="% of Sparx Distributed by Transaction Type"
                data={transactionTypesFromApi}
                totalValue={transactionTotal}
                configs={TRANSACTION_TYPES_CONFIGS}
                field="transactionType"
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <BarChartCard
                title="Gender"
                subTitle="% of Users that distributed Sparx by Gender"
                data={gendersFromApi}
                field="gender"
              />
            </Grid>
            <Grid item xs={12}>
              <BarChartCard
                title="Business Unit"
                subTitle="% of Users that distributed Sparx by Business Unit"
                data={businessUnitsFromApi}
                field="businessUnit"
              />
            </Grid>
          </Grid>
        </Grid>
      }
    </Stack>
  );
};

export { AnalysisPanel };
