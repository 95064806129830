import { CardContent, Collapse, Stack } from "@mui/material";
import { Commitment } from "./Commitment";
import { Participation } from "./Participation";
import { useInitiativeState } from "@/state";

export const CollaborationDetails = () => {
  const { collaboration } = useInitiativeState();

  return (
    <Collapse in={collaboration} timeout="auto" unmountOnExit>
      <CardContent>
        <Stack spacing={2}>
          <Commitment />
          <Participation />
        </Stack>
      </CardContent>
    </Collapse>
  );
};
