import {
  Card,
  CardContent,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { CollaborationDetails } from "./CollaborationDetails";
import { useInitiativeState } from "@/state";

const StyledCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.default,
  border: `1 solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  boxShadow: theme.shadows[1],
}));

export const Collaboration = () => {
  const { collaboration, setState } = useInitiativeState();

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Collaboration</Typography>
      <StyledCard elevation={0}>
        <CardContent
          sx={{
            pt: 1,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color="text.secondary">
              I’m actively looking for support
            </Typography>
            <Switch
              checked={collaboration}
              color="secondary"
              onChange={() => setState({ collaboration: !collaboration })}
            />
          </Stack>
        </CardContent>
        <CollaborationDetails />
      </StyledCard>
    </Stack>
  );
};
