import { Box, IconButton } from "@mui/material";
import { useInitiativeState } from "@/state";
import { StyledDatePicker } from "./StyledDatePicker";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

export const EndDate = () => {
  const { endDate, setState } = useInitiativeState();

  if (!endDate) return null;

  return (
    <Box flex={1} display="flex" alignItems="center" gap={1}>
      <StyledDatePicker
        label="end date"
        date={endDate}
        onChange={(date) =>
          setState({ endDate: date?.toISOString() ?? undefined })
        }
      />
      <IconButton size="small" onClick={() => setState({ endDate: undefined })}>
        <DeleteRoundedIcon />
      </IconButton>
    </Box>
  );
};
