const NoInitiatives = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="295"
    height="228"
    viewBox="0 0 295 228"
    fill="none"
  >
    <rect y="24" width="182" height="204" rx="16" fill="#F0F0F1" />
    <rect
      x="0.5"
      y="24.5"
      width="181"
      height="203"
      rx="15.5"
      stroke="black"
      stroke-opacity="0.12"
    />
    <circle cx="27.5" cy="51.5" r="11.5" fill="black" fill-opacity="0.08" />
    <rect
      x="16"
      y="97"
      width="107"
      height="13"
      rx="6.5"
      fill="black"
      fill-opacity="0.08"
    />
    <rect
      x="16"
      y="130"
      width="150"
      height="6"
      rx="3"
      fill="black"
      fill-opacity="0.08"
    />
    <rect
      x="16"
      y="144"
      width="150"
      height="6"
      rx="3"
      fill="black"
      fill-opacity="0.08"
    />
    <rect x="16" y="184" width="20" height="20" rx="6" fill="#DBD4FF" />
    <rect
      x="44"
      y="191"
      width="48"
      height="6"
      rx="3"
      fill="black"
      fill-opacity="0.08"
    />
    <rect x="113" width="182" height="204" rx="16" fill="white" />
    <rect
      x="113.5"
      y="0.5"
      width="181"
      height="203"
      rx="15.5"
      stroke="black"
      stroke-opacity="0.12"
    />
    <circle cx="140.5" cy="27.5" r="11.5" fill="black" fill-opacity="0.08" />
    <rect
      x="129"
      y="73"
      width="107"
      height="13"
      rx="6.5"
      fill="black"
      fill-opacity="0.08"
    />
    <rect
      x="129"
      y="106"
      width="150"
      height="6"
      rx="3"
      fill="black"
      fill-opacity="0.08"
    />
    <rect
      x="129"
      y="120"
      width="150"
      height="6"
      rx="3"
      fill="black"
      fill-opacity="0.08"
    />
    <rect x="129" y="160" width="20" height="20" rx="6" fill="#66FFE0" />
    <rect
      x="157"
      y="167"
      width="48"
      height="6"
      rx="3"
      fill="black"
      fill-opacity="0.08"
    />
  </svg>
);
export { NoInitiatives };
