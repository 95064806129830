import { API_ENDPOINTS, fetcher } from "@/config";
import { ContributionData } from "@/interface";

import { useUserState } from "@/state";
import useSWR from "swr";

export const useMyContributions = () => {
  const { authToken } = useUserState();

  const { data, mutate, isLoading } = useSWR<ContributionData[]>(
    `${API_ENDPOINTS.me.contributions}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return {
    data: data ?? [],
    mutate,
    isLoading,
  };
};
