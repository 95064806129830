import { Button } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { LeaveDialog } from "./LeaveDialog";
import { TransferDialog } from "./TransferDialog";
import { EnrollmentData } from "@/interface";
import { useUserState } from "@/state";
import { useInitiative } from "@/hooks/api";
import { toDetails } from "@/utils";

type Props = {
  enrollment?: EnrollmentData;
  mutate: () => void;
};

export const Joined = ({ enrollment, mutate }: Props) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const { userData } = useUserState();
  const { id } = useParams();
  const { data } = useInitiative(id ?? "");
  const { title, owners } = toDetails(data);

  const isInitiativeOwner = !!owners?.find(
    (owner) => owner?.id === userData?.id
  );

  if (!enrollment) return null;

  const onClose = () => {
    setOpen(false);
    mutate();
  };

  return (
    <>
      <Button
        variant={hover ? "contained" : "outlined"}
        color={hover ? "error" : "secondary"}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setOpen(true)}
      >
        {hover ? "Leave" : "Joined"}
      </Button>
      {isInitiativeOwner && owners.length <= 1 ? (
        <TransferDialog
          open={open}
          enrollmentId={enrollment.id}
          onClose={onClose}
          title={title}
        />
      ) : (
        <LeaveDialog
          open={open}
          enrollmentId={enrollment.id}
          onClose={onClose}
        />
      )}
    </>
  );
};
