import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import { useApprove } from "@/hooks/api";
import { ROUTES } from "@/config";
import { Reject } from "./Reject";

type Props = {
  mutate: () => void;
};

export const Review = ({ mutate }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { trigger, isMutating } = useApprove(id ?? "");

  const onApprove = () => {
    trigger().then(() => {
      mutate();
      navigate(`${ROUTES.initiative}/${id}`);
    });
  };

  return (
    <>
      <Reject mutate={mutate} />
      <LoadingButton
        startIcon={<ThumbUpRoundedIcon />}
        color="success"
        loading={isMutating}
        onClick={onApprove}
      >
        Approve
      </LoadingButton>
    </>
  );
};
