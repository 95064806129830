import { useMemo } from "react";
import { PieChart } from "@mui/x-charts";
import { GeneralCard } from "@/components";
import { donutGraphConfig } from "../utils/donutGraphConfig";
import { DonutGraphProps } from "../interfaces";

export const DonutGraphCard = ({data, totalValue, configs, field, title, subTitle}: DonutGraphProps) => {

  const graphData = useMemo(() => {
    return data
      ?.filter(dataEntry => configs[dataEntry[field as keyof typeof dataEntry]])
      ?.map(dataEntry => {
        const graphConfig = configs[dataEntry[field as keyof typeof dataEntry]];
        return {
          id: graphConfig.id,
          label: graphConfig.label,
          value: dataEntry.totalAmount,
          color: graphConfig.color || '#000',
        }
      }) ?? [];
  }, [data, configs, field]);

  return (
    <GeneralCard title={title} subTitle={subTitle}>
      <PieChart
        series={[
          {
            data: graphData,
            ...donutGraphConfig(totalValue)
          },
        ]}
        height={250}
      />
    </GeneralCard>
  );
}