enum InitiativeStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  FINISHED = "FINISHED",
  JOINED = "JOINED",
  PENDING = "PENDING",
}

export { InitiativeStatus };
