import { PropsWithChildren } from "react";
import { Typography, Stack, DialogContent, styled, Box, CardMedia } from "@mui/material";

type Props = PropsWithChildren<{
  title: string;
  subTitle: React.ReactNode;
  onClose?: () => void;
  banner?: string;
  bannerSubtitle1?: string;
  bannerSubtitle2?: string;
}>;

const StyledBox = styled(Box)({
  background: "radial-gradient(83.89% 168% at 69.88% 31.65%, #194CC3 2.24%, #062675 48.96%, #031A57 81.87%)",
  position: "relative",
  borderRadius: "6px",
  border: "none",
  height: '195px'
});

export const LeftAlignDialog = ({ title, subTitle, children, banner, bannerSubtitle1, bannerSubtitle2 }: Props) => {
  return (
    <>
      { banner && <StyledBox
        sx={(theme) => ({
          boxShadow: theme.shadows[4],
          color: theme.palette.primary.contrastText,
          padding: theme.spacing(2),
          margin: theme.spacing(0.5),
        })}
      >
        <CardMedia
          component="img"
          image={banner}
          sx={{
            position: "absolute",
            bottom: 23,
            right: 0,
            width: "auto",
          }}
        />
        <Typography sx={{px: "32px", pt: "32px"}} variant="h5">{title}</Typography>
        { bannerSubtitle1 &&<Typography sx={{px: "32px", pt: "8px", pr: "20%"}} variant="body1">{bannerSubtitle1}</Typography>}
        { bannerSubtitle2 && <Typography sx={{px: "32px", pr: "20%"}} variant="body1">{bannerSubtitle2}</Typography>}
      </StyledBox>}
      <DialogContent sx={{ p: 4 }}>
        <Stack gap={2}>
          <Stack alignItems="left" spacing={2} sx={{ flex: 1 }}>
            {!banner && <Typography variant="subtitle1">{title}</Typography>}
            <Typography variant="body1" textAlign="left">
              {subTitle}
            </Typography>
          </Stack>
          <Stack spacing={3}>{children}</Stack>
        </Stack>
      </DialogContent>
    </>
  );
};
