import { Box, Container, styled, ThemeProvider, createTheme} from "@mui/material";
import { CreateInitiativeButton, SubnavigationBar } from "@/components";
import { Introduction } from "./Introduction";
import x from "@/assets/heros/Vector.svg";
import background from "@/assets/heros/Galaxy.svg";
import { ROUTES } from "@/config";
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "288px",
  background: theme.palette.common.black,
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

export const TopSection = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <img src={x} style={{ position: "absolute", right: 0, height: "288px" }} />
      <ThemeProvider
        theme={(currentTheme) =>
          createTheme({ ...currentTheme, palette: { mode: "dark" } })
        }
      > 
        <SubnavigationBar 
          breadcrumbs={[{ label: "Initiatives", href: ROUTES.initiatives, icon: <LightbulbRoundedIcon /> }]}
          actions={<CreateInitiativeButton />}
          useBackgroundImage
        />  
      </ThemeProvider>
      
      <Root sx={{ pt: 8, pb: 2 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gap={2}
            flex={1}
            flexWrap="wrap"
          >
            <Introduction />
          </Box>
        </Container>
      </Root>
    </Box>
  );
};
