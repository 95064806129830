import { Theme } from "@mui/material/styles";
import { colorsAvatarsArray } from "../colors";

declare module "@mui/material/Avatar" {
  interface AvatarPropsVariantOverrides {
    counter: true;
    button: true;
    userSummary: true;
    randomColors: true;
  }
}

// Get a random index
const randomIndex = Math.floor(Math.random() * colorsAvatarsArray.length);

export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      variants: [
        {
          props: { variant: "counter" },
          style: {
            background: `${theme.palette.secondary.light}`,
            filter: "contrast(1)",
            color: `${theme.palette.secondary.dark}`,
            width: 20,
            height: 20,
            // margin: `0 0 0 ${theme.spacing(0.5)}`,
            padding: 1,
            fontSize: "12px !important",
            border: "0 !important",
            boxShadow: "none !important",
          },
        },
        {
          props: { variant: "button" },
          style: () => ({
            width: 24,
            height: 24,
            padding: 0,
            border: "none !important",
          }),
        },
        {
          props: { variant: "userSummary" },
          style: {
            background: `${theme.palette.info.main}`,
            color: "#CCFFF5",
            fontWeight: "bold",
            fontSize: 40,
            fontFamily: "Henderson BCG Mod",
            padding: `${theme.spacing(5)}`,
          },
        },
        {
          props: { variant: "randomColors" },
          style: () => ({
            width: 32,
            height: 32,
            color: colorsAvatarsArray[randomIndex].textColor,
            backgroundColor: colorsAvatarsArray[randomIndex].bgColor,
          }),
        },
      ],
      styleOverrides: {
        root: {},
      },
    },
  };
}
