import { Box, Button, Stack } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { AddLink } from "./AddLink";
import { useState } from "react";

import { LinksRenderer } from "./LinksRenderer";

export const Links = () => {
  const [addLink, setAddLink] = useState(false);

  return addLink ? (
    <AddLink onDone={() => setAddLink(false)} />
  ) : (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="text"
          color="primary"
          startIcon={<AddRoundedIcon />}
          onClick={() => setAddLink(true)}
        >
          Link
        </Button>
      </Box>
      <LinksRenderer />
    </Stack>
  );
};
