import { Avatar, Box, Paper, Stack, Typography, styled } from "@mui/material";
import OutlookLogo from "@/assets/OutlookLogo.svg";
import SlackLogo from "@/assets/SlackLogo.svg";
import { useRewardsState } from "@/state";

const StyledIcon = styled(Avatar)({
  background: "white",
  boxShadow: "0px 0px 0px 1px rgba(14, 63, 126, 0.01), 0px 0.5px 0.5px -0.25px rgba(42, 51, 69, 0.01), 0px 1px 1px -0.5px rgba(42, 51, 69, 0.01), 0px 2px 2px -1px rgba(42, 51, 69, 0.01), 0px 4px 4px -2px rgba(14, 63, 126, 0.02), 0px 8px 8px -4px rgba(14, 63, 126, 0.02)",
  cursor: "pointer"
});

type Props = {
  onClickOutlook?: () => void;
}

export const Crosspost = ({onClickOutlook}: Props) => {
  const { setState } = useRewardsState();

  return (
    <Paper sx={{ background: "#ECEFF1", padding: "8px 16px"}}>
      <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="body1">
        Send a thank you message in
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
      <StyledIcon onClick={() => setState({ openSlackDialog: true})}>
        <Box
          component="img"
          alt="Locked Rewards Icon"
          src={SlackLogo}
          sx={{ width: 20, height: 20 }}
        />
      </StyledIcon>
      <Typography variant="body1" color="text.secondary">or</Typography>
      <StyledIcon onClick={onClickOutlook}>
        <Box
          component="img"
          alt="Locked Rewards Icon"
          src={OutlookLogo}
          sx={{ width: 20, height: 20 }}
        />
      </StyledIcon>
    </Stack>
    </Stack>
    </Paper>
  );
};
