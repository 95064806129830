import {
  alpha,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import LockedRewards from "@/assets/LockedRewards.png";
import RewardsIcon from "@/assets/RewardsIcon.png";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import BorderedSection from "./common/BorderedSection";
import { calculateRemainingAndPercentage } from "../table/utils";
import { useRewardsState, useActivitiesState } from "@/state";
import { CustomDialog } from "@/components";

const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: alpha("#000000", 0.1),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};

export const LockedRewardsModal = ({ open, onClose }: Props) => {
  const { reward } = useRewardsState();
  const { setState: setActivitiesState } = useActivitiesState();

  const navigate = useNavigate();

  const { daysRemaining, percentageOfCycleComplete } =
    calculateRemainingAndPercentage(reward);

  return (
    <CustomDialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogContent sx={{ px: 4, pb: 4 }}>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseRoundedIcon />
        </IconButton>
        <Stack spacing={3}>
          <DialogTitle textAlign="center">
            Collect your Sparx after this cycle ends
          </DialogTitle>
          <Stack spacing={1} alignItems="center">
            <Box
              component="img"
              alt="Locked Rewards Icon"
              src={LockedRewards}
              sx={{ width: 61, pb: 1 }}
            />
            <Typography variant="subtitle1">
              Why you can’t collect your Sparx yet:
            </Typography>
            <Typography>
              Sparx can only be collected after the current cycle ends.
            </Typography>
          </Stack>

          <BorderedSection>
            <Stack spacing={2}>
              <Typography variant="subtitle1" textAlign="left">
                Current Cycle
              </Typography>

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <LinearProgressBar
                  variant="determinate"
                  value={percentageOfCycleComplete}
                  sx={{ width: "70%" }}
                />
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TodayRoundedIcon />
                  <Typography variant="subtitle1">{daysRemaining}</Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: alpha("#000000", 0.54) }}
                  >
                    {" "}
                    {daysRemaining === 1 ? `day` : `days`} left
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ padding: 1 }} />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  component="img"
                  alt="Rewards Icon"
                  src={RewardsIcon}
                  sx={{
                    width: 26,
                    height: 26,
                  }}
                />
                <Stack textAlign="left">
                  <Typography variant="body2">
                    Earn Sparx by contributing
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Add contributions now to improve your chances of receiving
                    Sparx.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </BorderedSection>

          <Stack spacing={1} sx={{ width: "100%" }} textAlign="center">
            <Button
              onClick={() => {
                navigate(ROUTES.my_activity);
                setActivitiesState({currentTab: "contributions"});
                onClose();
              }}
              variant="contained"
              color="primary"
            >
              Add Contributions
            </Button>
            <Typography variant="caption" color="text.secondary">
              The top 20% of contributors will be rewarded with a bonus boost at
              the end of the year.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
};
