import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { Container } from "../Container";
import { UserData } from "@/interface";
import { HiddenParticipants, NoParticipants, UserRowRenderer } from "./User";

const StyledTableCell = styled(TableCell)({
  fontWeight: 700,
});

type Props = {
  ownes: UserData[];
  participants: UserData[];
  sponsors: UserData[];
  isOwner: boolean;
};

export const Team = ({ ownes, participants, sponsors, isOwner }: Props) => {
  return (
    <Container>
      <Stack spacing={2}>
        <Typography variant="h6">Team</Typography>
        <TableRenderer
          ownes={ownes}
          participants={participants}
          sponsors={sponsors}
          isOwner={isOwner}
        />
      </Stack>
    </Container>
  );
};

const TableRenderer = ({ ownes, participants, sponsors, isOwner }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>User</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Role</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ownes.map((owner) => {
            return <UserRowRenderer key={owner.id} user={owner} role="Owner" />;
          })}

          {sponsors.map((sponsor) => {
            return (
              <UserRowRenderer key={sponsor.id} user={sponsor} role="Sponsor" />
            );
          })}

          {participantsRenderer(participants, isOwner)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const participantsRenderer = (users: UserData[], isOwner: boolean) => {
  if (users.length === 0) return <NoParticipants />;

  return isOwner ? (
    users.map((user) => <UserRowRenderer user={user} role="Participant" />)
  ) : (
    <HiddenParticipants users={users} role="Participant" />
  );
};
