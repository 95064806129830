import { useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Receiver } from "./Receiver";
import { useCommonState, useSparxState } from "@/state";
import { SaveButton } from "@/pages/Sparx/common/SaveButton";
import Sparxs from "@mui/icons-material/SendRounded";
import { LeaveMessage } from "./LeaveMessage";
import { Balance, CustomDialog } from "@/components";
import { CategoriesSelector } from "@/pages/Sparx/common/CategoriesSelector.tsx";

type Props = {
  onSuccess?: () => void;
}

export const SendSparxModal = ({onSuccess}: Props) => {
  const { sparxReceiver, setState } = useCommonState();
  const { sparx, balance, setActivity, setReceivers, setReset } =
    useSparxState();

  useEffect(() => {
    if (sparxReceiver) {
      setActivity();
      setReceivers([sparxReceiver]);
    }

    return () => {
      setReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setActivity, setReceivers, sparxReceiver]);

  const onClose = () => setState({ sparxReceiver: undefined });

  const onDone = () => {
    onClose();
    onSuccess?.();
  }

  if (!sparxReceiver) return null;

  return (
    <CustomDialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 650,
          },
        },
      }}
      open={sparxReceiver !== undefined}
      onClose={onClose}
    >
      <DialogTitle textAlign="center">Reward with Sparx</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          textAlign="center"
          color="text.secondary"
          mb={2}
        >
          Please choose the Sparx amount you wish to award for this
          contribution. Optionally, you can also leave a message for the
          recipient.
        </DialogContentText>
        <Stack spacing={3}>
          <Typography fontWeight={700}>Select Categories</Typography>
          <CategoriesSelector />
          <Receiver />
          <LeaveMessage />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Balance sparx={sparx} balance={balance} sx={{ mr: "auto" }} />
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <SaveButton title="Confirm & Save" onDone={onDone} endIcon={<Sparxs />} />
      </DialogActions>
    </CustomDialog>
  );
};
