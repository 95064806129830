import { API_ENDPOINTS, fetcher } from "@/config";
import { EnrollmentTransitionEnum } from "@/enums";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

type Args = {
  enrollmentId?: number | string;
};

export const useJoinInitiative = (initiativeId: string, isRejoin: boolean) => {
  const { userData } = useUserState();
  const { authToken } = useUserState();

  const join = async (url: string) => {
    await fetcher(
      url,
      {
        config: {
          method: "POST",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
          },
        },
      },

      {
        variant: "success",
        isOpen: true,
        message: "Joined initiative",
      }
    );
  };

  const rejoin = async (url: string, { arg }: { arg: Args }) => {
    const { enrollmentId } = arg;
    await fetcher(
      `${url}/${enrollmentId}`,
      {
        config: {
          method: "PATCH",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            transition: EnrollmentTransitionEnum.REENROLL,
            userId: userData?.id,
          }),
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Rejoined initiative",
      }
    );
  };

  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.initiatives.root}/${initiativeId}/enrollments`,
    isRejoin ? rejoin : join
  );

  return {
    trigger,
    isMutating,
  };
};
