import { ErrorCode } from "react-dropzone";
import { ExtendFile } from "./types";
import { FileError } from "@/interface";

export const fileData = (extendFile: ExtendFile) => {
  const { file, loading } = extendFile;
  // File
  return {
    key: `${file.name}-${Date.now()}`,
    name: file.name,
    size: file.size,
    type: file.type,
    loading,
  };
};

export const formatFileSize = (fileSize: number) => {
  if (fileSize === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(fileSize) / Math.log(k));

  return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const disabled = (file?: ExtendFile) => {
  if (!file) return true;
  if (file.errors && file.errors.length > 0) return true;
  return false;
};

export const getErrorMessage = (
  error: FileError,
  maxSize: number = 1048576
) => {
  if (error.code === ErrorCode.FileTooLarge)
    return `File is larger than ${formatFileSize(maxSize)}`;
  return error.message;
};
