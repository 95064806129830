import { DirectionFilterEnum, OwnershipFilterEnum } from "@/enums";

export interface EmptyStateFields {
  title: string;
  subtitle: string;
  button: string | null;
}

export type OwnershipData = {
  [key in OwnershipFilterEnum]?: EmptyStateFields;
};

type DirectionData = {
  [key in DirectionFilterEnum]?: OwnershipData | EmptyStateFields;
};

interface WhaleData extends DirectionData {}
interface ShrimpData extends DirectionData {}

interface SparxData {
  whale: WhaleData;
  shrimp: ShrimpData;
}

export const DEFAULT_EMPTY_STATE: EmptyStateFields = {
  title: "You haven’t spent or received any Sparx yet",
  subtitle: "All spent and received Sparx will be shown here",
  button: "Send Sparx",
};

export const emptyStateFields: SparxData = {
  whale: {
    [DirectionFilterEnum.ALL]: {
      [OwnershipFilterEnum.ALL]: {
        title: "You haven't spent or received any Sparx yet",
        subtitle: "All spent and received Sparx will be shown here",
        button: "Send Sparx",
      },
      [OwnershipFilterEnum.DELEGATED]: {
        title: "You haven't delegated any Sparx yet",
        subtitle: "All delegated Sparx will be shown here",
        button: "Delegate Sparx",
      },
    },
    [DirectionFilterEnum.RECEIVED]: {
      [OwnershipFilterEnum.ALL]: {
        title: "You haven't received or been delegated any Sparx yet",
        subtitle: "All received and delegated Sparx will be shown here",
        button: null,
      },
      [OwnershipFilterEnum.DELEGATED]: {
        title: "You haven't been delegated any Sparx yet",
        subtitle: "All Sparx delegated to you from other users will be shown here",
        button: null,
      },
      [OwnershipFilterEnum.SENT]: {
        title: "You haven't received any Sparx yet",
        subtitle: "All received Sparx will be shown here",
        button: null,
      },
    },
    [DirectionFilterEnum.SPENT]: {
      [OwnershipFilterEnum.ALL]: {
        title: "You haven't spent any Sparx yet",
        subtitle: "All spent and delegated Sparx will be shown here",
        button: "Send Sparx",
      },
      [OwnershipFilterEnum.DELEGATED]: {
        title: "You haven't delegated any Sparx yet",
        subtitle: "All Sparx that you delegate will be shown here",
        button: "Delegate Sparx",
      },
      [OwnershipFilterEnum.SENT]: {
        title: "You haven't spent any Sparx yet",
        subtitle: "All spent Sparx will be shown here",
        button: "Send Sparx",
      },
    },
  },
  shrimp: {
    [DirectionFilterEnum.ALL]: {
      title: "You haven’t spent or received any Sparx yet",
      subtitle: "All spent and received Sparx will be shown here",
      button: "Send Sparx",
    },
    [DirectionFilterEnum.RECEIVED]: {
      title: "You haven’t been delegated any Sparx yet",
      subtitle: "All Sparx delegated to you from other users will be shown here",
      button: null,
    },
    [DirectionFilterEnum.SPENT]: {
      title: "You haven’t spent any Sparx yet",
      subtitle: "All spent Sparx will be shown here",
      button: "Send Sparx",
    },
  },
};