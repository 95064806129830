import { useEffect } from "react";
import { LOGIN_URL } from "../config";
import { useUserState } from "../state";
import { Outlet, useLocation } from "react-router-dom";
import { setLocalStorage } from "@/utils";

const AuthProvider = () => {
  const location = useLocation();
  const { authToken, refreshToken } = useUserState();

  useEffect(() => {
    if (!authToken || !refreshToken) {
      setLocalStorage("pathname", location.pathname);
      window.location.replace(LOGIN_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, refreshToken]);

  if (authToken && refreshToken) {
    return <Outlet />;
  }

  return null;
};

export { AuthProvider };
