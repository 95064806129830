import {
  Allocation,
  FileError,
  SparxAllocation,
  SparxCycle,
  SparxFile,
} from "@/interface";
import fileDownload from "js-file-download";

export const toCycles = (sparxCycles: SparxCycle[]) => {
  let active: SparxCycle | undefined = undefined; // there should be only one active cycle
  const upcoming: SparxCycle[] = [];
  const closed: SparxCycle[] = [];

  sparxCycles.forEach((sc) => {
    const { state } = sc;
    if (state === "ACTIVE") active = toCycle(sc);
    if (state === "INACTIVE") upcoming.push(toCycle(sc));
    if (state === "CLOSED") closed.push(toCycle(sc));
  });

  return { active, upcoming, closed };
};

export const toCycle = (cycle: SparxCycle): SparxCycle => {
  return {
    ...cycle,
    distribution: cycle?.distribution ?? {
      total: 0,
      assigned: 0,
      lastUpdated: "",
    },
  };
};

// Normalize a number to a range between 0 and 100.
export const normalize = (value: number, min: number, max: number) => {
  if (max === min) return 0; // Avoid division by zero if the range is degenerate
  return ((value - min) / (max - min)) * 100;
};

export const toAllocations = (alloc?: SparxAllocation): Allocation[] => {
  const a: Allocation[] = [
    {
      employees: "X Leaders",
      individualSparx: alloc?.leaders ? "individualized" : "",
      totalSparx: alloc?.leaders?.total ?? "",
    },
  ];

  if (alloc) {
    const { mdp, peer } = alloc;
    a.push({
      employees: "MPDs",
      individualSparx: mdp?.individual,
      totalSparx: mdp?.total,
    });

    a.push({
      employees: "Peers",
      individualSparx: peer?.individual,
      totalSparx: peer?.total,
    });
  }

  return a;
};

export const toAlerts = (file?: SparxFile) => {
  const errors: FileError[] = [];
  const warnings: FileError[] = [];

  if (file) {
    const { errors: err, warnings: warn } = file;
    if (err?.length > 0) errors.push({ details: err });
    if (warn?.length > 0) warnings.push({ details: warn });
  }

  return { errors, warnings };
};

export const download = (
  authToken: string | undefined,
  url: string,
  filename: string
): Promise<void> => {
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error("Network error");
    }
    const blob = await response.blob();
    fileDownload(blob, filename);
  });
};

export const downloadZip = async (authToken: string | undefined, url: string): Promise<void> => {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network error");
    }

    const blob = await response.blob();
    const zip_url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = zip_url;
    a.download = `reports_${new Date().toISOString()}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(zip_url);
  } catch (error) {
    throw new Error("Error downloading the zip file.");
  }
};