import { EditTransactionPayload, SenderTrxResponseObject, UserData } from "@/interface";
import { create } from "zustand";

interface OperationsState {
  selectedTab: string;
  sender: UserData[];
  recipient: UserData[];
  senderTransactions?: SenderTrxResponseObject;
  selectedActivity?: EditTransactionPayload;
  updatedActivity?: boolean;

  updateActivityMessage: (message: string) => void;
  setState: (args: Partial<OperationsState>) => void;
}

const useOperationsState = create<OperationsState>((set, get) => ({
  selectedTab: "cycles",
  sender: [],
  recipient: [],
  senderTransactions: undefined,
  selectedActivity: undefined,
  updatedActivity: false,

  updateActivityMessage: (message: string) => {
    const { selectedActivity } = get();
    if (selectedActivity) {
      const originalMessage = selectedActivity.message;
      const isUpdated = message !== originalMessage;

      set({
        selectedActivity: { ...selectedActivity, message },
        updatedActivity: isUpdated,
      });
    }
  },

  setState: (args?: Partial<OperationsState>) => {
    set({ ...args });
  },
}));

export { useOperationsState };
