import { styled } from "@mui/material/styles";
import { ListItemButton } from "@mui/material/";
import { customColors } from "@/theme/colors";

export const StyledMenuItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme, active }) => {

  return {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    lineHeight: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,

    ...(active && {
      color: theme.palette.text.primary,
      background: customColors.menuBackgroundActive,
    }),

    "& .MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: theme.spacing(2),
      color: theme.palette.text.secondary,
      ...(active && {
        color: theme.palette.text.primary,
      }),
    },

    ":hover": {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.action.hoverOpacity,
      boxShadow: theme.shadows[2],
    },
  };
});
