import { Button, Dialog, DialogActions, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReplyRounded } from "@mui/icons-material";
import { LeftAlignDialog } from "@/components";
import { useAdminDeleteTrx } from "@/hooks/api";
import { useOperationsState } from "@/state";
import { TransactionItem } from "@/interface";

type Props = {
  transaction: TransactionItem;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const DeleteDialog = ({ transaction, open, onClose, onSubmit }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { sender } = useOperationsState();
  const { trigger, isMutating } = useAdminDeleteTrx(transaction.id ?? "");

  const onDelete = () => {
    trigger().then(() => {
      onSubmit();
      //TODO: Mutation doesn't work, want to update the table once the transaction is deleted
      // mutate(`${API_ENDPOINTS.admin.trx}/${sender[0].id}`);
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paperFullScreen": {
          borderRadius: theme.spacing(0),
        },
      }}
    >
      <LeftAlignDialog
        title={`You are reverting a transaction between ${sender[0].firstName} ${sender[0].lastName} and ${transaction.receiver.firstName} ${transaction.receiver.lastName}.`}
        subTitle="Are you sure you want to revert this transaction? This action is permanent."
        onClose={onClose}
      >
        <Stack spacing={3}>
          <Typography variant="body1" textAlign="left" color="text.primary">
            {transaction.amount} Sparx will be credited back to the sender's budget.
          </Typography>
          <DialogActions sx={{ p: 0 }}>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              startIcon={<ReplyRounded />}
              variant="contained"
              color="error"
              loading={isMutating}
              onClick={onDelete}
            >
              Revert Transaction
            </LoadingButton>
          </DialogActions>
        </Stack>
      </LeftAlignDialog>
    </Dialog>
  );
};
