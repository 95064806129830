import { useParams } from "react-router-dom";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDeleteInitiative } from "@/hooks/api";
import { CustomDialog } from "@/components";
import { useDetailsState } from "@/state";


type Props = {
  mutate: () => void;
};

export const DeleteDialog = ({ mutate }: Props) => {
  const { openDeleteDialog, setState } = useDetailsState();
  const { id } = useParams();
  const { trigger } = useDeleteInitiative(id ?? "");

  const onClose = () => setState({ openDeleteDialog: false });

  const onDelete = () => {
    trigger().then(() => {
      onClose();
      mutate();
    });
  };

  return (
    <CustomDialog open={openDeleteDialog} onClose={onClose}>
      <DialogTitle textAlign="center">Delete Initiative?</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={2}>
          Are you sure you want to delete this initiative? This action can not
          be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={false}
          onClick={onDelete}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
}
