import { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow as MuiTableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { useOperationsState } from "@/state";
import { NoDataMessage } from "@/components";
import { TransactionItem } from "@/interface";
import { consolidateTransactionsByActivityId } from "@/utils";
import { TableRow } from "./TableRow";
import { TableLoadingSkeleton } from "./TableLoadingSkeleton";

const sortTransactions = (transactions: TransactionItem[], orderBy: string, order: string) => {
  return transactions.sort((a, b) => {
    const getSortingNames = (transaction: TransactionItem) => {
      return {
        lastName: transaction.receiver?.lastName || "",
        firstName: transaction.receiver?.firstName || ""
      };
    };

    if (orderBy === "receiver") {
      const nameA = getSortingNames(a);
      const nameB = getSortingNames(b);
      
      // First compare by last name
      if (nameA.lastName !== nameB.lastName) {
        return (nameA.lastName < nameB.lastName ? -1 : 1) * (order === "asc" ? 1 : -1);
      }

      // If last names are the same, compare by first name
      return (nameA.firstName < nameB.firstName ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else if (orderBy === "type") {
      return (a.type < b.type ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else if (orderBy === "sparx") {
      return (a.amount - b.amount) * (order === "asc" ? 1 : -1);
    } else if (orderBy === "date") {
      return (new Date(a.createdAt ?? "") < new Date(b.createdAt ?? "") ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
    return 0;
  });
};

type Props = {
  isLoading: boolean
  mutate: () => void;
};

export const TransactionsTable = ({ isLoading, mutate }: Props) => {
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { sender, recipient, senderTransactions, setState } = useOperationsState();
  const transactions = useMemo(() => senderTransactions?.items || [], [senderTransactions]);

   // Handle sorting request, reset page on sort change
   const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);  // Reset page to 0 whenever sorting changes
  };

  const sortedTransactions = useMemo(() => {
    return sortTransactions(transactions, orderBy, order);
  }, [transactions, orderBy, order]);

  const paginatedTransactions = useMemo(() => {
    return sortedTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedTransactions, orderBy, order, page, rowsPerPage]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onEditButtonClick = (activityId: string | undefined) => {
    if (activityId) {
      const activity = consolidateTransactionsByActivityId(activityId, transactions);
      setState({
        selectedActivity: activity
      })
    } else {
      setState({
        selectedActivity: undefined
      });
    }
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <MuiTableRow
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <TableCell sx={{ width: "25%" }}>
                <TableSortLabel
                  active={orderBy === "receiver"}
                  direction={orderBy === "receiver" ? order : "asc"}
                  onClick={() => handleRequestSort("receiver")}
                >
                  <Typography variant="subtitle2">Recipient</Typography>
                </TableSortLabel>
              </TableCell >
              <TableCell align="right" sx={{ width: "25%" }}>
                <TableSortLabel
                  active={orderBy === "type"}
                  direction={orderBy === "type" ? order : "asc"}
                  onClick={() => handleRequestSort("type")}
                >
                  <Typography variant="subtitle2">Type</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sx={{ width: "20%" }}>
                <TableSortLabel
                  active={orderBy === "sparx"}
                  direction={orderBy === "sparx" ? order : "asc"}
                  onClick={() => handleRequestSort("sparx")}
                >
                  <Typography variant="subtitle2" pr={1}>Sparx</Typography>
                  <AutoAwesomeRoundedIcon sx={{ color: (theme) => `${theme.palette.primary.dark}`, mr: 1 }}/>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sx={{ width: "20%" }}>
                <TableSortLabel
                  active={orderBy === "date"}
                  direction={orderBy === "date" ? order : "asc"}
                  onClick={() => handleRequestSort("date")}
                >
                  <Typography variant="subtitle2">Date</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ width: "10%" }}/>
            </MuiTableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoadingSkeleton rowsNum={3} />
            ) : 
            !transactions.length ? (
              <NoDataMessage
                colSpan={5}
                title={
                  sender.length > 0 && recipient.length > 0
                    ? "No Transactions Between This Sender and Receiver"
                    : "No Transactions Found for This Sender"
                }
                message={
                  sender.length > 0 && recipient.length > 0
                    ? "Please try a different receiver or review all transactions for this sender."
                    : "It looks like this sender hasn't made any transactions yet."
                }
              />
            ) : (
              paginatedTransactions.map((transaction: TransactionItem) => (
                <TableRow
                  key={transaction.id}
                  transaction={transaction}
                  onEditButtonClick={onEditButtonClick}
                  mutate={mutate}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={transactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, page) => handleChangePage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};