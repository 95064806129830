import { Theme, alpha } from "@mui/material/styles";

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    activity: true;
  }
}

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      variants: [
        {
          props: { variant: "activity" },
          style: {
            backgroundColor: alpha(theme.palette.background.paper, 0.5),
            p: `${theme.spacing(4)} ${theme.spacing(2.5)} ${theme.spacing(
              2.5
            )} ${theme.spacing(2.5)} ${theme.spacing(2.5)}`,
            borderLeft: `1px solid ${theme.palette.background.paper}`,
            borderBottom: `1px solid ${theme.palette.background.paper}`,
            borderRight: `1px solid ${theme.palette.background.paper}`,
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            overflow: "hidden",
            background: "url('/images/lightBackground.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: `0px 
              ${theme.spacing(2)} 
              ${theme.spacing(2)}
              ${theme.spacing(2)}`,
          },
        },
      ],
    },
  };
}
