import { API_ENDPOINTS, fetcher } from "@/config";
import { RewardsByCycle } from "@/interface";
import { useUserState } from "@/state";
import useSWR from "swr";

export const useRewardsByCycle = (symbol: string) => {
  const { authToken } = useUserState();

  const { data, error, isLoading } = useSWR<RewardsByCycle>(
    symbol ? `${API_ENDPOINTS.rewards.root}/${symbol}` : null,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  const defaultData: RewardsByCycle = {
    shoutoutSenders: [],
    shoutoutSendersCount: 0,
    sparxSenders: [],
    sparxSendersCount: 0,
    totalShoutouts: 0,
    totalSparx: 0,
  };

  return {
    data: data || defaultData,
    error,
    isLoading,
  };
};
