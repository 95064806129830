import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUserState } from "../state";
import { getLocalStorage } from "@/utils";

const OktaCallback = () => {
  const { authToken, refreshToken, refresh } = useUserState();

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (authToken && refreshToken) {
    const origin = getLocalStorage("pathname");
    return <Navigate to={origin ?? "/"} replace />;
  }

  return <></>;
};

export { OktaCallback };
