import { useMemo } from "react";
import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { TransactionTypeData } from "@/interface";

export const useTransactionTypes = () => {
  const { authToken } = useUserState();

  const { data, isLoading, error } = useSWR<TransactionTypeData[]>(
    `${API_ENDPOINTS.admin.sparx_analysis.transaction_types}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  const totalValue = useMemo(() => {
    return data?.reduce((sum, transactionType) => sum + transactionType.totalAmount, 0) ?? 0;
  }, [data]);

  return {
    transactionTypes: data,
    totalValue,
    isLoading,
    error,
  };
};
