import { UserAvatar, UserInfo } from "@/components";
import { useSparxState, useUserState } from "@/state";
import {
  Box,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const StyledContainer = styled(TableContainer)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  ".no-border": {
    borderBottom: 0,
  },
}));

const StyledRow = styled(TableRow)({
  borderBottom: `1px solid ${grey[300]}`,
});

const StyledTableCell = styled(TableCell)({
  fontWeight: 700,
});

export const Receiver = () => {
  const { activity, setAmount } = useSparxState();
  const { userData } = useUserState();
  const transaction = activity?.transactions?.[0];

  const handleChange = (transactionId: string, value: number | string) => {
    // Regular expression to check if the value is an integer
    if (/^\d*$/.test(String(value))) {
      setAmount(transactionId, value);
    }
  };

  if (!transaction) return null;

  const canSendMultiple = userData?.isWhale;
  const { id, receiver, amount, error } = transaction;

  return (
    <StyledContainer>
      <Table>
        <TableHead>
          <StyledRow>
            <StyledTableCell>Contributer</StyledTableCell>
            <StyledTableCell align="right">Assigned Sparx</StyledTableCell>
          </StyledRow>
        </TableHead>
        <TableBody>
          <StyledRow className="no-border">
            <TableCell
              component="th"
              scope="row"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <UserAvatar user={receiver}>
                <UserInfo user={receiver} />
              </UserAvatar>
            </TableCell>
            <TableCell align="right">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
              >
                <FormHelperText
                  sx={{
                    // fontSize: 10,
                    color: (theme) => theme.palette.error.main,
                  }}
                >
                  {error}
                </FormHelperText>
                <TextField
                  dir="rtl"
                  type="number"
                  onChange={(e) => handleChange(id, e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      step: 1,
                      style: { textAlign: "right" },
                    },
                    style: {
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ width: "80px", borderRadius: 4 }}
                  value={amount}
                  error={!!error}
                  disabled={!canSendMultiple}
                />
              </Box>
            </TableCell>
          </StyledRow>
        </TableBody>
      </Table>
    </StyledContainer>
  );
};
