import { ContributionEditor } from "@/pages/common/editor/ContributionEditor";
import { EditorSaveButton } from "@/pages/common/EditorSaveButton";
import { InitiativeSelector } from "./InitiativeSelector";
import { useActivitiesState } from "@/state";
import { isEditorEmpty } from "@/utils";

type Props = {
  onMutate: () => void;
};

export const Editor = ({ onMutate }: Props) => {
  const { selectedInitiative, content, setState } = useActivitiesState();

  return (
    <ContributionEditor
      element={<InitiativeSelector />}
      onEditorChange={(html) => setState({ content: html })}
      sx={{ background: (theme) => theme.palette.common.white }}
    >
      <EditorSaveButton
        caption="Add contribution"
        content={content}
        initiativeId={selectedInitiative?.id}
        categoryId={selectedInitiative?.category?.id}
        subCategoryIds={selectedInitiative?.subCategories?.map((sub) => sub.id)}
        region={selectedInitiative?.region?.name ?? "Global"}
        onComplete={() => {
          setState({ content: undefined });
          onMutate();
        }}
        disabled={!selectedInitiative || isEditorEmpty(content ?? "")}
      />
    </ContributionEditor>
  );
};
