import { Theme } from "@mui/material/styles";
import { DrawerProps } from "@mui/material";

export default function Drawer(theme: Theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: DrawerProps }) => ({
          ...(ownerState.open === true && {
            width: theme.mixins.sideMenuOpenWidth,
          }),
          ...(ownerState.open === false && {
            width: theme.mixins.sideMenuClosedWidth,
          }),
        }),
        paper: {
          overflowY: "visible",
          background: theme.palette.grey[500],
        },
      },
    },
  };
}
