import { API_ENDPOINTS, fetcher } from "@/config";
import { Activity } from "@/interface";
import { useUserState } from "@/state";
import { toActivities } from "@/utils";
import { useMemo } from "react";
import useSWR from "swr";

export const useActivities = () => {
  const { authToken } = useUserState();

  const { data, error, isLoading } = useSWR<Activity[]>(
    `${API_ENDPOINTS.tokens.activities}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  const { sent, delegated } = useMemo(() => {
    return toActivities(data);
  }, [data]);

  const transactionsCount = (activities: Activity[]) => {
    let transactionCount = 0;
  
    activities.forEach(activity => {
      transactionCount += activity.transactions.length;
    });
  
    return transactionCount;
  };

  return {
    isEmpty: (sent.length === 0 && delegated.length === 0) || isLoading,
    isDelegatedEmpty: delegated.length === 0 || isLoading,
    isSentEmpty: sent.length === 0 || isLoading,
    sent,
    delegated,
    sentTransactionCount: transactionsCount(sent),
    delegatedTransactionCount: transactionsCount(delegated),
    isLoading,
    isError: error,
  };
};
