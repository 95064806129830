import { Alert, Stack, Typography } from "@mui/material";
import { getErrorMessage } from "../utils";
import { FileError } from "@/interface";

type Props = {
  warnings?: FileError[];
  errors?: FileError[];
};

export const Alerts = ({ warnings, errors }: Props) => {
  if (!warnings && !errors) return null;
  return (
    <Stack
      sx={{
        p: 1,
        "& > *": {
          borderRadius: (theme) => theme.spacing(1),
        },
        maxHeight: 150,
        overflow: "scroll",
      }}
      gap={1}
    >
      {warnings?.map((warning) => (
        <Alert key={warning.message} severity="warning">
          {warning.message}

          {warning?.details?.map((d, i) => {
            return (
              <Typography key={`${d}-${i}`} fontSize={12}>
                {d}
              </Typography>
            );
          })}
        </Alert>
      ))}
      {errors?.map((error) => (
        <Alert key={error.message} severity="error">
          {getErrorMessage(error)}

          {error?.details?.map((d, i) => {
            return (
              <Typography key={`${d}-${i}`} fontSize={12}>
                {d}
              </Typography>
            );
          })}
        </Alert>
      ))}
    </Stack>
  );
};
