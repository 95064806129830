import { Button, DialogContent, Stack } from "@mui/material";
import { ContributionData } from "@/interface";
import { EditorSaveButton } from "./EditorSaveButton";
import { useState } from "react";
import { ContributionEditor } from "@/pages/common/editor/ContributionEditor";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  title: string;
  contribution?: ContributionData;
  onClose: () => void;
  mutate: () => void;
};

export const EditContribution = ({
  open,
  contribution,
  title,
  onClose,
  mutate,
}: Props) => {
  const [content, setContent] = useState("");

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
      maxWidth="lg"
    >
      <DialogContent>
        <ContributionEditor
          caption="Edit contribution"
          initialContent={contribution?.details ?? ""}
          title={title}
          onEditorChange={(html) => setContent(html)}
        >
          <Stack direction="row" gap={1}>
            <Button onClick={onClose} variant="outlined" color="secondary">
              Cancel
            </Button>
            <EditorSaveButton
              caption="Save"
              content={content}
              contributionId={contribution?.id as string}
              categoryId={contribution?.category?.id}
              initiativeId={contribution?.initiative?.id}
              disabled={contribution?.details === content}
              onComplete={mutate}
            />
          </Stack>
        </ContributionEditor>
      </DialogContent>
    </CustomDialog>
  );
};
