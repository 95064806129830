import { Rewards } from "@/interface";

export const calculateRemainingAndPercentage = (rewards?: Rewards) => {
  if (!rewards) {
    return {
      daysRemaining: 0,
      percentageOfCycleComplete: 0,
    };
  }

  const startDate = new Date(rewards.startDate).getTime();
  const endDate = new Date(rewards.endDate).getTime();
  const currentDate = new Date().getTime();

  const totalDuration = endDate - startDate;
  const elapsedDuration = currentDate - startDate;

  const daysRemaining = rewards?.endDate
    ? Math.max(Math.floor((endDate - currentDate) / (1000 * 60 * 60 * 24)), 0)
    : 0;

  const percentageOfCycleComplete =
    totalDuration > 0
    ? Math.min(Math.floor((elapsedDuration / totalDuration) * 100), 100)
    : 0;

  return {
    daysRemaining,
    percentageOfCycleComplete,
  };
};
