import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Stack,
  Grid,
  Typography,
  Container,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import { useCalculatePaddingTop } from "@/hooks";
import { useActivities, useBalance } from "@/hooks/api";
import { ROUTES } from "@/config";
import { useSparxState } from "@/state";
import { ResponsiveContainer, SubnavigationBar } from "@/components";
import { UserAutocomplete } from "../common/UserAutoComplete";
import { AssignSparxForm } from "../common/AssignSparxForm";
import { SparxMessage } from "../common/SparxMessage";
import { QuestionsCard } from "../common/QuestionsCard";
import { SuccessDialog } from "../common/SuccessDialog";
import { SaveButton } from "../common/SaveButton";
import { BalanceCard } from "../BalanceCard";
import { CategoriesSelector } from "../common/CategoriesSelector.tsx";

export const EditSparx = () => {
  const paddingTop = useCalculatePaddingTop();
  useBalance();
  const [params] = useSearchParams();
  const activityId = params.get("id");
  const [dialog, setDialog] = useState<JSX.Element | null>(null);
  const theme = useTheme();
  const { sent } = useActivities();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onClose = () => setDialog(null);
  const { activity: activityData, setActivity, setReset } = useSparxState();

  useEffect(() => {
    if (activityId) {
      const activity = sent?.find((act) => act.id === activityId);
      setActivity(activity);
    }
    return () => {
      setReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId, sent]);

  return (
    <>
    <SubnavigationBar 
        breadcrumbs={[
          { label: "Sparx", href: ROUTES.sparx, icon: <AutoAwesomeRoundedIcon /> },
          { label: "Edit Sparx", href: `${ROUTES.edit_sparx}?id=${activityId}` }
        ]}
        actions={
          <SaveButton
            title="Update & Send"
            onDone={() => setDialog(<SuccessDialog onClose={onClose} />)}
          />
        }
      />  
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: 2,
          pt: paddingTop,
        }}
      >
        <ResponsiveContainer title="Edit Sparx">
          <Grid item xs={12} md={5}>
            <Stack spacing={{ xs: 2, md: 4 }}>
              <BalanceCard />
              <QuestionsCard />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={{ xs: 2, md: 4 }}>
              <Stack direction="row" spacing={1}>
                <VolunteerActivismRoundedIcon
                  sx={{ color: (theme) => theme.palette.info.dark }}
                />
                <Typography variant="h6">
                  Who will you reward with your Sparx?
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="subtitle2">Select Categories</Typography>
                <CategoriesSelector initialCategoryIds={activityData?.categoryIds ?? []} />
                <Typography variant="subtitle2">Choose recipients</Typography>
                <UserAutocomplete />
              </Stack>
              <AssignSparxForm />
              <SparxMessage />
            </Stack>
          </Grid>
        </ResponsiveContainer>
      </Container>
      <Dialog
        maxWidth="sm"
        open={!!dialog}
        fullScreen={fullScreen}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paperFullScreen": {
            borderRadius: theme.spacing(0),
          },
        }}
      >
        {dialog}
      </Dialog>
    </>
  );
};
