import { Box } from "@mui/material";
import { XChangeLogo } from "../../images";
import { ROUTES } from "../../config";
import { useNavigate } from "react-router-dom";

export const Logo = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      data-testid="logo-icon"
      onClick={() => {
        navigate(ROUTES.root);
      }}
      sx={{
        cursor: "pointer",
      }}
    >
      <XChangeLogo />
    </Box>
  );
};
