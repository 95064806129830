import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { Reward } from "@/interface";
import { useUserState } from "@/state";
// import { mock_rewards } from '@/__mocks__/mock_token_rewards';

export const useRewardSlider = (symbol: string) => {
    const { authToken } = useUserState();

    const { data, mutate, isLoading } = useSWR<Reward[]>(
      `${API_ENDPOINTS.rewards.carousel}`,
      (url: string) => fetcher(url.replace(":symbol", symbol), { authToken }),
      { revalidateOnFocus: false }
    );

    return {
      rewards: data ?? [],
      isLoading,
      mutate,
      symbol
    };
};
