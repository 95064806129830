import { DonutGraphConfig } from "../interfaces";

export const TRANSACTION_TYPES_CONFIGS: Record<string, DonutGraphConfig> = {
  ALLOCATION: {
    id: "1",
    label: "Allocated",
    color: "#FFAB91",
  },
  ASSIGNMENT: {
    id: "2",
    label: "Sent",
    color: "#90CAF9",
  },
  DELEGATION: {
    id: "3",
    label: "Delegated",
    color: "#B39DDB",
  },
};
