import { InitiativeStatus } from "@/enums";
import {
  Category,
  CategoryData,
  CommitmentEnum,
  EnrollmentData,
  InitiativeOffice,
  LinkItem,
  ParticipationEnum,
  UserData,
} from "@/interface";
import { Grid, Stack } from "@mui/material";
import { ReviewMessage } from "./ReviewMessage";
import { Overview } from "./Overview";
import { Team } from "./team/Team";
import { InfoBox } from "./actions/InfoBox";
import { Commitment } from "./Commitment";

type Props = {
  description: string;
  status: InitiativeStatus | undefined;
  isOwner: boolean;
  isAdmin?: boolean;
  region: string;
  offices: InitiativeOffice[];
  sponsors: UserData[];
  participants: UserData[];
  category: CategoryData | undefined;
  subCategories: Category[];
  links: LinkItem[];
  owners: UserData[];
  startDate: string | undefined;
  endDate: string | undefined;
  enrolled: EnrollmentData | undefined;
  dropped: EnrollmentData | undefined;
  canContribute: boolean;
  collaboration: boolean;
  commitment: CommitmentEnum;
  participation: ParticipationEnum;
  reviewNote: string | undefined;
  mutate: () => void;
};

export const AboutTab = ({
  description,
  status,
  region,
  offices,
  category,
  subCategories,
  links,
  owners,
  participants,
  sponsors,
  isOwner,
  reviewNote,
  startDate,
  endDate,
  enrolled,
  dropped,
  canContribute,
  collaboration,
  commitment,
  participation,
  mutate,
}: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Stack spacing={1}>
          <ReviewMessage reviewNote={reviewNote} status={status} />
          <Overview
            description={description}
            region={region}
            offices={offices}
            category={category}
            subCategories={subCategories}
            links={links}
          />
          <Team
            ownes={owners}
            participants={participants}
            sponsors={sponsors}
            isOwner={isOwner}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={1}>
          <InfoBox
            startDate={startDate}
            endDate={endDate}
            enrolled={enrolled}
            dropped={dropped}
            canContribute={canContribute}
            mutate={mutate}
          />
          <Commitment
            collaboration={collaboration}
            commitment={commitment}
            participation={participation}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
