
import { Theme } from "@mui/material/styles";

export default function Switch(theme: Theme) {
  return {  
    MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
          },
          switchBase: {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.success.main,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: theme.palette.success.light,
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: theme.palette.action.disabledBackground,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.action.disabledOpacity,
            },
          },
          thumb: {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            border: '1px solid',
          },
          track: {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.action.disabledBackground,
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        },
      },
  };
}
