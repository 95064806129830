import { Theme } from "@mui/material/styles";

export default function Select(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: theme.palette.background.default,
          boxShadow: theme.shadows[4],
        },
      },
    },
  };
}
