import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import { useInitiativeState } from "@/state";
import { useIsEditPage } from "@/hooks";
import { Category } from "@/interface";

type Props = {
  categories: Category[];
};

export const CategorySelector = ({ categories }: Props) => {
  const { categoryId, setState, errors } = useInitiativeState();
  const isEditPage = useIsEditPage();

  const handleChange = (e: SelectChangeEvent) => {
    setState({
      categoryId: e.target.value,
      subCategories: [],
      errors: { ...errors, categoryId: undefined },
    });
  };

  return (
    <>
      <FormControl size="small" fullWidth error={!!errors?.categoryId}>
        <InputLabel id="category-label">Category</InputLabel>
        <Select
          labelId="category-label"
          value={categoryId}
          label="Category"
          onChange={handleChange}
          error={!!errors?.categoryId}
          disabled={isEditPage}
        >
          {categories?.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText
          sx={{ fontSize: 12, color: (theme) => theme.palette.error.main }}
        >
          {errors?.categoryId}
        </FormHelperText>
      </FormControl>
      {isEditPage && (
        <FormHelperText sx={{ fontSize: 12 }}>
          Can’t edit the category after submission
        </FormHelperText>
      )}
    </>
  );
};
