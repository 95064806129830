import useSWRMutation from "swr/mutation";
import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import { EditTransactionPayload } from "@/interface";

export const useAdminEditTrx = (
  activityId: string,
) => {
  const { authToken } = useUserState();

  const editTransaction = async (
    url: string,
    { arg }: { arg: { data: EditTransactionPayload } }

  ) => {
    await fetcher(
      url,
      {
        config: {
          method: "PUT",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(arg.data),
        },
      }
    );
  }

  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.admin.trx}/${activityId}`,
    editTransaction
  );

  return {
    trigger,
    isMutating,
  };
};