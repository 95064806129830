import { API_ENDPOINTS, fetcher } from "@/config";
import { ActivityCounter } from "@/interface";
import { useUserState } from "@/state";
import useSWR from "swr";

export const useActivityCounter = () => {
  const { authToken } = useUserState();

  const { data, isLoading, error } = useSWR<ActivityCounter>(
    `${API_ENDPOINTS.me.activities}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );
  return {
    data,
    isLoading,
    error,
  };
};
