import { ExtendFile, FileUploader } from "@/components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { AllocationTable } from "../cycles/AllocationTable";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { FileRejection } from "react-dropzone";
import { disabled } from "@/components/FileUploader/utils";
import { Allocation } from "@/interface";
import { AllocationStep } from "@/enums";

type Props = {
  step: AllocationStep;
  extendFile?: ExtendFile;
  allocations: Allocation[];
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  onClose: () => void;
  onDelete: () => void;
  onReview: () => void;
};

export const Allocate = ({
  extendFile,
  step,
  allocations,
  onClose,
  onDrop,
  onDelete,
  onReview,
}: Props) => {
  if (step !== AllocationStep.ALLOCATE) return;

  return (
    <>
      <DialogTitle sx={{ p: 2 }}>
        <Stack alignItems="center" spacing={1}>
          <Typography variant="h5">Allocate Sparx to X Leaders</Typography>
          <Typography>
            Please upload the file listing X Leaders and their Sparx
            allocations.
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: { md: 4, sx: 2 } }}>
        <Stack spacing={2}>
          <FileUploader
            multiple={false}
            extendFile={extendFile}
            onDrop={onDrop}
            onDelete={onDelete}
          />
          <AllocationTable allocations={allocations.slice(0, 1)} secondary />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disabled(extendFile)}
          endIcon={<ArrowForwardRoundedIcon />}
          onClick={onReview}
        >
          Preview allocations
        </Button>
      </DialogActions>
    </>
  );
};
