import { Typography, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import { useSparxState } from "@/state";

type Props = {
  nextDisabled: boolean;
  prevDisabled: boolean;
  onNext: () => void;
  onPrev: () => void;
  steps: string;
};

export const DiscoverButtons = ({
  onNext,
  onPrev,
  steps,
  nextDisabled,
  prevDisabled,
}: Props) => {
  const { balance } = useSparxState();
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (!nextDisabled) onNext();
    else {
      if (balance > 0) navigate(ROUTES.send_sparx);
    }
  };

  return (
    <>
      <Typography typography="subtitle2">{steps}</Typography>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          color="secondary"
          disabled={prevDisabled}
          onClick={onPrev}
        >
          Previous
        </Button>
        {nextDisabled === false ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickHandler}
            autoFocus
          >
            Next
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={balance > 0 ? false : true}
            onClick={onClickHandler}
            autoFocus
          >
            Ready to send Sparx
          </Button>
        )}
      </Stack>
    </>
  );
};
