import { Theme } from "@mui/material/styles";

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          borderRadius: "100px",
          border: 0,
          height: "32px",
          ...theme.typography.subtitle2,
          whiteSpace: "nowrap",
          color: theme.palette.text.secondary,
          "&.Mui-selected": {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[2],
          },
          "&:not(:last-child)": {
            marginRight: theme.spacing(1),
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          padding: theme.spacing(0.25),
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.grey[50]}`,
        },
        grouped: {
          borderRadius: "100px !important",
        },
      },
    },
  };
}
