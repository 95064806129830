import { useState } from "react";
import { Button } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ExternalContributionModal } from "./ExternalContributionModal";
import { useSWRConfig } from "swr";
import { API_ENDPOINTS } from "@/config";

export const AddContribution = () => {
  const { mutate } = useSWRConfig();
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        startIcon={<AddRoundedIcon />}
        onClick={() => setOpen(true)}
      >
        Add external contribution
      </Button>
      <ExternalContributionModal
        open={open}
        onClose={onClose}
        mutate={() => mutate(`${API_ENDPOINTS.me.contributions}`)}
      />
    </>
  );
};
