import { InitiativeStatus } from "@/enums/InitiativeStatus";

export const getStatusText = (status: string): string => {
  switch (status) {
    case InitiativeStatus.DRAFT:
      return "Draft";
    case InitiativeStatus.SUBMITTED:
      return "Waiting for Approval";
    case InitiativeStatus.ACTIVE:
      return "Active";
    case InitiativeStatus.REJECTED:
      return "Rejected";
    case InitiativeStatus.DELETED:
      return "Deleted";
    case InitiativeStatus.FINISHED:
      return "Finished";
    default:
      return "";
  }
};