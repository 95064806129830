import { useState } from "react";
import { Button } from "@mui/material";
import { JoinDialog } from "./JoinDialog";

type Props = {
  display?: boolean;
  mutate: () => void;
};

export const Join = ({ display, mutate }: Props) => {
  const [open, setOpen] = useState(false);
  if (!display) return null;

  const onClose = () => {
    setOpen(false);
    mutate();
  };

  return (
    <>
      <Button
        variant="contained"
        color={"primary"}
        onClick={() => setOpen(true)}
      >
        Join
      </Button>
      <JoinDialog open={open} onClose={onClose} />
    </>
  );
};
