import { ROUTES } from "@/config";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  canSendMultiple?: boolean;
};

export const ReviewButton = ({ canSendMultiple }: Props) => {
  const navigate = useNavigate();
  if (!canSendMultiple) return null;

  const toReview = () => navigate(ROUTES.review_sparx_contributions);

  return (
    <Button variant="outlined" color="secondary" onClick={toReview}>
      Review Contributions
    </Button>
  );
};
