import { ContributionsByInitiative } from "@/interface";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  data: ContributionsByInitiative;
};

export const InitiativeInfo = ({ data }: Props) => {
  const navigate = useNavigate();
  const { initiative, isExternal } = data;

  const onClick = () => navigate(`/initiative/${initiative.id}`);

  return (
    <Stack>
      <Typography variant="subtitle1">Contributions added</Typography>
      <Typography>
        to&nbsp;
        {isExternal ? (
          <Typography color="primary" component={"span"} variant="body2">
            {initiative.title}
          </Typography>
        ) : (
          <Button variant="text" size="small" onClick={onClick}>
            {initiative.title}
          </Button>
        )}
      </Typography>
    </Stack>
  );
};
