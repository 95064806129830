import useSWR from "swr";
import { API_ENDPOINTS, fetcher } from "@/config";
import { INITIATIVES_PAGE_SIZE } from "@/constants";
import { InitiativeStatus } from "@/enums";
import { InitiativeData, PaginatedInitiatives } from "@/interface";
import { useSearchState, useUserState } from "@/state";
import { useEffect, useState } from "react";
import { toKeys, toQuery } from "@/utils";

export const useSearchInitiatives = () => {
  const { authToken } = useUserState();
  const [page, setPage] = useState(1);
  const [initiatives, setInitiatives] = useState<InitiativeData[]>([]);
  const [filteredInitiatives, setFilteredInitiatives] = useState<
    InitiativeData[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { regions, offices, categories, keyword } = useSearchState();

  const { data, error, isLoading } = useSWR<PaginatedInitiatives>(
    `${API_ENDPOINTS.initiatives.root}?status=${InitiativeStatus.ACTIVE}&page=${page}&size=${INITIATIVES_PAGE_SIZE}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  const {
    data: filtered,
    error: filteredError,
    isLoading: filteredLoading,
  } = useSWR<{
    matches: InitiativeData[];
  }>(searchQuery, (url: string) => fetcher(url, { authToken }), {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    const query = toQuery(
      toKeys(categories),
      toKeys(regions),
      toKeys(offices),
      keyword
    );
    setSearchQuery(query);
  }, [regions, offices, categories, keyword]);

  useEffect(() => {
    if (filtered) {
      setFilteredInitiatives(filtered?.matches);
    }
  }, [filtered]);

  useEffect(() => {
    if (data) {
      setInitiatives([...initiatives, ...data.items]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loadMore = () => {
    if (initiatives.length < total) setPage((page) => page + 1);
  };

  const isFiltered = searchQuery !== null;
  const total = isFiltered ? filteredInitiatives.length : data?.totalItems ?? 0;

  return {
    data: isFiltered ? filteredInitiatives : initiatives,
    error: isFiltered ? filteredError : error,
    isLoading: isLoading || filteredLoading,
    isFiltered,
    total,
    loadMore,
  };
};
