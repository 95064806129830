import {
  Reaction,
  RemoteReactions,
  ShoutoutData,
  ShoutoutReceived,
  ShoutoutsView,
} from "@/interface";
import { ReactionType } from "@/enums";

export const toShoutsReceivers = (
  receivers: ShoutoutReceived[],
  id?: number | string
): string => {
  // Initialize an array to hold the names
  const names: string[] = receivers.map((shoutReceived) => {
    // Determine if the current receiver is the same as the given id
    const isYou = shoutReceived.receiver.id === id;
    // Format the name, appending "(You)" if the ids match
    const name =
      shoutReceived.receiver.firstName +
      " " +
      shoutReceived.receiver.lastName +
      (isYou ? " (You)" : "");
    return name;
  });

  // Join all names with commas and replace the last comma with an "&" if there's more than one name
  const result = names.join(", ").replace(/, (?=[^,]*$)/, " & ");
  return result;
};

export const getShoutsToggleViews = (received = 0, given = 0) => {
  return [
    {
      value: ShoutoutsView.RECEIVED,
      label: `Received ${received}`,
    },
    {
      value: ShoutoutsView.GIVEN,
      label: `Given ${given}`,
    },
  ];
};

export const toReactions = (
  remote: RemoteReactions | undefined
): Reaction[] => {
  const reactions: Reaction[] = [];

  if (!remote) return reactions;

  Object.entries(remote).forEach(([key, value]) => {
    reactions.push({
      reactionType: key as ReactionType,
      users: new Map(value.map((reaction) => [reaction.userId, reaction])),
    });
  });

  return reactions.filter((r) => r.users.size !== 0);
};

export const addShoutReactions = (
  shouts: ShoutoutData[],
  userId: string,
  shoutoutId: string,
  reactionId: string,
  reactionType: ReactionType
) => {
  return shouts.map((shout) =>
    shout.id === shoutoutId
      ? {
          ...shout,
          reactions: {
            ...shout.reactions,
            [reactionType]: [
              ...(shout.reactions[reactionType] || []),
              {
                id: reactionId,
                userId: userId,
              },
            ],
          },
        }
      : shout
  );
};

export const replaceReactionId = (
  shouts: ShoutoutData[],
  shoutoutId: string,
  reactionId: string,
  reactionType: ReactionType,
  newReactionId: string
) => {
  const shoutout = shouts.find(({ id }) => id === shoutoutId);
  if (!shoutout) return shouts;

  const reactions = shoutout.reactions[reactionType];
  const reaction = reactions?.find(({ id }) => id === reactionId);
  if (!reaction) return shouts;

  reaction.id = newReactionId;
  return shouts;
};

export const deleteShoutReaction = (
  shouts: ShoutoutData[],
  shoutoutId: string,
  reactionId: string,
  reactionType: ReactionType
) => {
  return shouts.map((shout) =>
    shout.id === shoutoutId
      ? {
          ...shout,
          reactions: {
            ...shout.reactions,
            [reactionType]: shout.reactions[reactionType]?.filter(
              (r) => r.id !== reactionId
            ),
          },
        }
      : shout
  );
};
