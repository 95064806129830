import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
// types
import { HeadCell, Order } from "./type";
import { Contributor } from "@/interface";

const headCells: readonly HeadCell[] = [
  {
    id: "user",
    numeric: false,
    disablePadding: true,
    label: "Contributor",
  },
  {
    id: "numberOfContributions",
    numeric: true,
    disablePadding: false,
    label: "Contributions",
  },
  {
    id: "totalSparxReceivedFromSender",
    numeric: true,
    disablePadding: false,
    label: "Previously Sent",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Sparx Amount",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Contributor
  ) => void;
  order: Order;
  orderBy: string;
}

export const EnhancedListHead = (props: EnhancedTableProps) => {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Contributor) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{borderBottom: (theme) => `1px solid ${theme.palette.grey[50]}`}}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sx={{paddingLeft: headCell.disablePadding ? '8px' : 0}}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontWeight: "bold" }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
