import { KeyValuePair } from "@/interface";
import { Button, Chip } from "@mui/material";

type Props = {
  filters: Record<string, string>;
  onDelete: (kv: KeyValuePair) => void;
  onClear: () => void;
};

export const FiltersRenderer = ({ filters, onDelete, onClear }: Props) => {
  if (Object.keys(filters).length === 0) return null;

  return (
    <>
      {Object.keys(filters).map((key) => (
        <Chip
          key={key}
          label={filters[key]}
          size="medium"
          onDelete={() => onDelete({ key, value: filters[key] })}
          sx={{ height: 32 }}
        />
      ))}
      <Button
        variant="text"
        color="secondary"
        size="small"
        onClick={onClear}
        sx={{ height: 32 }}
      >
        Clear all
      </Button>
    </>
  );
};
