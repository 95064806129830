import { useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { CustomDialog } from "@/components";
import { SuccessMessage, UserData } from "@/interface";
import { SingleSelectAutocomplete } from "../SingleSelectAutocomplete";
import { Delegator } from "./Delegator";
import { DelegateMessage } from "./DelegateMessage";
import { DelegateButton } from "./DelegateButton";

type Props = {
  open: boolean;
  delegator?: UserData;
  onClose: () => void;
  onDone: (successMessage: SuccessMessage) => void;
  budget?: number;
}

export const DelegateOnBehalfModal = ({delegator, budget, open, onClose, onDone}: Props) => {
  const [recipient, setRecipient] = useState<UserData[]>([]);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(1);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) return;

    return () => {
      setRecipient([]);
      setMessage("");
      setAmount(1);
      setError("");
    };
  }, [open]);

  return (
    <CustomDialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 650,
          },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle textAlign="center">Delegate on Behalf</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          textAlign="center"
          color="text.secondary"
          mb={2}
        >
          The recipient will see this delegation as if it was made directly
          by the selected sender. Recipient will be informed about delegation.
        </DialogContentText>
        <Stack spacing={3}>
          <Delegator
            delegator={delegator}
            budget={budget}
            amount={amount}
            setAmount={setAmount}
            error={error}
            setError={setError}
          />
          <Typography variant="subtitle2">Select Recipient</Typography>
          <SingleSelectAutocomplete
            label="Select Recipient"
            helperText="Select Recipient of the delegation."
            value={recipient}
            onChange={(recipients) => setRecipient(recipients)}
            role="delegation_receiver"
            disabledOptionId={delegator?.id}
          />
          <DelegateMessage message={message} setMessage={setMessage}/>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <DelegateButton
          title="Confirm & Save"
          senderId={delegator?.id}
          receiverId={recipient[0]?.id}
          amount={amount}
          message={message}
          error={error}
          onDone={() => onDone({
            title: `Sparx Delegated on Behalf of ${delegator?.fullName}.`,
            subtitle: `${recipient[0]?.fullName} will receive ${amount} Delegated Sparx.`
          })}
        />
      </DialogActions>
    </CustomDialog>
  );
}