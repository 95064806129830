import { marked } from "marked";

// Set marked options
marked.setOptions({
  breaks: true,
  gfm: true,
});

export const markdownToHtml = (value: string) => {
  return marked.parse(value ?? "", { async: false }) as string;
};
