import { Box, BoxProps, Typography, styled } from "@mui/material";
import Sparx from "@mui/icons-material/AutoAwesomeRounded";
import { SparxBalance } from "@/interface";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 2),
}));

type Props = {
  sparx?: SparxBalance;
  balance: number;
} & BoxProps;

export const Balance = ({ sparx, balance, ...rest }: Props) => {
  return (
    <StyledBox {...rest}>
      <Typography
        variant="body1"
        sx={{ display: "flex", gap: 1, alignItems: "center" }}
      >
        <Sparx sx={{ color: (theme) => theme.palette.primary.dark }} />
        {Math.max(0, balance)}
      </Typography>
      <Typography variant="body2">{sparx?.cycle.name}</Typography>
    </StyledBox>
  );
};
