import { UserData } from "@/interface";
import { useUserState } from "@/state";
import { Box } from "@mui/material";
import { TaggedUser } from "./TaggedUser";

type Props = {
  receivers: UserData[];
};

export const TaggedUserList = ({ receivers }: Props) => {
  const { userData } = useUserState();
  if (receivers?.length === 0) return null;

  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {receivers.map((user) => {
        const highlight = user?.id === userData?.id;
        return (
          <TaggedUser key={user?.id} receiver={user} highlight={highlight} />
        );
      })}
    </Box>
  );
};
