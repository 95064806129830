import { useState, useMemo } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Typography,
} from "@mui/material";
import { RewardsTableRowItem } from "./RewardsTableRowItem";
import { useRewards } from "@/hooks/api";
import { Rewards } from "@/interface";
import { LockedRewardsModal } from "../modals/LockedRewardsModal";
import { CollectRewardsModal } from "../modals/CollectRewardsModal";
import { useRewardsState } from "@/state";

type OrderByOptions = "cycle" | "sparx" | "shoutouts" | "date";

export const RewardsTable = () => {
  const { openCollectRewardsModal, reset } = useRewardsState();
  const [orderBy, setOrderBy] = useState<OrderByOptions>("date");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const { rewards } = useRewards();

  const handleRequestSort = (property: OrderByOptions) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onClose = () => reset();

  const sortedRewards: Rewards[] = useMemo(() => {
    return rewards.slice().sort((a, b) => {
      let aValue: number | string;
      let bValue: number | string;

      switch (orderBy) {
        case "cycle":
          aValue = a.symbol;
          bValue = b.symbol;
          break;
        case "sparx":
          aValue = a.totalSparx ?? 0;
          bValue = b.totalSparx ?? 0;
          break;
        case "shoutouts":
          aValue = a.totalShoutouts ?? 0;
          bValue = b.totalShoutouts ?? 0;
          break;
        case "date":
        default:
          aValue = new Date(a.endDate).getTime();
          bValue = new Date(b.endDate).getTime();
          break;
      }

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [rewards, orderBy, order]);

  return (
    <>
      <TableContainer sx={{ paddingTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <TableCell>
                <TableSortLabel
                  active={orderBy === "cycle"}
                  direction={orderBy === "cycle" ? order : "asc"}
                  onClick={() => handleRequestSort("cycle")}
                >
                  <Typography variant="subtitle1">Cycle</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "sparx"}
                  direction={orderBy === "sparx" ? order : "asc"}
                  onClick={() => handleRequestSort("sparx")}
                >
                  <Typography variant="subtitle1">Sparx</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "shoutouts"}
                  direction={orderBy === "shoutouts" ? order : "asc"}
                  onClick={() => handleRequestSort("shoutouts")}
                >
                  <Typography variant="subtitle1">Shout-outs</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "date"}
                  direction={orderBy === "date" ? order : "asc"}
                  onClick={() => handleRequestSort("date")}
                >
                  <Typography variant="subtitle1">End Date</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRewards.map((reward) => (
              <RewardsTableRowItem key={reward.symbol} reward={reward} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <LockedRewardsModal
        open={openCollectRewardsModal === "locked"}
        onClose={onClose}
      />

      <CollectRewardsModal
        open={openCollectRewardsModal === "collect"}
        onClose={onClose}
      />
    </>
  );
};
