import { API_ENDPOINTS, fetcher } from "@/config";
import { SparxUserRole, UserData } from "@/interface";
import { useUserState } from "@/state";
import { toUsersQuery } from "@/utils";
import useSWR from "swr";

type Props = {
  value: string;
  role?: SparxUserRole;
  origin?: "sparx";
};

export const useUsers = ({ value, role, origin }: Props) => {
  const { authToken } = useUserState();
  const url = `${API_ENDPOINTS.users.search}/${value}${toUsersQuery(
    role,
    origin
  )}`;

  const { data, isLoading } = useSWR<UserData[]>(
    value.length >= 2 ? url : null,
    (url: string) => fetcher(url, { authToken }),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      dedupingInterval: 2000,
    }
  );
  
  return { data, isLoading };
};
