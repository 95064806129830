import { useActivitiesState } from "@/state";
import { getShoutsToggleViews } from "@/utils";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

export const TabHeader = () => {
  const { shoutoutsView, totalGiven, totalReceived, setState } =
    useActivitiesState();
  const views = getShoutsToggleViews(totalReceived, totalGiven);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h6">My shout-outs</Typography>
      <Box flexGrow={1} display="flex" justifyContent="center">
        <ToggleButtonGroup exclusive value={shoutoutsView}>
          {views.map(({ label, value }) => (
            <ToggleButton
              onClick={() => setState({ shoutoutsView: value })}
              value={value}
              key={value}
            >
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};
