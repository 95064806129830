import { useState, useCallback } from "react";
import EmojiPicker from 'emoji-picker-react';
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
} from "@mui/material";
import { CustomDialog, UserAvatar } from "@/components";
import { UserData } from "@/interface";
import { usePostSlackMessage, SlackMessageBodyRequest } from "@/hooks/api";
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';

const suggestedMessages =  ["Thank you!", "I appreciate your kind words", "I'm grateful for your support"];

type Props = {
  open: boolean;
  onCancel: () => void;
  author?: UserData;
};

export const SlackDialog = ({ open, onCancel, author }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const { trigger, isMutating } = usePostSlackMessage();

  const validateMessage = useCallback(() => {
    if (!message) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  }, [message]);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  }

  const handleSendMessage = async () => {
    if (!validateMessage() || !author) return;

    const data: SlackMessageBodyRequest = {
      recipientUserId: author.id,
      message,
    };

    try {
      await trigger({ data });
      onCancel();
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  const onEmojiPickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onEmojiSelect = (emoji: { emoji: string }) => {
    setMessage((prevMessage) => prevMessage + emoji.emoji);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
      <CustomDialog open={open} onClose={onCancel} 
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: 530,
              maxWidth: 646,
            },
          },
        }}
      >
          <Box
            sx={{
              background: "linear-gradient(1.09deg, #F6F7F9 2.36%, #EDE7F6 99.09%)",
              borderRadius: "6px",
              margin: 1,
            }}
          >
            <DialogTitle textAlign="center" alignContent="center" alignItems="center" sx={{padding: 0, my: 3, "& .MuiStack-root": { justifyContent: "center" }}}>
              <UserAvatar user={author} hasTooltip={false} />
              Say thanks {author ? `to ${author.firstName} ${author.lastName}` : ''}
              <DialogContentText textAlign="center">
                Your message will be sent as a DM in Slack
              </DialogContentText>
            </DialogTitle>
          </Box>

        <DialogContent sx={{padding: 3}}>
          <TextField
            value={message}
            label="Leave a message"
            variant="outlined"
            size="small"
            multiline
            rows={3}
            fullWidth
            error={error}
            helperText={error? "You are missing a message. Please enter one." : null}
            onChange={onTextChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onEmojiPickerClick} disableRipple>
                    <AddReactionOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
             "& .MuiInputAdornment-root": {
                alignItems: "flex-start",
              },
            }}
          />
          <Stack spacing={1} direction="row" mt={2}>
            {suggestedMessages.map((msg, index) => <Chip key={index} label={msg} onClick={() => setMessage(msg)}/>)}
          </Stack>
        </DialogContent>
        <DialogActions sx={{padding: 3}}>
            <Button onClick={onCancel} variant="outlined" color="secondary">
              Cancel
            </Button>
            <LoadingButton
              loading={isMutating}
              onClick={handleSendMessage}
              variant="contained"
              color="primary"
            >
              Send message
            </LoadingButton>
          </DialogActions>
          <Popover
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)} 
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <EmojiPicker height={400} onEmojiClick={onEmojiSelect}/>
          </Popover>
          

      </CustomDialog>
  );
};
