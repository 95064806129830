import { Box } from "@mui/material";
import { CategorySelector } from "./CategorySelector";
import { useCategories } from "@/hooks/api";
import { SubCategorySelector } from "./SubCategorySelector";

export const InitiativeCategory = () => {
  const { categories } = useCategories();

  return (
    <Box display="flex" gap={2}>
      <Box flex={1}>
        <CategorySelector categories={categories ?? []} />
      </Box>
      <Box flex={1}>
        <SubCategorySelector categories={categories ?? []} />
      </Box>
    </Box>
  );
};
