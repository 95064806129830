import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses, Tooltip } from "@mui/material";

export const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    data-testid="custom-tool-tip"
    {...props}
    classes={{ popper: className }}
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));
