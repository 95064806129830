import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useInitiativeState } from "@/state";
import { useReviewers } from "@/hooks/api";
import { Owners } from "./Owners";
import { Sponsors } from "./Sponsors";

type Props = {
  isCreatePage?: boolean;
};

export const Team = ({ isCreatePage }: Props) => {
  const { setState } = useInitiativeState();
  const { data } = useReviewers();

  // Automatically set reviewers when data is available
  useEffect(() => {
    if (data) {
      setState({ reviewers: data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Team</Typography>
      <Owners isCreatePage={isCreatePage} />
      {/* Hide reviewers component & set reviewers automatically to the platform admins */}
      {/* <Reviewers />  */}
      <Sponsors />
    </Stack>
  );
};
