import { useMemo } from "react";
import { BarChart } from "@mui/x-charts";
import { GeneralCard } from "@/components";
import { BarChartProps } from "../interfaces/barChartProps.ts";

export const BarChartCard = ({data, field, title, subTitle}: BarChartProps) => {

  const graphData = useMemo(() => {
    return data
      ?.filter(dataEntry => dataEntry[field as keyof typeof dataEntry] as unknown as string !== "Unknown")
      ?.map(dataEntry => {
        return {
          ...dataEntry,
          senderPercentage: parseFloat(
            (dataEntry.amount/dataEntry.totalAmount * 100)
              .toFixed(1)
          ),
          nonSenderPercentage: parseFloat(
            ((dataEntry.totalAmount - dataEntry.amount)/dataEntry.totalAmount * 100)
              .toFixed(1)
          ),
        }
      }) ?? [];
    }, [data, field]);

  const valueFormatter = (value: number | null, { dataIndex }: { dataIndex: number }, field: 'sent' | 'notSent') => {
    const item = graphData[dataIndex]
    const amount = field === 'sent' ? item.amount : item.totalAmount - item.amount;
    return `${value}% (${amount})`;
  };

  const computedGraphLeftMargin = graphData.reduce((longest, item) => {
    const value = item[field as keyof typeof item] as unknown as string;
    return value.length > longest.length ? value : longest;
  }, "").length * 6 + 15;


  const calculatedHeight = graphData.length * 80 + 80;

  return (
    <GeneralCard title={title} subTitle={subTitle}>
      <BarChart
        dataset={graphData}
        series={[
          { dataKey: 'senderPercentage', label: 'Sent', color: "#B39DDB", valueFormatter: (value, params) => valueFormatter(value, params, 'sent') },
          { dataKey: 'nonSenderPercentage', label: 'Not Sent', color: "#FFAB91", valueFormatter: (value, params) => valueFormatter(value, params, 'notSent') },
        ]}
        yAxis={[{ scaleType: 'band', dataKey: field, disableLine: true, disableTicks: true }]}
        xAxis={[{ disableLine: true, disableTicks: true, tickLabelStyle: { display: 'none' } }]}
        height={calculatedHeight}
        margin={{ left: computedGraphLeftMargin, top: 60 }}
        layout={"horizontal"}
      />
    </GeneralCard>
  )
}
