import ThemeProvider from "./theme";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";

const App = () => {
  return (
    <ThemeProvider>
      <RouterProvider router={routes} />
    </ThemeProvider>
  );
};

export { App };
