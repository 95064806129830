import { CardContent, Collapse, Stack } from "@mui/material";
import { SparxDistribution } from "./SparxDistribution";
import { SparxAllocation } from "./SparxAllocation";
import { SparxCycle } from "@/interface";
import { toAllocations } from "@/utils";
import { useCycleBySymbol } from "@/hooks/api";

type Props = {
  sparxCycle: SparxCycle;
  expanded: boolean;
};

export const CycleDetails = ({ sparxCycle, expanded }: Props) => {
  const { distribution, overallAllocation, state, symbol } = sparxCycle;
  const { cycle } = useCycleBySymbol(symbol, !expanded);

  const mergedDistribution = { ...distribution, ...cycle?.distribution };

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Stack spacing={4}>
          <SparxDistribution
            state={state}
            distribution={mergedDistribution}
            symbol={symbol}
          />
          <SparxAllocation
            allocations={toAllocations(overallAllocation)}
            symbol={symbol}
            filename={cycle?.file?.name ?? "allocation.csv"}
          />
        </Stack>
      </CardContent>
    </Collapse>
  );
};
