import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, useTheme, useMediaQuery } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import { ROUTES } from "@/config";
import { useDetailsState, useUserState } from "@/state";
import { SubnavigationBar } from "@/components";
import { useCalculatePaddingTop } from "@/hooks";
import { useBalance, useInitiative } from "@/hooks/api";
import { getEnrollments, toDetails, truncateString } from "@/utils";
import Tabs from "./Tabs";
import { ContributionsTab } from "./contributions/ContributionsTab";
import { AboutTab } from "./about/AboutTab";
import { Review } from "./header/Review";
import { Status } from "./header/Status";
import { Actions } from "../details/about/actions/Actions";
import { DeleteDialog } from "./about/actions/DeleteDialog";
import { InitiativeStatus, Roles } from "@/enums";

type Props = {
  isReview?: boolean;
  isPreview?: boolean;
};

export const Details = ({ isReview = false, isPreview = false }: Props) => {
  const paddingTop = useCalculatePaddingTop();
  const { id } = useParams();
  const { userData } = useUserState();
  const { currentTab, setState } = useDetailsState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isReviewOrPreview = isReview || isPreview;
  const shouldTruncateTitle = isSmallScreen || isMediumScreen;
  useBalance()
  const { data: initiativeData, mutate, isLoading } = useInitiative(id ?? "");

  const {
    title,
    region,
    offices,
    category,
    subCategories,
    description,
    owners,
    participants,
    sponsors,
    collaboration,
    participation,
    commitment,
    startDate,
    endDate,
    status,
    enrollments,
    reviewNote,
    links,
  } = toDetails(initiativeData);

  const isOwner = !!owners?.find((owner) => owner?.id === userData?.id);
  const isAdmin = userData?.roles?.includes(Roles.ADMIN);
  const { enrolled, dropped } = getEnrollments(enrollments, userData);
  const canContribute = enrolled !== undefined || dropped !== undefined;
  const baseRoute = isReview ? ROUTES.initiative_review : isPreview ? ROUTES.initiative_preview : ROUTES.initiative;

  useEffect(() => {
    if (isReviewOrPreview) setState({currentTab: "about" })
      else if (canContribute) setState({ currentTab: "contributions" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canContribute, isReviewOrPreview]);

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[
          { 
            label: "Initiatives", 
            href: ROUTES.initiatives, 
            icon: <LightbulbRoundedIcon /> },
          { 
            label: isLoading ? `Initiative Name`: shouldTruncateTitle ? truncateString(title) : title, 
            href: `${baseRoute}/${id}` 
          },
        ]}
        tabs={<Tabs/>}
        actions={
          <Box display="flex" alignItems="center" gap={1}>
            {isReview ? (
              status === InitiativeStatus.SUBMITTED ? (
                <Review mutate={mutate} />
              ) : null
            ) : isPreview ? (
              null
            ) : (
              <Actions
                enrolled={enrolled}
                dropped={dropped}
                isOwner={isOwner}
                mutate={mutate}
                status={status}
              />
            )}
          </Box>
        }
        statusChips={<Status status={status} />}
        showLastBreadcrumbOnly={isMediumScreen}
      />
      <Container sx={{pt: paddingTop}}>
        <TabContext value={currentTab}>
          <TabPanel value="contributions" sx={{ px: 0 }}>
            <ContributionsTab
              title={title}
              isOwner={isOwner}
              isAdmin={isAdmin}
              categoryId={category?.id}
              subCategoryIds={subCategories?.map((sub) => sub.id)}
              region={region}
              canContribute={canContribute}
              mutateInitiative={mutate}
            />
          </TabPanel>
          <TabPanel value="about" sx={{ px: 0 }}>
            <AboutTab
              isAdmin={isAdmin}
              isOwner={isOwner}
              description={description}
              reviewNote={reviewNote}
              status={status}
              region={region}
              offices={offices}
              category={category}
              subCategories={subCategories}
              participants={participants}
              owners={owners}
              sponsors={sponsors}
              startDate={startDate}
              endDate={endDate}
              collaboration={collaboration}
              participation={participation}
              commitment={commitment}
              canContribute={canContribute}
              links={links}
              enrolled={enrolled}
              dropped={dropped}
              mutate={mutate}
            />
          </TabPanel>
        </TabContext>
      </Container>
      <DeleteDialog mutate={mutate} />
    </>
  );
};


