import { useParams } from "react-router-dom";
import { ContributionsContainer } from "./Container";
import { ContributionsHeader } from "./Header";
import { ContributionList } from "./list/ContributionList";
import { contributionsByUser } from "@/utils";
import { useContributions } from "@/hooks/api";
import { useDetailsState, useUserState } from "@/state";
import { ContibutionView } from "@/interface";
import { EditorSaveButton } from "../../../common/EditorSaveButton";
import { NoContributions } from "./empty/Empty";
import { ContributionEditor } from "@/pages/common/editor/ContributionEditor";

type Props = {
  title: string;
  isOwner?: boolean;
  isAdmin?: boolean;
  canContribute: boolean;
  categoryId?: string;
  subCategoryIds?: string[];
  region: string;
  mutateInitiative: () => void;
};

export const ContributionsTab = ({
  title,
  isOwner,
  isAdmin,
  canContribute,
  categoryId,
  subCategoryIds,
  region,
  mutateInitiative,
}: Props) => {
  const { userData } = useUserState();
  const { id } = useParams();
  const { data, mutate } = useContributions(id ?? "");
  const { content, contibutionView, setState } = useDetailsState();

  const { all, mine } = contributionsByUser(data?.contributions, userData?.id);

  const contributions =
    contibutionView === ContibutionView.ALL ? all : mine ? [mine] : [];

  const onMutate = () => {
    mutate();
  };

  const showEditor = isOwner || canContribute;
  const showEmpty = !isOwner && !canContribute && contributions.length === 0;

  return (
      <ContributionsContainer>
        <ContributionsHeader
          total_all={data?.totContributions}
          total_mine={mine?.items.length ?? 0}
          showViewToggle={isOwner || isAdmin}
        />

        {showEditor && (
          <ContributionEditor
            title={title}
            onEditorChange={(html) => setState({ content: html })}
          >
            <EditorSaveButton
              caption="Add contribution"
              content={content}
              initiativeId={id}
              categoryId={categoryId}
              subCategoryIds={subCategoryIds}
              region={region}
              onComplete={() => {
                setState({ content: undefined });
                onMutate();
              }}
            />
          </ContributionEditor>
        )}

        {showEmpty ? (
          <NoContributions title={title} mutate={mutateInitiative} />
        ) : (
          <ContributionList
            title={title}
            isOwner={isOwner}
            isAdmin={isAdmin}
            groupedContributions={contributions}
            mutate={onMutate}
          />
        )}
      </ContributionsContainer>
  );
};
