enum SparxTransactionEnum {
  ALLOCATION = "ALLOCATION",
  DELEGATION = "DELEGATION",
  ASSIGNMENT = "ASSIGNMENT",
}

enum DirectionFilterEnum {
  ALL = "all",
  SPENT = "spent",
  RECEIVED = "received",
}

enum OwnershipFilterEnum {
  ALL = "all",
  SENT = "sent",
  DELEGATED = "delegated",
}

export { SparxTransactionEnum, DirectionFilterEnum, OwnershipFilterEnum };
