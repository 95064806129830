import { Theme } from "@mui/material/styles";

export default function AppBar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500],
          backgroundImage: "none",
          borderBottom: `${theme.palette.grey[300]} 1px solid`,
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        },
      },
    },
  };
}
