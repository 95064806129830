import { useState } from "react";
import { Divider, Fab, Menu, MenuItem, ListItemIcon, Stack, createTheme, ThemeProvider } from "@mui/material";
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import GppMaybeRoundedIcon from '@mui/icons-material/GppMaybeRounded';
import { FAQLink, supportLink, privacyPolicyLink } from "@/config";
import { ensureHttpPrefix } from "@/utils";
import DOMPurify from "dompurify";
import { useUIActionsState } from "@/state";

// Function to handle the click and navigate to the link
const handleLinkClick = (link: string) => () => {
  const sanitizedLink = ensureHttpPrefix(DOMPurify.sanitize(link));
  window.open(sanitizedLink, "_blank");
};

export const FloatingHelpButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { multipleFloatingButtonsInViewport, isSnackbarOpen } = useUIActionsState();

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const openMenu = Boolean(anchorEl);
  return (
    <>
    <Fab
      size="small"
      color="secondary"
      aria-label="add"
      onClick={onClick}
      sx={{
        position: "fixed",
        right: "16px",
        bottom: isSnackbarOpen?.isOpen ? "80px" : multipleFloatingButtonsInViewport ? "72px" : "16px",
        transition: "bottom 0.3s ease-in-out",  // Adding the transition here
      }}
    >
        {openMenu ? <CloseRoundedIcon /> : <QuestionMarkRoundedIcon />}
      </Fab>
      <ThemeProvider
        theme={(currentTheme) =>
          createTheme({ ...currentTheme, palette: { mode: "dark" } })
        }
      >
        <Menu
          anchorEl={anchorEl}
          id="profile-menu"
          data-testid="profile-menu"
          open={openMenu}
          onClose={onClose}
          onClick={onClose}
          sx={(theme) => ({
            "& .MuiPaper-root": {
              backgroundColor: "background.default",
              boxShadow: theme.shadows[6],
            },
            marginTop: "-8px",

          })}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Stack>
            <MenuItem onClick={handleLinkClick(FAQLink)}>
              <ListItemIcon>
                <HelpRoundedIcon />
              </ListItemIcon>
              FAQs
            </MenuItem>
            <MenuItem onClick={handleLinkClick(supportLink)}>
              <ListItemIcon>
                <SupportAgentRoundedIcon />
              </ListItemIcon>
              Contact support
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLinkClick(privacyPolicyLink)}>
              <ListItemIcon>
                <GppMaybeRoundedIcon />
              </ListItemIcon>
              Privacy Policy
            </MenuItem>
          </Stack>
        </Menu>
      </ThemeProvider>
    </>  
  );
};
