import {
  ActivityCounter,
  InitiativeOverview,
  ShoutoutsView,
} from "@/interface";
import { create } from "zustand";

interface ActivitiesState {
  shoutoutsView: ShoutoutsView;
  currentTab: string;
  totalGiven: number;
  totalReceived: number;
  selectedInitiative?: InitiativeOverview;
  content?: string;
  activityCounter?: ActivityCounter;
  shouldRefreshGivenShoutouts: boolean;

  setState: (args: Partial<ActivitiesState>) => void;
}

const useActivitiesState = create<ActivitiesState>((set) => ({
  shoutoutsView: ShoutoutsView.RECEIVED,
  currentTab: "contributions",
  totalGiven: 0,
  totalReceived: 0,
  shouldRefreshGivenShoutouts: false,

  setState: (args?: Partial<ActivitiesState>) => {
    set({ ...args });
  },
}));

export { useActivitiesState };
