import { useState } from "react";
import {
  Box,
  Typography,
  Stack,
  DialogActions,
  DialogContent,
  SvgIcon,
  IconButton,
} from "@mui/material";
import ReactMarkdown from 'react-markdown';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DiscoverButtons } from "../DiscoverButtons";
import dei from "@/assets/DEI.png";
import { actions } from "../utils";
import { useFilteredDeiMock } from "../hooks/useFilteredDeiMock.ts";

type Props = {
  fullScreen: boolean;
  onClose?: () => void;
};

export const DiscoverDialog = ({ fullScreen, onClose }: Props) => {
  const [current, setCurrent] = useState(0);
  const filteredDeiMock = useFilteredDeiMock()

  const to = actions(filteredDeiMock, current);

  const buttons = (
    <DiscoverButtons
      nextDisabled={to.nextDisabled()}
      prevDisabled={to.prevDisabled()}
      onNext={() => setCurrent(to.next())}
      onPrev={() => setCurrent(to.prev())}
      steps={to.steps()}
    />
  );

  return (
    <>
      <DialogContent sx={{ p: 0 }}>
        <IconButton
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
          }}
          onClick={onClose}
        >
          <CloseRoundedIcon />
        </IconButton>
        <Stack direction="row">
          {!fullScreen && <img src={dei} />}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "445px",
              p: 2,
            }}
          >
            <Stack justifyContent="center" spacing={2} sx={{ flex: 1 }}>
              <SvgIcon style={{ width: "64px", height: "64px" }}>
                <FormatQuoteRoundedIcon
                  sx={{ color: (theme) => theme.palette.info.dark }}
                />
              </SvgIcon>

              <Typography variant="subtitle1">
                {filteredDeiMock[current].question}
              </Typography>
              <Typography variant="body2">
                <ReactMarkdown>
                  {filteredDeiMock[current].scenario}
                </ReactMarkdown>
              </Typography>
            </Stack>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                justifySelf: "flex-end",
                p: 0,
              }}
            >
              {buttons}
            </Box>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "space-between",
          justifySelf: "flex-end",
        }}
      >
        {buttons}
      </DialogActions>
    </>
  );
};
