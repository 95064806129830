import { KeyValuePair } from "@/interface";
import { capitalize, MenuItem } from "@mui/material";

type Props = {
  options: KeyValuePair[];
  selected: Record<string, string>;
  onSelect?: (option: KeyValuePair) => void;
};

export const SingleSelect = ({ options, selected, onSelect }: Props) => {
  return options.map((option) => (
    <MenuItem
      key={option.key}
      selected={!!selected[option.key]}
      onClick={() => onSelect?.(option)}
    >
      {capitalize(option.value)}
    </MenuItem>
  ));
};
