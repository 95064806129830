import { Stack } from "@mui/material";
import { CycleRenderer } from "./CycleRenderer";
import { useState } from "react";
import { ClosedCycles } from "./ClosedCycles";
import { CycleContainer } from "./CycleContainer";
import { UploaderDialog } from "../uploader/UploaderDialog";
import { AllocationUploader } from "../uploader/AllocationUploader";
import { useCycles } from "@/hooks/api";

export const Cycles = () => {
  const [open, setOpen] = useState(false);
  const [symbol, setSymbol] = useState("");
  const { active, upcoming, closed, mutate } = useCycles();

  const onAllocate = (symbol: string) => {
    setSymbol(symbol);
    setOpen(true);
  };
  const onClose = () => setOpen(false);

  const isFirstCycle = !active;

  return (
    <>
      <Stack spacing={4}>
        {active && (
          <CycleContainer status="Active">
            <CycleRenderer sparxCycle={active} isFirstCycle={isFirstCycle} />
          </CycleContainer>
        )}
        {upcoming.length > 0 && (
          <CycleContainer status="Upcoming">
            {upcoming.map((cycle) => (
              <CycleRenderer
                key={cycle.symbol}
                sparxCycle={cycle}
                isFirstCycle={isFirstCycle}
                onAllocate={() => onAllocate(cycle.symbol)}
              />
            ))}
          </CycleContainer>
        )}
        {closed.length > 0 && (
          <ClosedCycles count={closed.length}>
            {closed.map((c, i) => (
              <CycleRenderer
                key={i}
                isFirstCycle={isFirstCycle}
                sparxCycle={c}
              />
            ))}
          </ClosedCycles>
        )}
      </Stack>
      <UploaderDialog open={open} onClose={onClose}>
        <AllocationUploader onClose={onClose} mutate={mutate} symbol={symbol} />
      </UploaderDialog>
    </>
  );
};
