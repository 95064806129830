import { ContributionEditor as ShoutoutEditor } from "@/pages/common/editor/ContributionEditor";
import { Receivers } from "../common/Receivers";
import { useShoutsState } from "@/state";
import { CreateShoutButton } from "./CreateShoutButton";
import { Visibility } from "../common/Visibility";

type Props = {
  onMutate: () => void;
};

export const ShoutoutsEditor = ({ onMutate }: Props) => {
  const { receivers, isPublic, setState } = useShoutsState();

  return (
    <ShoutoutEditor
      element={
        <Receivers
          receivers={receivers}
          onChange={(receivers) => setState({ receivers })}
        />
      }
      onEditorChange={(html) => setState({ content: html })}
      caption="Post shout-out for"
      placeholder="Shout-out and recognise your peers"
      showFooter={false}
      sx={{ background: (theme) => theme.palette.common.white }}
    >
      <Visibility
        isPublic={isPublic}
        onSelect={(isPublic) => setState({ isPublic })}
      />
      <CreateShoutButton onMutate={onMutate} />
    </ShoutoutEditor>
  );
};
