import { useMemo, useState } from "react";
import useSWR from "swr";
import {
  Box,
  Typography,
  Stack,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useUserState } from "@/state";
import { Category } from "@/interface";
import { API_ENDPOINTS, fetcher } from "@/config";
import { CategoryTags } from "@/components";
import { actions, toCategories } from "../utils";
import { DiscoverButtons } from "../DiscoverButtons";

type Props = {
  onClose?: () => void;
};

export const CriteriaDialog = ({ onClose }: Props) => {
  const [current, setCurrent] = useState(0);
  const { authToken } = useUserState();

  const { data } = useSWR<Category[]>(
    `${API_ENDPOINTS.initiatives.categories}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  const categories = useMemo(() => {
    return toCategories(data);
  }, [data]);

  const to = actions(categories, current);

  const category = categories[current];

  return (
    <>
      <DialogContent>
        <IconButton
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
            color: (theme) => theme.palette.common.white,
          }}
          onClick={onClose}
        >
          <CloseRoundedIcon />
        </IconButton>
        <Box
          sx={{
            backgroundColor: "info.dark",
            borderRadius: "24px",
            overflow: "hidden",
            mb: 2,
          }}
        >
          <img src={category?.svg || ""} />
        </Box>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h4">{category?.name}</Typography>
          <Stack
            spacing={1}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            alignContent="center"
            justifyContent="center"
          >
            {category?.tags.map((tag) => (
              <CategoryTags label={tag} key={tag} />
            ))}
          </Stack>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            {category?.description}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <DiscoverButtons
          nextDisabled={to.nextDisabled()}
          prevDisabled={to.prevDisabled()}
          onNext={() => setCurrent(to.next())}
          onPrev={() => setCurrent(to.prev())}
          steps={to.steps()}
        />
      </DialogActions>
    </>
  );
};
