import { Theme } from "@mui/material/styles";

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          transition: "all .1s ease-out",
          textDecoration: "none",
          "&:hover": {
            textDecorationLine: "underline",
            textDecorationThickness: 2,
            textDecorationColor: theme.palette.grey[300],
          },
        },
      },
    },
  };
}
