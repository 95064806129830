import { Card, CardContent, Stack, Typography } from "@mui/material";

type Props = {
  endDate: string;
}

export const DelegateInfoCard = ({endDate}: Props) => {
  const info = {
    caption: "delegating sparx",
    title: "Delegate your Sparx before {DATE}",
    content: "Remember, Sparx expire and do not roll over to the next quarter. ",
  }
  return (
    <Card
      sx={{
        background: (theme) => theme.palette.common.white,
        minHeight: "175px",
      }}
      elevation={0}
    >
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="overline">{info.caption}</Typography>
          <Typography variant="subtitle1">{info.title.replace("{DATE}", endDate)}</Typography>
          <Typography variant="body2">{info.content}</Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}