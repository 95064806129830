import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  styled,
} from "@mui/material";

import { Order } from "./type";
import { EnhancedListHead } from "./ListHead";
import { getComparator, stableSort } from "./utils";
import { RowRenderer } from "./RowRenderer";
import { useBulkSparxState } from "@/state";
import { Contributor } from "@/interface";
import { BodyRenderer } from "./BodyRenderer";

type Props = PropsWithChildren<{
  isLoading?: boolean;
  setActiveButton: (active: boolean) => void;
  usersSparx: Record<string, number>,
  selected: readonly string[];
  setUserSparxAmount: (userId: string, value: string | number) => void;
}>;

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(2),
}));

export const ContributorsTable = ({ isLoading, children, setActiveButton, usersSparx, selected, setUserSparxAmount }: Props) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Contributor>("numberOfContributions");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { contributors, setState } = useBulkSparxState();

  useEffect(() => { 
    if (selected.length > 0) {
      setActiveButton(true);
      return;
    }
    setActiveButton(false)
    setState({selectedContributors: contributors.filter((contributor) => selected.includes(contributor.user.id))});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setState({
      selectedContributors: contributors.filter((contributor) =>
        selected.includes(contributor.user.id)
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, contributors]);

  const handleRequestSort = (property: keyof Contributor) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(() => {
    return stableSort(contributors, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage, contributors]);

  return (
    <StyledBox>
      {children}
      <TableContainer>
        <Table>
          <EnhancedListHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(_, property) => handleRequestSort(property as any)}
          />
          <BodyRenderer
            isLoading={isLoading}
            isEmpty={visibleRows.length === 0}
          >
            <TableBody>
              {visibleRows.map((row) => {
                const isItemSelected = isSelected(row.user.id);

                return (
                  <RowRenderer
                    key={row.user.id}
                    contributor={row}
                    isItemSelected={isItemSelected}
                    setUserSparxAmount={setUserSparxAmount}
                    sparxAmount={usersSparx[row.user.id]}
                    onRowClick={() => setState({ contributor: row })}
                  />
                );
              })}
            </TableBody>
          </BodyRenderer>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={contributors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, page) => handleChangePage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledBox>
  );
};
