import { Stack, Typography } from "@mui/material";
import { SearchSection } from "./SearchSection";
import { useOperationsState } from "@/state";
import { SenderTransactionsContainer } from "./SenderTransactionsContainer";

const TransactionsPanel = () => {
  const { sender } = useOperationsState();
  
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h5" component="h1">
          Transactions
        </Typography>
        <Typography variant="body1">
          Find transactions, revert incorrect ones and delegate Sparx on behalf of users
        </Typography>
      </Stack>
      <SearchSection />
      {sender.length > 0 && (<SenderTransactionsContainer />)}
    </Stack>
  );
};

export { TransactionsPanel };
