export default function Tab() {
  return {
    MuiTab: {
      styleOverrides: {
        root: () => ({
          height: "64px",
        }),
      },
    },
  };
}
