import { Alert, Snackbar, Slide, SlideProps } from "@mui/material";
import { useUIActionsState } from "@/state";

const CustomSnackbar = () => {
  const { isSnackbarOpen, setSnackbarOpen } = useUIActionsState();
  const TransitionUp = (props: SlideProps) => {
    return <Slide {...props} direction="up" />;
  };

  return (
    isSnackbarOpen?.isOpen && (
      <Snackbar
        data-testid="test-snackbar"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        TransitionComponent={TransitionUp}
        open={isSnackbarOpen?.isOpen}
        onClose={() =>
          setSnackbarOpen({
            variant: undefined,
            isOpen: false,
            message: "",
            hasFooter: false,
          })
        }
        sx={{ marginBottom: isSnackbarOpen?.hasFooter ? "80px" : "auto" }}
      >
        <Alert
          data-testid="test-alert"
          severity={isSnackbarOpen?.variant}
          variant="filled"
          onClose={() =>
            setSnackbarOpen({
              variant: undefined,
              isOpen: false,
              message: "",
              hasFooter: false,
            })
          }
          sx={{ width: "100%" }}
        >
          {isSnackbarOpen?.message}
        </Alert>
      </Snackbar>
    )
  );
};

export { CustomSnackbar };
