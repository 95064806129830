import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ModalVariantsEnum } from "../../enums/ModalVariants";
import { useUIActionsState } from "../../state/useUIActionsState";

interface ModalProps {
  closeColor?: string;
  closePosition?: {
    top?: string | number;
    right?: string | number;
  };
  dialogContentPadding?: string;
  paperPadding?: string;
  enableOutsideClick?: boolean;
  showCloseButton?: boolean;
}

const Modal = ({
  closeColor,
  closePosition,
  dialogContentPadding,
  paperPadding,
  enableOutsideClick = false,
  showCloseButton = true,
}: ModalProps) => {
  const { isModalOpen, setModalOpen } = useUIActionsState();

  const handleModalClose = () => {
    setModalOpen({
      isOpen: false,
      content: null,
      actions: null,
      variant: ModalVariantsEnum.PRIMARY,
    });
  };

  return (
    isModalOpen?.isOpen &&
    isModalOpen.variant && (
      <Dialog
        data-testid="test-modal"
        variant={isModalOpen.variant}
        open={isModalOpen?.isOpen}
        onClose={(_event, reason) => {
          if (
            enableOutsideClick ||
            (reason !== "backdropClick" && reason !== "escapeKeyDown")
          ) {
            handleModalClose();
          }
        }}
        sx={{
          "& .MuiPaper-root": { padding: paperPadding || "40px 64px" },
        }}
      >
        {isModalOpen.title && (
          <DialogTitle variant={isModalOpen?.variantTitle || "inherit"}>
            {isModalOpen.title}
          </DialogTitle>
        )}
        {showCloseButton && (
          <IconButton
            data-testid="close-button"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: closePosition?.right || 8,
              top: closePosition?.top || 8,
              color: (theme) => closeColor || theme.palette.text.primary,
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        )}
        <DialogContent
          sx={{
            overflow: "visible",
            padding: dialogContentPadding || "16px 0px !important",
          }}
        >
          {isModalOpen?.content}
        </DialogContent>
        {isModalOpen?.actions && (
          <DialogActions sx={{ paddingTop: "16px" }}>{isModalOpen?.actions}</DialogActions>
        )}
        {isModalOpen?.footer && isModalOpen?.footer}
      </Dialog>
    )
  );
};

export { Modal };
