import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box } from "@mui/material";
import { SendRounded } from "@mui/icons-material";

type Props = {
  title: string;
  onClick: () => void;
} & LoadingButtonProps;

export const UpdateButton = ({ title, onClick, ...rest }: Props) => {

  return (
    <Box sx={{ mt: "auto", display: "flex", justifyContent: "flex-start" }}>
      <LoadingButton
        variant="contained"
        onClick={onClick}
        endIcon={<SendRounded />}
        {...rest}
      >
        {title}
      </LoadingButton>
    </Box>
  );
};
