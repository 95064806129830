import { Theme } from "@mui/material/styles";

export default function ButtonGroup(theme: Theme) {
  return {
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        styleOverrides: {
          firstButton: {
            borderRight: theme.spacing(0),
            borderColor: "transparent",
          },
          middleButton: {
            borderRight: theme.spacing(0),
          },
        },
      },
    },
  };
}
