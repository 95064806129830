import { useInitiativeState, useUserState } from "@/state";
import { UserData } from "@/interface";
import { useEffect } from "react";
import { UserAutocomplete } from "@/components";

type Props = {
  isCreatePage?: boolean;
};

export const Owners = ({ isCreatePage }: Props) => {
  const { userData } = useUserState();
  const { owners, setState } = useInitiativeState();

  useEffect(() => {
    if (userData && isCreatePage) setState({ owners: [userData] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onChange = (owners: UserData[]) => setState({ owners });

  return (
    <UserAutocomplete
      value={owners}
      onChange={onChange}
      helperText=" You are the owner by default"
      label="Owner"
    />
  );
};
