import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { MIN_DELEGATION_TOKENS } from "@/constants";
import { useSparxState, useUserState } from "@/state";
import { DelegateSparxButton } from "../common/DelegateSparxButton.tsx";
import { DelegateFrozenPopover } from "./DelegateFrozenPopover";

type Props = {
  disablePopover: boolean;
}

export const DelegateButtonWithPopover = ({disablePopover}: Props) => {
  const { balance } = useSparxState();
  const { userData } = useUserState();

  const [open, setOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState<number | undefined>(undefined);

  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const hiddenHoverEl = useRef<HTMLDivElement | null>(null);

  const canDelegate = userData?.canDelegate;

  useEffect(() => {
    if (anchorEl.current) {
      setButtonWidth(anchorEl.current.getBoundingClientRect().width);
    }
  }, [anchorEl]);

  const handleMouseLeave = (e: React.MouseEvent) => {
    if (anchorEl.current) {
      const buttonRect = anchorEl.current.getBoundingClientRect();

      const isOutsideHoverArea =
        e.clientX < buttonRect.left || e.clientX > buttonRect.right || e.clientY < buttonRect.top;

      if (isOutsideHoverArea) {
        setOpen(false);
      }
    }
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <Box
        ref={anchorEl}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={handleMouseLeave}
      >
        <DelegateSparxButton
          showButton={canDelegate}
          disabled={balance < MIN_DELEGATION_TOKENS || !disablePopover}
        />
      </Box>
      <Box
        ref={hiddenHoverEl}
        sx={{
          position: 'absolute',
          top: '100%',
          left: 0,
          width: buttonWidth,
          height: '8px',
          zIndex: 1,
          pointerEvents: 'auto',
        }}
        onMouseLeave={() => setOpen(false)}
      />
      <DelegateFrozenPopover
        open={open && !disablePopover}
        anchorEl={anchorEl.current}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />
    </Box>
  )
}