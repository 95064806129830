import { Box, styled } from "@mui/material";
import { TabHeader } from "./TabHeader";
import { Received } from "./Received";
import { Given } from "./Given";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.palette.common.white,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  gap: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
}));

export const Shoutouts = () => {
  return (
    <StyledBox>
      <TabHeader />
      <Received />
      <Given />
    </StyledBox>
  );
};
