import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";

interface Props {
  onMarkdownChanged: (markdown: string) => void;
}

export const MarkdownPlugin = ({ onMarkdownChanged }: Props) => {
  return (
    <OnChangePlugin
      onChange={(editorState) => {
        editorState.read(() => {
          const markdown = $convertToMarkdownString(TRANSFORMERS);
          onMarkdownChanged(markdown);
        });
      }}
    />
  );
};
