import { Box, Typography, alpha, styled, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useBulkSparxState } from "@/state";
import { GroupedAvatars } from "@/components";
import { UserData } from "@/interface";
import { AutoAwesomeRounded } from "@mui/icons-material";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${grey[300]}`,
  borderRadius: theme.spacing(1),
  overflow: "hidden",
  ".item": {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    "&  > *": { flex: 1 },
  },
  ".border-bottom": {
    borderBottom: `1px solid ${grey[300]}`,
  },
  ".error": {
    background: alpha(theme.palette.error.light, 0.1),
  },
}));

export const DistributionContent = () => {
  const theme = useTheme();
  const {
    selectedContributors,
  } = useBulkSparxState();

  const users = selectedContributors.map((c) => c.user) as UserData[];

  const totalSparx = selectedContributors.reduce((sum, contributor) => sum + (Number(contributor.amount) || 0), 0);

  return (
    <StyledBox>
      <Box className="item border-bottom">
        <Typography variant="subtitle2">Contributors</Typography>
        <Typography variant="subtitle2" align="right">
          Total Sparx
        </Typography>
      </Box>
      <Box className="item border-bottom">
        <Box>
          <GroupedAvatars users={users} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box display="flex" alignItems="center" pt={1}>
            <AutoAwesomeRounded
              sx={{ fontSize: 16, marginRight: 1, color: theme.palette.primary.dark }}
            />
            <Typography variant="subtitle2" sx={{ color: theme.palette.primary.dark }}>{totalSparx}</Typography>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};
