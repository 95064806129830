import { Container, Paper, Typography, styled } from "@mui/material";
import { RewardsTable } from "./table/RewardsTable";
import { CollectRewards } from "./collect/CollectRewards";
import { SubnavigationBar } from "@/components";
import { ROUTES } from "@/config";
import { useCalculatePaddingTop } from "@/hooks";
import RedeemRoundedIcon from '@mui/icons-material/RedeemRounded';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  overflow: "hidden",
  "&>div:not(:last-child)": {
    borderBottom: "0px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
}));

export const Rewards = () => {
  const paddingTop = useCalculatePaddingTop();

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[{ label: "Rewards", href: ROUTES.rewards, icon: <RedeemRoundedIcon /> }]}
      />
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pt: paddingTop,
        }}
      >
        <StyledPaper elevation={0}>
          <Container sx={{ position: "relative", paddingY: 2 }}>
            <Typography
              variant="h6"
              sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              Rewards
            </Typography>
          </Container>
          <RewardsTable />
        </StyledPaper>
      </Container>
      <CollectRewards />
    </>
  );
};
