import { useUserState } from "@/state";
import {
  Button,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import StatusChip from "../common/StatusChip";
import { EnrollmentData, InitiativeOwnershipData } from "@/interface";
import { CategoryIcon } from "@/components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import { EnrollmentStatusEnum } from "@/enums";

type Props = {
  enrollment: EnrollmentData;
};

export const RowItem = ({ enrollment }: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const { initiative } = enrollment;

  const isInitiativeOwner = enrollment.initiative.owners?.find(
    (owner: InitiativeOwnershipData): boolean => owner.userId === userData?.id
  );

  const isEnrollmentDropped: boolean = enrollment.status === EnrollmentStatusEnum.DROPPED;

  const onClick = () => navigate(`${ROUTES.initiative}/${initiative.id}`);

  return (
    <TableRow
      sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}
    >
      <TableCell>
        <Button onClick={onClick}>
          <Typography variant="body2">{initiative.title}</Typography>
        </Button>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CategoryIcon name={initiative.category?.name ?? ""} />
          <Typography variant="subtitle2">
            {initiative.category?.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {isInitiativeOwner ? (
          <Chip color="info" label="Owner" />
        ) : (
          <Chip label={isEnrollmentDropped ? "Follower" : "Participant"} />
        )}
      </TableCell>
      <TableCell>
        <StatusChip status={initiative.status} />
      </TableCell>
    </TableRow>
  );
};
