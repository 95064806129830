import { Box } from "@mui/material";
import { TabHeader } from "./TabHeader";
import { useMyContributions } from "@/hooks/api";
import { contributionsByInitiative } from "@/utils";
import { ContributionList } from "./ContributionList";
import { Editor } from "./Editor";

export const Contributions = () => {
  const { data, mutate } = useMyContributions();
  const { contributions } = contributionsByInitiative(data);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <TabHeader />
      <Editor onMutate={mutate} />
      <ContributionList data={contributions} onMutate={mutate} />
    </Box>
  );
};
