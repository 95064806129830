import { Chip, useTheme } from "@mui/material";

type VariantCategoryTags =
  | "purple"
  | "purpleSecondary"
  | "purpleOutlined"
  | "green";

type CategoryTagsProps = {
  variant?: VariantCategoryTags;
  label: string;
  size?: "medium" | "small";
  icon?: JSX.Element | null;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readOnly?: boolean;
};

const CategoryTags = ({
  variant = "purple",
  label,
  size = "small",
  icon,
  readOnly,
  onClick,
}: CategoryTagsProps) => {
  const theme = useTheme();

  const categoryTagsVariants: Record<
    string,
    { color?: string; bgColor?: string; outline?: string }
  > = {
    purple: {
      //color: `${theme.palette.secondary.main}`,
      //bgColor: `${theme.palette.secondary.light}`,
    },
    purpleSecondary: {
      color: `${theme.palette.secondary.contrastText}`,
      bgColor: `${theme.palette.secondary.main}`,
      outline: `1px solid ${theme.palette.secondary.dark}`,
    },
    purpleOutlined: {
      //color: `${theme.palette.secondary.main}`,
      //bgColor: `${theme.palette.background.default}`,
      //outline: `1px solid ${theme.palette.primary.main}`,
    },
    green: {
      //color: `${theme.palette.info.main}`,
      //bgColor: `${theme.palette.info.light}`,
    },
  };
  const { color, bgColor } = categoryTagsVariants[variant];

  const tagSize: Record<string, { paddingY: number; paddingX: number }> = {
    small: {
      paddingY: 0,
      paddingX: 0,
    },
    medium: {
      paddingY: 0.9,
      paddingX: 2,
    },
  };
  const { paddingY, paddingX } = tagSize[size];

  return (
    <>
      {icon ? (
        <Chip
          icon={icon}
          label={label}
          sx={{
            color,
            backgroundColor: bgColor,
            paddingY,
            paddingX,
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: 1,
          }}
          onClick={onClick}
        />
      ) : (
        <Chip
          disabled={readOnly}
          label={label}
          sx={{
            color,
            backgroundColor: bgColor,
            paddingY,
            paddingX,
          }}
          onClick={onClick}
        />
      )}
    </>
  );
};

export { CategoryTags };
export type { VariantCategoryTags };
