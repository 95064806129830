import { Box, Typography, styled } from "@mui/material";
import { CategoryIcon } from "@/components";

type Props = {
  categories: string[];
};

type Category = 'Diversity & Inclusion' | 'People Development' | 'Recruitment' | 'System' | 'Marketing' | "Chapter" | "Practice Area";

const StyledCategoryBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1.25),
  marginBottom: theme.spacing(1),
}));


const CategoryItem = ({ category }: { category: Category }) => {
  return (
    <StyledCategoryBox>
      <Box mr={1}>
        <CategoryIcon key={category} name={category}/>
      </Box>
      <Typography variant="subtitle2">{category}</Typography>
    </StyledCategoryBox>
  );
};

export const CategoriesWithIcon = ({ categories }: Props) => {
  return (
    <Box sx={{ width: '412px', px: 2, paddingBottom: "16px" }}>
      <Typography variant="subtitle2">Here’s where you shine</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', paddingTop: "16px" }}>
        {categories.map((category) => (
          <CategoryItem key={category} category={category as Category} />
        ))}
      </Box>
    </Box>
  );
};
