import { useBulkSparxState } from "@/state";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { AmountField } from "../AmountField";

type Props = {
  totalContributions?: number;
  isLoading?: boolean;
  onUpdateAmount?: (number: number) => void;
};

export const Amount = ({ totalContributions, isLoading, onUpdateAmount }: Props) => {
  const { contributor, setAmount } = useBulkSparxState();

  const onChangeAmount = (value: string | number) => {
    setAmount(contributor?.user.id ?? "", value)
    onUpdateAmount?.(Number(value));
  }

  return (
    <Box display="flex" justifyContent="space-between" gap={1}>
      <Stack spacing={1}>
        <Typography variant="subtitle1">Contributions</Typography>
        <Typography variant="h6">
          {isLoading ? <Skeleton width={50} /> : totalContributions}
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle1" align="right">
          Sparx Amount
        </Typography>
        <AmountField
          amount={contributor?.amount}
          error={contributor?.error}
          setAmount={(value) => onChangeAmount(value)}
        />
      </Stack>
    </Box>
  );
};
