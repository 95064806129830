import { Box, Stack, Typography } from "@mui/material";
import { useCategories } from "@/hooks/api";
import { Category } from "@/interface";
import { CategorySelector } from "./CategorySelector";
import { SubCategorySelector } from "./SubCategorySelector";

type Props = {
  categoryId: string;
  subCategory: Category[] | undefined;
  setCategory: (categoryId: string) => void;
  setSubCategory: (sub: Category[] | undefined) => void;
};

export const ContributionCategory = ({
  categoryId,
  subCategory,
  setCategory,
  setSubCategory,
}: Props) => {
  const { categories } = useCategories();

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">Category</Typography>
      <Box display="flex" gap={2}>
        <Box flex={1}>
          <CategorySelector
            categoryId={categoryId}
            categories={categories ?? []}
            setCategory={(category) => {
              setCategory(category);
              setSubCategory(undefined);
            }}
          />
        </Box>
        <Box flex={1}>
          <SubCategorySelector
            categoryId={categoryId}
            subCategory={subCategory}
            categories={categories ?? []}
            setSubCategory={setSubCategory}
          />
        </Box>
      </Box>
    </Stack>
  );
};
