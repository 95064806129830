import { API_ENDPOINTS, fetcher } from "@/config";
import { ApiReactionResponse } from "@/interface";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useDeleteReaction = () => {
  const { authToken } = useUserState();

  const deleteReaction = async (
    url: string,
    { arg }: { arg: { reactionId: string } }
  ) => {
    const { reactionId } = arg;

    const res = await fetcher<ApiReactionResponse>(`${url}/${reactionId}`, {
      config: {
        method: "DELETE",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    });

    return res;
  };

  const { trigger, isMutating } = useSWRMutation(
    API_ENDPOINTS.shoutouts.reactions,
    deleteReaction
  );

  return {
    trigger,
    isMutating,
  };
};
