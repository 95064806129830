import { useDeleteShoutout } from "@/hooks/api";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";
import { ShoutoutData } from "@/interface";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  shoutout?: ShoutoutData;
  onClose: () => void;
  mutate: () => void;
};

export const DeleteShoutout = ({ open, shoutout, onClose, mutate }: Props) => {
  const { trigger, isMutating } = useDeleteShoutout(shoutout?.id);

  const onLeave = () => {
    trigger().then(() => {
      onClose();
      mutate();
    });
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">Delete Shoutout?</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={2}>
          Are you sure you want to delete this shoutout? This action can not be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <LoadingButton
          startIcon={<DeleteRounded />}
          loading={isMutating}
          onClick={onLeave}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};
