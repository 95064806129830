import { PropsWithChildren } from "react";
import { Card, CardContent } from "@mui/material";

export const Container = ({ children }: PropsWithChildren) => {
  return (
    <Card
      sx={{
        background: "white",
        border: (theme) => `1 solid ${theme.palette.divider}`,
        p: 2,
      }}
      elevation={0}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
};
