import { SxProps, Theme, alpha } from "@mui/material";

import { CommitmentEnum, ParticipationEnum } from "@/interface";

import SignalCellular0BarRoundedIcon from "@mui/icons-material/SignalCellular0BarRounded";
import SignalCellular3BarRoundedIcon from "@mui/icons-material/SignalCellular3BarRounded";
import SignalCellular4BarRoundedIcon from "@mui/icons-material/SignalCellular4BarRounded";

import LaptopRoundedIcon from "@mui/icons-material/LaptopRounded";
import JoinLeftRoundedIcon from "@mui/icons-material/JoinLeftRounded";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";

const getStyle = (
  styled: boolean,
  commitment: CommitmentEnum
): SxProps<Theme> => {
  if (!styled) return {};

  switch (commitment) {
    case CommitmentEnum.LOW:
      return {
        color: (theme) => theme.palette.info.dark,
        background: (theme) => theme.palette.customColors.infoBackground,
        borderRadius: 1,
        p: 0.2,
      };
    case CommitmentEnum.MEDIUM:
      return {
        color: (theme) => theme.palette.warning.dark,
        background: (theme) => alpha(theme.palette.warning.light, 0.2),
        borderRadius: 1,
        p: 0.2,
      };
    case CommitmentEnum.HIGH:
      return {
        color: (theme) => theme.palette.error.dark,
        background: (theme) => alpha(theme.palette.error.light, 0.2),
        borderRadius: 1,
        p: 0.2,
      };
  }
};

const content = {
  [CommitmentEnum.LOW]:
    "All aspects of the initiative are set. I need support only in the execution of designated tasks.",
  [CommitmentEnum.MEDIUM]:
    "The initiative is mostly established, but I need support with further requirement definition and task execution.",
  [CommitmentEnum.HIGH]:
    "Many aspects of the initiative remain to be defined. I need active support in clarifying its scope and details.",
};

const hoursPerWeek = {
  [CommitmentEnum.LOW]: "Under 2 hours/week",
  [CommitmentEnum.MEDIUM]: "3-5 hours/week",
  [CommitmentEnum.HIGH]: "Over 5 hours/week",
};

export const commitmentHelper = () => {
  const getContent = (c: CommitmentEnum) => {
    return content[c];
  };

  const getIcon = (c: CommitmentEnum, styled: boolean) => {
    switch (c) {
      case CommitmentEnum.LOW:
        return <SignalCellular0BarRoundedIcon sx={getStyle(styled, c)} />;
      case CommitmentEnum.MEDIUM:
        return <SignalCellular3BarRoundedIcon sx={getStyle(styled, c)} />;
      case CommitmentEnum.HIGH:
        return <SignalCellular4BarRoundedIcon sx={getStyle(styled, c)} />;
    }
  };

  const getHoursPerWeek = (c: CommitmentEnum) => hoursPerWeek[c];

  return { getContent, getIcon, getHoursPerWeek };
};

export const participationHelper = (styled?: boolean) => {
  const sx: SxProps<Theme> = styled
    ? { background: (theme) => theme.palette.grey[50], borderRadius: 1, p: 0.2 }
    : {};

  const getParticipationIcon = (p: ParticipationEnum) => {
    switch (p) {
      case ParticipationEnum.REMOTE:
        return <LaptopRoundedIcon sx={sx} />;
      case ParticipationEnum.HYBRID:
        return <JoinLeftRoundedIcon sx={sx} />;
      case ParticipationEnum.IN_PERSON:
        return <HandshakeRoundedIcon sx={sx} />;
    }
  };

  return { getParticipationIcon };
};
