import { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { SparxUserRole, UserData } from "@/interface";
import { useUsers } from "@/hooks/api";
import { UserAvatar, UserInfo } from "@/components";
import { useUserState } from "@/state";

type Props = {
  value: UserData[];
  onChange: (user: UserData[]) => void;
  sx?: SxProps<Theme>;
  error?: boolean;
  helperText?: string;
  errorMsg?: string;
  disabled?: boolean;
  label: string;
  role?: SparxUserRole;
  disabledOptionId?: string;
};

export const SingleSelectAutocomplete = ({
  value,
  onChange,
  error,
  helperText,
  errorMsg,
  sx,
  disabled,
  label,
  role,
  disabledOptionId
}: Props) => {
  const { userData } = useUserState();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<UserData[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [noOptionsText, setNoOptionsText] = useState("Start typing a name or email");
  const { data, isLoading } = useUsers({ value: inputValue, role });
  
  useEffect(() => {
    setOptions(data ?? []);

    if (inputValue && !data) {
      setNoOptionsText("User not found");
    } else {
      setNoOptionsText("Start typing a name or email");
    }
  }, [data, inputValue]);

  /** 
   * The optionLabel is used to matched to the inputValue string to find the correct user.
   * In this case, we want users to be able to search by name, email, and employee id 
   * so here the label includes all those values.
   * We only display lastName, firstName of the selected option in the renderTags function.
   */
  const getOptionLabel = (option: UserData) => `${option.lastName}, ${option.firstName}, ${option.email}, ${option.id}`;

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_: any, user) => onChange(user)}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={open && isLoading}
      noOptionsText={noOptionsText}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option.id}
        >
          <UserAvatar user={option}>
            <UserInfo user={option} />
          </UserAvatar>
        </Box>
      )}
      getOptionDisabled={(option) => value.length >= 1 || option.id === disabledOptionId || option.id === userData?.id}
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <Chip
          label={`${option.lastName}, ${option.firstName}`}
          size="small"
          color="secondary"
          {...getTagProps({ index })}
          key={option.id}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
          helperText={errorMsg ?? helperText}
          label={label}
        />
      )}
      sx={sx}
    />
  );
};
