import { Theme } from "@mui/material/styles";

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape,
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.grey[100]}`,
          transition: "all .2s ease-out",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: `0px ${theme.spacing(2)} 0px ${theme.spacing(2)}`,
        },
      },
    },
    /*
    MuiCardHeader: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiCardContent: {
      styleOverrides: {
        root: {},
      },
    },
    */
  };
}
