import { UserData } from "@/interface";
import { create } from "zustand";

interface CommonState {
  sparxReceiver?: UserData;
  //
  setState: (args: Partial<CommonState>) => void;
}

const useCommonState = create<CommonState>((set) => ({
  rewardWithSparxModal: false,
  setState: (args?: Partial<CommonState>) => {
    set({ ...args });
  },
}));

export { useCommonState };
