import { Dialog, Stack, useMediaQuery, useTheme } from "@mui/material";
import { GuidelineCard } from "./GuidelineCard";
import { CriteriaDialog } from "./ContributionCriteria/CriteriaDialog";
import { useState } from "react";
import { DiscoverDialog } from "./DiscoverDEI/DiscoverDialog";

export const Guidelines = () => {
  const [dialog, setDialog] = useState<JSX.Element | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onClose = () => setDialog(null);

  const guidelineItems = [
    {
      caption: "EVALUATING CONTRIBUTIONS",
      title: "What contributions are eligible for Sparx?",
      content: "Guidelines to distribute Sparx among your peers, ensuring everyone's efforts are recognised.",
      onClick: () => setDialog(<CriteriaDialog onClose={onClose} />)
    },
    {
      caption: "AWARDING SPARX",
      title: "How to fairly distribute your Sparx",
      content: "Key questions to remember to ensure fair and equitable Sparx distribution among recipients.",
      onClick: () => setDialog(<DiscoverDialog onClose={onClose} fullScreen={fullScreen} />)
    }
  ];

  return (
    <Stack>
      <GuidelineCard items={guidelineItems} />

      <Dialog
        maxWidth="sm"
        open={!!dialog}
        fullScreen={fullScreen}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paperFullScreen": {
            borderRadius: theme.spacing(0),
          },
        }}
      >
        {dialog}
      </Dialog>
    </Stack>
  );
};
