import { useEffect, useRef } from "react";
import { StepLabel } from "@mui/material";
import { StepIcon } from "./StepIcon";

type StepProps = {
  isActive: boolean;
  type: "shoutout" | "transaction";
};

export const CustomStep = ({ isActive, type }: StepProps) => {
  const itemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActive && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isActive]);
  return (
    <StepLabel
      ref={itemRef}
      StepIconComponent={(props) => <StepIcon {...props} type={type} />}
    />
  );
};
