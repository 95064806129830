import { RewardsPlaceholder } from "./RewardsPlaceholder";
import { Stack, Typography, alpha } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded';

type Props = {
  type: "sparx" | "shoutout";
  flag: "open-lock" | "view-old" | "skip" | "open-gift" | undefined;
  value: number | null;
};

export const ConditionalCell = ({ flag, value, type }: Props) => {
  if (flag === "open-lock") return <RewardsPlaceholder />;
  return (
    <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
      {type === "sparx" && (
        <AutoAwesomeRoundedIcon
          sx={{
            color: (theme) => `${theme.palette.primary.dark}`,
            padding: "2px",
          }}
        />
      )}
      {type === "shoutout" && (
        <CelebrationRoundedIcon
          sx={{
            color: (theme) => `${theme.palette.success.main}`,
            padding: "2px",
          }}
        />
      )}
      <Typography variant="subtitle1" sx={{ color: alpha("#000000", 0.54) }}>
        {value ?? 0}
      </Typography>
    </Stack>
  );
};
