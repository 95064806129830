import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useInitiativeState } from "@/state";
import { useInitiativeMutation } from "@/hooks/api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";

type Props = {
  method: "POST" | "PATCH";
  initiativeId?: string;
};

export const ManageInitiativeButton = ({ method, initiativeId }: Props) => {
  const { isFormValid, getInitiative, resetState } = useInitiativeState();
  const { trigger } = useInitiativeMutation(method, initiativeId);
  const navigate = useNavigate();

  const handleClick = () => {
    const isValid = isFormValid();
    if (isValid) {
      trigger({ data: getInitiative() }).then((response) => {
        navigate(`${ROUTES.initiative}/${response.id}`);
      });
    }
  };

  useEffect(() => {
    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="button">
      <Button
        variant="contained"
        onClick={handleClick}
        endIcon={<ArrowForwardRoundedIcon />}
      >
        {method === "POST" ? "Send to Reviewer" : "Publish changes"}
      </Button>
    </Box>
  );
};
