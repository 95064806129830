import {
  Divider,
  IconButton,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { EditRounded, DeleteRounded, ReportRounded, MoreVertRounded, ReplyRounded } from "@mui/icons-material";

type MenuItemType = {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
};

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  deleteCaption: "Delete" | "Revert transaction";
  hideEditButton?: boolean;
  setOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onReport?: () => void;
};

export const OverflowMenu = ({
  open,
  anchorEl,
  setOpen,
  onClose,
  onDelete,
  onEdit,
  onReport,
  deleteCaption,
  hideEditButton,
}: Props) => {
  const menuItems: MenuItemType[] = [];

  if (onEdit) {
    menuItems.push({
      label: "Edit",
      icon: <EditRounded fontSize="small" />,
      onClick: onEdit,
    });
  }

  if (onDelete) {
    menuItems.push({
      label: deleteCaption,
      icon: deleteCaption === "Delete" ? <DeleteRounded /> : <ReplyRounded />,
      onClick: onDelete,
    });
  }

  if (onReport) {
    menuItems.push({
      label: "Report this shout-out",
      icon: <ReportRounded fontSize="small" />,
      onClick: onReport,
    });
  }


  return (
    <>
      <IconButton
        size="small"
        onClick={setOpen} // Changed to handleOpenMenu
        sx={{ display: hideEditButton ? "none" : undefined }}
      >
        <MoreVertRounded />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems.map((item, index) => (
          <div key={index}>
            {index > 0 && <Divider />}
            <MenuItem onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          </div>
        ))}
      </Menu>
    </>
  );
};
