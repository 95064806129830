import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useDeleteInitiative = (id: string) => {
  const { authToken } = useUserState();
  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.admin.initiatives}/${id}`,
    async (url: string) => {
      await fetcher(
        url,
        {
          config: {
            method: "DELETE",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${authToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        },

        {
          variant: "success",
          isOpen: true,
          message: "Initiative deleted",
        }
      );
    }
  );

  return { trigger, isMutating };
};
