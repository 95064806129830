import { useState } from "react";
import { Box, styled } from "@mui/material";
import { Stepper } from "../stepper/Stepper";
import { RewardCard } from "../card/RewardCard";
import { useRewardSlider, useUpdateReward } from "@/hooks/api";
import { useRewardsState } from "@/state";
import { useSWRConfig } from "swr";
import { API_ENDPOINTS } from "@/config";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
});

export const RewardSlider = () => {
  const { mutate } = useSWRConfig();
  const { symbol, setState } = useRewardsState();
  const { rewards, isLoading } = useRewardSlider(symbol ?? "");
  const { trigger } = useUpdateReward(symbol ?? "");

  const [activeStep, setActiveStep] = useState(0);

  const onNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onDone = () => {
    trigger().then(() => {
      mutate(API_ENDPOINTS.rewards.root);
    });
    setState({ openRewardsModal: false, openCollectRewardsModal: undefined });
  };

  return (
    <StyledBox>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <RewardCard
          key={activeStep}
          reward={rewards[activeStep]}
          isLoading={isLoading}
        />
      </Box>

      <Stepper
        rewards={rewards}
        activeStep={activeStep}
        onNext={onNext}
        onBack={onBack}
        onDone={onDone}
      />
    </StyledBox>
  );
};
