import { Box, Chip, Stack, Typography } from "@mui/material";
import { Container } from "./Container";
import { CommitmentEnum, ParticipationEnum } from "@/interface";
import { commitmentHelper, participationHelper } from "../../common/helpers";

type Props = {
  collaboration: boolean;
  commitment: CommitmentEnum;
  participation: ParticipationEnum;
};

export const Commitment = ({
  collaboration,
  commitment,
  participation,
}: Props) => {
  const { getParticipationIcon } = participationHelper(true);
  const { getContent, getIcon, getHoursPerWeek } = commitmentHelper();

  if (!collaboration) return null;

  return (
    <Container>
      <Stack spacing={2}>
        {collaboration && <div><Chip variant="outlined" label="Seeking contributors" color="primary" /></div>}
        <Box display="flex">
          <Stack flex={1} spacing={1}>
            <Typography variant="subtitle2">Commitment</Typography>
            <Box display="flex" alignItems="center" gap={1}>
              {getIcon(commitment, true)}
              <Typography>{commitment}</Typography>
            </Box>
          </Stack>
          <Stack flex={1} spacing={1}>
            <Typography variant="subtitle2">Collaboration</Typography>
            <Box display="flex" alignItems="center" gap={1}>
              {getParticipationIcon(participation)}
              <Typography>{participation}</Typography>
            </Box>
          </Stack>
        </Box>
        <Typography variant="subtitle2">What to expect</Typography>
        <Typography>{getHoursPerWeek(commitment)}</Typography>
        <Typography variant="body2" color="text.secondary">
          {getContent(commitment)}
        </Typography>
      </Stack>
    </Container>
  );
};
