import { API_ENDPOINTS, fetcher } from "@/config";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useAddContribution = () => {
  const { authToken } = useUserState();

  const add = async (url: string, { arg }: { arg: { data: any } }) => {
    await fetcher(
      url,
      {
        config: {
          method: "POST",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(arg.data),
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Contribution added with success",
        hasFooter: false,
      }
    );
  };

  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.contributions}`,
    add
  );

  return {
    trigger,
    isMutating,
  };
};
