export const isEditorEmpty = (inputString: string) => {
  // Target string to compare
  const targetString = '<p class="EditorTheme__paragraph"><br></p>';
  return inputString === "" || inputString === targetString;
};

export const truncateString = (inputString: string) => {
  const maxLength = 20
  if (inputString.length <= maxLength) {
      return inputString;
  }
  return inputString.slice(0, maxLength) + '...';
}