import { useEffect } from "react";
import { ShoutoutsView } from "@/interface";
import { useMyShoutouts } from "@/hooks/api";
import { ScrollToTopButton } from "@/components";
import { useActivitiesState } from "@/state";

import { ShoutoutsEditor, ShoutoutsList } from "@/pages/common/shoutouts";

export const Given = () => {
  const { setState, shoutoutsView, shouldRefreshGivenShoutouts } = useActivitiesState();
  const {
    shoutouts,
    total,
    isLoading,
    refresh,
    loadMore,
    deleteShoutout,
    editShoutout,
    addReaction,
    deleteReaction,
  } = useMyShoutouts(ShoutoutsView.GIVEN);

  useEffect(() => {
    setState({ totalGiven: total });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  useEffect(() => {
    if (shouldRefreshGivenShoutouts) {
      refresh();
      setState({ shouldRefreshGivenShoutouts: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGivenShoutouts]);

  if (shoutoutsView !== ShoutoutsView.GIVEN) return null;

  return (
    <>
      <ShoutoutsEditor onMutate={refresh} />
      <ShoutoutsList
        shouts={shoutouts}
        hasReactions
        isLoading={isLoading}
        loadMore={loadMore}
        onDelete={deleteShoutout}
        onEdit={editShoutout}
        onAddReaction={addReaction}
        onDeleteReaction={deleteReaction}
      />

      <ScrollToTopButton />
    </>
  );
};
