import { useBulkSparxState } from "@/state";
import { Box, IconButton } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const Header = () => {
  const { setState } = useBulkSparxState();
  const onClose = () => setState({ contributor: undefined });

  return (
    <Box
      sx={{
        height: 60,
        px: 2,
        py: 1,
      }}
    >
      <IconButton size="small" onClick={onClose}>
        <CloseRoundedIcon />
      </IconButton>
    </Box>
  );
};
