import { CustomDialog } from "@/components";
import { useLeaveInitiative } from "@/hooks/api";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useParams } from "react-router-dom";

type Props = {
  open: boolean;
  enrollmentId: number | string;
  onClose: () => void;
};

export const LeaveDialog = ({ open, enrollmentId, onClose }: Props) => {
  const { id } = useParams();
  const { trigger, isMutating } = useLeaveInitiative(id ?? "");

  const onLeave = () => {
    trigger({ enrollmentId }).then(() => onClose());
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">Leave Initiative?</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={2}>
          Are you sure you want to leave this initiative? Leaving will remove
          you from the group and notify the initiative owner.
        </DialogContentText>
        <DialogContentText variant="caption" textAlign="center">
          You can log past contributions anytime. If you reconsider, rejoining
          is possible later.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={isMutating}
          onClick={onLeave}
          variant="contained"
          color="error"
        >
          Leave
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};
