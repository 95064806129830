import { Container } from "@mui/material";
import { Contributions } from "./contributions";
import { Initiatives } from "./initiatives";
import { Shoutouts } from "./shoutouts";
import Tabs from "./common/Tabs";
import { useEffect } from "react";
import { useActivitiesState } from "@/state";
import { ROUTES } from "@/config";
import { useActivityCounter } from "@/hooks/api";
import { useCalculatePaddingTop } from "@/hooks";
import { SubnavigationBar } from "@/components";
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded';
import { TabContext, TabPanel } from "@mui/lab";


export const Activities = () => {
  const paddingTop = useCalculatePaddingTop();
  const { data } = useActivityCounter();
  const { currentTab } = useActivitiesState();

  const { setState } = useActivitiesState();

  useEffect(() => {
    if (data) {
      setState({ activityCounter: data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[{ label: "My Activity", href: ROUTES.my_activity, icon: <MonitorHeartRoundedIcon /> }]}
        tabs={<Tabs/>}
      />
      <Container sx={{ display: "flex", flexDirection: "column", gap: 2, pt: paddingTop}}>
        <TabContext value={currentTab}>
          <TabPanel value="contributions" sx={{ p: 0 }}>
            <Contributions />
          </TabPanel>
          <TabPanel value="initiatives" sx={{ p: 0 }}>
            <Initiatives />
          </TabPanel>
          <TabPanel value="shoutouts" sx={{ p: 0 }}>
            <Shoutouts />
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};
