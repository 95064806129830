import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import ReactMarkdown from 'react-markdown';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useState } from "react";
import { actions } from "../guideline/utils";
import { useFilteredDeiMock } from "@/pages/Sparx/guideline/hooks/useFilteredDeiMock.ts";

export const QuestionsCard = () => {
  const [current, setCurrent] = useState(0);
  const filteredDeiMock = useFilteredDeiMock()

  const to = actions(filteredDeiMock, current);

  return (
    <Card
      sx={(theme) => ({
        backgroundColor: `${theme.palette.background.default}`,
        boxShadow: "none",
      })}
    >
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingBottom={2}
        >
          <Typography variant="subtitle2">{to.steps()}</Typography>
          <ButtonGroup disableElevation variant="outlined" size="small">
            <Button
              startIcon={<KeyboardArrowLeftRoundedIcon />}
              onClick={() => setCurrent(to.prev())}
              disabled={to.prevDisabled()}
              variant="group"
            />
            <Button
              endIcon={<KeyboardArrowRightRoundedIcon />}
              onClick={() => setCurrent(to.next())}
              disabled={to.nextDisabled()}
              variant="group"
            />
          </ButtonGroup>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="body1" color="text.primary">
            {filteredDeiMock[current].question}
          </Typography>
          <Typography variant="body2" color="text.primary">
            <ReactMarkdown>
              {filteredDeiMock[current].scenario}
            </ReactMarkdown>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
