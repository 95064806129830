import { API_ENDPOINTS, fetcher } from "@/config";
import { SparxBalance } from "@/interface";
import { useUserState, useSparxState, useBulkSparxState } from "@/state";
import { useEffect } from "react";
import useSWR from "swr";

export const useBalance = () => {
  const { authToken } = useUserState();
  const { setBalance } = useSparxState();
  const { setBulkBalance } = useBulkSparxState();

  const { data } = useSWR<SparxBalance>(
    `${API_ENDPOINTS.tokens.balance}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (data) {
      setBalance(data);
      // Used for bulk sparx allocation
      setBulkBalance(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {data}
};
