import { TransactionTableCycleData, ExtendedTransaction } from "@/interface";
import { SparxTransactionEnum } from "@/enums";
import { TransactionItem } from "./TransactionItem";
import { EmptyCycleCard } from "./EmptyCycleCard";

type Props = {
  cycle: TransactionTableCycleData;
  isExpanded: boolean;
  mutate: (() => void) | undefined;
}

const CycleContainer = ({ cycle, isExpanded, mutate }: Props) => {
  
  if (!isExpanded) return null

  return (
    <>
      {cycle.transactions.length === 0 ? (
        <EmptyCycleCard isCurrentCycle={cycle.state === "ACTIVE"} />
      ) : (
        cycle.transactions.map((transaction: ExtendedTransaction) => (
          <TransactionItem
            key={transaction.id}
            transaction={transaction}
            hideEditButton={cycle.state !== "ACTIVE" || transaction.type !== SparxTransactionEnum.ASSIGNMENT}
            mutate={mutate}
          />
        ))
      )}
    </>
  )
}

export default CycleContainer;