import { ROUTES } from "@/config";

const getActiveState = (currentPath: string, itemTo: string): boolean => {
  switch (itemTo) {
    case ROUTES.root:
      return currentPath === ROUTES.root;
    case ROUTES.sparx:
      return currentPath.startsWith(ROUTES.sparx);
    case ROUTES.initiatives:
      return currentPath.includes("initiative");
    case ROUTES.my_activity:
      return currentPath === ROUTES.my_activity;
    case ROUTES.rewards:
      return currentPath === ROUTES.rewards;
    case ROUTES.operations:
      return currentPath === ROUTES.operations;
    default:
      return false;
  }
};

export { getActiveState };