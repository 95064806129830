import { Stack, StackProps, styled } from "@mui/material";
import { PropsWithChildren } from "react";

const Styled = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  justifyContent: "flex-end",
  alignItems: "center",
  color: theme.palette.text.disabled,
}));

type Props = PropsWithChildren & StackProps;

export const StyledStack = ({ children, ...rest }: Props) => {
  return <Styled {...rest}>{children}</Styled>;
};
