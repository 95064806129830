import { API_ENDPOINTS, fetcher } from "@/config";
import { InitiativeTransitionsEnum } from "@/enums";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

type Args = { reviewNote: string };

export const useReject = (id: string) => {
  const { authToken } = useUserState();

  const reject = async (url: string, { arg }: { arg: Args }) => {
    const { reviewNote } = arg;
    await fetcher(
      url,
      {
        config: {
          method: "PATCH",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ reviewNote }),
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Initiative rejected",
      }
    );
  };

  const { trigger, isMutating } = useSWRMutation(
    `${API_ENDPOINTS.initiatives.root}/${id}/${InitiativeTransitionsEnum.REJECT}`,
    reject
  );

  return {
    trigger,
    isMutating,
  };
};
