import { ContributionData } from "@/interface";
import { Box, Stack, Typography, alpha } from "@mui/material";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import { SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import { grey } from "@mui/material/colors";

type Props = {
  contribution: ContributionData;
  isLast: boolean;
};

export const ContributionRenderer = ({ contribution, isLast }: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ ml: 1 }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <SvgIcon
            fontSize="medium"
            sx={{
              borderRadius: "50%",
              background: "red",
              p: 0.5,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.5),
            }}
          >
            <FlagRoundedIcon
              sx={{ color: (theme) => theme.palette.primary.dark }}
            />
          </SvgIcon>
          <Typography variant="subtitle2">Contributed</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2" sx={{ color: grey[500] }}>
            {dayjs(contribution.createdAt).format("ll")}
          </Typography>
        </Stack>
      </Box>
      <Box className={`divider ${isLast ? "last" : ""}`} sx={{ pl: 3 }}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: contribution.details }}
        />
      </Box>
    </Box>
  );
};
