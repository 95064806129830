import { forwardRef } from "react";
import { Box, styled } from "@mui/material";
import { InitiativeCard, InitiativeCardSkeleton } from "..";
import { InitiativeData } from "@/interface";
import { useNavigate } from "react-router-dom";
import { Components, VirtuosoGrid } from "react-virtuoso";
import { ROUTES } from "@/config";
import { Empty } from "./Empty";

type Props = {
  initiatives: InitiativeData[];
  isFiltered?: boolean;
  isLoading?: boolean;
  loadMore: () => void;
};

const responsive = (width: string) => {
  return {
    flexBasis: width,
    maxWidth: width,
  };
};

const ListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "start",
  alignContent: "start",
  gap: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    "& > div": {
      ...responsive("calc(25% - 16px)"),
    },
  },
  [theme.breakpoints.between("md", "lg")]: {
    "& > div": {
      ...responsive("calc(33.33% - 16px)"),
    },
  },
  [theme.breakpoints.between("sm", "md")]: {
    "& > div": {
      ...responsive("calc(50% - 16px)"),
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& > div": {
      ...responsive("calc(100% - 16px)"),
    },
  },
}));

const List: Components["List"] = forwardRef(({ style, children }, listRef) => {
  return (
    <ListContainer
      style={{ padding: 0, ...style, margin: 0 }}
      component="div"
      ref={listRef}
    >
      {children}
    </ListContainer>
  );
});

export const InitiativeList = ({
  initiatives,
  isFiltered,
  isLoading,
  loadMore,
}: Props) => {
  const navigate = useNavigate();

  if (!isLoading && isFiltered && initiatives.length === 0) return <Empty />;

  return (
    <VirtuosoGrid
      useWindowScroll
      totalCount={initiatives.length}
      overscan={10}
      components={{
        List,
        Footer: () =>
          isLoading ? (
            <ListContainer
              sx={{
                pt: initiatives.length > 0 ? 2 : 0,
              }}
            >
              {[...Array(4)].map((_, index) => (
                <Box key={index}>
                  <InitiativeCardSkeleton />
                </Box>
              ))}
            </ListContainer>
          ) : null,
      }}
      itemContent={(index) => (
        <Box key={initiatives[index].id}>
          <InitiativeCard
            data={initiatives[index]}
            onClick={() =>
              navigate(`${ROUTES.initiative}/${initiatives[index].id}`)
            }
          />
        </Box>
      )}
      endReached={loadMore}
    />
  );
};
