import { API_ENDPOINTS, fetcher } from "@/config";
import { SparxCycle } from "@/interface";
import { useUserState } from "@/state";
import useSWR from "swr";

export const useCycleBySymbol = (symbol: string, skip: boolean) => {
  const { authToken } = useUserState();

  const { data, isLoading, error } = useSWR<{ cycle: SparxCycle }>(
    skip ? null : `${API_ENDPOINTS.tokens.cycles}/${symbol}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );
  return {
    cycle: data?.cycle,
    isLoading,
    error,
  };
};
