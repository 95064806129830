import { TableCell, TableRow, Skeleton, Stack } from "@mui/material";

export const TableLoadingSkeleton = ({ rowsNum }: {rowsNum: number}) => {
  return [...Array(rowsNum)].map((_, index) => (
    <TableRow 
      key={index}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
      })}
    >
      <TableCell>
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rounded" height={24} width={"50%"}/>
        </Stack>
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" height={24} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" height={24} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" height={24} />
      </TableCell>
      <TableCell />
    </TableRow>
  ));
};