import { useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { ContributionCategory } from "./Category";
import { Category } from "@/interface";
import { RegionSelector } from "./RegionSelector";
import { ContributionEditor } from "@/pages/common/editor/ContributionEditor";
import { EditorSaveButton } from "@/pages/common/EditorSaveButton";
import { CustomDialog } from "@/components";

type Props = {
  open: boolean;
  onClose: () => void;
  mutate: () => void;
};

export const ExternalContributionModal = ({ open, onClose, mutate }: Props) => {
  const [region, setRegion] = useState("global");
  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState<Category[] | undefined>(
    undefined
  );
  const [content, setContent] = useState("");

  return (
    <CustomDialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            px: 1,
          },
        },
      }}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle textAlign="center">External contribution</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          textAlign="center"
          color="text.secondary"
          mb={2}
        >
          Support for non-client initiatives and events outside the platform.
        </DialogContentText>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <RegionSelector value={region} setRegion={setRegion} />
          <ContributionCategory
            categoryId={categoryId}
            subCategory={subCategory}
            setCategory={setCategoryId}
            setSubCategory={setSubCategory}
          />
        </Stack>
        <ContributionEditor
          title="External contribution"
          onEditorChange={(html) => setContent(html)}
          sx={{ background: (theme) => theme.palette.background.default }}
        >
          <Box display="flex" gap={1}>
            <Button onClick={onClose} variant="outlined" color="secondary">
              Cancel
            </Button>
            <EditorSaveButton
              caption="Add External contribution"
              content={content}
              categoryId={categoryId}
              subCategoryIds={
                subCategory?.map((sub) => String(sub.id)) ?? undefined
              }
              region={region}
              onComplete={() => {
                setContent("");
                mutate();
                onClose();
              }}
            />
          </Box>
        </ContributionEditor>
      </DialogContent>
    </CustomDialog>
  );
};
