import { Box, Chip, Typography } from "@mui/material";

type Props = {
  total?: number;
};

export const Header = ({ total }: Props) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h5">Shout-outs</Typography>
      {total && (
        <Chip
          size="small"
          label={<Typography>{total}</Typography>}
          sx={(theme) => ({
            borderRadius: theme.shape,
          })}
        />
      )}
    </Box>
  );
};
